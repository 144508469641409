import './index.css';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { IEditGroupFormData, IGroup } from '../../../../types/groups';
import { GroupsRepository } from '../../../../api/groups/repository';
import { GroupsService } from '../../../../api/groups/service';
import { Dialog, FormElement, Toast } from '@cycling-web/analog-ui';
import { useGroupsStore } from '../../../../store/groups/slice';
import { InputControl, TextareaControl } from '@cycling-web/common';
import { DEFAULT_GROUP } from '../../context';
import { MultipleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../../constants';

type IProps = {
  group: IGroup;
  onDismiss: () => void;
};

export const EditGroupFormModal = ({ group, onDismiss }: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const upsertGroup = useGroupsStore((s) => s.upsertGroup);

  const groupsRepository = useMemo(() => {
    return new GroupsRepository(new GroupsService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        name: z.string().min(1, { message: t('validation.required') }),
        athleteIds: z
          .array(z.number())
          .min(1, { message: t('validation.required') }),
      })
      .passthrough();
  }, [t]);

  const form = useForm<IEditGroupFormData>({
    defaultValues: {
      id: group.id,
      name: group.name,
      description: group.description,
      athleteIds: group.athleteIds,
      createdAt: group.createdAt || new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    },
    resolver: zodResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = form;
  const athleteIds = watch('athleteIds');

  const onAthleteChange = useCallback(
    (values: number[]) => {
      setValue('athleteIds', values);
    },
    [setValue]
  );

  const createGroup = useCallback(
    (formData: IEditGroupFormData) => {
      setLoading(true);
      groupsRepository
        .createGroup(formData)
        .then((group: IGroup) => {
          upsertGroup(group);
          onDismiss();
        })
        .catch((error: AxiosError) => {
          if (error?.response?.status !== ApiErrorCode.Unauthorized) {
            Toast.error(
              {
                title: t('error.create_group_title'),
                message: t('error.create_group_message'),
              },
              { toastId: 'create_group' }
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [groupsRepository, upsertGroup, t, onDismiss]
  );

  const updateGroup = useCallback(
    (formData: IEditGroupFormData) => {
      setLoading(true);
      groupsRepository
        .updateGroup(formData)
        .then(() => {
          upsertGroup(formData);
          onDismiss();
        })
        .catch((error: AxiosError) => {
          if (error?.response?.status !== ApiErrorCode.Unauthorized) {
            Toast.error(
              {
                title: t('error.update_group_title'),
                message: t('error.update_group_message'),
              },
              { toastId: 'update_group' }
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [groupsRepository, upsertGroup, t, onDismiss]
  );

  const onSubmit = useCallback(() => {
    handleSubmit(
      (formData: IEditGroupFormData) => {
        if (group.id !== DEFAULT_GROUP.id) {
          updateGroup({
            ...formData,
            id: group.id,
          });
        } else {
          createGroup(formData);
        }
      },
      (errors) => {
        console.log(errors);
      }
    )();
  }, [handleSubmit, group, updateGroup, createGroup]);

  return (
    <FormProvider {...form}>
      <Dialog
        title={group ? t('label.edit_group') : t('label.create_new_group')}
        onDismiss={onDismiss}
        outsidePress={false}
        submitButtonProps={{
          content: group ? t('action.save') : t('action.create'),
          onClick: onSubmit,
          loading: loading,
        }}
      >
        <div className="edit-group-form-modal">
          <FormElement
            label={t('label.group_name')}
            message={errors.name?.message}
          >
            <InputControl
              name="name"
              placeholder={t('placeholder.group_name')}
              invalid={!!errors.name}
            />
          </FormElement>

          <FormElement
            label={t('label.group_description')}
            message={errors.name?.message}
          >
            <TextareaControl
              name="description"
              placeholder={t('placeholder.group_description')}
              maxLength={999}
              invalid={!!errors.name}
            />
          </FormElement>

          <FormElement
            label={t('label.athletes')}
            message={errors.athleteIds?.message}
          >
            <MultipleAthleteFilter
              value={athleteIds}
              onChange={onAthleteChange}
              style={{
                minWidth: '100%',
                maxWidth: '100%',
              }}
              dropdownProps={{ minWidth: 'auto' }}
              selectProps={{ invalid: !!errors.athleteIds }}
            />
          </FormElement>
        </div>
      </Dialog>
    </FormProvider>
  );
};
