import { ISelectOption } from '@cycling-web/analog-ui';
import { useMemo } from 'react';
import { useGroupsStore } from '../../../store/groups/slice';
import { IGroup } from '../../../types/groups';
import { IBaseGroupFilterProps } from './types';

export const useViewModel = (props: IBaseGroupFilterProps) => {
  const groups = useGroupsStore((s) => s.groups);
  const groupsLoaded = useGroupsStore((s) => s.groupsLoaded);

  const options: ISelectOption[] = useMemo(() => {
    if (props.options) {
      return props.options;
    }

    return groups
      .filter((a: IGroup) => a.id !== undefined)
      .map((a: IGroup) => {
        return {
          id: a.id.toString(),
          text: a.name,
        };
      });
  }, [props.options, groups]);

  return {
    options,
    loaded: groupsLoaded,
  };
};
