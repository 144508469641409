import './index.css';
import { useEffect, useMemo, useRef } from 'react';
import { useSmartChartsStore } from '../../store/slice';
import { ISmartChartTurn } from '../../../../types/smart-charts';
import { MessageLoading } from '../Message/MessageLoading';
import { Message } from '../Message';
import { Suggestions } from '../Suggestions';
import { useSmartChartsContext } from '../../context';

export const ChatBody = () => {
  const config = useSmartChartsStore((s) => s.config);
  const processingBufferMessage = useSmartChartsStore(
    (s) => s.processingBufferMessage
  );
  const { expanded } = useSmartChartsContext();

  const messages = useMemo(() => {
    const messages = config?.conversation_turns.turns || [];
    const sorted = [...messages];
    sorted.sort((a, b) => {
      return (
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    });

    return sorted;
  }, [config?.conversation_turns.turns]);

  const messagesScrollableElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      const el = messagesScrollableElementRef.current;
      if (el) {
        el.scrollTo(0, el.scrollHeight);
      }
    });
  }, [messages, processingBufferMessage, expanded]);

  const messagesJSX = messages.map((message: ISmartChartTurn, i: number) => {
    return <Message key={i} message={message} />;
  });

  return (
    <div className="sc2__chat-body" ref={messagesScrollableElementRef}>
      {messagesJSX}
      <MessageLoading />
      <Suggestions />
    </div>
  );
};
