import { useEffect, useMemo, useRef } from 'react';
import { IPerformanceReport } from '../../../types/performance';
import { Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { GroupsRepository } from '../../../api/groups/repository';
import { GroupsService } from '../../../api/groups/service';
import { useParams } from 'react-router';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { useGroupReportsStore } from '../store/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchGroupReports = () => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const filters = useGroupReportsStore((s) => s.filters);
  const setReports = useGroupReportsStore((s) => s.setReports);
  const setReportsLoaded = useGroupReportsStore((s) => s.setReportsLoaded);
  const fetchingRef = useRef<boolean>(false);

  const groupsRepository = useMemo(() => {
    return new GroupsRepository(new GroupsService());
  }, []);

  useEffect(() => {
    if (fetchingRef.current || !groupId) {
      return;
    }

    const { startDate, endDate } = calculateDateRange(filters.period);

    fetchingRef.current = true;
    groupsRepository
      .getGroupReport({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        groupId: +groupId,
      })
      .then((reports: IPerformanceReport[]) => {
        setReports(reports);
      })
      .catch((error: AxiosError) => {
        setReportsLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_group_report_title'),
              message: t('error.get_group_report_message'),
            },
            { toastId: 'get_group_report' }
          );
        }
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [
    filters.period,
    groupId,
    groupsRepository,
    setReports,
    setReportsLoaded,
    t,
  ]);
};
