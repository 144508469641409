export function getPaginationValues(count: number, page: number): string[] {
  const MAX = 7;
  const DOTS = '...';
  const result: string[] = [];

  if (count <= MAX) {
    for (let i = 1; i <= count; i++) {
      result.push(i.toString());
    }

    return result;
  }

  if (page <= 4) {
    return ['1', '2', '3', '4', '5', DOTS, count.toString()];
  }

  if (page >= count - 3) {
    return [
      '1',
      DOTS,
      (count - 4).toString(),
      (count - 3).toString(),
      (count - 2).toString(),
      (count - 1).toString(),
      count.toString(),
    ];
  }

  return [
    '1',
    DOTS,
    (page - 1).toString(),
    page.toString(),
    (page + 1).toString(),
    DOTS,
    count.toString(),
  ];
}
