import {
  IGetAnthropometryRequest,
  IGetAnthropometryResponse,
  IGetStaffProfileResponse,
  ISaveFeedbackRequest,
  ISaveFeedbackResponse,
  ISetAnthropometryRequest,
  ISetAnthropometryResponse,
  ISetDateOfNextSFTRequest,
  ISetDateOfNextSFTResponse,
  ISetTargetPowerOfSFTRequest,
  ISetTargetPowerOfSFTResponse,
  IUpdateStaffRequest,
  IUpdateStaffResponse,
} from './types';
import { axiosInstance } from '../../axios-instance';
import qs from 'qs';
import { R } from '../../types/common';

interface ICoachService {
  getAnthropometry: (
    p: IGetAnthropometryRequest
  ) => R<IGetAnthropometryResponse>;
  setAnthropometry: (
    p: ISetAnthropometryRequest
  ) => Promise<ISetAnthropometryResponse>;
  setDateOfNextSFT: (
    p: ISetDateOfNextSFTRequest
  ) => Promise<ISetDateOfNextSFTResponse>;
  setTargetPowerOfSFT: (
    p: ISetTargetPowerOfSFTRequest
  ) => Promise<ISetTargetPowerOfSFTResponse>;

  updateProfile: (p: IUpdateStaffRequest) => R<IUpdateStaffResponse>;
  getProfile: () => R<IGetStaffProfileResponse>;

  saveFeedback: (p: ISaveFeedbackRequest) => R<ISaveFeedbackResponse>;
}

export class CoachService implements ICoachService {
  async getAnthropometry(
    p: IGetAnthropometryRequest
  ): R<IGetAnthropometryResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.get(`/v1/coach/anthros?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async setAnthropometry(
    p: ISetAnthropometryRequest
  ): Promise<ISetAnthropometryResponse> {
    try {
      return axiosInstance.post(`/v1/coach/input/anthro`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async setDateOfNextSFT(
    p: ISetDateOfNextSFTRequest
  ): Promise<ISetDateOfNextSFTResponse> {
    try {
      return axiosInstance.post(`/v1/coach/input/dateOfNextSft`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async setTargetPowerOfSFT(
    p: ISetTargetPowerOfSFTRequest
  ): Promise<ISetTargetPowerOfSFTResponse> {
    try {
      return axiosInstance.post(`/v1/coach/input/targetPowerOfSFT`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateProfile(p: IUpdateStaffRequest): R<IUpdateStaffResponse> {
    try {
      return axiosInstance.put(`/v1/coach/updateProfile`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getProfile(): R<IGetStaffProfileResponse> {
    try {
      return axiosInstance.get(`/v1/coach/profile`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async saveFeedback(p: ISaveFeedbackRequest): R<ISaveFeedbackResponse> {
    try {
      return axiosInstance.post(`/v1/coach/saveFeedback`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
