import './index.css';
import { Dialog, Table } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useViewModel } from './useViewModel';
import { IAthlete } from '../../../../types/athletes';

type IProps = {
  athletes: IAthlete[];
  onDismiss: () => void;
};

export const CheckInSummaryModal = ({ onDismiss, athletes }: IProps) => {
  const { t } = useTranslation();
  const { columns, items, onRenderCell, onRowClick, emptyStateProps } =
    useViewModel({
      athletes,
    });

  return (
    <Dialog
      className="check-summary-modal"
      onDismiss={onDismiss}
      outsidePress={false}
      title={t('label.check_in')}
      onRenderFooter={() => null}
    >
      <Table
        className="checkin-summary-table"
        columns={columns}
        items={items}
        onRenderCell={onRenderCell}
        onRowClick={onRowClick}
        emptyState={emptyStateProps}
      />
    </Dialog>
  );
};
