import './index.css';
import { Tooltip, Typography } from '@cycling-web/analog-ui';
import { IAthlete } from '../../../../../../types/athletes';
import { HrvRhrUtils } from '../../../../../../utils/hrv-rhr';

type IProps = {
  athlete: IAthlete;
};

export const HRVRHRFeedback = ({ athlete }: IProps) => {
  const hrvVariance = athlete?.hrvVariance || 0;
  const rhrVariance = athlete?.rhrVariance || 0;

  const status = HrvRhrUtils.getStatus({ rhrVariance, hrvVariance });

  if (!status) {
    return (
      <div className="hrv-rhr-feedback__toggle">
        <div className="hrv-rhr-feedback__toggle-bar default-color" />
      </div>
    );
  }

  const content = HrvRhrUtils.getContent(status);

  return (
    <Tooltip
      className="hrv-rhr-feedback__toggle"
      anchor={
        <div
          className="hrv-rhr-feedback__toggle-bar"
          style={{ background: content.color }}
        />
      }
      content={
        <Typography className="hrv-rhr-feedback__content" text={content.text} />
      }
      maxWidth="400px"
      placement="top"
    />
  );
};
