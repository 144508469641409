import { useBoolean } from '@cycling-web/analog-ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IAthlete } from '../../types/athletes';
import { ISmartChartsContext } from './types';
import { useTitle } from '../../hooks/useTitle';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useFetchHistory } from './hooks/useFetchHistory';
import { useFetchConversation } from './hooks/useFetchConversation';
import { ROUTES } from '../../router/routes';
import { useSmartChartsStore } from './store/slice';
import { useAthletesStore } from '../../store/athletes/slice';
import {
  ISmartChartConfig,
  ISmartChartConfigBase,
  ISmartChartTurn,
  SmartChartActor,
} from '../../types/smart-charts';
import { useUsersStore } from '../../store/users/slice';
import { SmartChartsRepository } from '../../api/smart-charts2/repository';
import { SmartChartsService } from '../../api/smart-charts2/service';
import { format } from 'date-fns';
import { useUpdateConfig } from './hooks/useUpdateConfig';
import { useUserRole } from '../../hooks/useUserRole';

export const useViewModel = () => {
  const { chartId } = useParams();
  /** When new message is sent and there is no conversation yet, this flag prevents
   * fetching config and compute data */
  const isNewConversation = useRef<boolean>(false);
  const conversationBuffer = useRef<ISmartChartTurn[]>([]);

  const [expanded, setExpanded] = useState<boolean>(false);

  useFetchHistory();
  useFetchConversation({
    isNewConversation,
    conversationBuffer,
  });
  const { updateConfig } = useUpdateConfig();
  const { isAthlete } = useUserRole();
  const baseUrl: string = useMemo((): string => {
    const pathname = window.location.pathname.split('/');
    if (pathname.at(-1) !== ROUTES.SMART_CHARTS) {
      pathname.pop();
    }
    return pathname.join('/');
  }, []);

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  const { t } = useTranslation();
  useTitle(t('label.smart_charts'));
  const navigate = useNavigate();
  const stableNavigateRef = useRef(navigate);

  const userProfile = useUsersStore((s) => s.userProfile);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);

  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const setProcessingBufferMessage = useSmartChartsStore(
    (s) => s.setProcessingBufferMessage
  );
  const config = useSmartChartsStore((s) => s.config);
  const datasource_group_specific =
    config?.ai_graph_in_scope?.data_sources?.datasource_group_specific;

  const {
    value: showHistory,
    setTrue: openHistory,
    setFalse: dismissHistory,
  } = useBoolean(false);

  const {
    value: showAthletesModal,
    setTrue: openAthletesModal,
    setFalse: dismissAthletesModal,
  } = useBoolean(false);

  const selectedAthletes = useSmartChartsStore((s) => s.selectedAthletes);
  const setSelectedAthletes = useSmartChartsStore((s) => s.setSelectedAthletes);
  const athletes = useAthletesStore((s) => s.athletes);

  useEffect(() => {
    if (isAthlete) {
      setSelectedAthletes(athletes);
    }
  }, [athletes, isAthlete, setSelectedAthletes]);

  useEffect(() => {
    if (!athletesLoaded || !datasource_group_specific) {
      return;
    }

    const selectedAthletes: IAthlete[] = [];
    const values = Object.values(datasource_group_specific);

    for (let i = 0; i < values.length; i++) {
      const athleteMeta = values[i][0].metadata;
      const athlete = athletesMap[athleteMeta.athlete_id];
      selectedAthletes.push(athlete);
    }
    setSelectedAthletes(selectedAthletes);
  }, [
    athletesLoaded,
    athletesMap,
    datasource_group_specific,
    setSelectedAthletes,
  ]);

  const onSelectAthletes = useCallback(
    (athletes: IAthlete[]) => {
      function areArraysEqual(arr1: IAthlete[], arr2: IAthlete[]): boolean {
        if (arr1.length !== arr2.length) {
          return false;
        }

        const sortedArr1 = [...arr1].sort((a, b) => a.id - b.id);
        const sortedArr2 = [...arr2].sort((a, b) => a.id - b.id);

        return sortedArr1.every(
          (item, index) => item.id === sortedArr2[index].id
        );
      }

      setSelectedAthletes(athletes);

      if (!areArraysEqual(selectedAthletes, athletes)) {
        updateConfig(athletes)
          .then(() => {
            useSmartChartsStore.getState().reloadChart();
          })
          .catch((e) => {
            console.log(e);
          });
      }

      dismissAthletesModal();
    },
    [selectedAthletes, setSelectedAthletes, dismissAthletesModal, updateConfig]
  );

  const startNewSession = useCallback(() => {
    setExpanded(false);
    useSmartChartsStore.getState().startConversation();
    stableNavigateRef.current(`${baseUrl}`);
  }, [baseUrl]);

  const handleChunkReceive = useCallback(
    (chunk: string) => {
      const lines = chunk.split('\n');

      lines.forEach((line) => {
        if (line.startsWith('data: ')) {
          const jsonData = line.substring(6);

          try {
            const parsedChunk = JSON.parse(jsonData) as ISmartChartTurn;
            setProcessingBufferMessage(parsedChunk);

            if (parsedChunk.message) {
              conversationBuffer.current.push(parsedChunk);
            }
          } catch (err) {
            console.error('Failed to parse JSON:', err);
          }
        }
      });
    },
    [setProcessingBufferMessage]
  );

  const applyMessagesFromBuffer = useCallback(() => {
    if (conversationBuffer.current.length > 0) {
      useSmartChartsStore.getState().appendMessages(conversationBuffer.current);
      conversationBuffer.current = [];
    }
  }, []);

  const sendMessage = useCallback(
    (value: string) => {
      if (value.trim() === '' || !userProfile) {
        return;
      }

      const userMessage: ISmartChartTurn = {
        id: `${Date.now()}`,
        actor: SmartChartActor.User,
        actor_user_id: userProfile.id,
        created_at: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"),
        is_in_progress: false,
        message: value,
        next_suggestions: [],
        generated_diff_message: null,
        turn_subprocesses: [],
        ai_graph_snapshot_data: null,
      };
      conversationBuffer.current.push(userMessage);

      const fetchConversation = chartId
        ? Promise.resolve(chartId)
        : smartChartsRepository
            .createConversation(selectedAthletes)
            .then((conversation: ISmartChartConfigBase) => {
              useSmartChartsStore.getState().appendHistory(conversation);
              isNewConversation.current = true;
              stableNavigateRef.current(`${baseUrl}/${conversation.id}`);
              return conversation.id;
            });

      let _chartId: string = chartId || '';
      fetchConversation
        .then((id: string) => {
          _chartId = id;
          if (!isNewConversation.current) {
            applyMessagesFromBuffer();
          }
          return smartChartsRepository.sendMessage({
            graphId: id,
            query: value,
            onChunkReceive: handleChunkReceive,
          });
        })
        .then(() => {
          applyMessagesFromBuffer();
          smartChartsRepository
            .getComputeData({
              graphId: _chartId,
            })
            .then((config: ISmartChartConfig) => {
              useSmartChartsStore.getState().updateHistory({
                id: config.id,
                ai_graph_meta_data: config.ai_graph_meta_data,
              });
              useSmartChartsStore.getState().setConfig(config);
            });
        })
        .finally(() => {
          setProcessingBufferMessage(undefined);
        });
    },
    [
      applyMessagesFromBuffer,
      baseUrl,
      chartId,
      handleChunkReceive,
      selectedAthletes,
      setProcessingBufferMessage,
      smartChartsRepository,
      userProfile,
    ]
  );

  useEffect(() => {
    return () => {
      useSmartChartsStore.getState().reset();
    };
  }, []);

  const context: ISmartChartsContext = useMemo((): ISmartChartsContext => {
    return {
      showAthletesModal,
      openAthletesModal,
      dismissAthletesModal,
      onSelectAthletes,
      selectedAthletes,
      startNewSession,
      showHistory,
      openHistory,
      dismissHistory,
      sendMessage,
      baseUrl,
      expanded,
      setExpanded,
    };
  }, [
    dismissAthletesModal,
    onSelectAthletes,
    openAthletesModal,
    showAthletesModal,
    selectedAthletes,
    startNewSession,
    showHistory,
    openHistory,
    dismissHistory,
    sendMessage,
    baseUrl,
    expanded,
    setExpanded,
  ]);

  return {
    context,
  };
};
