import './index.css';
import { Button, LabelValue } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { PencilLine } from 'lucide-react';
import { ProfileDetailsFormModal } from '../ProfileDetailsFormModal';
import { useViewModel } from './useViewModel';

export const ProfileDetails = () => {
  const { t } = useTranslation();
  const {
    userProfile,
    showProfileDetailsModal,
    openProfileDetailsModal,
    dismissProfileDetailsModal,
    phone,
  } = useViewModel();

  return (
    <div className="settings-profile__details">
      <LabelValue
        direction="column"
        label={t('label.name')}
        value={userProfile?.fullName}
      />

      <LabelValue
        direction="column"
        label={t('label.email')}
        value={userProfile?.email}
      />

      <LabelValue
        direction="column"
        label={t('label.phone_number')}
        value={phone}
      />

      <div className="settings-profile__details-actions">
        <Button
          variant="quiet"
          startIcon={<PencilLine />}
          content={t('action.edit')}
          onClick={openProfileDetailsModal}
        />
      </div>
      {showProfileDetailsModal && (
        <ProfileDetailsFormModal onDismiss={dismissProfileDetailsModal} />
      )}
    </div>
  );
};
