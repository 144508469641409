import { FormElement, Skeleton } from '@cycling-web/analog-ui';
import { FormColumn, FormRow } from '@cycling-web/common';
import { useTranslation } from 'react-i18next';

export const PersonalInfoFormLoading = () => {
  const { t } = useTranslation();
  return (
    <div className="onboarding__personal-form">
      <FormRow>
        <Skeleton width="72px" height="72px" />
      </FormRow>

      <FormElement label={t('label.name')}>
        <Skeleton width="100%" height="40px" />
      </FormElement>

      <FormRow>
        <FormColumn>
          <FormElement label={t('label.phone_number')}>
            <Skeleton width="100%" height="40px" />
          </FormElement>
        </FormColumn>
        <FormColumn>
          <FormElement label={t('label.nationality')}>
            <Skeleton width="100%" height="40px" />
          </FormElement>
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FormElement label={t('label.gender')}>
            <Skeleton width="100%" height="40px" />
          </FormElement>
        </FormColumn>
        <FormColumn>
          <FormElement label={t('label.timezone')}>
            <Skeleton width="100%" height="40px" />
          </FormElement>
        </FormColumn>
      </FormRow>
    </div>
  );
};
