import './index.css';
import { Button, Typography } from '@cycling-web/analog-ui';
import { Trans, useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../router/routes';
import { useCookieContext } from '../../context/cookieContext';
import { useNavigate } from 'react-router';
import { useCallback } from 'react';

export const Cookies = () => {
  const { t } = useTranslation();
  const context = useCookieContext();
  const navigate = useNavigate();
  const redirectToNotice = useCallback(() => {
    navigate(`/${ROUTES.LEGAL}/${ROUTES.COOKIES_NOTICE}`);
  }, [navigate]);

  if (window.location.pathname.includes(ROUTES.SIGN_OUT)) {
    return null;
  }

  return (
    <div className="cookies">
      <Typography
        variant="h3"
        weight="bold"
        text={t('legal.cookies_banner_title')}
      />
      <Typography
        variant="body"
        weight="regular"
        text={t('legal.cookies_banner_text_1')}
      />
      <Typography
        variant="body"
        weight="regular"
        text={
          <Trans i18nKey="legal.cookies_banner_text_2">
            You can find out more about our use of cookies at our
            <a
              href={`/${ROUTES.LEGAL}/${ROUTES.COOKIES_NOTICE}`}
              target="_blank"
              className="cookies__link"
              rel="noreferrer"
            >
              Cookies Notice
            </a>
            , and about our data privacy measures at our
            <a
              href={`/${ROUTES.LEGAL}/${ROUTES.PRIVACY_NOTICE}`}
              target="_blank"
              className="cookies__link"
              rel="noreferrer"
            >
              Privacy Notice
            </a>
            .
          </Trans>
        }
      />
      <footer className="cookies-actions">
        <Button
          variant="quiet"
          content={t('legal.manage_cookies')}
          onClick={redirectToNotice}
        />
        <Button
          variant="secondary"
          content={t('action.reject_all')}
          onClick={context.onRejectAll}
        />
        <Button
          content={t('action.accept_all')}
          onClick={context.onAcceptAll}
        />
      </footer>
    </div>
  );
};
