import './index.css';
import { EMPTY } from '../../../constants';
import { ITagProps, TruncatedTags } from '@cycling-web/analog-ui';
import { UserRole } from '@cycling-web/common';

type IProps = {
  role: UserRole[];
  tagProps?: Partial<ITagProps>;
};

export const RoleTag = ({ role, tagProps }: IProps) => {
  if (!role) {
    return EMPTY;
  }

  const items: ITagProps[] = role.map((role) => {
    return {
      id: role,
      text: role.toLowerCase(),
    };
  });

  return (
    <TruncatedTags className="user-roles" items={items} tagProps={tagProps} />
  );
};
