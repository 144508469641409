import { ISelectContext, ISelectOption, ISelectProps } from './types';
import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { useControlledValue } from '../../hooks/useControlledValue';

export const useViewModel = (props: ISelectProps): ISelectContext => {
  const { value, onClear, dropdownProps, disabled }: ISelectProps = props;

  const localValue: ISelectOption[] = useMemo((): ISelectOption[] => {
    if (Array.isArray(value)) {
      return value;
    }

    return value ? [value] : [];
  }, [value]);

  const { value: isOpen, onChange: setIsOpen } = useControlledValue<boolean>({
    controlledValue: dropdownProps?.isOpen,
    controlledOnChange: dropdownProps?.setIsOpen,
  });

  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled, setIsOpen]);

  const toggleOpen = useCallback(() => {
    if (disabled) {
      return;
    }
    setIsOpen(!isOpen);
  }, [disabled, setIsOpen, isOpen]);

  const handleClear = onClear
    ? (e?: MouseEvent): void => {
        e?.stopPropagation();
        onClear();
      }
    : undefined;

  return {
    ...props,
    isOpen: isOpen ?? false,
    setIsOpen,
    toggleOpen,
    localValue,
    handleClear,
    dropdownProps: {
      ...props.dropdownProps,
      isOpen: isOpen || false,
    },
    onLazyLoad: props.onLazyLoad,
  };
};
