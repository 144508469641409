import type { ITruncatedTagsProps } from './types';
import type { ITagProps } from '../Tag/types';
import type { IPopoverProps } from '../Popover/types';
import {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './index.css';
import { Tag } from '../Tag';
import { Popover } from '../Popover';
import { clsx } from 'clsx';

export const TruncatedTags = ({
  items,
  disabled,
  onRemove,
  onClick,
  tagProps,
  popoverProps,
  className,
}: ITruncatedTagsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tagRefs = useRef<RefObject<HTMLDivElement | null>[]>([]);

  const commonTagProps: Partial<ITagProps> = useMemo(() => {
    return {
      startIcon: tagProps?.startIcon,
      size: tagProps?.size || 'm',
      variant: tagProps?.variant || 'neutral',
      shape: tagProps?.shape || 'rounded',
      className: tagProps?.className,
    };
  }, [tagProps]);

  const localPopoverProps: Partial<IPopoverProps> = useMemo(() => {
    return {
      placement: popoverProps?.placement || 'bottom-end',
      maxWidth: popoverProps?.maxWidth || '',
      minWidth:
        popoverProps?.minWidth ||
        (containerRef.current
          ? `${containerRef.current?.offsetWidth}px`
          : undefined),
      minHeight: popoverProps?.minHeight || '',
      maxHeight: popoverProps?.maxHeight || '',
      portal: popoverProps?.portal || true,
      className: popoverProps?.className,
    };
  }, [popoverProps]);

  const [visibleIndexMap, setVisibleIndexMap] = useState<
    Record<number, boolean>
  >({});
  const [hiddenTagsCount, setHiddenTagsCount] = useState<number>(0);

  const [isOpenTagsPopover, setIsOpenTagsPopover] = useState<boolean>(false);

  const openTagsPopover = useCallback(() => {
    setIsOpenTagsPopover(true);
  }, []);

  const handleRemove = useCallback(
    (item: ITagProps) => {
      if (!onRemove || disabled) {
        return undefined;
      }

      return (): void => {
        onRemove(item);
      };
    },
    [onRemove, disabled]
  );

  const handleClick = useCallback(
    (item: ITagProps) => {
      if (!onClick || disabled) {
        return undefined;
      }

      return (): void => {
        onClick(item);
      };
    },
    [onClick, disabled]
  );

  const tagsJSX = items.map((item: ITagProps, index: number) => {
    if (!tagRefs.current[index]) {
      tagRefs.current[index] = createRef();
    }

    const rootClass: string = clsx(
      'analog-truncated-tags__tag-wrapper',
      !visibleIndexMap[index] && 'analog-truncated-tags__tag-wrapper--hidden'
    );

    const key = item.id || (item.text ? item.text.toString() : index);

    return (
      <div key={key} className={rootClass} ref={tagRefs.current[index]}>
        <Tag
          {...item}
          variant={item.variant || commonTagProps.variant}
          size={item.size || commonTagProps.size}
          shape={item.shape || commonTagProps.shape}
          onRemove={handleRemove(item)}
          onClick={handleClick(item)}
          text={item.text}
        />
      </div>
    );
  });

  useEffect(() => {
    if (!containerRef?.current || !tagRefs.current) {
      return;
    }

    const observer = new ResizeObserver(() => {
      if (!containerRef?.current || !tagRefs.current) {
        return;
      }

      const GAP = 4;
      const COUNT_TAG_WIDTH = commonTagProps.size === 'm' ? 32 : 24;

      const availableWidth = containerRef.current.offsetWidth - COUNT_TAG_WIDTH;

      let totalWidth = 0;
      const visibleIndexMap: Record<number, boolean> = {};
      let hiddenTags = 0;

      for (let i = 0; i < tagRefs.current.length; i++) {
        const currentTag: HTMLDivElement | null = tagRefs.current[i].current;

        if (!currentTag) {
          continue;
        }

        totalWidth +=
          (tagRefs.current[i].current as HTMLDivElement).offsetWidth + GAP;

        if (totalWidth < availableWidth) {
          visibleIndexMap[i] = true;
        } else {
          hiddenTags++;
        }
      }

      if (hiddenTags === 0) {
        setIsOpenTagsPopover(false);
      }

      setVisibleIndexMap(visibleIndexMap);
      setHiddenTagsCount(hiddenTags);
    });

    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [containerRef, tagRefs, items, commonTagProps.size]);

  const hiddenTagsJSX = items
    .filter((_: ITagProps, i: number) => !visibleIndexMap[i])
    .map((item: ITagProps, i: number) => {
      const key = item.id || (item.text ? item.text.toString() : i);
      return (
        <Tag
          {...item}
          key={key}
          variant={item.variant || commonTagProps.variant}
          size={item.size || commonTagProps.size}
          shape={item.shape || commonTagProps.shape}
          onRemove={handleRemove(item)}
          onClick={handleClick(item)}
          text={item.text}
        />
      );
    });

  const rootClass: string = clsx('analog-truncated-tags', className);

  return (
    <div className={rootClass} ref={containerRef}>
      {tagsJSX}
      {hiddenTagsCount > 0 && (
        <div className="analog-truncated-tags__tagCount">
          <Popover
            {...localPopoverProps}
            content={
              <div className="analog-truncated-tags__valueHiddenTags">
                {hiddenTagsJSX}
              </div>
            }
            isOpen={isOpenTagsPopover}
            setIsOpen={setIsOpenTagsPopover}
            anchor={
              <Tag
                {...commonTagProps}
                onClick={openTagsPopover}
                text={`+${hiddenTagsCount}`}
              />
            }
            delay={{
              open: 200,
              close: 0,
            }}
          />
        </div>
      )}
    </div>
  );
};
