import { useTrainingLoadStore } from '../store/slice';
import { useCallback } from 'react';
import { ITrainingLoadFilters } from '../types';
import { AnyValue } from '../../../types/common';
import { IHandleChangeFilter } from '@cycling-web/common';

type IUseStateFilters = {
  filters: ITrainingLoadFilters;
  setFilters: (filters: Partial<ITrainingLoadFilters>) => void;
  handleChangeFilter: IHandleChangeFilter<ITrainingLoadFilters>;
};

export const useStateFilters = (): IUseStateFilters => {
  const filters = useTrainingLoadStore((s) => s.filters);
  const setFilters = useTrainingLoadStore((s) => s.setFilters);

  const handleChangeFilter = useCallback(
    (key: keyof ITrainingLoadFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  );

  return {
    filters,
    setFilters,
    handleChangeFilter,
  };
};
