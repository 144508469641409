import './index.css';
import AnalogSportLogoDark from '../../assets/illustrations/analog-sport-logo-lg.svg?react';
import { ReactNode, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useIsAuthenticated } from '@azure/msal-react';
import { ROUTES } from '../../router/routes';
import { LS } from '../../constants';
import { useAppStore } from '../../store/app/slice';

type IProps = {
  children: ReactNode;
};

export const Auth = ({ children }: IProps) => {
  const setAppLoading = useAppStore((s) => s.setLoading);

  const nextUrl = useMemo(() => {
    const backUrl = localStorage.getItem(LS.PreviousPage);
    return backUrl || `/${ROUTES.TEAMS}`;
  }, []);

  const navigate = useNavigate();
  const authenticatedMS = useIsAuthenticated();

  useEffect(() => {
    if (authenticatedMS) {
      navigate(nextUrl);
      localStorage.removeItem(LS.PreviousPage);
    } else {
      setAppLoading(false);
    }
  }, [setAppLoading, authenticatedMS, navigate, nextUrl]);

  return (
    <div className="auth__underlay">
      <div className="auth">
        <header className="auth__header">
          <AnalogSportLogoDark width={230} />
        </header>
        <div className="auth__main">{children}</div>
      </div>
    </div>
  );
};
