import './index.css';
import { List } from './components/List';
import { useTranslation } from 'react-i18next';
import { Page } from '@cycling-web/common';
import { AthleteFilters } from './components/AthleteFilters';
import { useTitle } from '../../hooks/useTitle';

export const Athletes = () => {
  const { t } = useTranslation();
  useTitle(t('label.athletes'));

  return (
    <Page pageHeaderProps={{ title: t('label.athletes') }}>
      <AthleteFilters />
      <List />
    </Page>
  );
};
