import { Typography } from '@cycling-web/analog-ui';

type IProps = {
  number: number;
  title: string;
  image: string;
};

export const InstructionStep = ({ number, title, image }: IProps) => {
  return (
    <div className="uh__instruction-step">
      <Typography
        variant="subtitle"
        weight="bold"
        text={number}
        className="uh__instruction-step-number"
      />
      <div className="uh__instruction-step-inner">
        <Typography
          variant="caption"
          weight="regular"
          text={title}
          className="uh__instruction-step-title"
        />
        <div className="uh__instruction-step-image">
          <img src={image} alt={title} />
        </div>
      </div>
    </div>
  );
};
