import './index.css';
import { ChevronUp, Ellipsis, Maximize2, Minimize2 } from 'lucide-react';
import { clsx } from 'clsx';
import {
  IconButton,
  IMenuBaseOption,
  IMenuOption,
  Menu,
  Typography,
  useBoolean,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useAiAssistantStore } from '../../store/slice';
import { AIService } from '../../../../api/ai/service';
import { AIRepository } from '../../../../api/ai/repository';
import { useParams } from 'react-router';
import { FeedbackModal } from '../FeedbackModal';

export const Toggle = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const isOpen = useAiAssistantStore((s) => s.isOpen);
  const toggleIsOpen = useAiAssistantStore((s) => s.toggleIsOpen);
  const setMessages = useAiAssistantStore((s) => s.setMessages);
  const isExpanded = useAiAssistantStore((s) => s.isExpanded);
  const toggleIsExpanded = useAiAssistantStore((s) => s.toggleIsExpanded);

  const {
    value: showFeedbackModal,
    setTrue: openFeedbackModal,
    setFalse: dismissFeedbackModal,
  } = useBoolean(false);

  const aiRepository = useMemo(() => {
    return new AIRepository(new AIService());
  }, []);

  const rootClass = clsx(
    'ai-assistant__toggle',
    isOpen && 'ai-assistant__toggle--open'
  );

  const menuOptions: IMenuOption[] = [
    {
      id: 'reset_conversation',
      text: t('label.reset_conversation'),
    },
    {
      id: 'send_feedback',
      text: t('label.send_feedback'),
    },
  ];

  const handleResetConversation = useCallback(() => {
    if (!athleteId) {
      return;
    }
    aiRepository
      .closeConversation({
        athleteId: +athleteId,
      })
      .then(() => {
        setMessages([]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [aiRepository, athleteId, setMessages]);

  const onMenuChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id === 'reset_conversation') {
        handleResetConversation();
      }
      if (option.id === 'send_feedback') {
        openFeedbackModal();
      }
    },
    [handleResetConversation, openFeedbackModal]
  );

  const handleOpen = useCallback(() => {
    if (isOpen && isExpanded) {
      toggleIsExpanded();
    }
    toggleIsOpen();
  }, [isExpanded, isOpen, toggleIsExpanded, toggleIsOpen]);

  const handleExpand = useCallback(() => {
    toggleIsExpanded();
  }, [toggleIsExpanded]);

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className={rootClass} onClick={handleOpen}>
      <div className="ai-assistant__toggle-title">
        <Typography
          variant="headline"
          weight="bold"
          text={t('label.coach_ana')}
          className="ai-assistant__toggle-titleText"
        />
      </div>
      <div className="ai-assistant__toggle-actions">
        {isOpen && (
          <div
            className="ai-assistant__toggle-open-actions"
            onClick={stopPropagation}
          >
            <Menu
              options={menuOptions}
              onChange={onMenuChange}
              dropdownProps={{
                anchor: <IconButton content={<Ellipsis />} size="s" />,
                placement: 'bottom-end',
              }}
            />
            <IconButton
              size="s"
              content={isExpanded ? <Minimize2 /> : <Maximize2 />}
              onClick={handleExpand}
            />
          </div>
        )}
        <IconButton
          size="s"
          content={<ChevronUp className="ai-assistant__toggle-chevron" />}
        />
      </div>

      {showFeedbackModal && <FeedbackModal onDismiss={dismissFeedbackModal} />}
    </div>
  );
};
