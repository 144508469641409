import { AIService } from './service';
import {
  ICloseConversationRequest,
  IGetChatHistoryRequest,
  ISendChatMessageRequest,
  ISendMessageFeedbackRequest,
  ISendMessageFeedbackResponse,
} from './types';
import { IChatbotHistory, IChatbotHistoryMessage } from '../../types/chatbot';

/** The repository is responsible for data transformation */

export interface IAIRepository {
  getChatHistory: (p: IGetChatHistoryRequest) => Promise<IChatbotHistory>;
  sendChatMessage: (
    p: ISendChatMessageRequest
  ) => Promise<IChatbotHistoryMessage[]>;
  sendMessageFeedback: (
    p: ISendMessageFeedbackRequest
  ) => Promise<ISendMessageFeedbackResponse>;
  closeConversation: (p: ICloseConversationRequest) => Promise<void>;
}

export class AIRepository implements IAIRepository {
  constructor(private readonly service: AIService) {}

  async getChatHistory(p: IGetChatHistoryRequest): Promise<IChatbotHistory> {
    const response = await this.service.getChatHistory(p);
    return response.data;
  }

  async sendChatMessage(
    p: ISendChatMessageRequest
  ): Promise<IChatbotHistoryMessage[]> {
    const response = await this.service.sendChatMessage(p);
    return response.data;
  }

  async sendMessageFeedback(
    p: ISendMessageFeedbackRequest
  ): Promise<ISendMessageFeedbackResponse> {
    const response = await this.service.sendMessageFeedback(p);
    return response.data;
  }

  async closeConversation(p: ICloseConversationRequest): Promise<void> {
    const response = await this.service.closeConversation(p);
    return response.data;
  }
}
