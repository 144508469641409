import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { DeepPartial, ICookieConsent } from '@cycling-web/common';
import { useAppStore } from '../../../store/app/slice';
import { LS } from '../../../constants';
import { CookieManager } from '../components/Cookies/components/CookieManager';
import merge from 'lodash/merge';

export const CURRENT_COOKIES_VERSION = 12;

export type ICookieContext = {
  onAcceptAll: () => void;
  onRejectAll: () => void;
  onSavePreference: (cookieConsent: DeepPartial<ICookieConsent>) => void;
  cookieConsent: ICookieConsent | undefined;
};

const CookieContext = createContext<ICookieContext>({} as ICookieContext);

export const useCookieContext = () => useContext(CookieContext);

export const CookieContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const cookieConsent = useAppStore((s) => s.cookies);

  const clearPadding = useCallback(() => {
    setTimeout(() => {
      const scrollElement = document.querySelector<HTMLDivElement>(
        '.legal-content__main'
      );
      if (scrollElement) {
        scrollElement.style.paddingBottom = '0px';
      }
    });
  }, []);

  const onAcceptAll = useCallback(() => {
    const next: ICookieConsent = {
      essential: {
        all: true,
      },
      optional: {
        analytical: true,
      },
    };

    localStorage.setItem(LS.Cookies, JSON.stringify(next));
    localStorage.setItem(
      LS.CookiesVersion,
      JSON.stringify({
        d: Date.now(),
        v: CURRENT_COOKIES_VERSION.toString(),
      })
    );
    useAppStore.getState().setShowCookiesBanner(false);
    useAppStore.getState().setCookies(next);
    clearPadding();
  }, [clearPadding]);

  const onRejectAll = useCallback(() => {
    const next: ICookieConsent = {
      essential: {
        all: true,
      },
      optional: {
        analytical: false,
      },
    };

    localStorage.setItem(LS.Cookies, JSON.stringify(next));
    localStorage.setItem(
      LS.CookiesVersion,
      JSON.stringify({
        d: Date.now(),
        v: CURRENT_COOKIES_VERSION.toString(),
      })
    );
    useAppStore.getState().setShowCookiesBanner(false);
    useAppStore.getState().setCookies(next);
    clearPadding();
  }, [clearPadding]);

  const onSavePreference = useCallback(
    (nextCookies: DeepPartial<ICookieConsent>) => {
      const next = { ...merge({}, cookieConsent, nextCookies) };

      localStorage.setItem(LS.Cookies, JSON.stringify(next));
      localStorage.setItem(
        LS.CookiesVersion,
        JSON.stringify({
          d: Date.now(),
          v: CURRENT_COOKIES_VERSION.toString(),
        })
      );
      useAppStore.getState().setShowCookiesBanner(false);
      useAppStore.getState().setCookies(next);
    },
    [cookieConsent]
  );

  const context = useMemo((): ICookieContext => {
    return {
      onAcceptAll,
      onRejectAll,
      cookieConsent,
      onSavePreference,
    };
  }, [cookieConsent, onAcceptAll, onRejectAll, onSavePreference]);

  return (
    <CookieContext.Provider value={context}>
      {children}
      <CookieManager />
    </CookieContext.Provider>
  );
};
