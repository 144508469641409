import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useEffect, useMemo, useRef } from 'react';
import { WellnessRepository } from '../../../api/wellness/repository';
import { WellnessService } from '../../../api/wellness/service';
import { IWellness } from '../../../types/wellness';
import { Toast } from '@cycling-web/analog-ui';
import { useAthleteWellnessStore } from '../store/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchAthleteWellness = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const fetching = useRef<boolean>(false);
  const setWellness = useAthleteWellnessStore((s) => s.setWellness);
  const setWellnessLoaded = useAthleteWellnessStore((s) => s.setWellnessLoaded);

  const wellnessRepository = useMemo(() => {
    return new WellnessRepository(new WellnessService());
  }, []);

  useEffect(() => {
    if (!athleteId || fetching.current) {
      return;
    }

    fetching.current = true;
    wellnessRepository
      .getLastUpdatedWellness(athleteId)
      .then((wellness: IWellness) => {
        setWellness(wellness);
      })
      .catch((error: AxiosError) => {
        setWellnessLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_athlete_wellness_title'),
              message: t('error.get_athlete_wellness_message'),
            },
            { toastId: 'get_athlete_wellness' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [athleteId, setWellness, setWellnessLoaded, t, wellnessRepository]);
};
