import './index.css';
import { OnboardingFooter } from '../Footer';
import { Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { IPlatform, IPlatformEnum } from '../../../../types/platform';
import { useCallback, useMemo } from 'react';
import { TrainingPeaks } from '../../../../components/data-sharing/TrainingPeaks';
import { usePlatformsStore } from '../../../../store/platforms/slice';
import { useFetchPlatforms } from '../../../../hooks/useFetchPlatforms';

export const OnboardingPlatforms = () => {
  const { t } = useTranslation();
  const { fetchPlatforms } = useFetchPlatforms();

  const platforms = usePlatformsStore((s) => s.platforms);
  const platformsLoaded = usePlatformsStore((s) => s.platformsLoaded);

  const stepCompleted = useMemo(() => {
    const apps: IPlatformEnum[] = [IPlatformEnum.TrainingPeaks];
    return (
      platforms.filter((p: IPlatform) => {
        return apps.includes(p.platformName as IPlatformEnum);
      }).length > 0
    );
  }, [platforms]);

  const connectedPlatforms: Record<IPlatformEnum, IPlatform> = useMemo(() => {
    return platforms.reduce((acc: Record<string, IPlatform>, p: IPlatform) => {
      acc[p.platformName as IPlatformEnum] = p;
      return acc;
    }, {});
  }, [platforms]);

  const onConnect = useCallback(() => {
    fetchPlatforms();
  }, [fetchPlatforms]);

  return (
    <>
      <header className="onboarding-platforms__header">
        <Typography
          variant="h4"
          weight="bold"
          className="onboarding-platforms__header-title"
          text={t('label.data_sharing')}
        />
      </header>
      <div className="onboarding-platforms__list">
        <Typography
          variant="headline"
          weight="regular"
          className="onboarding-platforms__list-title"
          text={t('label.integrated_apps')}
        />

        <div className="onboarding-platforms__list-items">
          <TrainingPeaks
            platform={connectedPlatforms[IPlatformEnum.TrainingPeaks]}
            platformLoaded={platformsLoaded}
            onConnect={onConnect}
          />
        </div>
      </div>
      <OnboardingFooter stepCompleted={stepCompleted} />
    </>
  );
};
