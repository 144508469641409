import { createContext, useContext } from 'react';
import { ISmartChartsContext } from '../types';

export const SmartChartsContext = createContext<ISmartChartsContext>(
  {} as ISmartChartsContext
);

export const useSmartChartsContext = () => {
  return useContext(SmartChartsContext);
};
