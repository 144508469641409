import { useEffect, useMemo, useRef } from 'react';
import { AIRepository } from '../../../api/ai/repository';
import { AIService } from '../../../api/ai/service';
import { useParams } from 'react-router';
import { IChatbotHistory } from '../../../types/chatbot';
import { useAiAssistantStore } from '../store/slice';
import { DEFAULT_MESSAGE } from '../constants';

export const useFetchChatHistory = () => {
  const { athleteId } = useParams();
  const fetching = useRef<boolean>(false);

  const setConfig = useAiAssistantStore((s) => s.setConfig);
  const setConfigLoaded = useAiAssistantStore((s) => s.setConfigLoaded);
  const setMessages = useAiAssistantStore((s) => s.setMessages);
  const setMessagesLoaded = useAiAssistantStore((s) => s.setMessagesLoaded);

  const aiRepository = useMemo(() => {
    return new AIRepository(new AIService());
  }, []);

  useEffect(() => {
    if (fetching.current || !athleteId) {
      return;
    }

    fetching.current = true;
    aiRepository
      .getChatHistory({
        athleteId: +athleteId,
      })
      .then((response: IChatbotHistory) => {
        setConfig(response);
        setMessages(
          response.messages.length === 0 ? [DEFAULT_MESSAGE] : response.messages
        );
      })
      .catch((e) => {
        console.log(e);
        setConfigLoaded(true);
        setMessagesLoaded(true);
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [
    aiRepository,
    athleteId,
    setConfig,
    setConfigLoaded,
    setMessages,
    setMessagesLoaded,
  ]);
};
