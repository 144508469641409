import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { Toast } from '@cycling-web/analog-ui';
import { useAdminTeamMemberStore } from '../store/slice';
import { UsersRepository } from '../../../api/users/repository';
import { UsersService } from '../../../api/users/service';
import { IUserBase } from '../../../types/users';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchAdminTeamMembers = () => {
  const { t } = useTranslation();
  const fetching = useRef<boolean>(false);

  const setUsers = useAdminTeamMemberStore((s) => s.setUsers);
  const setUsersLoaded = useAdminTeamMemberStore((s) => s.setUsersLoaded);

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  useEffect(() => {
    if (fetching.current) {
      return;
    }

    fetching.current = true;
    usersRepository
      .getUsers()
      .then((users: IUserBase[]) => {
        setUsers(users);
      })
      .catch((error: AxiosError) => {
        setUsersLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_users_title'),
              message: t('error.get_users_message'),
            },
            { toastId: 'get_users' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [setUsers, setUsersLoaded, t, usersRepository]);
};
