import { IAiAssistantSlice, IAiAssistantState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IChatbotConfig, IChatbotHistoryMessage } from '../../../types/chatbot';

export const initialState: IAiAssistantState = {
  isOpen: false,
  isExpanded: false,
  config: null,
  configLoaded: false,
  messages: [],
  messagesLoaded: false,
  isProcessing: false,
};

export const useAiAssistantStore = create<IAiAssistantSlice>()(
  immer((set) => ({
    ...initialState,
    toggleIsOpen: (isOpen?: boolean) => {
      set((state) => {
        state.isOpen = isOpen !== undefined ? isOpen : !state.isOpen;
      });
    },
    setConfig: (config: IChatbotConfig | null) => {
      set((state) => {
        state.config = config;
        state.configLoaded = true;
      });
    },
    updateConfig: (config: Partial<IChatbotConfig>) => {
      set((state) => {
        if (state.config) {
          state.config = {
            ...state.config,
            ...config,
          };
        }
      });
    },
    setConfigLoaded: (loaded: boolean) => {
      set((state) => {
        state.configLoaded = loaded;
      });
    },
    setMessages: (messages: IChatbotHistoryMessage[]) => {
      set((state) => {
        state.messages = messages;
        state.messagesLoaded = true;
      });
    },
    setMessagesLoaded: (loaded: boolean) => {
      set((state) => {
        state.messagesLoaded = loaded;
      });
    },
    setIsProcessing: (isProcessing: boolean) => {
      set((state) => {
        state.isProcessing = isProcessing;
      });
    },
    appendMessages: (messages: IChatbotHistoryMessage[]) => {
      set((state) => {
        state.messages = [...state.messages, ...messages];
        state.isProcessing = false;
      });
    },
    toggleIsExpanded: (isExpanded?: boolean) => {
      set((state) => {
        state.isExpanded =
          isExpanded !== undefined ? isExpanded : !state.isExpanded;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
