import './index.css';
import { IUserBase } from '../../../../types/users';
import { EMPTY } from '../../../../constants';

type IProps = {
  user: IUserBase | null | undefined;
  onClick: () => void;
};

export const AssignedStaff = ({ user, onClick }: IProps) => {
  if (!user) {
    return EMPTY;
  }

  return (
    <div className="admin-team-mapping__assigned-staff" onClick={onClick}>
      {user.name}
    </div>
  );
};
