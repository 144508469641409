export type IPlatform = {
  athleteId: number;
  tenantId: number;
  platformName: string;
  platformIdentifier: string;
  synced: boolean;
  lastFetched: string;
  disconnected: boolean;
  createdOn: string;
  updatedOn: string;
  invalid: boolean;
};

export enum IPlatformEnum {
  TrainingPeaks = 'TRAINIGPEAKS',
  UltraHuman = 'ULTRAHUMAN',
  Wahoo = 'WAHOO',
  Whoop = 'WHOOP',
}
