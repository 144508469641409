import './index.css';
import { Dialog } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { UHStep1 } from './UHStep1';
import { useCallback, useState } from 'react';
import { UHStep2 } from './UHStep2';

type IProps = {
  onCompleteIntegration: () => void;
  onDismiss: () => void;
};

export const UltraHumanModal = ({
  onCompleteIntegration,
  onDismiss,
}: IProps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(1);

  const onStepChange = useCallback((d: number) => {
    return () => {
      setStep((step) => {
        step += d;
        return step;
      });
    };
  }, []);

  const onComplete = useCallback(() => {
    onCompleteIntegration();
    onDismiss();
  }, [onDismiss, onCompleteIntegration]);

  return (
    <Dialog
      title={t('label.ultrahuman_account_connection')}
      onDismiss={onDismiss}
      outsidePress={false}
      className="ultrahuman-onboarding-modal"
      onRenderFooter={() => null}
    >
      <div className="ultrahuman-onboarding-modal">
        {step === 1 && (
          <UHStep1 onDismiss={onDismiss} onStepChange={onStepChange} />
        )}
        {step === 2 && (
          <UHStep2 onStepChange={onStepChange} onComplete={onComplete} />
        )}
      </div>
    </Dialog>
  );
};
