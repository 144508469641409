import { Skeleton } from '@cycling-web/analog-ui';
import { IAthlete } from '../../../../../../types/athletes';
import { Parameter } from '../Parameter';

type IProps = {
  athlete: IAthlete | null | undefined;
  loading?: boolean;
};

export const ATL = ({ athlete, loading }: IProps) => {
  if (loading) {
    return <Skeleton width="60px" height="24px" />;
  }

  const label = 'ATL';
  const value = athlete?.atl.toFixed(0) ?? '--';

  return <Parameter label={label} value={value} />;
};
