import * as echarts from 'echarts';
import { ChartColor } from '../constants/colors';

type IProps = {
  from: string;
  to?: string;
  direction?: 'to-right' | 'to-bottom';
};

export const getGradient = ({
  from,
  to = ChartColor.GradientEnd,
  direction = 'to-bottom',
}: IProps) => {
  const [x0, y0, x2, y2] =
    direction === 'to-right' ? [0, 0, 1, 0] : [0, 0, 0, 1];

  return {
    color: new echarts.graphic.LinearGradient(x0, y0, x2, y2, [
      { offset: 0, color: from },
      { offset: 1, color: to || ChartColor.GradientEnd },
    ]),
  };
};
