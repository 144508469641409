import { useContext, useEffect, useRef } from 'react';
import { EmptyState } from '../EmptyState';
import { ITableContext } from './types';
import { TableContext } from './TableContext';

export const TableEmptyState = () => {
  const { emptyState }: ITableContext = useContext(TableContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window === 'undefined' || !ref.current) {
      return;
    }

    const scrollableTable = ref.current.parentElement?.parentElement;

    if (!scrollableTable) {
      return;
    }

    const updateEmptyStatePosition = () => {
      if (!ref.current || !scrollableTable) {
        return;
      }

      const scrollLeft = scrollableTable.scrollLeft;
      const tableWidth = scrollableTable.clientWidth;

      ref.current.style.left = `${tableWidth / 2 + scrollLeft}px`;
    };

    scrollableTable.addEventListener('scroll', updateEmptyStatePosition);

    return () => {
      scrollableTable.removeEventListener('scroll', updateEmptyStatePosition);
    };
  }, [ref]);

  if (!emptyState) {
    return null;
  }

  return (
    <div className="analog-table__emptyState" ref={ref}>
      <EmptyState {...emptyState} />
    </div>
  );
};
