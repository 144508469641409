import './index.css';
import { ITableContext, ITableProps } from './types';
import { useViewModel } from './useViewModel';
import { clsx } from 'clsx';
import { TableHead } from './TableHead';
import { TableBody } from './TableBody';
import { TableContext } from './TableContext';
import { TablePagination } from './TablePagination';
import { TableBodyLoading } from './TableBodyLoading';
import { TableEmptyState } from './TableEmptyState';

export const Table = (props: ITableProps) => {
  const { className, pagination, loading, emptyState }: ITableProps = props;

  const rootClass: string = clsx('analog-table', className);
  const context: ITableContext = useViewModel(props);

  return (
    <TableContext.Provider value={context}>
      <div className={rootClass}>
        <div className="analog-table__wrapper">
          <div className="analog-table__table">
            <TableHead />
            {loading ? <TableBodyLoading /> : emptyState ? null : <TableBody />}
          </div>

          {!loading && emptyState && <TableEmptyState />}
        </div>

        {!loading && pagination && <TablePagination />}
      </div>
    </TableContext.Provider>
  );
};
