import './index.css';
import { useCallback, useState } from 'react';
import { ISliderKeys } from '../index';
import { Slider, Typography } from '@cycling-web/analog-ui';

type IProps = {
  question: string;
  options: string[];
  name: string;
  onChange: (name: ISliderKeys, value: string) => void;
};

export const FeedbackFormSlider = ({
  question,
  options,
  name,
  onChange,
}: IProps) => {
  const [displayValue, setDisplayValue] = useState<string>(options[0]);
  const handleChange = useCallback(
    (value: number) => {
      setDisplayValue(options[value]);
      onChange(name as ISliderKeys, options[value]);
    },
    [name, onChange, options]
  );

  return (
    <div className="subjective-feedback-form__slider">
      <Typography
        as="div"
        className="subjective-feedback-form__question"
        text={question}
      />
      <Slider
        max={options.length - 1}
        onChange={handleChange}
        displayValue={displayValue}
      />
      <div className="subjective-feedback-form__boundaries">
        <Typography
          className="subjective-feedback-form__boundary"
          text={options[0]}
        />
        <Typography
          className="subjective-feedback-form__boundary"
          text={options[options.length - 1]}
        />
      </div>
    </div>
  );
};
