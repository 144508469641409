import './index.css';
import { ISelectOption, Select, Skeleton } from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useViewModel } from './useViewModel';
import { ISingleGroupFilterProps } from './types';
import { ALL_FILTER_OPTION_ID } from '../../../constants';

export const SingleGroupFilter = (props: ISingleGroupFilterProps) => {
  const {
    value,
    onChange,
    dropdownProps,
    onClear,
    selectProps,
    skeletonProps,
    style = {
      minWidth: '220px',
    },
    showAllOption = false,
  } = props;
  const { t } = useTranslation();
  const { options, loaded } = useViewModel(props);
  const singleOptions: ISelectOption[] = useMemo(() => {
    const result: ISelectOption[] = [];

    if (showAllOption) {
      result.push({
        id: ALL_FILTER_OPTION_ID.toString(),
        text: t('label.all_athletes'),
      });
    }

    return result.concat(options);
  }, [options, showAllOption, t]);

  const handleChange = useCallback(
    (option: ISelectOption) => {
      onChange(Number(option.id));
    },
    [onChange]
  );

  return loaded ? (
    <div className="group-filter" style={style}>
      <Select
        options={singleOptions}
        value={singleOptions.find((o: ISelectOption) => value === +o.id)}
        multiple={false}
        placeholder={t('placeholder.group')}
        dropdownProps={{
          ...dropdownProps,
          minWidth: dropdownProps?.minWidth || '400px',
          maxHeight: dropdownProps?.maxHeight || '264px',
        }}
        onChange={handleChange}
        onClear={onClear}
        {...selectProps}
      />
    </div>
  ) : (
    <Skeleton
      width={skeletonProps?.width || '220px'}
      height={skeletonProps?.height || '40px'}
    />
  );
};
