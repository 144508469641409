import { useLocation } from 'react-router';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast, useBoolean } from '@cycling-web/analog-ui';
import { useAdminTeamMemberStore } from '../../../AdminPanel/store/slice';
import { UsersRepository } from '../../../../api/users/repository';
import { UsersService } from '../../../../api/users/service';
import { renameFile } from '../../../../utils/files';
import { IUpdatePictureRequest } from '../../../../api/users/types';

export const useViewModel = () => {
  const { pathname } = useLocation();
  const backUrl = useMemo(() => {
    const tmp = pathname.split('/');
    tmp.pop();
    tmp.pop();
    return tmp.join('/');
  }, [pathname]);
  const { t } = useTranslation();
  const loading = false;

  const [file, setFile] = useState<File | undefined>(undefined);
  const [base64, setBase64] = useState<string | undefined>(undefined);

  const {
    value: showUploadPictureModal,
    setTrue: openUploadPictureModal,
    setFalse: dismissUploadPictureModal,
  } = useBoolean(false);

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  const {
    value: showUserFormModal,
    setTrue: openUserFormModal,
    setFalse: dismissUserFormModal,
  } = useBoolean(false);

  const currentUser = useAdminTeamMemberStore((s) => s.currentUser);

  const {
    value: showConfirmDelete,
    setTrue: openConfirmDelete,
    setFalse: dismissConfirmDelete,
  } = useBoolean(false);

  const handleConfirmDelete = useCallback((): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }, []);

  const onImageChange = useCallback(
    (file: File, base64: string) => {
      setFile(file);
      setBase64(base64);
      openUploadPictureModal();
    },
    [openUploadPictureModal]
  );

  const onImageUpload = useCallback(
    (picture: File | null, src: string | null): Promise<void> => {
      if (!picture || !currentUser) {
        return Promise.resolve();
      }

      const payload: IUpdatePictureRequest = {
        userId: currentUser.id,
        picture: renameFile(picture),
      };

      return usersRepository
        .updatePicture(payload)
        .then(() => {
          if (src && currentUser) {
            useAdminTeamMemberStore.getState().updateUser({
              ...currentUser,
              picture: src,
            });
          }
        })
        .catch(() => {
          Toast.error({
            title: t('error.file_too_large_title'),
          });
        });
    },
    [currentUser, t, usersRepository]
  );

  return {
    backUrl,
    currentUser,
    loading,
    onImageChange,
    openUserFormModal,
    showConfirmDelete,
    dismissConfirmDelete,
    handleConfirmDelete,
    showUserFormModal,
    dismissUserFormModal,
    showUploadPictureModal,
    file,
    base64,
    dismissUploadPictureModal,
    onImageUpload,
  };
};
