import './index.css';
import { Outlet } from 'react-router';

export const AthleteHealth = () => {
  return (
    <div className="health-page">
      <Outlet />
    </div>
  );
};
