import { useContext } from 'react';
import { ITableColumn, ITableContext } from './types';
import { TableContext } from './TableContext';
import { TableCell } from './TableCell';
import { Checkbox } from '../Checkbox';
import { Skeleton } from '../Skeleton';

export const TableBodyLoading = () => {
  const { checkbox, columns, skeletonCount }: ITableContext =
    useContext(TableContext);

  const blankRows: number[] = new Array(skeletonCount || 4).fill(1);
  const DEFAULT_WIDTH = 80;
  const PADDINGS = 32;

  const tableBodyJSX = blankRows.map((_, i: number) => {
    const itemsJSX = columns.map((c: ITableColumn) => {
      let width = `${DEFAULT_WIDTH - PADDINGS}px`;

      if (c.maxWidth !== undefined) {
        if (typeof c.maxWidth === 'string') {
          width = `calc(${c.maxWidth} - ${PADDINGS})`;
        } else {
          width = `${c.maxWidth - PADDINGS}px`;
        }
      } else if (c.minWidth !== undefined) {
        if (typeof c.minWidth === 'string') {
          width = `calc(${c.minWidth} - ${PADDINGS})`;
        } else {
          width = `${c.minWidth - PADDINGS}px`;
        }
      }

      return (
        <TableCell
          key={c.key}
          minWidth={c.minWidth}
          maxWidth={c.maxWidth}
          grow={c.grow}
          align={c.align}
        >
          <Skeleton width={width} height="20px" style={{ flexGrow: 1 }} />
        </TableCell>
      );
    });

    return (
      <div className="analog-table__row" key={i}>
        {checkbox && (
          <TableCell minWidth={50} maxWidth={50}>
            <Checkbox disabled />
          </TableCell>
        )}
        {itemsJSX}
      </div>
    );
  });

  return (
    <div className="analog-table__tableBody analog-table__tableBody--loading">
      {tableBodyJSX}
    </div>
  );
};
