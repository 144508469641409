import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'lucide-react';
import { useAthleteStore } from '../../../../store/athlete/slice';
import {
  Button,
  IMenuBaseOption,
  IMenuOption,
  Menu,
} from '@cycling-web/analog-ui';
import { ReportIllnessFormModal } from '../../../../components/ReportIllnessFormModal';
import { ReportRehabilitationFormModal } from '../../../../components/ReportRehabilitationFormModal';
import { ReportInjuryFormModal } from '../../../../components/ReportInjuryFormModal';
import { useAthleteMedicalReportsStore } from '../../store/slice';
import {
  IMedicalReport,
  IMedicalReportRecord,
} from '../../../../types/medical';
import { MedicalRepository } from '../../../../api/medical/repository';
import { MedicalService } from '../../../../api/medical/service';
import { useAthletesStore } from '../../../../store/athletes/slice';

export const ReportMenu = () => {
  const { t } = useTranslation();
  const [showAddDataModal, setShowAddDataModal] = useState<string>('');
  const athlete = useAthleteStore((s) => s.athlete);

  const handleAppendReport = useCallback((report: IMedicalReport) => {
    const medicalRepository = new MedicalRepository(new MedicalService());
    const record: IMedicalReportRecord =
      medicalRepository.convertReportToRecord(report);

    useAthleteMedicalReportsStore.getState().appendReport(record);
    useAthletesStore.getState().updateAthlete({
      id: record.athleteId,
      medicalReport: report,
    });
  }, []);

  const dismissAddDataModal = useCallback(() => {
    setShowAddDataModal('');
  }, []);

  const menuOptions: IMenuOption[] = [
    { id: 'report_illness', text: t('label.report_illness') },
    { id: 'report_rehabilitation', text: t('label.report_rehabilitation') },
    { id: 'report_injury', text: t('label.report_injury') },
  ];

  const onMenuChange = useCallback((option: IMenuBaseOption) => {
    if (option.id) {
      setShowAddDataModal(option.id);
    }
  }, []);

  return (
    <>
      <Menu
        options={menuOptions}
        onChange={onMenuChange}
        dropdownProps={{
          anchor: (
            <Button content={t('action.report')} endIcon={<ChevronDown />} />
          ),
          placement: 'bottom-end',
          offset: 0,
        }}
      />

      {athlete && (
        <>
          {showAddDataModal === 'report_illness' && (
            <ReportIllnessFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
              onSubmitSuccess={handleAppendReport}
            />
          )}
          {showAddDataModal === 'report_rehabilitation' && (
            <ReportRehabilitationFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
              onSubmitSuccess={handleAppendReport}
            />
          )}
          {showAddDataModal === 'report_injury' && (
            <ReportInjuryFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
              onSubmitSuccess={handleAppendReport}
            />
          )}
        </>
      )}
    </>
  );
};
