import { ITrainingLoadSlice, ITrainingLoadState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ITrainingLoadFilters } from '../types';
import { DateGroupBy, DateRange } from '../../../types/enums';
import { ITrainingLoad, IWorkoutDetail } from '../../../types/performance';

export const initialFilters: ITrainingLoadFilters = {
  period: DateRange.ThreeMonth,
  dateGroupBy: DateGroupBy.Day,
};

export const initialState: ITrainingLoadState = {
  filters: initialFilters,
  workoutDetails: [],
  workoutDetailsLoaded: false,
  trainingLoad: null,
  trainingLoadLoaded: false,
};

export const useTrainingLoadStore = create<ITrainingLoadSlice>()(
  immer((set) => ({
    ...initialState,
    setFilters: (filters: Partial<ITrainingLoadFilters>) => {
      set((state) => {
        state.filters = {
          ...state.filters,
          ...filters,
        };
      });
    },
    setWorkoutDetails: (workoutDetails: IWorkoutDetail[]) => {
      set((state) => {
        state.workoutDetails = workoutDetails;
        state.workoutDetailsLoaded = true;
      });
    },
    setWorkoutDetailsLoaded: (loaded: boolean) => {
      set((state) => {
        state.workoutDetailsLoaded = loaded;
      });
    },
    setTrainingLoad: (trainingLoad: ITrainingLoad | null) => {
      set((state) => {
        state.trainingLoad = trainingLoad;
        state.trainingLoadLoaded = true;
      });
    },
    setTrainingLoadLoaded: (loaded: boolean) => {
      set((state) => {
        state.trainingLoadLoaded = loaded;
      });
    },
    reset: () => {
      set({
        ...initialState,
        filters: initialFilters,
      });
    },
  }))
);
