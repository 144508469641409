import './index.css';
import {
  IChatbotHistoryMessage,
  IChatbotHistoryMessageContent,
} from '../../../../types/chatbot';
import { clsx } from 'clsx';
import { MarkdownPart } from './MarkdownPart';
import { FractionPart } from './FractionPart';
import { LatexPart } from './LatexPart';
import { MessageFeedback } from '../MessageFeedback';

type IProps = {
  message: IChatbotHistoryMessage;
  content: IChatbotHistoryMessageContent;
  isUser: boolean;
  isLast: boolean;
};

const parseMessage = (message: string) => {
  const parts: Array<{ type: string; content: string }> = [];
  const regex =
    /(\$\$.*?\$\$|\$.*?\$|\\\[.*?\\]|\\\((.*?)\\\)|\\begin\{(.*?)}|\\frac\{.*?}\{.*?})/g;
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = regex.exec(message)) !== null) {
    if (match.index > lastIndex) {
      parts.push({
        type: 'markdown',
        content: message.slice(lastIndex, match.index),
      });
    }

    const latex = match[0];
    if (/\\frac\{.*?}\{.*?}/.test(latex)) {
      parts.push({ type: 'fraction', content: latex });
    } else {
      parts.push({ type: 'latex', content: latex });
    }
    lastIndex = regex.lastIndex;
  }

  if (lastIndex < message.length) {
    parts.push({
      type: 'markdown',
      content: message.slice(lastIndex),
    });
  }

  return parts;
};

export const Message = ({ message, content, isUser, isLast }: IProps) => {
  const rootClass = clsx(
    'ai-assistant__message',
    isUser ? 'ai-assistant__message--right' : ''
  );

  const parts = parseMessage(content.text.value);
  const partsJSX = parts.map((part, index: number) => {
    return (
      <div key={`wrapper-${index}`} className="ai-message__part">
        {part.type === 'markdown' && (
          <MarkdownPart key={index} content={part.content} />
        )}
        {part.type === 'fraction' && (
          <FractionPart key={index} content={part.content} />
        )}
        {part.type === 'latex' && (
          <LatexPart key={index} content={part.content} />
        )}
      </div>
    );
  });

  const showMessageFeedback =
    !isUser && isLast && !message.feedback_type && message.id !== '';

  return (
    <div className={rootClass}>
      <div className="ai-assistant__message-content">
        <div className="ai-assistant__message-content-parts">{partsJSX}</div>
      </div>
      {showMessageFeedback && <MessageFeedback message={message} />}
    </div>
  );
};
