import { MedicalService } from './service';
import {
  IMedicalFile,
  IMedicalNote,
  IMedicalReport,
  IMedicalReportRecord,
  IReportIllnessFormData,
  IReportInjuryFormData,
  IReportRehabilitationFormData,
  IUploadFileFormData,
  MedicalRecordType,
} from '../../types/medical';
import {
  IGetFilesRequest,
  IGetMedicalReportByIdRequest,
  IGetMedicalReportRecordsRequest,
  IGetNotesRequest,
  IGetTeamMedicalReportRequest,
  IMarkRecoveredRequest,
} from './types';
import { AxiosResponse } from 'axios';
import { format, formatISO, parseISO } from 'date-fns';

/** The repository is responsible for data transformation */

export interface IMedicalRepository {
  reportIllness: (p: IReportIllnessFormData) => Promise<IMedicalReport>;
  reportRehabilitation: (
    p: IReportRehabilitationFormData
  ) => Promise<IMedicalReport>;
  reportInjury: (p: IReportInjuryFormData) => Promise<IMedicalReport>;
  getReports: (
    p: IGetMedicalReportRecordsRequest
  ) => Promise<IMedicalReportRecord[]>;
  getReportById: (p: IGetMedicalReportByIdRequest) => Promise<IMedicalReport>;
  getTeamReport: (
    p: IGetTeamMedicalReportRequest
  ) => Promise<IMedicalReportRecord[]>;

  getNotes: (p: IGetNotesRequest) => Promise<IMedicalNote[]>;
  createNote: (p: IMedicalNote) => Promise<IMedicalNote>;
  updateNote: (p: IMedicalNote) => Promise<IMedicalNote[]>;
  deleteNote: (p: number) => Promise<AxiosResponse<void>>;

  getFiles: (p: IGetFilesRequest) => Promise<IMedicalFile[]>;
  uploadFile: (p: IUploadFileFormData) => Promise<IMedicalFile>;
  downloadFile: (p: number) => Promise<string>;
  deleteFile: (p: number) => Promise<AxiosResponse<void>>;

  markRecovered: (p: IMarkRecoveredRequest) => Promise<IMedicalReport>;
  convertReportToRecord: (report: IMedicalReport) => IMedicalReportRecord;
}

export class MedicalRepository implements IMedicalRepository {
  constructor(private readonly service: MedicalService) {}

  async reportIllness(p: IReportIllnessFormData): Promise<IMedicalReport> {
    const response = await this.service.createReport({
      ...p,
      onsetDate: this.parseDateToDTO(p.onsetDate),
      estimatedRecovery: this.parseDateToDTO(p.estimatedRecovery),
    });
    return response.data;
  }

  async reportRehabilitation(
    p: IReportRehabilitationFormData
  ): Promise<IMedicalReport> {
    const response = await this.service.createReport({
      ...p,
      modalityUsed: JSON.stringify(p.modalityUsed),
      assessmentTools: JSON.stringify(p.assessmentTools),
      onsetDate: this.parseDateToDTO(p.onsetDate),
      estimatedRecovery: this.parseDateToDTO(p.estimatedRecovery),
    });
    return response.data;
  }

  async reportInjury(p: IReportInjuryFormData): Promise<IMedicalReport> {
    const response = await this.service.createReport({
      ...p,
      onsetMode: JSON.stringify(p.onsetMode),
      onsetDate: this.parseDateToDTO(p.onsetDate),
      estimatedRecovery: this.parseDateToDTO(p.estimatedRecovery),
    });
    return response.data;
  }

  async getReports(
    p: IGetMedicalReportRecordsRequest
  ): Promise<IMedicalReportRecord[]> {
    const response = await this.service.getReports(p);
    return response.data;
  }

  async getReportById(
    p: IGetMedicalReportByIdRequest
  ): Promise<IMedicalReport> {
    const response = await this.service.getReportById(p);
    return response.data;
  }

  async getTeamReport(
    p: IGetTeamMedicalReportRequest
  ): Promise<IMedicalReportRecord[]> {
    const response = await this.service.getTeamReport(p);
    return response.data;
  }

  async getNotes(p: IGetNotesRequest): Promise<IMedicalNote[]> {
    const response = await this.service.getNotes(p);
    return response.data;
  }

  async createNote(p: IMedicalNote): Promise<IMedicalNote> {
    const response = await this.service.createNote(p);
    return response.data;
  }

  async updateNote(p: IMedicalNote): Promise<IMedicalNote[]> {
    const response = await this.service.updateNote(p);
    return response.data;
  }

  async deleteNote(id: number): Promise<AxiosResponse<void>> {
    return await this.service.deleteNote({
      id,
    });
  }

  async getFiles(p: IGetFilesRequest): Promise<IMedicalFile[]> {
    const response = await this.service.getFiles(p);
    return response.data;
  }

  async uploadFile(p: IUploadFileFormData): Promise<IMedicalFile> {
    const formData = new FormData();
    formData.append('medicalReportId', p.medicalReportId.toString());
    formData.append('multipartFile', p.multipartFile);
    formData.append('fileDescription', p.fileDescription || '');

    const response = await this.service.uploadFile(formData);
    return response.data;
  }

  async downloadFile(fileId: number): Promise<string> {
    const response = await this.service.downloadFile({
      fileId,
    });
    return response.data;
  }

  async deleteFile(fileId: number): Promise<AxiosResponse<void>> {
    return await this.service.deleteFile({
      fileId,
    });
  }

  async markRecovered(p: IMarkRecoveredRequest): Promise<IMedicalReport> {
    const response = await this.service.markRecovered(p);
    return response.data;
  }

  private parseDateToDTO(date: Date | undefined): number {
    const d = date || new Date();
    const formattedDate = format(d, "yyyy-MM-dd'T'HH:mm:ss");
    const utcDate = formatISO(parseISO(formattedDate));
    const nextDate = new Date(utcDate);
    return nextDate.getTime() / 1000;
  }

  convertReportToRecord(report: IMedicalReport): IMedicalReportRecord {
    let issue = '';

    if (report.recordType === MedicalRecordType.Illness) {
      issue = report.symptom;
    } else if (report.recordType === MedicalRecordType.Physio) {
      issue = report.bodyLocation;
    } else if (report.recordType === MedicalRecordType.Injury) {
      issue = report.area;
    }

    return {
      id: report.id,
      athleteId: report.athleteId,
      recordType: report.recordType,
      issue,
      severity: report.severity,
      onsetDate: report.onsetDate,
      estimatedRecovery: report.estimatedRecovery,
      createdBy: report.createdBy,
      notesCount: report.note ? 1 : 0,
      attachmentsCount: 0,
    };
  }
}
