import './index.css';
import ReactSlider from 'react-slider';
import { ISliderProps } from './types';
import classNames from 'clsx';
import { useRef, useState } from 'react';
import { Typography } from '../Typography';

export const Slider = (props: ISliderProps) => {
  const { className, displayValue, onChange } = props;
  const rootClass = classNames('analog-slider', className);
  const sliderValueRef = useRef<HTMLDivElement>(null);
  const [showSliderValue, setShowSliderValue] = useState<boolean>(false);

  const [adjustStyle, setAdjustStyle] = useState({});

  const onBeforeChange = () => {
    setShowSliderValue(true);
  };

  const onAfterChange = () => {
    setShowSliderValue(false);
  };

  const handleChange = (value: number) => {
    setTimeout(() => {
      const element = sliderValueRef.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const viewportWidth = window.innerWidth;

        let nextAdjustStyle = {};

        if (rect.right > viewportWidth) {
          const overflowRight = rect.right - viewportWidth;
          nextAdjustStyle = { left: `calc(50% - ${overflowRight + 8}px)` };
        } else if (rect.left < 0) {
          const overflowLeft = -rect.left;
          nextAdjustStyle = { left: `calc(50% + ${overflowLeft + 8}px)` };
        }

        setAdjustStyle(nextAdjustStyle);
      }
    });

    onChange(value);
  };

  return (
    <div className={rootClass}>
      <ReactSlider
        className={rootClass}
        marks
        markClassName="analog-slider__mark"
        min={props.min || 0}
        max={props.max || 9}
        onChange={handleChange}
        onBeforeChange={onBeforeChange}
        onAfterChange={onAfterChange}
        thumbClassName="analog-slider__thumb"
        trackClassName="analog-slider__track"
        renderThumb={(p, s) => {
          return (
            <div {...p}>
              {showSliderValue && (
                <>
                  <div
                    className="analog-slider__thumbValue"
                    ref={sliderValueRef}
                    style={adjustStyle}
                  >
                    <Typography text={displayValue} variant="subtitle" />
                  </div>
                  <div className="analog-slider__thumbArrow" />
                </>
              )}
              <div className="analog-slider__thumbInner"></div>
            </div>
          );
        }}
      />
    </div>
  );
};
