import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import {
  IEmptyStateProps,
  ITableColumn,
  ITableItem,
} from '@cycling-web/analog-ui';
import { ReactNode, useCallback, useMemo } from 'react';
import { Athlete } from '../../../../components/columns/Athlete';
import { ArrowRight, User } from 'lucide-react';
import { ROUTES } from '../../../../router/routes';
import {
  ILatestTrainingLoad,
  IPerformanceReport,
} from '../../../../types/performance';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { EMPTY } from '../../../../constants';
import { TsbTag } from '../../../../components/columns/TsbTag';

type IProps = {
  trainingLoad: ILatestTrainingLoad[];
};

export const useViewModel = ({ trainingLoad }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const athletesMap = useAthletesStore((s) => s.athletesMap);

  const items = trainingLoad;

  const columns: ITableColumn[] = useMemo(() => {
    const columns: ITableColumn[] = [
      {
        key: 'athlete',
        name: t('label.athlete'),
        minWidth: 220,
        grow: 1,
      },
      {
        key: 'tsb',
        name: t('label.tsb'),
        minWidth: 100,
        grow: 100,
      },
      {
        key: 'details',
        name: '',
        minWidth: 110,
        maxWidth: 110,
        align: 'right',
      },
    ];

    return columns;
  }, [t]);

  const onRenderCell = (column: ITableColumn, item: ITableItem) => {
    const castedItem = item as IPerformanceReport;
    const athlete = athletesMap[castedItem.athleteId];

    const renderMap: Record<string, ReactNode> = {
      athlete: athlete ? <Athlete athlete={athlete} /> : EMPTY,
      tsb: <TsbTag tsb={castedItem.tsb} />,
      details: <ArrowRight />,
    };

    return renderMap[column.key];
  };

  const onRowClick = useCallback(
    (item: ITableItem) => {
      const castedItem = item as IPerformanceReport;
      const tmp = pathname.split('/');
      tmp.pop();

      navigate(
        `${tmp.join('/')}/${ROUTES.ATHLETES}/${castedItem.athleteId}/${
          ROUTES.TRAINING_LOAD
        }`
      );
    },
    [navigate, pathname]
  );

  const emptyStateProps = useMemo((): IEmptyStateProps | undefined => {
    return items.length === 0
      ? {
          icon: <User size={32} />,
          text: t('banner.empty_dashboard_tsb_message'),
        }
      : undefined;
  }, [items.length, t]);

  return {
    columns,
    items,
    onRenderCell,
    onRowClick,
    emptyStateProps,
  };
};
