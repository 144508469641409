import { forwardRef } from 'react';
import { BaseButton } from './index';
import type { ForwardedRef } from 'react';
import type { IButtonProps } from './types';

export const Button = forwardRef(
  (props: IButtonProps, buttonRef: ForwardedRef<HTMLButtonElement>) => {
    const content = typeof props.content === 'string' ? props.content : '';

    return (
      <BaseButton
        {...props}
        ref={buttonRef}
        isIcon={false}
        aria-label={props['aria-label'] || content || 'Button'}
      >
        {props.content}
      </BaseButton>
    );
  }
);

Button.displayName = 'Button';
