import {
  ICreateNoteRequest,
  ICreateNoteResponse,
  ICreateReportRequest,
  ICreateReportResponse,
  IDeleteFileRequest,
  IDeleteFileResponse,
  IDeleteNoteRequest,
  IDeleteNoteResponse,
  IDownloadFileRequest,
  IDownloadFileResponse,
  IGetFilesRequest,
  IGetFilesResponse,
  IGetMedicalReportByIdRequest,
  IGetMedicalReportByIdResponse,
  IGetMedicalReportRecordsRequest,
  IGetMedicalReportRecordsResponse,
  IGetNotesRequest,
  IGetNotesResponse,
  IGetTeamMedicalReportRequest,
  IGetTeamMedicalReportResponse,
  IMarkRecoveredRequest,
  IMarkRecoveredResponse,
  IUpdateNoteRequest,
  IUpdateNoteResponse,
  IUploadFileRequest,
  IUploadFileResponse,
} from './types';
import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';
import qs from 'qs';

interface IMedicalService {
  createReport: (p: ICreateReportRequest) => R<ICreateReportResponse>;
  getReports: (
    p: IGetMedicalReportRecordsRequest
  ) => R<IGetMedicalReportRecordsResponse>;
  getReportById: (
    p: IGetMedicalReportByIdRequest
  ) => R<IGetMedicalReportByIdResponse>;
  getTeamReport: (
    p: IGetTeamMedicalReportRequest
  ) => R<IGetTeamMedicalReportResponse>;

  getNotes: (p: IGetNotesRequest) => R<IGetNotesResponse>;
  createNote: (p: ICreateNoteRequest) => R<ICreateNoteResponse>;
  updateNote: (p: IUpdateNoteRequest) => R<IUpdateNoteResponse>;
  deleteNote: (p: IDeleteNoteRequest) => R<IDeleteNoteResponse>;

  getFiles: (p: IGetFilesRequest) => R<IGetFilesResponse>;
  uploadFile: (p: IUploadFileRequest) => R<IUploadFileResponse>;
  downloadFile: (p: IDownloadFileRequest) => R<IDownloadFileResponse>;
  deleteFile: (p: IDeleteFileRequest) => R<IDeleteFileResponse>;

  markRecovered: (p: IMarkRecoveredRequest) => R<IMarkRecoveredResponse>;
}

export class MedicalService implements IMedicalService {
  async createReport(p: ICreateReportRequest): R<ICreateReportResponse> {
    try {
      return axiosInstance.post(`/v1/medical`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReports(
    p: IGetMedicalReportRecordsRequest
  ): R<IGetMedicalReportRecordsResponse> {
    const params = qs.stringify(p);
    try {
      return axiosInstance.get(`/v1/medical?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReportById(
    p: IGetMedicalReportByIdRequest
  ): R<IGetMedicalReportByIdResponse> {
    try {
      return axiosInstance.get(`/v1/medical/${p.id}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getTeamReport(
    p: IGetTeamMedicalReportRequest
  ): R<IGetTeamMedicalReportResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.get(`/v1/medical/team?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getNotes(p: IGetNotesRequest): R<IGetNotesResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.get(`/v1/medical/note?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createNote(p: ICreateNoteRequest): R<ICreateNoteResponse> {
    try {
      return axiosInstance.post(`/v1/medical/note`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateNote(p: IUpdateNoteRequest): R<IUpdateNoteResponse> {
    try {
      return axiosInstance.put(`/v1/medical/note`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteNote(p: IDeleteNoteRequest): R<IDeleteNoteResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.delete(`/v1/medical/note?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getFiles(p: IGetFilesRequest): R<IGetFilesResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.get(`/v1/medical/file?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async uploadFile(p: IUploadFileRequest): R<IUploadFileResponse> {
    const params = qs.stringify({
      medicalReportId: p.get('medicalReportId'),
      fileDescription: p.get('fileDescription'),
    });
    try {
      return axiosInstance.post(
        `/v1/medical/file/upload?${params}`,
        {
          multipartFile: p.get('multipartFile'),
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async downloadFile(p: IDownloadFileRequest): R<IDownloadFileResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.get(`/v1/medical/file/download?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteFile(p: IDeleteFileRequest): R<IDeleteFileResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.delete(`/v1/medical/file?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async markRecovered(p: IMarkRecoveredRequest): R<IMarkRecoveredResponse> {
    try {
      const params = qs.stringify({
        recoveredOn: p.recoveredOn,
      });
      return axiosInstance.put(`/v1/medical/markRecovered/${p.id}?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
