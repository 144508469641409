import { IAdminTeamMappingsSlice, IAdminTeamMappingsState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ITeamMapping } from '../../../types/users';

export const initialState: IAdminTeamMappingsState = {
  mappings: [],
  mappingsLoaded: false,
};

export const useAdminTeamMappingsStore = create<IAdminTeamMappingsSlice>()(
  immer((set) => ({
    ...initialState,
    setMappings: (mappings: ITeamMapping[]) => {
      set((state) => {
        state.mappings = mappings;
        state.mappingsLoaded = true;
      });
    },
    setMappingsLoaded: (loaded: boolean) => {
      set((state) => {
        state.mappingsLoaded = loaded;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
