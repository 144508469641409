import {
  ICreateGroupRequest,
  ICreateGroupResponse,
  IDeleteGroupRequest,
  IDeleteGroupResponse,
  IGetGroupByIdRequest,
  IGetGroupByIdResponse,
  IGetGroupReportRequest,
  IGetGroupReportResponse,
  IGetGroupsResponse,
  IUpdateGroupRequest,
  IUpdateGroupResponse,
} from './types';
import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';
import qs from 'qs';

interface IGroupsService {
  getGroups: () => R<IGetGroupsResponse>;
  getGroupById: (p: IGetGroupByIdRequest) => R<IGetGroupByIdResponse>;
  createGroup: (p: ICreateGroupRequest) => R<ICreateGroupResponse>;
  updateGroup: (p: IUpdateGroupRequest) => R<IUpdateGroupResponse>;
  deleteGroup: (p: IDeleteGroupRequest) => R<IDeleteGroupResponse>;
  getGroupReport: (p: IGetGroupReportRequest) => R<IGetGroupReportResponse>;
}

export class GroupsService implements IGroupsService {
  async getGroups(): R<IGetGroupsResponse> {
    try {
      return axiosInstance.get(`/v1/group/all`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getGroupById(p: IGetGroupByIdRequest): R<IGetGroupByIdResponse> {
    try {
      return axiosInstance.get(`/v1/group/${p.id}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createGroup(p: ICreateGroupRequest): R<ICreateGroupResponse> {
    try {
      return axiosInstance.post(`/v1/group/add`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateGroup(p: IUpdateGroupRequest): R<IUpdateGroupResponse> {
    try {
      return axiosInstance.put(`/v1/group/update`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteGroup(p: IDeleteGroupRequest): R<IDeleteGroupResponse> {
    try {
      const params = qs.stringify(p, { arrayFormat: 'comma' });
      return axiosInstance.delete(`/v1/group/delete?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getGroupReport(p: IGetGroupReportRequest): R<IGetGroupReportResponse> {
    try {
      const params = qs.stringify({
        groupId: p.groupId,
      });
      return axiosInstance.get(
        `/v1/report/group/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
