import './index.css';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import {
  Avatar,
  Dialog,
  FormElement,
  ISelectOption,
  Toast,
} from '@cycling-web/analog-ui';
import { SelectSingleControl, UserRole } from '@cycling-web/common';
import { useAdminTeamMemberStore } from '../../../AdminPanel/store/slice';
import { IAthleteMappingForm, ITeamMapping } from '../../../../types/users';
import { UsersRepository } from '../../../../api/users/repository';
import { UsersService } from '../../../../api/users/service';
import { useAdminTeamMappingsStore } from '../../store/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../../constants';

type IProps = {
  athleteId: number;
  athleteToStaffMap: Record<UserRole, number | undefined>;
  onDismiss: () => void;
};

export const AssignRoleFormModal = ({
  athleteId,
  athleteToStaffMap,
  onDismiss,
}: IProps) => {
  const { t } = useTranslation();
  const staff = useAdminTeamMemberStore((s) => s.staff);
  const staffMap = useAdminTeamMemberStore((s) => s.staffMap);
  const setMappings = useAdminTeamMappingsStore((s) => s.setMappings);
  const [loading, setLoading] = useState<boolean>(false);

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  const userOptions: Record<UserRole, ISelectOption[]> = useMemo(() => {
    const options: Record<UserRole, ISelectOption[]> = {
      [UserRole.Coach]: [],
      [UserRole.SportsDirector]: [],
      [UserRole.Nutritionist]: [],
      [UserRole.Physiotherapist]: [],
      [UserRole.MentalCoach]: [],
      [UserRole.Doctor]: [],
      [UserRole.SecondaryDoctor]: [],
      [UserRole.Admin]: [],
      [UserRole.Athlete]: [],
      [UserRole.Management]: [],
    };

    staff.forEach((user) => {
      if (user.staffId) {
        const option = {
          id: user.staffId ? user.staffId.toString() : '',
          text: user.name,
        };

        for (const key in options) {
          if ((user.roles || []).includes(key as UserRole)) {
            options[key].push(option);
          }
        }
      }
    });

    return options;
  }, [staff]);

  const form = useForm<IAthleteMappingForm>({
    defaultValues: {
      athleteId: athleteId,
      roleStaffIdMap: {
        coach: athleteToStaffMap[UserRole.Coach],
        sports_director: athleteToStaffMap[UserRole.SportsDirector],
        nutritionist: athleteToStaffMap[UserRole.Nutritionist],
        physiotherapist: athleteToStaffMap[UserRole.Physiotherapist],
        mental_coach: athleteToStaffMap[UserRole.MentalCoach],
        doctor: athleteToStaffMap[UserRole.Doctor],
        secondary_doctor: athleteToStaffMap[UserRole.SecondaryDoctor],
      },
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = form;

  const handleSave = useCallback(
    (formData: IAthleteMappingForm) => {
      setLoading(true);

      usersRepository
        .updateAthleteMapping(formData)
        .then(() => {
          return usersRepository.getTeamMappings();
        })
        .then((mappings: ITeamMapping[]) => {
          setMappings(mappings);
          onDismiss();
        })
        .catch((error: AxiosError) => {
          if (error?.response?.status !== ApiErrorCode.Unauthorized) {
            Toast.error(
              {
                title: t('error.assign_role_title'),
                message: t('error.assign_role_message'),
              },
              { toastId: 'assign_role' }
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [onDismiss, t, setMappings, usersRepository]
  );

  const onSubmit = useCallback(() => {
    handleSubmit(
      (formData: IAthleteMappingForm) => {
        handleSave(formData);
      },
      (errors) => {
        console.log(errors);
      }
    )();
  }, [handleSave, handleSubmit]);

  const onRenderOption = useCallback(
    (option: ISelectOption) => {
      return <Avatar src={staffMap[option.id].picture} text={option.text} />;
    },
    [staffMap]
  );

  const handleClear = useCallback(
    (name: string) => {
      return () => {
        // @ts-ignore
        setValue(name, '');
      };
    },
    [setValue]
  );

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.assign_roles')}
        onDismiss={onDismiss}
        outsidePress={false}
        submitButtonProps={{
          content: t('action.save'),
          onClick: onSubmit,
          loading: loading,
        }}
      >
        <div className="assign-role-form-modal">
          <FormElement label={t('label.coach')}>
            <SelectSingleControl
              name="roleStaffIdMap.coach"
              placeholder={t('placeholder.select_user')}
              options={userOptions[UserRole.Coach]}
              onRenderOption={onRenderOption}
              dropdownProps={{
                maxHeight: '260px',
              }}
              search
              onClear={handleClear('roleStaffIdMap.coach')}
            />
          </FormElement>

          <FormElement label={t('label.sports_director')}>
            <SelectSingleControl
              name="roleStaffIdMap.sports_director"
              placeholder={t('placeholder.select_user')}
              options={userOptions[UserRole.SportsDirector]}
              onRenderOption={onRenderOption}
              dropdownProps={{
                maxHeight: '260px',
              }}
              search
              onClear={handleClear('roleStaffIdMap.sports_director')}
            />
          </FormElement>

          <FormElement label={t('label.nutritionist')}>
            <SelectSingleControl
              name="roleStaffIdMap.nutritionist"
              placeholder={t('placeholder.select_user')}
              options={userOptions[UserRole.Nutritionist]}
              onRenderOption={onRenderOption}
              dropdownProps={{
                maxHeight: '260px',
              }}
              search
              onClear={handleClear('roleStaffIdMap.nutritionist')}
            />
          </FormElement>

          <FormElement label={t('label.physiotherapist')}>
            <SelectSingleControl
              name="roleStaffIdMap.physiotherapist"
              placeholder={t('placeholder.select_user')}
              options={userOptions[UserRole.Physiotherapist]}
              onRenderOption={onRenderOption}
              dropdownProps={{
                maxHeight: '260px',
              }}
              search
              onClear={handleClear('roleStaffIdMap.physiotherapist')}
            />
          </FormElement>

          <FormElement label={t('label.mental_coach')}>
            <SelectSingleControl
              name="roleStaffIdMap.mental_coach"
              placeholder={t('placeholder.select_user')}
              options={userOptions[UserRole.MentalCoach]}
              onRenderOption={onRenderOption}
              dropdownProps={{
                maxHeight: '260px',
              }}
              search
              onClear={handleClear('roleStaffIdMap.mental_coach')}
            />
          </FormElement>

          <FormElement label={t('label.doctor')}>
            <SelectSingleControl
              name="roleStaffIdMap.doctor"
              placeholder={t('placeholder.select_user')}
              options={userOptions[UserRole.Doctor]}
              onRenderOption={onRenderOption}
              dropdownProps={{
                maxHeight: '260px',
              }}
              search
              onClear={handleClear('roleStaffIdMap.doctor')}
            />
          </FormElement>

          <FormElement label={t('label.secondary_doctor')}>
            <SelectSingleControl
              name="roleStaffIdMap.secondary_doctor"
              placeholder={t('placeholder.select_user')}
              options={userOptions[UserRole.SecondaryDoctor]}
              onRenderOption={onRenderOption}
              dropdownProps={{
                maxHeight: '260px',
              }}
              search
              onClear={handleClear('roleStaffIdMap.secondary_doctor')}
            />
          </FormElement>
        </div>
      </Dialog>
    </FormProvider>
  );
};
