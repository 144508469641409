import { useEffect, useState } from 'react';

type IProps = {
  targetValue: number;
};

export const AnimatedNumber = ({ targetValue }: IProps) => {
  const [displayedValue, setDisplayedValue] = useState(0);
  const duration = 1250;

  useEffect(() => {
    const start = performance.now();
    const initialValue = displayedValue;

    const animate = (currentTime: number) => {
      const elapsed = currentTime - start;
      const progress = Math.min(elapsed / duration, 1);
      const animatedValue = Math.round(
        initialValue + (targetValue - initialValue) * progress
      );

      setDisplayedValue(animatedValue);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animate as any);
  }, [targetValue]);

  return <>{displayedValue}%</>;
};
