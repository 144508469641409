import { createContext, useContext } from 'react';

export type IOnboardingContext = {
  termsAndConditionsConfirmed: boolean;
  setTermsAndConditionsConfirmed: (f: boolean) => void;
  privacyNoticeConfirmed: boolean;
  setPrivacyNoticeConfirmed: (f: boolean) => void;
};

export const OnboardingContext = createContext<IOnboardingContext>(
  {} as IOnboardingContext
);

export const useOnboardingContext = () => useContext(OnboardingContext);
