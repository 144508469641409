import { useEffect, useMemo, useRef } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { useAthletePerformanceStore } from '../store/slice';
import { format } from 'date-fns';
import {
  IFatigueResistance,
  IFatigueResistanceRelative,
} from '../../../types/performance';
import { calculateDateRange } from '../../../utils/utils';
import { Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchFatigueResistance = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const filters = useAthletePerformanceStore((s) => s.filters);
  const setFatigueResistance = useAthletePerformanceStore(
    (s) => s.setFatigueResistance
  );
  const setFatigueResistanceLoaded = useAthletePerformanceStore(
    (s) => s.setFatigueResistanceLoaded
  );
  const fetchingRef = useRef<boolean>(false);
  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService());
  }, []);

  useEffect(() => {
    if (fetchingRef.current || !athleteId) {
      return;
    }
    fetchingRef.current = true;

    const { startDate, endDate } = calculateDateRange(filters.shared.period);

    const request1 = performanceRepository.getFatigueResistance({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      athleteId: +athleteId,
    });

    const request2 = performanceRepository.getFatigueResistanceRelative({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      athleteId: +athleteId,
    });

    Promise.all([request1, request2])
      .then((response: [IFatigueResistance, IFatigueResistanceRelative]) => {
        setFatigueResistance(response[0], response[1]);
      })
      .catch((error: AxiosError) => {
        setFatigueResistanceLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_power_duration_title'),
              message: t('error.get_power_duration_message'),
            },
            { toastId: 'get_power_duration' }
          );
        }
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [
    athleteId,
    filters.shared.period,
    performanceRepository,
    setFatigueResistance,
    setFatigueResistanceLoaded,
    t,
  ]);
};
