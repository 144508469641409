import './index.css';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { UsersRepository } from '../../../../api/users/repository';
import { UsersService } from '../../../../api/users/service';
import {
  IInviteUserForm,
  IUserBase,
  UserStatus,
} from '../../../../types/users';
import { InputControl, UserRole } from '@cycling-web/common';
import {
  Dialog,
  FormElement,
  IMultiSelectOnChange,
  ISelectOption,
  Select,
  Toast,
} from '@cycling-web/analog-ui';
import { useAdminTeamMemberStore } from '../../../AdminPanel/store/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../../constants';

type IProps = {
  onDismiss: () => void;
};

export const InviteUserFormModal = ({ onDismiss }: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const roleOptions: ISelectOption[] = [
    {
      id: UserRole.Athlete,
      text: t('label.athlete'),
    },
    {
      id: 'divider',
      text: '',
      type: 'divider',
    },
    { id: UserRole.Admin, text: t('label.admin') },
    { id: UserRole.SportsDirector, text: t('label.sports_director') },
    { id: UserRole.Coach, text: t('label.coach') },
    { id: UserRole.Doctor, text: t('label.doctor') },
    { id: UserRole.SecondaryDoctor, text: t('label.secondary_doctor') },
    { id: UserRole.Nutritionist, text: t('label.nutritionist') },
    { id: UserRole.Physiotherapist, text: t('label.physiotherapist') },
    { id: UserRole.MentalCoach, text: t('label.mental_coach') },
    { id: UserRole.Management, text: t('label.management') },
  ];

  const userRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        userEmail: z.string().min(1, { message: t('validation.required') }),
        userRoles: z
          .array(z.string())
          .min(1, { message: t('validation.required') }),
        fullName: z.string().min(1, { message: t('validation.required') }),
      })
      .passthrough();
  }, [t]);

  const form = useForm<IInviteUserForm>({
    defaultValues: {
      userEmail: '',
      userRoles: [],
      fullName: '',
    },
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = form;
  const userRoles = watch('userRoles');

  const handleSave = useCallback(
    (formData: IInviteUserForm) => {
      setLoading(true);

      userRepository
        .inviteUser(formData)
        .then(() => {
          const user: IUserBase = {
            id: -1,
            email: formData.userEmail,
            name: formData.fullName,
            roles: formData.userRoles as UserRole[],
            picture: '',
            status: UserStatus.Pending,
          };
          useAdminTeamMemberStore.getState().appendUser(user);
          onDismiss();
        })
        .catch((error: AxiosError) => {
          if (error?.response?.status !== ApiErrorCode.Unauthorized) {
            // @ts-ignore
            const message = error.response?.data?.message;
            Toast.error(
              {
                title: t('error.invite_user_title'),
                message: message || t('error.invite_user_message'),
              },
              { toastId: 'invite_user' }
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [onDismiss, t, userRepository]
  );

  const onSubmit = useCallback(() => {
    handleSubmit(
      (formData: IInviteUserForm) => {
        handleSave(formData);
      },
      (errors) => {
        console.log(errors);
      }
    )();
  }, [handleSave, handleSubmit]);

  const onRoleChange = useCallback(
    (options: ISelectOption[], selectedOption: ISelectOption) => {
      const isAthleteSelected = selectedOption?.id === UserRole.Athlete;

      if (isAthleteSelected) {
        const nextRoles = [selectedOption.id];
        setValue('userRoles', nextRoles);
      } else {
        let nextRoles = options.map((v: ISelectOption) => v.id);
        const hasAthlete = nextRoles.includes(UserRole.Athlete);

        if (hasAthlete) {
          nextRoles = nextRoles.filter(
            (role: string) => role !== UserRole.Athlete
          );
        }
        setValue('userRoles', nextRoles);
      }
    },
    [setValue]
  );

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.invite_user')}
        onDismiss={onDismiss}
        outsidePress={false}
        submitButtonProps={{
          content: t('action.save'),
          onClick: onSubmit,
          loading: loading,
        }}
      >
        <div className="performance-form-modal">
          <FormElement
            label={t('label.email')}
            message={errors.userEmail?.message}
          >
            <InputControl
              name="userEmail"
              placeholder={t('placeholder.email')}
              invalid={!!errors.userEmail}
            />
          </FormElement>

          <FormElement
            label={t('label.name')}
            message={errors.fullName?.message}
          >
            <InputControl
              name="fullName"
              placeholder={t('placeholder.full_name')}
              invalid={!!errors.fullName}
            />
          </FormElement>

          <FormElement
            label={t('label.role')}
            message={errors.userRoles?.message}
          >
            <Select
              placeholder={t('placeholder.role')}
              options={roleOptions}
              value={roleOptions.filter((o: ISelectOption) =>
                userRoles.includes(o.id)
              )}
              onChange={onRoleChange as IMultiSelectOnChange}
              invalid={!!errors.userRoles}
              multiple
              tags
            />
          </FormElement>
        </div>
      </Dialog>
    </FormProvider>
  );
};
