import './index.css';
import { AxiosError } from 'axios';
import {
  Button,
  Divider,
  IconButton,
  onCopy,
  Tag,
  Typography,
} from '@cycling-web/analog-ui';
import { Widget } from './Widget';
import { Copy, X } from 'lucide-react';
import { useEffect, useState } from 'react';

type IProps = {
  error: AxiosError;
  onDismiss: () => void;
};

export const BackendErrorOverlay = ({ error, onDismiss }: IProps) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  const handleCopyClick = () => {
    if (!error.config) {
      return;
    }

    const divider = `\n\n------------------------------------------------\n\n`;

    const url = `URL [${error.config.method?.toUpperCase()}]:\n\n${
      error.config.baseURL
    }${error.config.url}\n\n`;

    const body =
      (error.config.method === 'post' || error.config.method === 'put') &&
      error.config.data
        ? `Body:\n\n${JSON.stringify(JSON.parse(error.config.data), null, 2)}`
        : 'Body: ';

    const token = `Bearer Token:\n\n${
      error.config.headers.Authorization || ''
    }\n\n`;
    const team = `Team:\n\n${error.config.headers['X-team-name'] || ''}`;

    const response = `Response:\n\n${JSON.stringify(
      error.response?.data,
      null,
      2
    )}`;

    const content = `${url}${divider}${body}${divider}${token}${divider}${team}${divider}${response}`;

    onCopy(content);
    setCopied(true);
  };

  if (!error.config) {
    return null;
  }

  return (
    <div className="backend-error-overlay">
      <div className="backend-error-overlay__widget">
        <IconButton
          className="backend-error-overlay__x"
          variant="quietNeutral"
          content={<X />}
          onClick={onDismiss}
        />

        <header className="backend-error-overlay__header">
          <Typography
            as="h3"
            variant="h3"
            weight="bold"
            text="An error occurred"
          />
          <div>
            Click the button below to copy everything about this error and send
            it to the development team.
          </div>
          <div>
            <Button
              className="backend-error-overlay__copy-all"
              startIcon={<Copy />}
              content={copied ? 'Copied' : 'Copy error details'}
              onClick={handleCopyClick}
            />
          </div>
        </header>

        <Divider className="backend-error-overlay__divider" />

        <Widget
          title="URL"
          copyContent={`${error.config.baseURL}${error.config.url}`}
        >
          <Tag
            text={error.config.method?.toUpperCase()}
            size="m"
            className="backend-error-overlay__error-method"
          />
          {error.config.baseURL}
          {error.config.url}
        </Widget>

        {(error.config.method === 'post' || error.config.method === 'put') &&
          error.config.data && (
            <Widget title="Request Body">
              <pre className="backend-error-overlay__error-json analog-typography--body">
                {JSON.stringify(JSON.parse(error.config.data), null, 2)}
              </pre>
            </Widget>
          )}

        <Widget title="Bearer Token">
          {error.config.headers.Authorization || '-'}
        </Widget>

        <Widget title="Team">
          {error.config.headers['X-team-name'] || '-'}
        </Widget>

        <Widget
          title="Response"
          copyContent={JSON.stringify(error.response?.data, null, 2)}
        >
          <pre className="backend-error-overlay__error-json analog-typography--body">
            {JSON.stringify(error.response?.data, null, 2)}
          </pre>
        </Widget>
      </div>
    </div>
  );
};
