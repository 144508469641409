import './index.css';
import { NavLink } from 'react-router';
import { clsx } from 'clsx';
import { INavigationLink } from '@cycling-web/common';

type IProps = {
  item: INavigationLink;
};

export const MenuLink = ({ item }: IProps) => {
  const standaloneLinkClass = ({ isActive }: { isActive: boolean }) => {
    return clsx(
      'side-navigation__standalone-link',
      'analog-typography--body',
      'bold',
      isActive && 'side-navigation__standalone-link--active'
    );
  };

  return (
    <NavLink className={standaloneLinkClass} to={item.url}>
      {item.icon && item.icon}
      <span className="side-navigation__standalone-link-text">{item.text}</span>
    </NavLink>
  );
};
