import {
  IAthleteMedicalReportsSlice,
  IAthleteMedicalReportsState,
} from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IAthleteMedicalReportsFilters } from '../types';
import { IMedicalReport, IMedicalReportRecord } from '../../../types/medical';
import { MedicalRepository } from '../../../api/medical/repository';
import { MedicalService } from '../../../api/medical/service';

export const initialFilters: IAthleteMedicalReportsFilters = {
  search: '',
};

export const initialState: IAthleteMedicalReportsState = {
  reports: [],
  reportsLoaded: false,
  filters: {
    search: initialFilters.search,
  },
};

const medicalRepository = new MedicalRepository(new MedicalService());

export const useAthleteMedicalReportsStore =
  create<IAthleteMedicalReportsSlice>()(
    immer((set) => ({
      ...initialState,
      setReports: (reports: IMedicalReportRecord[]) => {
        set((state) => {
          state.reports = reports;
          state.reportsLoaded = true;
        });
      },
      setReportsLoaded: (loaded: boolean) => {
        set((state) => {
          state.reportsLoaded = loaded;
        });
      },
      appendReport: (report: IMedicalReportRecord) => {
        set((state) => {
          state.reports.push(report);
        });
      },
      setFilters: (filters: Partial<IAthleteMedicalReportsFilters>) => {
        set((state) => {
          state.filters = {
            ...state.filters,
            ...filters,
          };
        });
      },
      reset: () => {
        set(initialState);
      },
    }))
  );
