import { useEffect, useMemo, useRef } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { IPerformanceReport } from '../../../types/performance';
import { Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { usePerformanceReportStore } from '../../PerformanceReport/store/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchWeeklyPerformanceReports = () => {
  const { t } = useTranslation();
  const filters = usePerformanceReportStore((s) => s.filters);
  const setReports = usePerformanceReportStore((s) => s.setReports);
  const setReportsLoaded = usePerformanceReportStore((s) => s.setReportsLoaded);
  const fetchingRef = useRef<boolean>(false);

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService());
  }, []);

  useEffect(() => {
    if (fetchingRef.current) {
      return;
    }

    fetchingRef.current = true;
    performanceRepository
      .getPerformanceReport(filters.week)
      .then((reports: IPerformanceReport[]) => {
        setReports(reports);
      })
      .catch((error: AxiosError) => {
        setReportsLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_weekly_performance_report_title'),
              message: t('error.get_weekly_performance_report_message'),
            },
            { toastId: 'get_weekly_performance_report' }
          );
        }
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [filters.week, performanceRepository, setReports, setReportsLoaded, t]);
};
