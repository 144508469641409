import './index.css';
import { Outlet } from 'react-router';
import { useFetchEnums } from '../../hooks/useFetchEnums';
import { useBaseUrl } from '../../hooks/useBaseUrl';
import { useTranslation } from 'react-i18next';
import { INavigationItem } from '@cycling-web/common';
import { useMemo } from 'react';
import { ROUTES } from '../../router/routes';
import { User, Zap } from 'lucide-react';
import { SideNavigation } from '../../components/SideNavigation';
import { useUsersStore } from '../../store/users/slice';
import { useFetchAthletes } from '../../hooks/useFetchAthletes';

export const EntryAthlete = () => {
  useFetchEnums();
  useFetchAthletes();

  const userProfile = useUsersStore((s) => s.userProfile);
  const baseUrl = useBaseUrl();
  const defaultUrl = `${baseUrl}/${ROUTES.ATHLETE}/${userProfile?.athleteId}`;

  const { t } = useTranslation();
  const items: INavigationItem[] = useMemo(() => {
    if (!userProfile) {
      return [];
    }
    return [
      {
        url: `${defaultUrl}/${ROUTES.PROFILE}`,
        icon: <User />,
        text: t('label.my_profile'),
      },
      {
        url: `${defaultUrl}/${ROUTES.SMART_CHARTS}`,
        icon: <Zap />,
        text: t('label.smart_charts'),
      },
    ];
  }, [defaultUrl, t, userProfile]);

  return (
    <div className="athlete">
      <SideNavigation defaultUrl={defaultUrl} items={items} />
      <div className="athlete-content">
        <Outlet />
      </div>
    </div>
  );
};
