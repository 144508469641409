import { useForm } from 'react-hook-form';
import { Toast } from '@cycling-web/analog-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { IPerformanceInfoForm } from '../types';
import { AthletesRepository } from '../../../../../api/athletes/repository';
import { AthletesService } from '../../../../../api/athletes/service';
import {
  localWithUTCMidnightTimestamp,
  UTCMidnightToSameDate,
} from '../../../../../utils/date-time';
import { IAthlete } from '../../../../../types/athletes';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../../../constants';

type IProps = {
  athlete: IAthlete;
};

export const useViewModel = ({ athlete }: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const athletesRepository = useMemo(() => {
    return new AthletesRepository(new AthletesService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        dobTimeStampUTC: z.date().refine((date) => !isNaN(date.getTime()), {
          message: t('validation.required'),
        }),
        height: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v >= 150 && v <= 200, {
            message: t('validation.out_of_range', { min: 150, max: 200 }),
          }),
        weight: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v >= 40 && v <= 100, {
            message: t('validation.out_of_range', { min: 40, max: 100 }),
          }),
        ftp: z
          .string()
          .min(1, { message: t('validation.required') })
          .refine((val) => /^\d+$/.test(val), {
            message: t('validation.numeric'),
          })
          .refine((val) => +val >= 100 && +val <= 999, {
            message: t('validation.out_of_range', { min: 100, max: 999 }),
          }),
      })
      .passthrough();
  }, [t]);

  const form = useForm<IPerformanceInfoForm>({
    defaultValues: {
      dobTimeStampUTC: athlete.dobTimeStampUTC
        ? UTCMidnightToSameDate(athlete.dobTimeStampUTC)
        : undefined,
      weight: athlete.weight ? athlete.weight.toString() : '',
      height: athlete.height ? athlete.height.toString() : '',
      ftp: athlete.ftp ? athlete.ftp.toString() : '',
    },
    resolver: zodResolver(schema),
  });
  const { handleSubmit } = form;

  const formatDate = useCallback((date: Date) => {
    return date;
  }, []);

  const onSubmit = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      handleSubmit(
        (formData: IPerformanceInfoForm) => {
          setLoading(true);

          athletesRepository
            .updateProfile({
              ...athlete,
              ...formData,
              dobTimeStampUTC: localWithUTCMidnightTimestamp(
                formData.dobTimeStampUTC
              ),
              weight: +formData.weight,
              height: +formData.height,
              ftp: +formData.ftp,
            })
            .then(() => {
              resolve(true);
            })
            .catch((error: AxiosError) => {
              if (error?.response?.status !== ApiErrorCode.Unauthorized) {
                Toast.error({
                  title: t('error.update_profile_title'),
                  message: t('error.update_profile_message'),
                });
              }
            })
            .finally(() => {
              setLoading(false);
            });
        },
        () => {
          return resolve(false);
        }
      )();
    });
  }, [athlete, athletesRepository, handleSubmit, t]);

  return {
    form,
    onSubmit,
    formatDate,
    loading,
  };
};
