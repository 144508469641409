import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useEffect, useMemo, useRef } from 'react';
import { useAthleteStore } from '../../../store/athlete/slice';
import { AthletesRepository } from '../../../api/athletes/repository';
import { AthletesService } from '../../../api/athletes/service';
import { IAthlete } from '../../../types/athletes';
import { Toast } from '@cycling-web/analog-ui';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchAthlete = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const fetching = useRef<boolean>(false);

  const setAthlete = useAthleteStore((s) => s.setAthlete);
  const setAthleteLoaded = useAthleteStore((s) => s.setAthleteLoaded);

  const athletesRepository = useMemo(() => {
    return new AthletesRepository(new AthletesService());
  }, []);

  useEffect(() => {
    if (fetching.current || !athleteId) {
      return;
    }

    fetching.current = true;
    athletesRepository
      .getAthleteById(athleteId)
      .then((athlete: IAthlete) => {
        setAthlete(athlete);
      })
      .catch((error: AxiosError) => {
        setAthleteLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_athlete_by_id_title'),
              message: t('error.get_athlete_by_id_message'),
            },
            { toastId: 'get_athlete_by_id' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [setAthleteLoaded, athletesRepository, athleteId, setAthlete, t]);
};
