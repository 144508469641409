import './index.css';
import AnalogSportLogoBg from '../../assets/illustrations/analog-sport-logo-bg.svg';
import AnalogSportLogoLogo from '../../assets/illustrations/analog-sport-logo.svg';
import AnalogSportLogoCollapse from '../../assets/illustrations/analog-sport-logo-collapse.svg';
import { useTranslation } from 'react-i18next';
import { MenuDesktop } from './components/MenuDesktop';
import { Link } from 'react-router';
import { INavigationItem } from '@cycling-web/common';
import { MenuMobile } from './components/MenuMobile';

type IProps = {
  defaultUrl: string;
  items: INavigationItem[];
};

export const SideNavigation = ({ defaultUrl, items }: IProps) => {
  const { t } = useTranslation();
  return (
    <aside className="side-navigation">
      <Link
        to={defaultUrl}
        className="side-navigation__logo"
        style={{ backgroundImage: `url(${AnalogSportLogoBg})` }}
      >
        <img
          className="side-navigation__logo-l"
          src={AnalogSportLogoLogo}
          alt={t('label.analog_sport_logo')}
        />
        <img
          className="side-navigation__logo-s"
          src={AnalogSportLogoCollapse}
          alt={t('label.analog_sport_logo')}
        />
      </Link>

      <div className="side-navigation__content">
        <MenuDesktop items={items} />
        <MenuMobile items={items} />
      </div>
    </aside>
  );
};
