import './index.css';
import { ReactNode } from 'react';
import { Skeleton, Typography } from '@cycling-web/analog-ui';

type IProps = {
  title: string;
  icon: ReactNode;
  count: number;
  total: number;
  loading: boolean;
  onClick: () => void;
};

export const SummaryCard = (props: IProps) => {
  return (
    <div className="dashboard-summary__card" onClick={props.onClick}>
      <header className="dashboard-summary__card-header">
        <Typography
          className="dashboard-summary__card-title"
          weight="bold"
          variant="headline"
          text={props.title}
          loading={props.loading}
          skeletonProps={{
            width: '80px',
          }}
        />
        {!props.loading && props.icon}
      </header>
      <div className="dashboard-summary__card-details">
        {props.loading ? (
          <Skeleton height="38.4px" width="140px" />
        ) : (
          <>
            <Typography
              className="dashboard-summary__card-details-value"
              variant="h2"
              weight="bold"
              text={props.count}
            />
            <Typography variant="h4" text={`/${props.total}`} />
          </>
        )}
      </div>
    </div>
  );
};
