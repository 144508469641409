import './index.css';
import { Typography } from '@cycling-web/analog-ui';
import { ReactNode } from 'react';

type IProps = {
  title: string;
  children: ReactNode;
};

export const PlatformsGroup = ({ title, children }: IProps) => {
  return (
    <div className="settings__platform-group">
      <Typography
        className="settings__platform-group-title"
        variant="headline"
        text={title}
      />
      <div className="settings__platform-group-items">{children}</div>
    </div>
  );
};
