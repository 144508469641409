import './index.css';
import { DailyWellnessScores } from './components/DailyWellnessScores';
import { useViewModel } from './useViewModel';
import { OverallWellnessChart } from './components/OverallWellnessChart';
import { RhrChart } from './components/RhrChart';
import { HrvChart } from './components/HrvChart';
import { BodyCompositionChartWrapper } from './components/BodyCompositionChartWrapper';

export const AthleteWellness = () => {
  const {
    filters,
    handleChangeFilter,
    selfReportedWellness,
    selfReportedWellnessLoading,
    heartMeasurements,
    heartMeasurementsLoading,
  } = useViewModel();

  return (
    <div className="wellness-page">
      <DailyWellnessScores />

      <div className="wellness-page__charts">
        <OverallWellnessChart
          data={selfReportedWellness}
          loading={selfReportedWellnessLoading}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
        />
        <BodyCompositionChartWrapper />
        <HrvChart
          data={heartMeasurements}
          loading={heartMeasurementsLoading}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
        />
        <RhrChart
          data={heartMeasurements}
          loading={heartMeasurementsLoading}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
        />
      </div>
    </div>
  );
};
