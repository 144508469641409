import { useParams } from 'react-router';
import { useBaseUrl } from '../../hooks/useBaseUrl';
import { ROUTES } from '../../router/routes';
import { useGroupsStore } from '../../store/groups/slice';
import { useEffect } from 'react';
import { IGroup } from '../../types/groups';
import { EmptyState } from '@cycling-web/analog-ui';
import { User } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useFetchGroupReports } from './hooks/useFetchGroupReports';
import { useGroupReportsStore } from './store/slice';
import { setTitle } from '../../hooks/useTitle';
import { useFetchPinnedTabs } from './hooks/useFetchPinnedTabs';
import { usePinnedTabStore } from '../../store/pinned-tabs/slice';

export const useViewModel = () => {
  useFetchGroupReports();
  useFetchPinnedTabs();

  const { t } = useTranslation();
  const { groupId } = useParams();

  const baseUrl = useBaseUrl();
  const backUrl = `${baseUrl}/${ROUTES.COACH}/${ROUTES.GROUPS}`;

  const setGroup = useGroupsStore((s) => s.setGroup);
  const groups = useGroupsStore((s) => s.groups);
  const groupsLoaded = useGroupsStore((s) => s.groupsLoaded);
  const group = useGroupsStore((s) => s.group);

  useEffect(() => {
    const nextGroup = groups.find((g: IGroup) => g.id.toString() === groupId);
    if (nextGroup) {
      setTitle(t(nextGroup.name));
    }
    setGroup(nextGroup || null);
  }, [t, groupsLoaded, setGroup, groups, groupId]);

  useEffect(() => {
    return () => {
      useGroupReportsStore.getState().reset();
      usePinnedTabStore.getState().setPinnedTabsLoaded(false);
      usePinnedTabStore.getState().setPinnedTabs([]);
      useGroupsStore.getState().setGroup(null);
    };
  }, []);

  const emptyState = (
    <EmptyState
      icon={<User size={32} />}
      title={t('banner.empty_group_title')}
      text={t('banner.empty_group_message')}
    />
  );

  return {
    backUrl,
    groupsLoaded,
    group,
    emptyState,
  };
};
