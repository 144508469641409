import { IMedicalReportsSlice, IMedicalReportsState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IMedicalReportsFilters } from '../types';
import { IMedicalReportRecord } from '../../../types/medical';

export const initialFilters: IMedicalReportsFilters = {
  search: '',
};

export const initialState: IMedicalReportsState = {
  reports: [],
  reportsLoaded: false,
  filters: initialFilters,
};

export const useMedicalReportsStore = create<IMedicalReportsSlice>()(
  immer((set) => ({
    ...initialState,
    setReports: (reports: IMedicalReportRecord[]) => {
      set((state) => {
        state.reports = reports;
        state.reportsLoaded = true;
      });
    },
    setReportsLoaded: (loaded: boolean) => {
      set((state) => {
        state.reportsLoaded = loaded;
      });
    },
    setFilters: (filters: Partial<IMedicalReportsFilters>) => {
      set((state) => {
        state.filters = {
          ...state.filters,
          ...filters,
        };
      });
    },
    reset: () => {
      set({
        ...initialState,
        filters: initialFilters,
      });
    },
  }))
);
