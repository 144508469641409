import { useTranslation } from 'react-i18next';
import { Page } from '@cycling-web/common';
import { AdminPageTabs } from './components/AdminPageTabs';
import { Outlet } from 'react-router';
import { useFetchAdminTeamMembers } from './hooks/useFetchAdminTeamMembers';

export const AdminPanel = () => {
  const { t } = useTranslation();
  useFetchAdminTeamMembers();

  return (
    <Page pageHeaderProps={{ title: t('label.admin_panel') }}>
      <AdminPageTabs />
      <Outlet />
    </Page>
  );
};
