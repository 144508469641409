import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { ITabsItem } from '@cycling-web/analog-ui';
import { ROUTES } from '../../../../router/routes';
import { PageTabs } from '@cycling-web/common';

export const PerformanceReportTabs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const url = pathname.split('/');

  const items: ITabsItem[] = [
    {
      path: `${ROUTES.PERFORMANCE_TABLE}`,
      text: t('label.summary_table'),
      isActive: url.at(-1) === ROUTES.PERFORMANCE_TABLE,
      preserveSearchParams: true,
    },
    {
      path: ROUTES.PERFORMANCE_CHART,
      text: t('label.performance_chart'),
      isActive: url.at(-1) === ROUTES.PERFORMANCE_CHART,
      preserveSearchParams: true,
    },
  ];

  return <PageTabs tabs={items} />;
};
