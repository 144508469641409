import './index.css';
import { MCP } from './components/MCP';
import { VO2Max } from './components/VO2Max';
import { CTL } from './components/CTL';
import { ATL } from './components/ATL';
import { TSB } from './components/TSB';
import { RHR } from './components/RHR';
import { HRV } from './components/HRV';
import { StatsHeader } from './components/StatsHeader';
import { HRVRHRFeedback } from './components/HRVRHRFeedback';
import { useContext } from 'react';
import { AthleteCardContext } from '../../context';
import { useUserRole } from '../../../../hooks/useUserRole';

export const Stats = () => {
  const { isStaff } = useUserRole();
  const { athlete, loading } = useContext(AthleteCardContext);

  return (
    <div className="athlete-card__stats">
      <StatsHeader />

      <div className="athlete-card__stats-wrapper">
        <div className="athlete-card__stats-header">
          <CTL athlete={athlete} loading={loading} />
          <ATL athlete={athlete} loading={loading} />
          <TSB athlete={athlete} loading={loading} />
        </div>

        <div className="athlete-card__stats-middle">
          <MCP athlete={athlete} loading={loading} direction="column" />
          <div className="athlete-card__stats-divider" />
          <VO2Max athlete={athlete} loading={loading} direction="column" />
        </div>

        <div className="athlete-card__stats-footer">
          <RHR athlete={athlete} loading={loading} />
          <HRV athlete={athlete} loading={loading} />
        </div>
      </div>

      {isStaff && <HRVRHRFeedback athlete={athlete} />}
    </div>
  );
};
