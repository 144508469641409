import {
  createRef,
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import type { ITabsItem, ITabsProps } from './types';
import './index.css';
import { Tag } from '../Tag';
import { Tooltip } from '../Tooltip';
import { Spinner } from '../Spinner';
import { Info } from 'lucide-react';
import { clsx } from 'clsx';

export const Tabs = ({ items, onChange, content, className }: ITabsProps) => {
  const underlineRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<RefObject<HTMLButtonElement | null>[]>([]);
  const activeTab = useMemo(() => {
    return items.findIndex((tab: ITabsItem) => tab.isActive);
  }, [items]);

  const setLinePosition = useCallback((element: HTMLButtonElement) => {
    if (underlineRef.current) {
      const width: number = element.offsetWidth;
      const x: number = element.offsetLeft;

      underlineRef.current.style.left = `${x}px`;
      underlineRef.current.style.width = `${width}px`;
    }
  }, []);

  useEffect(() => {
    if (activeTab < 0) {
      return;
    }

    if (items.length > 0 && tabRefs.current[activeTab].current) {
      const element = tabRefs.current[activeTab].current;
      if (element) {
        setLinePosition(element);
      }
    }
  }, [activeTab, items, setLinePosition]);

  const handleChange = useCallback(
    (index: number) => (e: MouseEvent<HTMLButtonElement>) => {
      onChange(index);
      e.currentTarget.blur();
    },
    [onChange]
  );

  const itemsJSX = items.map((item: ITabsItem, index: number) => {
    if (!tabRefs.current[index]) {
      tabRefs.current[index] = createRef();
    }

    const itemClass: string = clsx(
      'analog-tabs__tab-item',
      'analog-typography--body bold',
      item.isActive && 'analog-tabs__tab-item--active'
    );
    return (
      <button
        key={index}
        className={itemClass}
        onClick={handleChange(index)}
        disabled={item.disabled}
        ref={tabRefs.current[index]}
      >
        {item.text}
        {!item.disabled && item.tooltipProps && (
          <div className="analog-tabs__tab-item-tooltip">
            <Tooltip
              {...item.tooltipProps}
              anchor={
                <Info className="analog-tabs__tab-tooltipIcon" size={20} />
              }
            />
          </div>
        )}
        {item.tag && (
          <div className="analog-tabs__tab-item-tag">
            <Tag
              size={item.tag.size}
              shape={item.tag.shape}
              variant={item.tag.variant}
              text={item.tag.text}
            />
          </div>
        )}
        {!item.disabled && item.loading && (
          <div className="analog-tabs__tab-item-spinner">
            <Spinner size="s" />
          </div>
        )}
      </button>
    );
  });

  const rootClass: string = clsx('analog-tabs', className);

  const contentJSX =
    content || (items[activeTab] ? items[activeTab].content : null);

  return (
    <div className={rootClass}>
      <div className="analog-tabs__tabs">
        <div className="analog-tabs__tabs-items">{itemsJSX}</div>
        <div className="analog-tabs__tabs-divider" />
        <div className="analog-tabs__active-underline" ref={underlineRef} />
      </div>
      {contentJSX && <div className="analog-tabs__content">{contentJSX}</div>}
    </div>
  );
};
