import './index.css';
import { Select } from '@cycling-web/analog-ui';
import { IExtendSelectProps, ISelectOption } from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeRange } from '../../../types/enums';

type IProps = {
  options?: TimeRange[];
  value: TimeRange;
  onChange: (value: TimeRange) => void;
  selectProps?: IExtendSelectProps;
};

export const TimeRangeFilter = ({
  value,
  onChange,
  options = [
    TimeRange.TenSeconds,
    TimeRange.ThirtySeconds,
    TimeRange.OneMinute,
    TimeRange.FiveMinutes,
    TimeRange.TenMinutes,
    TimeRange.TwentyMinutes,
    TimeRange.ThirtyMinutes,
    TimeRange.SixtyMinutes,
  ],
  selectProps,
}: IProps) => {
  const { t } = useTranslation();

  const textMap: Record<TimeRange, string> = useMemo(() => {
    return {
      [TimeRange.TenSeconds]: t('label.n_seconds', { n: 10 }),
      [TimeRange.ThirtySeconds]: t('label.n_seconds', { n: 30 }),
      [TimeRange.OneMinute]: t('label.n_minute', { n: 1 }),
      [TimeRange.FiveMinutes]: t('label.n_minutes', { n: 5 }),
      [TimeRange.TenMinutes]: t('label.n_minutes', { n: 10 }),
      [TimeRange.TwentyMinutes]: t('label.n_minutes', { n: 20 }),
      [TimeRange.ThirtyMinutes]: t('label.n_minutes', { n: 30 }),
      [TimeRange.SixtyMinutes]: t('label.n_minutes', { n: 60 }),
    };
  }, [t]);

  const selectOptions: ISelectOption[] = useMemo(() => {
    return options.map((o: TimeRange) => {
      return {
        id: o.toString(),
        text: textMap[o],
      };
    });
  }, [options, textMap]);

  const handleChange = useCallback(
    (option: ISelectOption) => {
      onChange(option.id as TimeRange);
    },
    [onChange]
  );

  return (
    <div className="timeframe-filter">
      <Select
        options={selectOptions}
        value={selectOptions.find((o: ISelectOption) => o.id === value)}
        onChange={handleChange}
        placeholder={t('placeholder.timeframe')}
        {...selectProps}
      />
    </div>
  );
};
