type IProps = {
  blob: Blob;
  fileName: string;
  mime: string | undefined;
};

export const dataURLToBlob = (dataURL: string): IProps => {
  const [header, base64] = dataURL.split(',');
  const mime = header.match(/:(.*?);/)?.[1];
  const binary = atob(base64);
  const array = new Uint8Array(binary.length);

  for (let i = 0; i < binary.length; i++) {
    array[i] = binary.charCodeAt(i);
  }

  return {
    blob: new Blob([array], { type: mime }),
    fileName: `img-${Date.now()}`,
    mime,
  };
};

export const blobToFile = (props: IProps) => {
  return new File([props.blob], props.fileName, { type: props.mime });
};

export const imageUrlToBlob = async (imageUrl: string): Promise<Blob> => {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }
    return await response.blob();
  } catch (error) {
    console.error('Error converting image URL to Blob:', error);
    throw error;
  }
};
