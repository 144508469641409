import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { FileMinus } from 'lucide-react';
import { usePerformanceReportStore } from '../../../PerformanceReport/store/slice';
import { IEmptyStateProps, ITableColumn } from '@cycling-web/analog-ui';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { filterReportsDelegate } from '../../../PerformanceReport/utils/filterReportsDelegate';
import { useTableColumns } from '../../hooks/useTableColumns';
import { sortByAthleteDelegate } from '../../../../utils/sortByAthleteDelegate';
import { useTableRenderMap } from '../../hooks/useTableRenderMap';
import { IPerformanceReport } from '../../../../types/performance';
import { useUsersStore } from '../../../../store/users/slice';

export const useViewModel = () => {
  const { t } = useTranslation();
  const filters = usePerformanceReportStore((s) => s.filters);
  const items = usePerformanceReportStore((s) => s.reports);
  const loaded = usePerformanceReportStore((s) => s.reportsLoaded);
  const userProfile = useUsersStore((s) => s.userProfile);
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const [currentReport, setCurrentReport] = useState<IPerformanceReport | null>(
    null
  );

  const filteredItems = useMemo(() => {
    const filtered = items.filter(filterReportsDelegate(filters, athletesMap));
    filtered.sort(sortByAthleteDelegate(athletesMap));
    return filtered;
  }, [items, athletesMap, filters]);

  const reportsWithFeedback = useMemo(() => {
    const filtered = items.filter((r: IPerformanceReport) => r.commentEnabled);
    filtered.sort(sortByAthleteDelegate(athletesMap));
    return filtered;
  }, [athletesMap, items]);

  const unfinishedReports = useMemo(() => {
    return reportsWithFeedback.filter((r) => !r.coachRating);
  }, [reportsWithFeedback]);

  const openFeedbackModal = useCallback(() => {
    const startIndex = reportsWithFeedback.findIndex((r) => !r.coachRating);
    if (reportsWithFeedback[startIndex]) {
      setCurrentReport(reportsWithFeedback[startIndex]);
    }
  }, [reportsWithFeedback, setCurrentReport]);

  const dismissFeedbackModal = useCallback(() => {
    setCurrentReport(null);
  }, [setCurrentReport]);

  const columns: ITableColumn[] = useTableColumns();
  const onRenderCell = useTableRenderMap();

  const emptyStateProps: IEmptyStateProps | undefined =
    loaded && filteredItems.length === 0
      ? {
          title: t('banner.empty_reports_title'),
          text: t('banner.empty_reports_message'),
          icon: <FileMinus size={32} />,
        }
      : undefined;

  return {
    columns,
    items: filteredItems,
    loading: !loaded,
    emptyStateProps,
    onRenderCell,
    openFeedbackModal,
    dismissFeedbackModal,
    userProfile,
    currentReport,
    reportsWithFeedback,
    unfinishedReports,
  };
};
