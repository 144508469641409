import { Controller, useFormContext } from 'react-hook-form';
import { IInputProps, Input } from '@cycling-web/analog-ui';

type IProps = IInputProps & {
  name: string;
};

export const InputControl = (props: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, onBlur, value } }) => (
        <Input {...props} onChange={onChange} onBlur={onBlur} value={value} />
      )}
    />
  );
};
