import { useEffect } from 'react';
import { useFetchAthleteMedicalReport } from './hooks/useFetchAthleteMedicalReport';
import { useAthleteMedicalReportStore } from './store/slice';

export const useViewModel = () => {
  const reportLoaded = useAthleteMedicalReportStore((s) => s.reportLoaded);
  const report = useAthleteMedicalReportStore((s) => s.report);
  const reset = useAthleteMedicalReportStore((s) => s.reset);
  const setReport = useAthleteMedicalReportStore((s) => s.setReport);
  const setReportLoaded = useAthleteMedicalReportStore(
    (s) => s.setReportLoaded
  );
  useFetchAthleteMedicalReport();

  useEffect(() => {
    return () => {
      setReport(null);
      setReportLoaded(false);
    };
  }, [setReport, setReportLoaded]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return {
    reportLoaded,
    report,
  };
};
