import { EMPTY } from '../../constants';
import { TsbUtils } from '../../utils/tsb';

type IProps = {
  value: number | string | undefined;
  empty?: string;
};

export const TSBToken = ({ value, empty = EMPTY }: IProps) => {
  if (!value) {
    return empty;
  }
  const numericValue = Number(value);
  const status = TsbUtils.getStatus(numericValue);
  const { color } = TsbUtils.getContent(status);

  return <span style={{ color }}>{Math.round(numericValue)}</span>;
};
