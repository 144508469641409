import { useCallback, useContext } from 'react';
import { monthLabels } from './utils/constants';
import { ICalendarContext, TimePeriod } from './types';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { CalendarContext } from './context';
import { IconButton } from '../Button/IconButton';
import { Button } from '../Button/Button';

export const TimePeriodControl = () => {
  const {
    handleTimePeriodChange,
    dateForPeriod,
    onDateForPeriodChange,
    timePeriod,
  }: ICalendarContext = useContext(CalendarContext);

  const handleChangeMonth = useCallback(
    (delta: number) => () => {
      const nextDate: Date = new Date(dateForPeriod);

      let monthIndex: number = nextDate.getMonth() + delta;
      let year = nextDate.getFullYear();

      if (monthIndex < 0) {
        monthIndex = 11;
        year--;
      } else if (monthIndex > 11) {
        monthIndex = 0;
        year++;
      }

      nextDate.setMonth(monthIndex);
      nextDate.setFullYear(year);
      onDateForPeriodChange(nextDate);
    },
    [dateForPeriod, onDateForPeriodChange]
  );

  const onMonthViewClick = useCallback((): void => {
    handleTimePeriodChange(TimePeriod.Months);
  }, [handleTimePeriodChange]);

  const onYearsViewClick = useCallback((): void => {
    handleTimePeriodChange(TimePeriod.Years);
  }, [handleTimePeriodChange]);

  return (
    <div className="analog-calendar__periodControl">
      <Button
        className="analog-calendar__periodControlButton"
        variant="quietNeutral"
        content={monthLabels[dateForPeriod.getMonth()]}
        onClick={onMonthViewClick}
      />
      <Button
        className="analog-calendar__periodControlButton"
        variant="quietNeutral"
        content={dateForPeriod.getFullYear()}
        onClick={onYearsViewClick}
      />

      {timePeriod === TimePeriod.Days && (
        <div className="analog-calendar__monthButtons">
          <IconButton
            variant="quietNeutral"
            onClick={handleChangeMonth(-1)}
            content={<ChevronLeft size={16} />}
          />
          <IconButton
            variant="quietNeutral"
            onClick={handleChangeMonth(1)}
            content={<ChevronRight size={16} />}
          />
        </div>
      )}
    </div>
  );
};
