import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useDictionaryValue } from '../../../../hooks/useDictionaryValue';
import {
  IEmptyStateProps,
  ITableColumn,
  ITableItem,
} from '@cycling-web/analog-ui';
import { ReactNode, useCallback, useMemo } from 'react';
import { IAthlete } from '../../../../types/athletes';
import { Athlete } from '../../../../components/columns/Athlete';
import { SeverityTag } from '../../../../components/columns/SeverityTag';
import { ArrowRight, Heart } from 'lucide-react';
import { ROUTES } from '../../../../router/routes';
import { EMPTY } from '../../../../constants';

type IProps = {
  athletes: IAthlete[];
};

export const useViewModel = ({ athletes }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const getDictionaryValue = useDictionaryValue();

  const items = athletes;

  const columns: ITableColumn[] = useMemo(() => {
    const columns: ITableColumn[] = [
      {
        key: 'athlete',
        name: t('label.athlete'),
        minWidth: 220,
        grow: 1,
      },
      {
        key: 'issue',
        name: t('label.issue'),
        minWidth: 100,
        grow: 100,
      },
      {
        key: 'severity',
        name: t('label.severity'),
        minWidth: 110,
        maxWidth: 110,
      },
      {
        key: 'details',
        name: '',
        minWidth: 110,
        maxWidth: 110,
        align: 'right',
      },
    ];

    return columns;
  }, [t]);

  const onRenderCell = (column: ITableColumn, item: ITableItem) => {
    const castedItem = item as IAthlete;
    const medicalReport = castedItem.medicalReport;

    const renderMap: Record<string, ReactNode> = {
      athlete: <Athlete athlete={castedItem} />,
      issue: medicalReport
        ? getDictionaryValue({
            recordType: medicalReport.recordType,
            key: medicalReport.area,
          })
        : EMPTY,
      severity: medicalReport ? (
        <SeverityTag severity={medicalReport.severity} />
      ) : (
        EMPTY
      ),
      details: <ArrowRight />,
    };

    return renderMap[column.key];
  };

  const onRowClick = useCallback(
    (item: ITableItem) => {
      const castedItem = item as IAthlete;
      if (!castedItem.medicalReport) {
        return;
      }

      const tmp = pathname.split('/');
      tmp.pop();

      navigate(
        `${tmp.join('/')}/${ROUTES.MEDICAL_REPORT_TABLE}/${
          castedItem.medicalReport.id
        }`
      );
    },
    [navigate, pathname]
  );

  const emptyStateProps = useMemo((): IEmptyStateProps | undefined => {
    return items.length === 0
      ? {
          icon: <Heart size={32} />,
          text: t('banner.empty_dashboard_health_message'),
          className: 'health-summary-table__empty-state',
        }
      : undefined;
  }, [items.length, t]);

  return {
    columns,
    items,
    onRenderCell,
    onRowClick,
    emptyStateProps,
  };
};
