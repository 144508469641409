import { useEffect, useMemo, useRef, useState } from 'react';
import './index.css';
import { clsx } from 'clsx';
import { ITextareaProps } from './types';

export const Textarea = (props: ITextareaProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [length, setLength] = useState<number>(0);

  const nativeProps: ITextareaProps = useMemo(() => {
    const nativeProps: ITextareaProps = { ...props };
    delete nativeProps.invalid;
    delete nativeProps.autosize;
    delete nativeProps.variant;

    return nativeProps;
  }, [props]);

  const rootClass: string = clsx(
    'analog-textarea',
    `analog-textarea--${props.variant || 'dark'}`,
    props.disabled && 'analog-textarea--disabled',
    props.invalid && 'analog-textarea--invalid',
    props.autosize && 'analog-textarea--autosize',
    props.className
  );

  useEffect(() => {
    if (props.autosize && textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + 'px';
    }
  }, [props.autosize, props.value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (props.maxLength !== undefined) {
      setLength(e.currentTarget.value.length);
    }

    if (props.onChange) {
      props.onChange(e.currentTarget.value, e);
    }
  };

  return (
    <div className={rootClass}>
      <textarea
        {...nativeProps}
        ref={textareaRef}
        className="analog-textarea__textarea analog-typography--body"
        onChange={handleChange}
      />
      {props.maxLength && !props.disabled && (
        <div className="analog-textarea__length analog-typography--caption">
          {length}/{props.maxLength}
        </div>
      )}
    </div>
  );
};
