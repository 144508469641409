import { useAthletesStore } from '../../../store/athletes/slice';
import { Avatar, ISelectOption } from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { IAthlete } from '../../../types/athletes';
import { IBaseAthleteFilterProps } from './types';

export const useViewModel = (props: IBaseAthleteFilterProps) => {
  const athletes = useAthletesStore((s) => s.athletes);
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);

  const options: ISelectOption[] = useMemo(() => {
    if (props.options) {
      return props.options;
    }

    return athletes
      .filter((a: IAthlete) => a.id !== undefined)
      .map((a: IAthlete) => {
        return {
          id: a.id.toString(),
          text: a.fullName,
        };
      });
  }, [props.options, athletes]);

  const onRenderOption = useCallback(
    (option: ISelectOption) => {
      const athlete: IAthlete = athletesMap[+option.id];
      return (
        <Avatar
          src={athlete.picture}
          text={athlete.fullName}
          direction="default"
          size="s"
        />
      );
    },
    [athletesMap]
  );

  return {
    options,
    onRenderOption,
    athletesLoaded,
  };
};
