import { IGroupsSlice, IGroupsState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IGroup, IGroupsFilters } from '../../types/groups';

export const initialFilters: IGroupsFilters = {
  search: '',
};

export const initialState: IGroupsState = {
  groups: [],
  group: null,
  groupsLoaded: false,
  filters: initialFilters,
};

export const useGroupsStore = create<IGroupsSlice>()(
  immer((set) => ({
    ...initialState,
    setGroups: (groups: IGroup[]) => {
      set((state) => {
        state.groups = groups;
        state.groupsLoaded = true;
      });
    },
    setGroup: (group: IGroup | null) => {
      set((state) => {
        state.group = group;
      });
    },
    setGroupsLoaded: (groupsLoaded: boolean) => {
      set((state) => {
        state.groupsLoaded = groupsLoaded;
      });
    },
    upsertGroup: (group: IGroup) => {
      set((state) => {
        const index = state.groups.findIndex((g: IGroup) => g.id === group.id);
        if (index >= 0) {
          state.groups[index] = group;
        } else {
          state.groups = [...state.groups, group];
        }
      });
    },
    deleteGroups: (ids: number[]) => {
      set((state) => {
        state.groups = state.groups.filter((g: IGroup) => !ids.includes(g.id));
      });
    },
    setFilters: (filters: Partial<IGroupsFilters>) => {
      set((state) => {
        state.filters = {
          ...state.filters,
          ...filters,
        };
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
