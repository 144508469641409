import { useCallback, useContext } from 'react';
import { monthLabels } from './utils/constants';
import { ICalendarContext, TimePeriod } from './types';
import { CalendarContext } from './context';
import { clsx } from 'clsx';

export const Months = () => {
  const {
    handleTimePeriodChange,
    value,
    onChange,
    onDateForPeriodChange,
    range,
    innerRange,
    currentInnerRangePointer,
    translations,
  }: ICalendarContext = useContext(CalendarContext);

  const onMonthClick = useCallback(
    (monthIndex: number) => (): void => {
      if (range) {
        const currentInnerRangeValue =
          innerRange.current[currentInnerRangePointer.current];

        if (!currentInnerRangeValue) {
          innerRange.current.push(new Date());
        }

        const nextValue: Date =
          innerRange.current[innerRange.current.length - 1] || new Date();
        nextValue.setMonth(monthIndex);
        onDateForPeriodChange(nextValue);
        handleTimePeriodChange(TimePeriod.Days);
      } else {
        const nextValue: Date = value ? new Date(value) : new Date();
        nextValue.setMonth(monthIndex);
        onChange(nextValue);
        onDateForPeriodChange(nextValue);
        handleTimePeriodChange(TimePeriod.Days);
      }
    },
    [
      range,
      innerRange,
      currentInnerRangePointer,
      onDateForPeriodChange,
      handleTimePeriodChange,
      value,
      onChange,
    ]
  );

  const monthsJSX = (translations?.months || monthLabels).map(
    (month: string, i: number) => {
      const today: Date = new Date();
      const isCurrentMonth: boolean = today.getMonth() === i;
      const isSelectedMonth = range ? false : !!value && value.getMonth() === i;

      const rootClass: string = clsx(
        'analog-calendar__dateButton analog-calendar__dateButton--current analog-calendar__month',
        isCurrentMonth && 'analog-calendar__dateButton--today',
        isSelectedMonth && 'analog-calendar__dateButton--selected'
      );

      return (
        <button key={month} className={rootClass} onClick={onMonthClick(i)}>
          {month}
        </button>
      );
    }
  );

  return (
    <div className="analog-calendar__months-wrapper">
      <div className="analog-calendar__dates analog-typography--button-m">
        {monthsJSX}
      </div>
    </div>
  );
};
