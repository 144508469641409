import { IAthletePerformanceSlice, IAthletePerformanceState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IAthletePerformanceFilters } from '../types';
import { DateGroupBy, DateRange, WorkOptions } from '../../../types/enums';
import {
  IFatigueResistance,
  IFatigueResistanceRelative,
  IPdCurve,
  IPdCurveRelative,
  IPeak,
  IPowerDerivative,
} from '../../../types/performance';
import { DataType } from '../../../types/common';
import merge from 'lodash/merge';

export const initialFilters: IAthletePerformanceFilters = {
  shared: {
    period: DateRange.ThreeMonth,
  },
  powerProfile: {
    period1: DateRange.ThreeMonth,
    period2: DateRange.Month,
    dataType: DataType.Absolute,
  },
  powerDerivatives: {
    dataType: DataType.Absolute,
  },
  mmp: {
    dataType: DataType.Absolute,
    dateGroupBy: DateGroupBy.Day,
  },
  durability: {
    dataType: DataType.Absolute,
    work: WorkOptions.kJ2000,
  },
};

export const initialState: IAthletePerformanceState = {
  filters: initialFilters,
  peak: {},
  peakLoaded: false,
  pdCurve: null,
  pdCurveRelative: null,
  pdCurveLoaded: false,
  powerDerivatives: null,
  powerDerivativesLoaded: false,
  fatigueResistance: null,
  fatigueResistanceRelative: null,
  fatigueResistanceLoaded: false,
};

export const useAthletePerformanceStore = create<IAthletePerformanceSlice>()(
  immer((set) => ({
    ...initialState,

    setPeak: (dateRange: string, peak: IPeak[]) => {
      set((state) => {
        state.peak[dateRange] = peak;
        state.peakLoaded = true;
      });
    },
    setPeakLoaded: (peakLoaded: boolean) => {
      set((state) => {
        state.peakLoaded = peakLoaded;
      });
    },
    setPowerDuration: (
      pd: IPdCurve | null,
      pdRelative: IPdCurveRelative | null
    ) => {
      set((state) => {
        state.pdCurve = pd;
        state.pdCurveRelative = pdRelative;
        state.pdCurveLoaded = true;
      });
    },
    setPowerDurationLoaded: (loaded: boolean) => {
      set((state) => {
        state.pdCurveLoaded = loaded;
      });
    },
    setPowerDerivatives: (powerDerivatives: IPowerDerivative | null) => {
      set((state) => {
        state.powerDerivatives = powerDerivatives;
        state.powerDerivativesLoaded = true;
      });
    },
    setPowerDerivativesLoaded: (loaded: boolean) => {
      set((state) => {
        state.powerDerivativesLoaded = loaded;
      });
    },
    setFatigueResistance: (
      fatigueResistance: IFatigueResistance | null,
      fatigueResistanceRelative: IFatigueResistanceRelative | null
    ) => {
      set((state) => {
        state.fatigueResistance = fatigueResistance;
        state.fatigueResistanceRelative = fatigueResistanceRelative;
        state.fatigueResistanceLoaded = true;
      });
    },
    setFatigueResistanceLoaded: (loaded: boolean) => {
      set((state) => {
        state.fatigueResistanceLoaded = loaded;
      });
    },
    setFilters: (filters: Partial<IAthletePerformanceFilters>) => {
      set((state) => {
        state.filters = merge({}, state.filters, filters);
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
