import './index.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { ITabsItem } from '@cycling-web/analog-ui';
import { ROUTES } from '../../../../router/routes';
import { PageTabs } from '@cycling-web/common';
import { useMemo } from 'react';
import { useUserRole } from '../../../../hooks/useUserRole';

export const SettingsTabs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const url = pathname.split('/');
  const { isAthlete } = useUserRole();

  const items: ITabsItem[] = useMemo(() => {
    const tabs: ITabsItem[] = [
      {
        path: ROUTES.SETTINGS_PROFILE,
        text: t('label.my_profile'),
        isActive: url.at(-1) === ROUTES.SETTINGS_PROFILE,
      },
    ];

    if (isAthlete) {
      tabs.push({
        path: ROUTES.DATA_SHARING,
        text: t('label.data_sharing'),
        isActive: url.at(-1) === ROUTES.DATA_SHARING,
      });
    }

    return tabs;
  }, [isAthlete, t, url]);

  return <PageTabs tabs={items} />;
};
