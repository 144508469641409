import './index.css';
import { Outlet } from 'react-router';
import { GroupTabs } from './components/GroupTabs';
import { useViewModel } from './useViewModel';
import { Page } from '@cycling-web/common';
import { GroupDetailsPageTitle } from './components/PageTitle';

export const GroupDetails = () => {
  const { backUrl, group, groupsLoaded, emptyState } = useViewModel();
  return (
    <Page
      pageHeaderProps={{
        title: <GroupDetailsPageTitle />,
        backUrl: backUrl,
      }}
    >
      {groupsLoaded && group ? (
        <>
          <GroupTabs />
          <Outlet />
        </>
      ) : (
        emptyState
      )}
    </Page>
  );
};
