import { IAthlete } from '../types/athletes';

type Item = {
  athleteId: number;
};

export function sortByAthleteDelegate(
  athletesMap: Record<string, IAthlete>,
  direction: 'asc' | 'desc' = 'asc'
) {
  return (a: Item, b: Item) => {
    const athleteA = athletesMap[a.athleteId];
    const athleteB = athletesMap[b.athleteId];

    const lastNameA = inferLastName(athleteA);
    const lastNameB = inferLastName(athleteB);

    const comparisonResult = lastNameA.localeCompare(lastNameB);

    return direction === 'asc' ? comparisonResult : -comparisonResult;
  };
}

function inferLastName(athlete: IAthlete): string {
  if (!athlete) {
    return '';
  }

  if (athlete.fullName && !athlete.lastName) {
    return athlete.fullName
      .split(' ')
      .filter((_, i: number) => i > 0)
      .join(' ');
  }

  return athlete.lastName;
}
