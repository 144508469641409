import './index.css';
import { IconButton } from '@cycling-web/analog-ui';
import { Send } from 'lucide-react';
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAiAssistantStore } from '../../store/slice';
import { AIRepository } from '../../../../api/ai/repository';
import { AIService } from '../../../../api/ai/service';
import { IChatbotHistoryMessage } from '../../../../types/chatbot';
import { useTranslation } from 'react-i18next';
import { ExamplePrompts } from '../ExamplePrompts';

export const Footer = () => {
  const { t } = useTranslation();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const isOpen = useAiAssistantStore((s) => s.isOpen);
  const config = useAiAssistantStore((s) => s.config);
  const setIsProcessing = useAiAssistantStore((s) => s.setIsProcessing);
  const appendMessages = useAiAssistantStore((s) => s.appendMessages);
  const aiRepository = useMemo(() => {
    return new AIRepository(new AIService());
  }, []);
  const [inputValue, setInputValue] = useState<string>('');

  const onInputChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  }, []);

  const sendMessage = useCallback(
    (query: string) => {
      if (config?.athlete_id === undefined) {
        return;
      }
      appendMessages([
        {
          id: `msg_${Date.now()}`,
          created_at: Date.now() / 1000,
          assistant_id: config.assistant_id,
          thread_id: config.thread_id,
          role: 'user',
          content: [
            {
              type: 'text',
              text: {
                value: query,
                annotations: [],
              },
            },
          ],
          feedback_type: '',
        },
      ]);

      setIsProcessing(true);
      aiRepository
        .sendChatMessage({
          athleteId: config.athlete_id,
          query,
        })
        .then((messages: IChatbotHistoryMessage[]) => {
          // TODO: It can also be an error. This is an extra check.
          if (Array.isArray(messages)) {
            appendMessages(messages);

            if (messages.length > 0) {
              useAiAssistantStore.getState().updateConfig({
                thread_id: messages[0].thread_id,
                assistant_id: messages[0].assistant_id,
              });
            }
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [
      aiRepository,
      appendMessages,
      config?.assistant_id,
      config?.athlete_id,
      config?.thread_id,
      setIsProcessing,
    ]
  );

  const handleSendMessage = useCallback(() => {
    if (!inputValue) {
      return;
    }

    sendMessage(inputValue);
    setInputValue('');
  }, [sendMessage, inputValue]);

  useEffect(() => {
    setTimeout(() => {
      if (isOpen && textareaRef.current) {
        textareaRef.current.focus();
      }
    });
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      if (textareaRef.current) {
        const MAX_HEIGHT = 96;
        textareaRef.current.style.height = '48px';
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = `${Math.min(
          scrollHeight,
          MAX_HEIGHT
        )}px`;
        textareaRef.current.style.overflowY =
          scrollHeight > MAX_HEIGHT ? 'scroll' : 'hidden';
      }
    });
  }, [inputValue]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  const onSelectExample = useCallback(
    (example: string) => {
      sendMessage(example);
    },
    [sendMessage]
  );

  return (
    <div className="ai-assistant__footer">
      <div className="ai-assistant__footer-textarea-wrapper">
        <textarea
          className="ai-assistant__footer-textarea analog-typography--body"
          value={inputValue}
          onChange={onInputChange}
          ref={textareaRef}
          onKeyDown={handleKeyDown}
          placeholder={t('placeholder.message')}
        />
        <div className="ai-assistant__footer-actions">
          <IconButton
            variant="quietNeutralDark"
            content={<Send />}
            onClick={handleSendMessage}
          />
        </div>
      </div>
      <ExamplePrompts onSelect={onSelectExample} />
    </div>
  );
};
