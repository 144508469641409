export function differByPercent(a: number, b, d: number): boolean {
  const absA = Math.abs(a);
  const absB = Math.abs(b);

  if (absA === 0 && absB === 0) {
    return true;
  }

  if (absA === 0 || absB === 0) {
    return false;
  }

  const difference = Math.abs(absA - absB);
  const average = (absA + absB) / 2;
  const percentageDifference = (difference / average) * 100;

  return percentageDifference >= d;
}
