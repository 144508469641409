import { IMonthDays, MonthPeriod } from '../types';

export const getDaysInMonth = (d?: Date): IMonthDays => {
  const date: Date = d || new Date();

  const result: IMonthDays = {
    month: date.getMonth(),
    year: date.getFullYear(),
    days: [],
  };

  const daysInCurrentMonth: number = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();
  const weekDayOfFirstDay: number = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  ).getDay();
  const weekDayOfLastDay: number = new Date(
    date.getFullYear(),
    date.getMonth(),
    daysInCurrentMonth
  ).getDay();

  if (weekDayOfFirstDay !== 1) {
    let daysFromPrevMonth: number = weekDayOfFirstDay - 2;

    if (daysFromPrevMonth < 0) {
      daysFromPrevMonth = 7 - Math.abs(daysFromPrevMonth);
    }

    let lastDayInPrevMonth: number = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();

    for (let i = daysFromPrevMonth; i >= 0; i--) {
      result.days[i] = {
        period: MonthPeriod.Previous,
        date: new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          lastDayInPrevMonth
        ),
      };
      lastDayInPrevMonth--;
    }
  }

  for (let i = 1; i <= daysInCurrentMonth; i++) {
    result.days.push({
      period: MonthPeriod.Current,
      date: new Date(date.getFullYear(), date.getMonth(), i),
    });
  }

  if (weekDayOfLastDay !== 0) {
    const daysFromNextMonth: number = 7 - weekDayOfLastDay;

    for (let i = 1; i <= daysFromNextMonth; i++) {
      result.days.push({
        period: MonthPeriod.Next,
        date: new Date(date.getFullYear(), date.getMonth() + 1, i),
      });
    }
  }

  return result;
};
