import './index.css';
import { clsx } from 'clsx';
import { Typography } from '@cycling-web/analog-ui';
import { ReactNode } from 'react';

type IProps = {
  label?: ReactNode;
  value?: ReactNode;
  variance?: ReactNode;
  units?: ReactNode;
  direction?: 'row' | 'column';
  className?: string;
};

export const Parameter = ({
  label,
  value,
  variance,
  units,
  direction = 'row',
  className,
}: IProps) => {
  const rootClass = clsx(
    'athlete-parameter',
    `athlete-parameter--${direction}`,
    className
  );

  return (
    <div className={rootClass}>
      <Typography
        className="athlete-parameter__label"
        variant="subtitle"
        weight="regular"
        text={label}
      />
      <div className="athlete-parameter__value-wrapper">
        {variance && (
          <Typography
            className="athlete-parameter__variance"
            variant="subtitle"
            weight="regular"
            text={variance}
          />
        )}
        {value && (
          <Typography
            className="athlete-parameter__value"
            variant="body"
            weight="regular"
            text={value}
          />
        )}
        {units && (
          <Typography
            className="athlete-parameter__units"
            variant="subtitle"
            weight="regular"
            text={units}
          />
        )}
      </div>
    </div>
  );
};
