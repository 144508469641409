import { format, parse } from 'date-fns';
import { DateRange } from '../types/enums';

const apiDateFormat = 'yyyy-MM-dd';
const localDateFormat = 'dd/MM/yyyy';

export function pdFormatTime(seconds: number): string {
  if (seconds >= 3600) {
    return Math.round(seconds / 3600) + 'h';
  } else if (seconds >= 60) {
    return Math.round(seconds / 60) + 'm';
  } else {
    return Math.round(seconds) + 's';
  }
}

export const formatTime: (seconds: number) => string = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (seconds < 60) {
    return seconds + 's';
  } else if (hours > 0) {
    if (remainingMinutes === 0 && remainingSeconds === 0) {
      return hours + 'h';
    } else if (remainingSeconds === 0) {
      return hours + 'h ' + remainingMinutes + 'm';
    } else {
      return hours + 'h ' + remainingMinutes + 'm ' + remainingSeconds + 's';
    }
  } else if (remainingSeconds === 0) {
    return minutes + 'm';
  } else {
    return minutes + 'm ' + remainingSeconds + 's';
  }
};

export const getStartAndEndDateOfWeek = (
  previousWeek = false
): { start: Date; end: Date } => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  let diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);

  if (previousWeek) {
    diff -= 7;
  }

  const startOfWeek = new Date(today.setDate(diff));
  startOfWeek.setHours(0, 0, 0, 0);

  let endOfWeek: Date;

  if (!previousWeek) {
    endOfWeek = new Date();
  } else {
    endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);
  }

  endOfWeek.setHours(23, 59, 59, 999);

  return {
    start: startOfWeek,
    end: endOfWeek,
  };
};

export const calculateDateRange = (
  dateDuration: DateRange
): { startDate: Date; endDate: Date } => {
  let startDate: Date;
  let endDate = new Date();

  switch (dateDuration) {
    case DateRange.Week:
      {
        const { start, end } = getStartAndEndDateOfWeek(true);
        startDate = start;
        endDate = end;
      }
      break;
    case DateRange.FourDays:
    case DateRange.TwoWeeks:
    case DateRange.EightDays:
    case DateRange.Month:
    case DateRange.TwoMonth:
    case DateRange.ThreeMonth:
    case DateRange.Year:
      endDate = new Date();
      startDate = new Date(endDate.getTime() - dateDuration);
      break;
    default:
      throw new Error('Invalid periodType');
  }
  return {
    startDate,
    endDate,
  };
};

export const formatDate: (date: Date) => string = (date) => {
  return format(date, localDateFormat);
};

export const parseDate: (input: string) => Date = (input) =>
  parse(input, apiDateFormat, new Date());

export const severityToOpacity = (severity: string): number => {
  switch (severity) {
    case 'MILD':
      return 0.45;
    case 'MODERATE':
      return 0.65;
    case 'SEVERE':
      return 0.85;
    default:
      return 1;
  }
};
