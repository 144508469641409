import { formatTime, pdFormatTime } from '../../../utils/utils';
import { generateSmoothCurveForSmartCharts } from './generateSmoothCurveForSmartCharts';
import {
  IAIComputeResponse,
  ISmartChartsAxis,
  ISmartChartsPlot,
  ISmartChartsPlotData,
  ISmartChartsYAxis,
  ISmartChartsYAxisData,
  ISmartGraphSparseSeries,
} from './types';
import { IAthlete } from '../../../types/athletes';

export const customDateFormatter = (value: string): string => {
  const date = new Date(value);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

function convertToNumericArray(data: any) {
  return Object.entries(data).map(([key, value]) => [parseFloat(key), value]);
}

// Function to convert object to sparse series data
function convertToSparseSeriesData(
  obj: ISmartGraphSparseSeries
): { name: string; value: string }[] {
  return Object.entries(obj).map(([key, value]) => ({ name: key, value }));
}

// funtion to create EChart Options
export const createGraphOptions = (
  aiGraphInScope: any,
  plots: any,
  series: any,
  yAxisLabel: any,
  xAxisLabel: any,
  isLogScaleEnabled: boolean
) => {
  const dateUnits = ['date', 'day'];
  /*
    * Format of Mapping:
    <y_axies_length>: {
          left: <left_offset>,
          right: <right_offset>
        }
    */
  const yAxisGridOffsetMapping: {
    [key: number]: { left: number; right: number };
  } = {
    1: {
      left: 15,
      right: 15,
    },
    2: {
      left: 15,
      right: 15,
    },
    3: {
      left: 50,
      right: 15,
    },
    4: {
      left: 50,
      right: 50,
    },
    5: {
      left: 80,
      right: 50,
    },
    6: {
      left: 100,
      right: 150,
    },
  };

  return {
    title: {
      text: aiGraphInScope?.title?.value,
      left: 'left',
      textStyle: {
        color: '#f3f3f6',
        fontSize: 12,
        fontWeight: 550,
        fontFamily: 'ESKlarheitKurrent, sans-serif',
      },
    },
    textStyle: {
      fontFamily: 'ESKlarheitKurrent, sans-serif',
      fontSize: 12,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      backgroundColor: '#3e4246',
      borderColor: '#3e4246',
      formatter: function (params: any) {
        let tooltipTopTitle = '';
        if (dateUnits?.includes(aiGraphInScope?.x_axes[0]?.unit)) {
          const date = new Date(params[0].name);
          tooltipTopTitle = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });
        } else if (isLogScaleEnabled) {
          tooltipTopTitle = `Time: <b>${formatTime(params[0].axisValue)}</b>`;
        } else tooltipTopTitle = params[0].name;

        let tooltipContent = tooltipTopTitle + '<br/>';
        params.forEach((param: any) => {
          const marker = param.marker;
          const seriesName = param.seriesName;
          const value = param.value;

          if (value && value !== null) {
            // If the graph is log scaled, the value attribute is different
            if (!isLogScaleEnabled)
              tooltipContent +=
                marker + ' ' + seriesName + ':  ' + `<b>${value}</b>` + '<br/>';
            else
              tooltipContent +=
                marker +
                ' ' +
                seriesName +
                ':  ' +
                `<b>${parseInt(param.value[1])} ${yAxisLabel[0].name}</b>` +
                '<br/>';
          }
        });
        return `<div style="font-size: 11px;">${tooltipContent}</div>`;
      },
      textStyle: {
        color: '#f3f3f6',
        fontFamily: 'ESKlarheitKurrent, sans-serif',
        fontSize: 12,
        itemGap: 20,
      },
    },
    legend: {
      orient: 'horizontal',
      itemGap: 12,
      textStyle: {
        color: '#f3f3f6',
        fontFamily: 'ESKlarheitKurrent, sans-serif',
        fontSize: 10,
      },
      top: '10%',
    },
    barMaxWidth: '12px',
    grid: {
      top: 80,
      left: yAxisGridOffsetMapping[
        aiGraphInScope?.computed_data?.y_axes_data?.length
      ]?.left,
      bottom: 20,
      right:
        yAxisGridOffsetMapping[
          aiGraphInScope?.computed_data?.y_axes_data?.length
        ]?.right,
      containLabel: true,
    },
    xAxis: xAxisLabel,
    yAxis: yAxisLabel,
    series: series,
    darkMode: true,
  };
};

export const getYAxisPosition = (id: string, yAxisMetaData: any) => {
  const yAxisData = yAxisMetaData?.find((axis: any) => {
    return axis?.linked_axis_ids?.includes(id);
  });
  return yAxisData?.position;
};

export const validateGraphConfig = (graphData: any) => {
  if (!graphData) {
    return false;
  }
  if (!graphData?.xAxis || graphData.xAxis.length === 0) {
    return false;
  }
  if (!graphData?.yAxis || graphData.yAxis.length === 0) {
    return false;
  }
  if (!graphData?.series || graphData.series.length === 0) {
    return false;
  }
  for (const series of graphData.series) {
    if (
      series.yAxisIndex !== undefined &&
      !graphData.yAxis[series.yAxisIndex]
    ) {
      return false;
    }
  }
  return true;
};

export const computeGraphOption = (
  computeData: IAIComputeResponse,
  filterAthleteDetails: IAthlete[] = []
) => {
  const aiGeneratedGraph =
    computeData?.ai_graph_in_scope?.ai_generated_graphs[0];
  const plots = aiGeneratedGraph?.plots || [];
  const computedData = aiGeneratedGraph?.computed_data;

  const dateUnits = ['date', 'day'];
  const isDateUnit = dateUnits?.includes(aiGeneratedGraph?.x_axes[0]?.unit);
  const isLogScaleEnabled = aiGeneratedGraph?.x_axes[0]?.range_log_scaled;
  const xAxisSeriesData =
    aiGeneratedGraph?.computed_data?.x_axes_data[0]?.series_only_values_data;

  const formatTimeAxis = (value) => {
    const timePoints = [
      1, 2, 5, 10, 20, 40, 60, 120, 300, 600, 1200, 2400, 3600, 7200, 14400,
      21600,
    ];
    const closestPoint = timePoints.reduce((prev, curr) => {
      return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
    });
    if (Math.abs(value - closestPoint) / closestPoint < 0.001) {
      if (closestPoint >= 3600) {
        return closestPoint / 3600 + 'h';
      } else if (closestPoint >= 60) {
        return closestPoint / 60 + 'm';
      }
      return closestPoint + 's';
    }
    return '';
  };

  // 1. Map the X Axis data
  const xAxisLabel = {
    type: isLogScaleEnabled
      ? 'log'
      : aiGeneratedGraph?.x_axes[0]?.series_data_type || 'category',
    name: aiGeneratedGraph?.x_axes[0]?.unit_readable_short_name,
    nameLocation: 'middle',
    nameGap: 25,
    ...(isLogScaleEnabled && { min: 1 }),
    ...(isLogScaleEnabled && { max: 21600 }),
    data: aiGeneratedGraph?.computed_data?.x_axes_data[0]
      ?.series_only_values_data,
    axisLabel: {
      formatter: isDateUnit
        ? customDateFormatter
        : isLogScaleEnabled
        ? (value) => pdFormatTime(value)
        : `{value}`,
      rotate: 0,
      textStyle: {
        fontSize: 10,
        color: '#f3f3f6',
      },
    },
    axisTick: {
      alignWithLabel: true,
      show: true,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#666',
      },
    },
    axisPointer: {
      label: {
        fontSize: 11,
        formatter: isDateUnit
          ? (value) => customDateFormatter(value?.value)
          : isLogScaleEnabled
          ? (value) => formatTime(value?.value)
          : `{value}`,
      },
    },
    splitLine: {
      show: false,
      lineStyle: {
        type: 'dashed',
        opacity: 0.2,
      },
    },
  };

  // 2. Map the Y Axis data
  const yAxisLabel = aiGeneratedGraph?.y_axes?.map(
    (yAxis: ISmartChartsYAxis, index: number) => {
      const matchingPlot = plots?.find((plot: ISmartChartsPlot) =>
        yAxis?.linked_plot_ids?.includes(plot.id)
      );

      // offset values for multiple y axes on the same position (max 6 y axes)
      const offsets = {
        1: 30,
        2: 60,
        3: 90,
        4: 120,
        5: 150,
        6: 180,
      };

      return {
        type: 'value', // type is not being sent from the api, so defaulting to value
        name: yAxis?.unit_readable_short_name,
        position: getYAxisPosition(yAxis.id, computedData?.y_axes_data),
        axisLabel: {
          formatter: `{value}`,
          color: matchingPlot?.plot_view_metadata?.color_name,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: matchingPlot?.plot_view_metadata?.color_name,
          },
        },
        axisPointer: {
          show: false,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: false,
        },
        textStyle: {
          color: '#f3f3f6',
          fontFamily: 'ESKlarheitKurrent, sans-serif',
          fontSize: 12,
        },
        ...(index > 1 && { offset: 30 * index }),
        min: yAxis?.range_automatic_min_value
          ? 'dataMin'
          : yAxis?.range_min_value,
        max: yAxis?.range_automatic_max_value
          ? 'dataMax'
          : yAxis?.range_max_value,
      };
    }
  );

  const checkIfLinkedYAxisExists = (currentPlotId: string) => {
    const matchingYAxes = aiGeneratedGraph?.y_axes?.find(
      (axis: ISmartChartsAxis) => axis.linked_plot_ids.includes(currentPlotId)
    );
    if (matchingYAxes) {
      const yAxisDataElement = computedData?.y_axes_data?.find(
        (axis: ISmartChartsYAxisData) =>
          axis.linked_axis_ids[0] === matchingYAxes.id
      );
      return !!yAxisDataElement;
    }
    return false;
  };

  const getYAxisIndex = (currentPlotId: string) => {
    let yAxisIndex: number | null = null;
    const matchingYAxes = aiGeneratedGraph?.y_axes?.find(
      (axis: ISmartChartsAxis) => axis.linked_plot_ids.includes(currentPlotId)
    );
    if (matchingYAxes) {
      computedData?.y_axes_data?.forEach(
        (axis: ISmartChartsYAxisData, index: number) => {
          if (axis?.linked_axis_ids?.[0] === matchingYAxes?.id) {
            yAxisIndex = index;
          }
        }
      );
    }
    return yAxisIndex;
  };

  // 3. Map the Series data
  const series = aiGeneratedGraph?.computed_data?.plots_data?.map(
    (plotData: ISmartChartsPlotData, index: number) => {
      const matchingPlot = plots.find(
        (plot: ISmartChartsPlot) => plot.id === plotData.linked_plot_id
      );

      // get parsed data into a constant for passing it into a generateSmooth function
      const parsed2dArrayData: [number, number][] = convertToNumericArray(
        plotData?.series_key_value_data
      ) as [number, number][];

      return {
        name:
          filterAthleteDetails?.length > 1
            ? plotData?.readable_name_with_datasource
            : plotData?.name,
        type: matchingPlot?.plot_view_metadata.type,
        data: isLogScaleEnabled
          ? matchingPlot?.plot_view_metadata?.is_smoothening
            ? generateSmoothCurveForSmartCharts(parsed2dArrayData)
            : convertToNumericArray(plotData?.series_key_value_data)
          : convertToSparseSeriesData(plotData?.series_key_value_data),
        symbol:
          matchingPlot?.plot_view_metadata.line_mark_points_shape ||
          'emptyCircle',
        smooth: matchingPlot?.plot_view_metadata?.is_smoothening,
        color: matchingPlot?.plot_view_metadata.color_name,
        showSymbol: matchingPlot?.plot_view_metadata.is_show_on_legend,
        connectNulls: true,
        lineStyle: {
          type: matchingPlot?.plot_view_metadata.line_style_type || 'solid',
          opacity: matchingPlot?.plot_view_metadata?.is_smoothening ? 0.6 : 1,
        },
        backgroundStyle: {
          color: matchingPlot ? 'rgba(35, 41, 50, 0.6)' : 'rgba(0, 0, 0, 0)',
        },
        ...(aiGeneratedGraph?.y_axes?.length > 1 &&
          index !== 0 &&
          checkIfLinkedYAxisExists(plotData?.linked_plot_id) && {
            yAxisIndex: getYAxisIndex(plotData?.linked_plot_id),
          }),
        ...(matchingPlot?.plot_view_metadata?.is_area_shaded_plot && {
          areaStyle: {
            color: matchingPlot?.plot_view_metadata?.area_shaded_color_name,
            opacity: isLogScaleEnabled ? 0.2 : 0.7,
          },
        }),
      };
    }
  );

  return createGraphOptions(
    aiGeneratedGraph,
    plots,
    series,
    yAxisLabel,
    xAxisLabel,
    isLogScaleEnabled
  );
};
