import { useTranslation } from 'react-i18next';
import { TSBToken } from '../../../../components/TSBToken';
import { Divider, LabelValue, Typography } from '@cycling-web/analog-ui';

type IProps = {
  ctl: number;
  atl: number;
  tsb: number;
};

export const TodayDetails = ({ ctl, atl, tsb }: IProps) => {
  const { t } = useTranslation();
  return (
    <div className="performance-today">
      <Typography weight="bold" text={`${t('label.today')}:`} />
      <LabelValue label={t('label.ctl')} value={ctl} />
      <Divider direction="vertical" height={16} />
      <LabelValue label={t('label.atl')} value={atl} />
      <Divider direction="vertical" height={16} />
      <LabelValue label={t('label.tsb')} value={<TSBToken value={tsb} />} />
    </div>
  );
};
