import './index.css';
import {
  Badge,
  Button,
  IconButton,
  Popover,
  Typography,
} from '@cycling-web/analog-ui';
import { FilterIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { useBreakpoints } from '../../hooks/useBreakpoints';

export type IFiltersBarProps = {
  onClear?: () => void;
  filters?: ReactNode;
  collapse?: boolean;
};

export const FiltersBar = ({
  onClear,
  filters,
  collapse = false,
}: IFiltersBarProps) => {
  const { t } = useTranslation();
  const { isSmallTablet } = useBreakpoints();

  const anchor = (collapse || isSmallTablet) && (
    <Badge
      hide={true}
      color="primary"
      badge={''}
      content={
        <IconButton variant="quietNeutralDark" content={<FilterIcon />} />
      }
    />
  );

  const content = (collapse || isSmallTablet) && (
    <div className="filters-popover-content">
      <header className="filters-popover-content__header">
        <Typography
          variant="headline"
          weight="bold"
          text={t('label.filters')}
        />
        {onClear && (
          <Button
            size="s"
            variant="quietNeutral"
            content={t('action.reset')}
            onClick={onClear}
          />
        )}
      </header>
      <div className="filters-popover-content__filters">{filters}</div>
    </div>
  );

  return (
    <div className="filters-bar">
      {collapse || isSmallTablet ? (
        <Popover
          anchor={anchor}
          content={content}
          minWidth="320px"
          maxWidth="320px"
        />
      ) : (
        filters
      )}
    </div>
  );
};
