import './index.css';
import { Select } from '@cycling-web/analog-ui';
import { IExtendSelectProps, ISelectOption } from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkOptions } from '../../../types/enums';

type IProps = {
  options?: WorkOptions[];
  value: WorkOptions;
  onChange: (value: WorkOptions) => void;
  selectProps?: IExtendSelectProps;
};

export const WorkFilter = ({
  value,
  onChange,
  options = [WorkOptions.kJ2000, WorkOptions.kJ3000],
  selectProps,
}: IProps) => {
  const { t } = useTranslation();

  const selectOptions: ISelectOption[] = useMemo(() => {
    return options.map((o: WorkOptions) => {
      return {
        id: o.toString(),
        text: t('label.kj_n', { n: o }),
      };
    });
  }, [options, t]);

  const handleChange = useCallback(
    (option: ISelectOption) => {
      onChange(+option.id);
    },
    [onChange]
  );

  return (
    <div className="work-filter">
      <Select
        options={selectOptions}
        value={selectOptions.find((o: ISelectOption) => +o.id === value)}
        onChange={handleChange}
        placeholder={t('placeholder.work_option')}
        {...selectProps}
      />
    </div>
  );
};
