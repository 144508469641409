import './index.css';
import { ITooltipProps, Tooltip, Typography } from '@cycling-web/analog-ui';
import { Info } from 'lucide-react';
import { FiltersBar, IFiltersBarProps } from '@cycling-web/common';
import { ReactNode } from 'react';

type IProps = {
  title: string;
  subtitle?: ReactNode;
  tooltipProps?: Omit<ITooltipProps, 'anchor'>;
  filtersBarProps?: IFiltersBarProps;
  actions?: ReactNode;
};

export const ChartHeader = ({
  title,
  subtitle,
  tooltipProps,
  filtersBarProps,
  actions,
}: IProps) => {
  return (
    <header className="chart-header">
      <div className="chart-header__left">
        <div className="chart-header__left-title">
          <Typography
            className="chart-header__title"
            text={title}
            variant="h4"
            as="h4"
            weight="bold"
          />
          {tooltipProps && (
            <Tooltip
              {...tooltipProps}
              delay={
                tooltipProps.delay || {
                  open: 300,
                  close: 0,
                }
              }
              anchor={
                <Info className="chart-header__tooltip-anchor" size={20} />
              }
            />
          )}
        </div>
        {subtitle && subtitle}
      </div>

      {filtersBarProps && (
        <div className="chart-header__filters">
          <FiltersBar {...filtersBarProps} />
        </div>
      )}
      {actions && <div className="chart-header__actions">{actions}</div>}
    </header>
  );
};
