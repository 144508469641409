import './index.css';
import { IScoreCard } from '../DailyWellnessScores/types';
import { Skeleton, Typography } from '@cycling-web/analog-ui';
import { LastUpdatedTooltip } from '../LastUpdatedTooltip';
import { useAthleteWellnessStore } from '../../store/slice';
import { useAthleteStore } from '../../../../store/athlete/slice';

interface IProps {
  scoreCard: IScoreCard;
}

const ScoresCard = ({ scoreCard }: IProps) => {
  const { label, value, lastUpdatedAt, source } = scoreCard;
  const wellnessLoaded = useAthleteWellnessStore((s) => s.wellnessLoaded);
  const athleteLoaded = useAthleteStore((s) => s.athleteLoaded);

  return (
    <div className="scores_card">
      {wellnessLoaded && athleteLoaded ? (
        <div className="scores_card__value">
          <Typography
            variant="h3"
            weight="regular"
            text={value}
            className="scores_card__value-text"
          />
        </div>
      ) : (
        <Skeleton width="80px" height="31.2px" />
      )}
      <div className="scores_card__parameter">
        <Typography
          variant="subtitle"
          weight="light"
          text={label}
          className="scores_card__parameterName"
        />
      </div>
      {wellnessLoaded && (
        <LastUpdatedTooltip
          lastUpdated={lastUpdatedAt ? new Date(lastUpdatedAt) : undefined}
          source={source}
        />
      )}
    </div>
  );
};

export default ScoresCard;
