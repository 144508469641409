import './index.css';
import { Select } from '@cycling-web/analog-ui';
import { ISelectOption } from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format, startOfWeek, subWeeks } from 'date-fns';

type IProps = {
  weeks?: number;
  value: string;
  onChange: (value: string) => void;
};

export const WeeksFilter = ({ weeks = 5, value, onChange }: IProps) => {
  const { t } = useTranslation();

  const generateWeeks = useCallback(
    (count: number): ISelectOption[] => {
      const weeks: ISelectOption[] = [];
      const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
      const weekNumber = format(currentWeekStart, 'w');
      const year = format(currentWeekStart, 'yyyy');

      weeks.push({
        id: `${weekNumber}_${year}`,
        text: t('label.current_week'),
      });

      for (let i = 1; i <= count; i++) {
        const weekStartDate = subWeeks(currentWeekStart, i);
        const weekNumber = format(weekStartDate, 'w');
        const monthName = format(weekStartDate, 'MMMM');
        const year = format(weekStartDate, 'yyyy');

        weeks.push({
          id: `${weekNumber}_${year}`,
          text: `W${weekNumber} ${monthName}`,
        });
      }

      return weeks;
    },
    [t]
  );

  const options: ISelectOption[] = useMemo(() => {
    return generateWeeks(weeks);
  }, [generateWeeks, weeks]);

  const handleChange = useCallback(
    (option: ISelectOption) => {
      onChange(option.id);
    },
    [onChange]
  );

  return (
    <div className="weeks-filter">
      <Select
        options={options}
        value={options.find((o: ISelectOption) => o.id === value)}
        onChange={handleChange}
        placeholder={t('placeholder.week')}
      />
    </div>
  );
};
