import './index.css';
import Gauge from './gauge.svg?react';
import Needle from './needle.svg?react';
import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { EMPTY } from '../../../../constants';
import { Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { AnimatedNumber } from './AnimatedNumber';

type IProps = {
  value: number;
  lastUpdated?: Date | undefined;
};

export const GaugeScore = ({ value: _value, lastUpdated }: IProps) => {
  const { t } = useTranslation();

  const lastUpdatedValue = lastUpdated
    ? format(lastUpdated, 'dd/MM/yyyy')
    : EMPTY;

  const [value, setValue] = useState(0);

  useEffect(() => {
    let nextValue = Math.round(_value);
    if (nextValue < 0) {
      nextValue = 0;
    }

    if (nextValue > 100) {
      nextValue = 100;
    }

    setValue(nextValue);
  }, [_value]);

  const style = useMemo(() => {
    const degree = -90 + (value / 100) * 180;
    return {
      transform: `translateX(-50%) rotate(${degree}deg)`,
    };
  }, [value]);

  return (
    <div className="gauge-score">
      <Gauge />
      <Needle className="gauge-score__needle" style={style} />
      <div className="gauge-score__details">
        <Typography
          className="gauge-score__details-value"
          variant="h1"
          weight="bold"
          text={<AnimatedNumber targetValue={value} />}
        />
        <Typography
          className="gauge-score__details-updated"
          variant="caption"
          text={`${t('label.last_updated')}: ${lastUpdatedValue}`}
        />
      </div>
    </div>
  );
};
