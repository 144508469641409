import { Controller, useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { Segment } from '@cycling-web/analog-ui';
import { ISegmentProps } from '@cycling-web/analog-ui';

type IProps = ISegmentProps & {
  name: string;
};

export const SegmentControl = (props: IProps) => {
  const { control } = useFormContext();
  const { name, buttons, ...rest } = props;

  const [activeButton, setActiveButton] = useState<number>(0);

  const handleChange = useCallback(
    (onChange: (...event: any[]) => void) => {
      return (index: number) => {
        setActiveButton(index);
        onChange(buttons[index].id);
      };
    },
    [buttons]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => {
        return (
          <Segment
            activeButton={activeButton}
            buttons={buttons}
            onChange={handleChange(onChange)}
            {...rest}
          />
        );
      }}
    />
  );
};
