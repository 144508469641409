import {
  ICloseConversationRequest,
  ICloseConversationResponse,
  IGetChatHistoryRequest,
  IGetChatHistoryResponse,
  ISendChatMessageRequest,
  ISendChatMessageResponse,
  ISendMessageFeedbackRequest,
  ISendMessageFeedbackResponse,
} from './types';
import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';

interface IAIService {
  getChatHistory: (p: IGetChatHistoryRequest) => R<IGetChatHistoryResponse>;
  sendChatMessage: (p: ISendChatMessageRequest) => R<ISendChatMessageResponse>;
  sendMessageFeedback: (
    p: ISendMessageFeedbackRequest
  ) => R<ISendMessageFeedbackResponse>;
  closeConversation: (
    p: ICloseConversationRequest
  ) => R<ICloseConversationResponse>;
}

export class AIService implements IAIService {
  async getChatHistory(p: IGetChatHistoryRequest): R<IGetChatHistoryResponse> {
    try {
      return axiosInstance.get(`/v1/ai/v1/chat/athlete/${p.athleteId}/history`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendChatMessage(
    p: ISendChatMessageRequest
  ): R<ISendChatMessageResponse> {
    try {
      return axiosInstance.post(`/v1/ai/v1/chat/athlete/${p.athleteId}/ask`, {
        query: p.query,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendMessageFeedback(
    p: ISendMessageFeedbackRequest
  ): R<ISendMessageFeedbackResponse> {
    try {
      return axiosInstance.post(
        `/v1/ai/v1/chat/athlete/${p.athleteId}/feedback`,
        p.body
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async closeConversation(
    p: ICloseConversationRequest
  ): R<ICloseConversationResponse> {
    try {
      return axiosInstance.post(
        `/v1/ai/v1/chat/athlete/${p.athleteId}/close_conversation`,
        {
          reason: 'Closed by user',
        }
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
