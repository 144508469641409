import debounce from 'lodash/debounce';
import { trackChartInteraction } from '../../../ms/log-insights';

type IProps = {
  name: string;
};

const debouncedMouseOver = debounce((params: any, name: string) => {
  const payload = {
    chartName: name,
    seriesName: params.seriesName || '',
  };
  trackChartInteraction('hover', payload);
}, 500);

export const handleChartMouseOver = ({ name }: IProps) => {
  return (params: any) => {
    debouncedMouseOver(params, name);
  };
};
