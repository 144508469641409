import { cloneElement, ReactHTMLElement, ReactNode } from 'react';
import type { IButtonSize } from './types';

interface IProps {
  icon: ReactNode;
  size: IButtonSize;
}

export const ButtonAdornmentIcon = ({ icon, size = 's' }: IProps) => {
  const iconSizeMap: Record<IButtonSize, number> = {
    xs: 14,
    s: 16,
    m: 20,
    l: 24,
  };

  if (!icon) {
    return null;
  }

  return cloneElement(icon as ReactHTMLElement<HTMLElement>, {
    size: iconSizeMap[size],
  });
};
