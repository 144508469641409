import { useTranslation } from 'react-i18next';
import { SCORE_TYPE } from '../DailyWellnessScores/types';
import ScoresCard from '../ScoresCard';
import './index.css';
import { WellnessWidget } from '../WellnessWidget';
import { useMemo } from 'react';
import { EMPTY } from '../../../../constants';
import { useAthleteWellnessStore } from '../../store/slice';

export const BodyComposition = () => {
  const { t } = useTranslation();
  const wellness = useAthleteWellnessStore((s) => s.wellness);

  const scores = useMemo(() => {
    return [
      {
        value: wellness?.height?.data ? wellness.height.data.toString() : EMPTY,
        label: t('label.height'),
        lastUpdatedAt: wellness?.height?.date,
        type: SCORE_TYPE.COACH,
        units: t('units.cm'),
      },
      {
        value: wellness?.bodyMass?.data
          ? wellness.bodyMass.data.toString()
          : EMPTY,
        label: t('label.body_mass'),
        lastUpdatedAt: wellness?.bodyMass?.date,
        type: SCORE_TYPE.COACH,
        units: t('units.kg'),
      },
      {
        value: wellness?.bmi?.data ? wellness.bmi.data.toString() : EMPTY,
        label: t('label.body_mass_index'),
        lastUpdatedAt: wellness?.bmi?.date,
        type: SCORE_TYPE.COACH,
        units: '',
      },
      {
        value: wellness?.skinFold?.data
          ? wellness.skinFold.data.toString()
          : EMPTY,
        label: t('label.sum_of_skinfolds'),
        lastUpdatedAt: wellness?.skinFold?.date,
        type: SCORE_TYPE.COACH,
        units: t('units.mm'),
      },
      {
        value: wellness?.bodyFatPercent?.data
          ? wellness.bodyFatPercent.data.toString()
          : EMPTY,
        label: t('label.calculated_body_fat'),
        lastUpdatedAt: wellness?.bodyFatPercent?.date,
        type: SCORE_TYPE.COACH,
        units: '%',
      },
      {
        value: wellness?.fatMass?.data
          ? wellness.fatMass.data.toString()
          : EMPTY,
        label: t('label.fat_mass'),
        lastUpdatedAt: wellness?.fatMass?.date,
        type: SCORE_TYPE.COACH,
        units: t('units.kg'),
      },
    ];
  }, [t, wellness]);

  const scoreCards = scores.map((score) => (
    <ScoresCard key={score.label} scoreCard={score} />
  ));

  return (
    <WellnessWidget text={t('label.body_composition')}>
      <div className="body-composition__cards">{scoreCards}</div>
    </WellnessWidget>
  );
};
