import { MessageCircle, Paperclip } from 'lucide-react';
import { IMedicalReportRecord } from '../../types/medical';

type IProps = {
  item: IMedicalReportRecord;
};

export const Details = ({ item }: IProps) => {
  return (
    <div className="medical-report-table__icons analog-typography--body">
      <div className="medical-report-table__icons__col">
        <MessageCircle size={16} />
        {item.notesCount}
      </div>
      <div className="medical-report-table__icons__col">
        <Paperclip size={16} />
        {item.attachmentsCount}
      </div>
    </div>
  );
};
