import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ITableColumn } from '@cycling-web/analog-ui';

export const useTableColumns = () => {
  const { t } = useTranslation();

  return useMemo((): ITableColumn[] => {
    return [
      {
        key: 'user',
        name: t('label.user'),
        minWidth: 210,
      },
      {
        key: 'role',
        name: t('label.role'),
        minWidth: 140,
      },
      {
        key: 'status',
        name: t('label.status'),
        minWidth: 160,
        maxWidth: 160,
      },
      {
        key: 'actions',
        name: '',
        minWidth: 60,
        maxWidth: 60,
      },
    ];
  }, [t]);
};
