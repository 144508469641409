import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

export const useViewModel = () => {
  const { instance, inProgress } = useMsal();
  const loading =
    inProgress === InteractionStatus.Login ||
    inProgress === InteractionStatus.HandleRedirect;

  const signInWithMicrosoft = useCallback(() => {
    const loginRequest = {
      scopes: ['openid'],
      prompt: 'select_account',
    };

    instance.loginRedirect(loginRequest);
  }, [instance]);

  return {
    signInWithMicrosoft,
    loading,
  };
};
