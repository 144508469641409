import { IAthlete, IAthletesFilters } from '../../../../types/athletes';

export const filterAthletesDelegate = (filters: IAthletesFilters) => {
  return (athlete: IAthlete) => {
    let valid = true;

    if (
      filters.search &&
      !athlete?.fullName.toLowerCase().includes(filters.search.toLowerCase())
    ) {
      valid = false;
    }

    return valid;
  };
};
