import { IAttachmentProps } from './types';
import { Download, File, Trash2 } from 'lucide-react';
import { Typography } from '../Typography';
import { formatFileSize } from '../../utils/formatFileSize';
import { IconButton } from '../Button/IconButton';
import { useCallback } from 'react';
import { clsx } from 'clsx';
import { Message } from '../Message';

export const Attachment = (props: IAttachmentProps) => {
  const {
    file,
    onRemove,
    onDownload,
    invalid,
    message,
    showFileSize = true,
    name,
    size,
    downloading,
    deleting,
  } = props;
  const rootClass = clsx(
    'analog-attachment',
    invalid && 'analog-attachment--invalid'
  );

  const handleRemove = useCallback(() => {
    if (onRemove) {
      onRemove(file);
    }
  }, [file, onRemove]);

  const handleDownload = useCallback(() => {
    if (onDownload) {
      onDownload(file);
    }
  }, [file, onDownload]);

  return (
    <div className={rootClass}>
      <div className="analog-attachment__content">
        <div className="analog-attachment__icon">
          <File />
        </div>
        <div className="analog-attachment__details">
          <Typography
            as="div"
            className="analog-attachment__name"
            text={name || file?.name || '-'}
          />
          {showFileSize && (
            <Typography
              as="div"
              className="analog-attachment__size"
              text={size ? size : file ? formatFileSize(file.size) : '-'}
            />
          )}
        </div>
        {(onRemove || onDownload) && (
          <div className="analog-attachment__actions">
            {onDownload && (
              <IconButton
                size="m"
                variant="quietNeutralDark"
                content={<Download />}
                onClick={handleDownload}
                loading={downloading}
              />
            )}
            {onRemove && (
              <IconButton
                size="m"
                variant="quietNeutralDark"
                content={<Trash2 />}
                onClick={handleRemove}
                loading={deleting}
              />
            )}
          </div>
        )}
      </div>
      {message && <Message text={message} variant="error" />}
    </div>
  );
};
