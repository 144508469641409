import './index.css';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../hooks/useTitle';
import { Link } from 'react-router';
import { ROUTES } from '../../../../router/routes';

export const PrivacyNotice = () => {
  const { t } = useTranslation();
  useTitle(t('label.privacy_notice'));
  const url = `${window.location.origin}${window.location.pathname}`;

  return (
    <div className="privacy-notice">
      <p style={{ marginBottom: '32px' }}>
        <i>Last Reviewed and Effective: 13 November 2024</i>
      </p>
      <p>
        This Privacy Notice explains how we use the Personal Data that we
        collect or generate both in relation to the Platform and our Services.
      </p>
      <p>
        You can find the definitions of all capitalized terms in the{' '}
        <Link to={`/${ROUTES.LEGAL}/${ROUTES.GLOSSARY}`}>Glossary</Link>.
      </p>
      <div className="reset-counter">
        <ol>
          <li>
            <div>
              <div id="section1">
                <strong>KEY FEATURES</strong>
              </div>
              <p>
                It is important that you take the time to read this Privacy
                Notice in full to understand how we use your Personal Data, but
                these are some key features of our handling of your Personal
                Data that you should, in particular, be aware of:
              </p>
              <ul>
                <li>
                  In providing our Services to you, we will collect various
                  types of Personal Data that you, or Staff in your Team, may
                  provide. If you are an Athlete, with your permission, some of
                  this data may come from the Integrated Apps and Integrated
                  Devices that you connect to your Account.
                </li>
                <li>
                  If you are an Athlete, some of this Personal Data will include
                  Health Data, which is a particularly sensitive type of
                  Personal Data. We will obtain your consent before we process
                  any of your Health Data. You may withdraw your consent to any
                  of this collection / use at any time by notifying us using the
                  details in <a href={`${url}#section13`}>Section 13</a>.
                </li>
                <li>
                  We use your Personal Data primarily to provide the Services to
                  you, and as applicable, other Users who are part of your
                  Team(s).
                </li>
                <li>
                  We may share your Personal Data with Coaches and other Staff
                  within your Team(s), if they also have an Account with us
                  linked to your Team.
                </li>
                <li>
                  We may also share your Personal Data with certain other third
                  parties where it is necessary to do so in order to provide you
                  with the Services.
                </li>
                <li>
                  Your Personal Data may be transferred and / or processed
                  outside of the country where you are based. In this case, we
                  will only transfer your Personal Data where there are
                  appropriate measures in place to ensure its privacy and
                  security, and only in accordance with applicable law.
                </li>
                <li>
                  You may have certain rights under data protection laws in your
                  jurisdiction. Where this is the case, you may exercise these
                  rights at any time by contacting us using the details in{' '}
                  <a href={`${url}#section13`}>Section 13</a>.
                </li>
              </ul>
            </div>
          </li>

          <li>
            <div>
              <div id="section2">
                <strong>BACKGROUND</strong>
              </div>
              <ol>
                <li>
                  Analog and our Affiliates collect and use certain Personal
                  Data. We are responsible for ensuring that we use that
                  Personal Data in compliance with data protection laws.
                </li>
                <li>
                  We respect the privacy of our Users and are committed to
                  keeping all your Personal Data secure. This Privacy Notice
                  governs our handling of Personal Data in the course of
                  carrying on commercial activities.
                </li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <div id="section3">
                <strong>THE SERVICES WE PROVIDE</strong>
              </div>
              <ol>
                <li>
                  <div>
                    <div>
                      This Privacy Notice concerns the following information
                      that we collect about you:
                    </div>
                    <ul className="upper-alpha-parentheses">
                      <li>information we receive through the Platform; and</li>
                      <li>
                        information we receive through the provision of our
                        Services.
                      </li>
                    </ul>
                  </div>
                </li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <div id="section4">
                <strong>THE TYPES OF PERSONAL DATA WE COLLECT</strong>
              </div>
              <ol>
                <li>
                  <div>
                    Many of the Services we offer and which you (by accessing
                    such Services via the Platform) have engaged us to provide
                    require us to obtain Personal Data in order to perform such
                    Services. Users may be unable to access the Platform and
                    Services if such Personal Data are not provided.
                  </div>
                </li>
                <li>
                  <div>
                    <div>
                      In relation to the Services, we will collect and process
                      the following Personal Data:
                    </div>
                    <ul>
                      <li>
                        <p>
                          <strong>Information that you provide to us</strong>.
                          This includes information about you that you provide
                          to us through your Account. The nature of the Services
                          you are using will determine the kind of Personal Data
                          we might ask for, though such information may include
                          (by way of a non-exhaustive list):
                        </p>
                        <ul>
                          <li>
                            <p>
                              basic Personal Data (such as first name, last
                              name, affiliated Team name, business or Team email
                              address, personal email address, business phone
                              number, personal phone number, business address or
                              personal address);
                            </p>
                          </li>
                          <li>
                            <p>social media handles;</p>
                          </li>
                          <li>
                            <p>
                              for Athletes only: certain Health Data (such as
                              skinfold measurements, weight, blood test data,
                              sleep data, performance data, and menstrual cycle
                              and pregnancy information); and
                            </p>
                          </li>
                          <li>
                            <p>
                              any further information that you choose to share
                              on the Platform which may be considered Personal
                              Data.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          <strong>
                            For Athletes only: Information that a Coach or other
                            Staff in your Team might provide in relation to you,
                            through their Account
                          </strong>
                          . This includes information about your training and
                          performance, such as comments on your performance or
                          workout plans.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            For Athletes only: Information that Medical Staff on
                            your Team provides to us through their Account
                          </strong>
                          . Such information may include certain Health Data
                          (such as blood test data).
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            Information that we collect or generate about you
                          </strong>
                          . This includes (by way of non-exhaustive list):
                        </p>
                        <ul>
                          <li>
                            <p>for Athletes only:</p>
                            <ul>
                              <li>
                                <p>
                                  certain workout metrics (such as performance
                                  metrics, training load, training stress
                                  balance and automatically detected training
                                  intervals);
                                </p>
                              </li>
                              <li>
                                <p>
                                  talent identification metrics (such as a World
                                  Tour Success Probability Score);
                                </p>
                              </li>
                              <li>
                                <p>
                                  comparative metrics (such as a comparison of
                                  your performance metrics against global
                                  rankings, or against your Team average or
                                  other individual athletes on your Team); and
                                </p>
                              </li>
                              <li>
                                <p>
                                  progress tracking metrics (such as graphs
                                  depicting your performance against personal or
                                  Team targets);
                                </p>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <p>
                              any information regarding the Services you have
                              engaged us to provide (by accessing such Services
                              via the Platform) and our interactions with you;
                              and
                            </p>
                          </li>
                          <li>
                            <p>
                              a file with your contact history to be used for
                              enquiry purposes so that we may ensure that you
                              are satisfied with the Services which we have
                              provided to you.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          <strong>
                            Information we obtain from other sources
                          </strong>
                          . This includes the Personal Data provided to us by
                          third-party service providers, agencies or other
                          publicly available sources where applicable, including
                          any Integrated Apps or Integrated Devices that you
                          have consented to us connecting with your Account.
                          This information includes (by way of non-exhaustive
                          list):
                        </p>
                        <ul>
                          <li>
                            <p>for Athletes only:</p>
                            <ul>
                              <li>
                                <p>
                                  workout metrics (such as mean / max power
                                  achieved, distance covered, average speed and
                                  energy exerted in kJ);
                                </p>
                              </li>
                              <li>
                                <p>
                                  certain Health Data (such as menstrual cycle
                                  information, sleep data and heart rate and
                                  power threshold data); and
                                </p>
                              </li>
                              <li>
                                <p>
                                  your geolocation data in relation to your
                                  training; and
                                </p>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <p>for Coaches, workout plans.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          <strong>Cookies</strong>
                        </p>
                        <ul>
                          <li>
                            <p>
                              When you visit the Platform, Cookies are used to
                              collect technical information about the services
                              that you use, and how you use them.
                            </p>
                          </li>
                          <li>
                            <p>
                              For more information on the Cookies we use, please
                              see <a href={`${url}#section11`}>Section 11</a> of
                              this Privacy Notice and our{' '}
                              <Link
                                to={`/${ROUTES.LEGAL}/${ROUTES.COOKIES_NOTICE}`}
                              >
                                Cookies Notice
                              </Link>
                              .
                            </p>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <p>
                          <strong>Anonymised data</strong>
                        </p>
                        <ul>
                          <li>
                            <p>
                              We may anonymise and aggregate certain of your
                              information (e.g. performance and training data)
                              and use such anonymised and aggregated data to
                              improve or enhance our services or to offer
                              different services.
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <div id="section5">
                <strong>HOW WE USE YOUR INFORMATION</strong>
              </div>
              <ol>
                <li>
                  <div>
                    <div>
                      Your Personal Data may be stored and processed by us in
                      the following ways and for the following purposes:
                    </div>
                    <ul>
                      <li>
                        <p>
                          to allow you to use and access the functionality
                          provided by our Services;
                        </p>
                      </li>
                      <li>
                        <p>to set you up to use our Services;</p>
                      </li>
                      <li>
                        <p>
                          to understand feedback on our Services and to help
                          provide more information on the use of those Services
                          quickly and easily;
                        </p>
                      </li>
                      <li>
                        <p>
                          to communicate with you in order to provide you with
                          services or information about us and our Services;
                        </p>
                      </li>
                      <li>
                        <p>to understand your needs and interests;</p>
                      </li>
                      <li>
                        <p>
                          for the management and administration of our business;
                        </p>
                      </li>
                      <li>
                        <p>
                          for ongoing review and improvement of the information
                          provided on the Platform to ensure it is user friendly
                          and to prevent any potential disruptions or cyber
                          attacks;
                        </p>
                      </li>
                      <li>
                        <p>
                          in order to comply with and in order to assess
                          compliance with applicable laws, rules and
                          regulations, and internal policies and procedures;
                        </p>
                      </li>
                      <li>
                        <p>
                          for the administration and maintenance of databases
                          storing Personal Data; or
                        </p>
                      </li>
                      <li>
                        <p>
                          in order to improve our Services to you, including
                          training and improving artificial intelligence models
                          implemented in our Services provided to you or your
                          Team(s).
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <div>
                      In relation to each purpose for which we use Personal Data
                      we will ensure that the usage complies with the law and,
                      in particular, the usage is covered by a lawful
                      justification. Those justification will include:
                    </div>
                    <ul>
                      <li>
                        <p>
                          we need to do so in order to perform our contractual
                          obligations with our customers and third-party
                          providers;
                        </p>
                      </li>
                      <li>
                        <p>we have obtained your consent to do so;</p>
                      </li>
                      <li>
                        <p>
                          we have legal and regulatory obligations that we have
                          to discharge;
                        </p>
                      </li>
                      <li>
                        <p>
                          we need to do so in order to establish, exercise or
                          defend our legal rights or for the purpose of legal
                          proceedings; and / or
                        </p>
                      </li>
                      <li>
                        <p>
                          the use of your Personal Data as described is
                          necessary for our legitimate business interests, such
                          as:
                        </p>
                        <ul>
                          <li>
                            <p>
                              allowing us to effectively and efficiently manage
                              and administer the operation of our business;
                            </p>
                          </li>
                          <li>
                            <p>
                              maintaining compliance with internal policies and
                              procedures;
                            </p>
                          </li>
                          <li>
                            <p>
                              monitoring the use of our copyrighted materials;
                              and
                            </p>
                          </li>
                          <li>
                            <p>
                              enabling quick and easy access to information on
                              our Services.
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    Some of the Personal Data we collect and use in order to
                    provide our Services is Health Data. This type of Personal
                    Data is considered to be particularly sensitive and is
                    subject to additional protections under law. For this
                    reason, we will only collect and use this type of Personal
                    Data where you have consented to this or otherwise where the
                    law specifically permits us to collect and use such data.
                  </div>
                </li>
                <li>
                  <div>
                    We will take steps to ensure that the Personal Data is
                    accessed only by our or our Affiliates’ employees or
                    contractors that have a need to do so for the purposes
                    described in this Privacy Notice.
                  </div>
                </li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <div id="section6">
                <strong>DISCLOSURE OF YOUR INFORMATION TO THIRD PARTIES</strong>
              </div>
              <ol>
                <li>
                  <div>
                    We may share your Personal Data within our Affiliates for
                    the purposes described above. We will take steps to ensure
                    that access to Personal Data is restricted to our and our
                    Affiliates’ employees or contractors who have a legitimate
                    interest in the purposes described in this Privacy Notice.
                  </div>
                </li>
                <li>
                  <div>
                    We may share your Personal Data with other Users who are
                    part of your Team(s). If you are an Athlete, where we have
                    your consent to share it, some of this Personal Data will be
                    Health Data. This is for the purpose of providing Services
                    to you and the applicable Team(s).
                  </div>
                </li>
                <li>
                  <div>
                    <div>
                      We may also share your Personal Data to persons other than
                      to our Affiliates in the following circumstances:
                    </div>
                    <ul>
                      <li>
                        <p>
                          with third party agents and contractors for the
                          purposes of providing services to us (for example, our
                          accountants, professional advisors, IT and
                          communications providers and debt collectors). These
                          third parties will be subject to appropriate data
                          protection obligations and they will only use your
                          Personal Data as described in this Privacy Notice;
                        </p>
                      </li>
                      <li>
                        <p>
                          with third party suppliers for the purposes of
                          providing the Services to you (for example, to third
                          parties who provide software that is integrated into
                          our Services). These third parties will be subject to
                          appropriate data protection obligations and they will
                          only use your Personal Data as described in this
                          Privacy Notice. If you sign up directly with a third
                          party to receive their service then their collection
                          and processing of your Personal Data will be subject
                          to their privacy notice and the third party will be
                          responsible for how they handle your Personal Data;
                        </p>
                      </li>
                      <li>
                        <p>
                          to the extent required by law, for example if we are
                          under a duty to disclose your Personal Data in order
                          to comply with any legal obligation (including,
                          without limitation, in order to comply with tax
                          reporting requirements and disclosures to regulators),
                          or to establish, exercise or defend its legal rights;
                        </p>
                      </li>
                      <li>
                        <p>
                          if we sell our business or assets, in which case we
                          may need to disclose your Personal Data to the
                          prospective buyer for due diligence purposes; and
                        </p>
                      </li>
                      <li>
                        <p>
                          if we are acquired by a third party, in which case the
                          Personal Data held by us about you will be disclosed
                          to the third party buyer.
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <div id="section7">
                <strong>INTERNATIONAL TRANSFERS OF PERSONAL DATA</strong>
              </div>
              <ol>
                <li>
                  <div>
                    We are a global business. Our customers and our operations
                    are spread around the world. As a result, we collect and
                    transfer Personal Data on a global basis. That means that we
                    may transfer your Personal Data to locations outside of your
                    country or jurisdiction.
                  </div>
                </li>
                <li>
                  <div>
                    <div>
                      Where we transfer your Personal Data outside of your
                      country or jurisdiction, we will ensure that it is
                      protected and transferred in a manner consistent with all
                      applicable legal requirements, for example by:
                    </div>
                    <ul>
                      <li>
                        <p>
                          ensuring that the country or jurisdiction that we send
                          the data to has been approved pursuant to your
                          country’s or jurisdiction’s data protection authority
                          as offering an adequate level of protection for
                          Personal Data; or
                        </p>
                      </li>
                      <li>
                        <p>
                          putting in place agreements or contractual clauses, as
                          approved by the relevant data protection authority,
                          with the recipient of the Personal Data that obliges
                          the recipient to protect your Personal Data.
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    You can obtain more details of the protection given to your
                    Personal Data when it is transferred outside of your country
                    or jurisdiction (including, where required by law, a copy of
                    any applicable standard data protection clauses which we
                    have entered into in relation to transfer of your Personal
                    Data) by contacting us as described in{' '}
                    <a href={`${url}#section13`}>Section 13</a> below.
                  </div>
                </li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <div id="section8">
                <strong>HOW WE SAFEGUARD YOUR INFORMATION</strong>
              </div>
              <ol>
                <li>
                  <div>
                    We have extensive controls in place to maintain the security
                    of our information and information systems. User files are
                    protected with safeguards according to the sensitivity of
                    the relevant information. Appropriate controls (such as
                    restricted access) are placed on our computer systems.
                    Physical access to areas where Personal Data is gathered,
                    processed or stored is limited to authorised employees or
                    contractors.
                  </div>
                </li>

                <li>
                  <div>
                    As a condition of employment, our and our Affiliates’
                    employees and contractors are required to follow all
                    applicable laws and regulations, including in relation to
                    data protection law. Access to sensitive Personal Data is
                    limited to those employees or contractors who need to it to
                    perform their roles. Unauthorised use or disclosure of
                    confidential client information by our employees and
                    contractors is prohibited and may result in disciplinary
                    measures.
                  </div>
                </li>

                <li>
                  <div>
                    When you contact us about your Account or your Subscription,
                    you may be asked for some Personal Data. This type of
                    safeguard is designed to ensure that only you, or someone
                    authorised by you, has access to your Account.
                  </div>
                </li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <div id="section9">
                <strong>HOW LONG WE KEEP YOUR PERSONAL DATA</strong>
              </div>
              <ol>
                <li>
                  <div>
                    <div>
                      How long we will hold your Personal Data for will vary and
                      will be determined by the following cumulative criteria:
                    </div>
                    <ul>
                      <li>
                        <p>
                          the purpose for which we are using it – we will need
                          to keep your Personal Data for as long as is necessary
                          for that purpose; and
                        </p>
                      </li>
                      <li>
                        <p>
                          legal obligations – laws or regulation may set a
                          minimum period for which we have to keep your Personal
                          Data.
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <div id="section10">
                <strong>YOUR RIGHTS</strong>
              </div>
              <ol>
                <li>
                  <div>
                    <div>
                      In all the above cases in which we collect, use or store
                      your Personal Data, you may under applicable law have the
                      following rights which you can exercise free of charge.
                      These rights may include:
                    </div>
                    <ul>
                      <li>
                        <p>
                          the right to obtain information regarding the
                          processing of your Personal Data and access to the
                          Personal Data which we hold about you;
                        </p>
                      </li>

                      <li>
                        <p>
                          the right to withdraw your consent to the processing
                          of your Personal Data at any time. Please note,
                          however, that we may still be entitled to process your
                          Personal Data if we have another legitimate reason for
                          doing so. For example, we may need to retain Personal
                          Data to comply with a legal obligation. In addition,
                          if you withdraw consent, certain features of our
                          Services may not be available to you (e.g. because we
                          cannot use your Health Data);
                        </p>
                      </li>

                      <li>
                        <p>
                          in some circumstances, the right to receive some
                          Personal Data in a structured, commonly used and
                          machine-readable format and/or request that we
                          transmit those data to a third party where this is
                          technically feasible. Please note that this right only
                          applies to Personal Data which you have provided
                          directly to us;
                        </p>
                      </li>

                      <li>
                        <p>
                          the right to request that we rectify your Personal
                          Data if it is inaccurate or incomplete;
                        </p>
                      </li>

                      <li>
                        <p>
                          the right to request that we erase your Personal Data
                          in certain circumstances. Please note that there may
                          be circumstances where you ask us to erase your
                          Personal Data, but we are legally entitled to retain
                          it;
                        </p>
                      </li>

                      <li>
                        <p>
                          the right to object to, or request that we restrict,
                          our processing of your Personal Data in certain
                          circumstances. Again, there may be circumstances where
                          you object to, or ask us to restrict, our processing
                          of your Personal Data but we are legally entitled to
                          refuse that request; and
                        </p>
                      </li>

                      <li>
                        <p>
                          the right to lodge a complaint with the relevant data
                          protection regulator if you think that any of your
                          rights have been infringed by us.
                        </p>
                      </li>

                      <li>
                        <p>
                          If we are not able to honor your consumer request, in
                          certain circumstances, you may have the right to
                          appeal our decision.
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    If you are a US resident, you also have the right to opt-out
                    of targeted advertising, the sale of your Personal Data, or
                    certain automated profiling in furtherance of decisions that
                    produce legal or similarly significant effects concerning
                    you. We do not engage in these activities.
                  </div>
                </li>

                <li>
                  <div>
                    You can exercise your rights by contacting us using the
                    details listed in{' '}
                    <a href={`${url}#section13`}>Section 13</a> below.
                  </div>
                </li>

                <li>
                  <div>
                    Further information about your rights may be obtained by
                    contacting the supervisory data protection authority located
                    in your jurisdiction.
                  </div>
                </li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <div id="section11">
                <strong>COOKIES</strong>
              </div>
              <ol>
                <li>
                  <div>
                    This section on Cookies and the consent that you gave for
                    the use of Cookies apply specifically to each use of the
                    Platform. You gave your consent to the use of Cookies in the
                    Cookie Banner as described in the Cookie Notice and this
                    section. We try to keep the information on the Cookies
                    up-to-date, but note that the information is always a
                    snapshot. Because of the constant innovation of the
                    Platform, the non-static character of the internet and the
                    third parties involved in the use of Cookies, it is possible
                    that all Cookies and information are not available here.
                  </div>
                </li>

                <li>
                  <div>
                    <div>
                      To the extent that we collect Personal Data with the help
                      of Cookies, we will process it in accordance with this
                      Privacy Notice. We additionally note that:
                    </div>
                    <ul className="upper-alpha-parentheses">
                      <li>
                        we process any Personal Data that we collect using
                        essential cookies (those that are required for the
                        operation of the Platform) to provide you with an
                        error-free working website and app; and
                      </li>

                      <li>
                        we process any Personal Data that we collect using
                        analytical, performance, functional or targeting Cookies
                        exclusively on the basis of your consent, as provided
                        through your selections within our Cookies Banner. You
                        may withdraw your consent to this processing in whole or
                        in part at any time by visiting our{' '}
                        <Link to={`/${ROUTES.LEGAL}/${ROUTES.COOKIES_NOTICE}`}>
                          Cookies Notice
                        </Link>
                        .
                      </li>
                    </ul>
                  </div>
                </li>

                <li>
                  <div>
                    <div>Types of Cookies and purposes</div>
                    <ul>
                      <li>
                        For more information on the Cookies we use, and the
                        please see our{' '}
                        <Link to={`/${ROUTES.LEGAL}/${ROUTES.COOKIES_NOTICE}`}>
                          Cookies Notice
                        </Link>
                        .
                      </li>
                    </ul>
                  </div>
                </li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <div id="section12">
                <strong>DESIGNATED REPRESENTATIVE</strong>
              </div>
              <ol>
                <li>
                  For the contact of our designated representatives in the UK
                  and EU, please email us at dataprivacy-sports@analog.io.
                </li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <div id="section13">
                <strong>QUESTIONS AND CONCERNS</strong>
              </div>
              <ol>
                <li>
                  <div>
                    <div>
                      For further information regarding our processing of your
                      Personal Data, this Privacy Notice, questions relating to
                      consent, or in order to exercise the rights mentioned
                      above, please contact us using the following contact
                      details:
                    </div>
                    <p>
                      <span>Address:</span>
                      <span>
                        PO Box 77821, Abu Dhabi, UAE, Attn: Analog Studios FZ
                        LLC, Data Privacy Team
                      </span>
                    </p>
                    <p>
                      <span>Email Address:</span>
                      <span>dataprivacy-sports@analog.io</span>
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    We are usually able to resolve privacy questions or concerns
                    promptly and effectively. If you are not satisfied with the
                    response you receive from us, you may escalate concerns to
                    the applicable privacy regulator in your jurisdiction. Upon
                    request, we will provide you with the contact information
                    for that regulator.
                  </div>
                </li>
              </ol>
            </div>
          </li>
        </ol>

        <h3>
          <strong>
            CALIFORNIA RESIDENTS: NOTICE AT COLLECTION AND PERSONAL DATA RIGHTS
          </strong>
        </h3>

        <p>
          This section of the Privacy Notice only applies to residents of
          California. If you are not a resident of California, this section does
          not apply to you. Terms that are capitalized are defined in the CCPA.
        </p>

        <p>
          We provide this Notice at Collection and Personal Data Rights for
          California Residents (the “CCPA Privacy Notice”) to supplement the
          information contained in the Privacy Notice. This CCPA Privacy Notice
          describes the Personal Data we may have collected directly from you or
          indirectly about you if you use the Platform and/or Services or
          otherwise interact with Analog, and how we have used and/or disclosed
          your Personal Data, as required by the California Consumer Privacy Act
          of 2018, as amended by the California Privacy Rights Act of 2020,
          (collectively the “CCPA”).
        </p>

        <p>
          For additional details about our privacy practices, please see the
          other sections of our Privacy Notice.
        </p>
      </div>

      <div className="reset-counter">
        <ol>
          <li>
            <div>
              <div>
                <strong>
                  Categories and Examples of Personal Data Collected
                </strong>
              </div>
              <ol>
                <li>
                  <div>
                    <div>
                      As described in more detail in{' '}
                      <a href={`${url}#section4`}>Section 4</a> of the Privacy
                      Notice above, we may collect the following categories of
                      Personal Data from or about you:
                    </div>
                    <ul>
                      <li>
                        <div>
                          Identifiers, such as your name, email address, or
                          postal address.
                        </div>
                      </li>

                      <li>
                        <div>
                          Internet or other similar network activity, such as
                          your browsing history (with respect to the Platform),
                          search history (with respect to the Platform), and
                          information on how you use the Platform or Services.
                        </div>
                      </li>

                      <li>
                        <div>
                          For Athletes only: geolocation data (relating to your
                          training).
                        </div>
                      </li>

                      <li>
                        <div>
                          Sensitive Personal Data: We may also collect
                          information that may be considered to be sensitive
                          Personal Data, including sensitive health data as
                          described in more detail in Section 4. We collect this
                          sensitive Personal Data for the business purposes
                          listed below. We do not sell or share this Sensitive
                          Personal Data, as those terms are defined by the CCPA,
                          and we do not use or disclose Sensitive Personal Data
                          for purposes other than those permitted under the
                          CCPA.
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ol>
            </div>
          </li>
          <li>
            <div>
              <div>
                <strong>Purposes for which Personal Data Is Disclosed.</strong>
              </div>
              <p>
                See <a href={`${url}#section6`}>Section 6</a> of the Privacy
                Notice which describes the business or commercial purposes for
                which your Personal Data may be disclosed.
              </p>
            </div>
          </li>
          <li>
            <div>
              <div>
                <strong>“Selling” and “Sharing” Personal Data</strong>
              </div>
              <p>
                As defined by the CCPA, we may sell and share certain online
                identifiers for the purposes of online advertising and
                analytics. Analog does not have actual knowledge that it sells
                or shares Personal Data about consumers under 16 years of age.
                You have the right to opt-out of selling and sharing. For more
                information about these activities and to exercise your right to
                opt-out of selling and sharing, please see our “Privacy Rights”
                page.
              </p>
            </div>
          </li>
          <li>
            <div>
              <div>
                <strong>Retention Period</strong>
              </div>
              <p>
                See <a href={`${url}#section9`}>Section 9</a> of the Privacy
                Notice which describes the factors we consider when determining
                how long we need to retain your Personal Data.
              </p>
            </div>
          </li>
          <li>
            <div>
              <div>
                <strong>Other California Rights</strong>
              </div>
              <p>
                California’s “Shine the Light” law (Civil Code Section §
                1798.83) permits users of the Platform that are California
                residents to request certain information regarding our
                disclosure of Personal Data to third parties for their direct
                marketing purposes. To request relevant information, please send
                an email to dataprivacy-sports@analog.io or write us at: Analog
                Studios FZ LLC, Attn: Legal, PO Box 77821, Abu Dhabi, UAE.
              </p>
            </div>
          </li>
          <li>
            <div>
              <div>
                <strong>Contact Information</strong>
              </div>
              <p>
                If California residents have any questions or comments about
                this notice, the ways in which Analog collects and uses your
                information described above and in the Privacy Notice, your
                choices and rights regarding such use, or wish to exercise your
                rights under California law, please do not hesitate to contact
                us at:
              </p>
              <p>
                <strong>Phone:</strong> <span>+971 505794771</span>
              </p>
              <p>
                <strong>Email:</strong>{' '}
                <span>dataprivacy-sports@analog.io</span>
              </p>
              <p>
                <strong>Postal Address:</strong>{' '}
                <span>
                  ATTN: Analog Studios FZ LLC, California Privacy Team, PO Box
                  77821, Abu Dhabi, UAE
                </span>
              </p>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};
