import { ReactNode, useMemo } from 'react';
import './index.css';
import { IAvatarFonts, IAvatarProps, IAvatarSize } from './types';
import { clsx } from 'clsx';
import { Skeleton } from '../Skeleton';
import { getInitials } from '../../utils/getInitials';

export const Avatar = ({
  size = 's',
  shape = 'rounded',
  text,
  subtext,
  initials,
  src,
  alt,
  showDetails = true,
  className,
  loading,
  direction = 'default',
}: IAvatarProps) => {
  const rootClass = clsx(
    'analog-avatar',
    `analog-avatar--${size}`,
    `analog-avatar--${shape}`,
    `analog-avatar--${direction}`,
    className
  );

  const fontClassMap: Record<IAvatarSize, IAvatarFonts> = {
    xs: {
      letters: 'analog-typography--caption',
      title: 'analog-typography--caption',
      subtext: 'analog-typography--footnote light',
      skeletonImageSize: '24px',
      skeletonTitleWidth: '100px',
      skeletonTitleHeight: '14px',
      skeletonSubtextWidth: '70px',
      skeletonSubtextHeight: '14px',
    },
    s: {
      letters: 'analog-typography--body regular',
      title: 'analog-typography--body regular',
      subtext: 'analog-typography--caption light',
      skeletonImageSize: '32px',
      skeletonTitleWidth: '130px',
      skeletonTitleHeight: '18px',
      skeletonSubtextWidth: '100px',
      skeletonSubtextHeight: '12px',
    },
    m: {
      letters: 'analog-typography--subtitle',
      title: 'analog-typography--body bold',
      subtext: 'analog-typography--subtitle light',
      skeletonImageSize: '40px',
      skeletonTitleWidth: '130px',
      skeletonTitleHeight: '21px',
      skeletonSubtextWidth: '100px',
      skeletonSubtextHeight: '18px',
    },
    l: {
      letters: 'analog-typography--subtitle',
      title: 'analog-typography--h4 bold',
      subtext: 'analog-typography--subtitle light',
      skeletonImageSize: '48px',
      skeletonTitleWidth: '150px',
      skeletonTitleHeight: '24px',
      skeletonSubtextWidth: '120px',
      skeletonSubtextHeight: '18px',
    },
    xl: {
      letters: 'analog-typography--h4',
      title: 'analog-typography--h4 bold',
      subtext: 'analog-typography--subtitle light',
      skeletonImageSize: '56px',
      skeletonTitleWidth: '150px',
      skeletonTitleHeight: '24px',
      skeletonSubtextWidth: '120px',
      skeletonSubtextHeight: '18px',
    },
    xxl: {
      letters: 'analog-typography--h4',
      title: 'analog-typography--h4 bold',
      subtext: 'analog-typography--subtitle light',
      skeletonImageSize: '64px',
      skeletonTitleWidth: '150px',
      skeletonTitleHeight: '24px',
      skeletonSubtextWidth: '120px',
      skeletonSubtextHeight: '18px',
    },
  };

  const lettersClass: string = clsx(
    'analog-avatar__letters',
    `${fontClassMap[size].letters}`
  );

  const letters: string = initials || getInitials(text);

  const imgJSX = loading ? (
    <Skeleton
      width={fontClassMap[size].skeletonImageSize}
      height={fontClassMap[size].skeletonImageSize}
      shape={shape}
    />
  ) : (
    <div className="analog-avatar__circle">
      {src && <img className="analog-avatar__image" alt={alt} src={src} />}
      {!src && letters && <span className={lettersClass}>{letters}</span>}
    </div>
  );

  const titleJSX: ReactNode =
    text &&
    (loading ? (
      <Skeleton
        width={fontClassMap[size].skeletonTitleWidth}
        height={fontClassMap[size].skeletonTitleHeight}
      />
    ) : (
      <div className={`analog-avatar__text ${fontClassMap[size].title}`}>
        {text}
      </div>
    ));

  const subTextJSX: ReactNode =
    subtext &&
    (loading ? (
      <Skeleton
        width={fontClassMap[size].skeletonSubtextWidth}
        height={fontClassMap[size].skeletonSubtextHeight}
      />
    ) : (
      <div className={`analog-avatar__subtext ${fontClassMap[size].subtext}`}>
        {subtext}
      </div>
    ));

  return (
    <div className={rootClass}>
      {imgJSX}
      {showDetails && (text || subtext) && (
        <div className="analog-avatar__details">
          {titleJSX}
          {subTextJSX}
        </div>
      )}
    </div>
  );
};
