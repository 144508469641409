import type { CSSProperties } from 'react';
import type { ISkeletonProps } from './types';
import './index.css';
import { clsx } from 'clsx';

export const Skeleton = (props: ISkeletonProps) => {
  const {
    width = '200px',
    height = `${16 * 1.43}px`,
    shape = 'rounded',
    className,
    style: styles,
  } = props;

  const customStyle: CSSProperties = styles || {};

  const style: CSSProperties = {
    ...customStyle,
    width,
    height,
    borderRadius: shape === 'round' ? height : '8px',
  };

  const rootClass: string = clsx('analog-skeleton', className);

  return <div className={rootClass} style={style} />;
};
