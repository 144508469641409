import './index.css';
import { Switch, Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { ReactNode, useCallback } from 'react';

type IProps = {
  title: string;
  text?: string;
  children?: ReactNode;
  showToggle?: boolean;
  checked?: boolean;
  onChange?: () => void;
};

export const CookiesGroup = ({
  title,
  text,
  showToggle,
  children,
  checked,
  onChange,
}: IProps) => {
  const { t } = useTranslation();

  const handleChange = useCallback(() => {
    if (onChange) {
      onChange();
    }
  }, [onChange]);

  return (
    <div className="cookies-group__wrapper">
      <div className="cookies-group">
        <div className="cookies-group__details">
          <Typography
            variant="subtitle"
            weight="bold"
            text={title}
            className="cookies-group__details-title"
          />
          {text && (
            <Typography
              variant="subtitle"
              text={text}
              className="cookies-group__details-text"
            />
          )}
        </div>
        {showToggle && (
          <div className="cookies-group__actions">
            <Switch
              checked={!!checked}
              onChange={handleChange}
              labelOff={t('action.accept')}
              variant="primaryDark"
            />
          </div>
        )}
      </div>
      {children && <div className="cookies-group__items">{children}</div>}
    </div>
  );
};
