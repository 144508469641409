import './index.css';
import { Select } from '@cycling-web/analog-ui';
import { IExtendSelectProps, ISelectOption } from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from '../../../types/enums';

type IProps = {
  days?: DateRange[];
  value: DateRange;
  onChange: (value: DateRange) => void;
  selectProps?: IExtendSelectProps;
};

export const LastNDaysFilter = ({
  value,
  onChange,
  days = [
    DateRange.Week,
    DateRange.Month,
    DateRange.TwoMonth,
    DateRange.ThreeMonth,
    DateRange.Year,
  ],
  selectProps,
}: IProps) => {
  const { t } = useTranslation();

  const options: ISelectOption[] = useMemo(() => {
    return days.map((d: DateRange) => {
      return {
        id: d.toString(),
        text:
          d === DateRange.Week
            ? t('label.last_week')
            : t('label.last_n_days', { d: d / (24 * 60 * 60 * 1000) }),
      };
    });
  }, [days, t]);

  const handleChange = useCallback(
    (option: ISelectOption) => {
      onChange(+option.id);
    },
    [onChange]
  );

  return (
    <div className="last-n-days-filter">
      <Select
        options={options}
        value={options.find((o: ISelectOption) => +o.id === value)}
        onChange={handleChange}
        placeholder={t('placeholder.last_n_days')}
        {...selectProps}
      />
    </div>
  );
};
