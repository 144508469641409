export const ChartColor = {
  Text: '#bbc1c9',
  AxisLabelText: '#f3f3f6',
  AxisNameText: '#bbc1c9',

  PointerLine: '#bbc1c9',
  AxisLine: '#bbc1c9',
  GridLine: 'rgba(85, 91, 98, 0.3)',

  ScatterBorder: '#f3f3f6',

  TooltipBackground: '#3e4246',
  TooltipBorder: '#3e4246',

  LightBlue: 'rgba(68, 195, 243, 1)',
  LightBlue50: 'rgba(68, 195, 243, 0.5)',
  LightBlue30: 'rgba(68, 195, 243, 0.3)',

  Blue: 'rgba(61, 95, 255, 1)',
  Blue50: 'rgba(61, 95, 255, 0.5)',
  Blue30: 'rgba(61, 95, 255, 0.3)',

  LightPurple: 'rgba(145, 135, 255, 1)',
  LightPurple50: 'rgba(145, 135, 255, 0.5)',
  LightPurple30: 'rgba(145, 135, 255, 0.3)',

  Purple: 'rgba(106, 95, 192, 1)',
  Purple50: 'rgba(106, 95, 192, 0.5)',
  Purple30: 'rgba(106, 95, 192, 0.3)',

  Orange: 'rgba(207, 75, 43, 1)',
  Orange50: 'rgba(207, 75, 43, 0.5)',
  Orange30: 'rgba(207, 75, 43, 0.3)',

  Red: 'rgba(213, 38, 38, 1)',
  Red50: 'rgba(213, 38, 38, 0.5)',
  Red30: 'rgba(213, 38, 38, 0.3)',

  LightRed: 'rgba(239, 83, 80, 1)',
  LightRed50: 'rgba(239, 83, 80, 0.5)',
  LightRed30: 'rgba(239, 83, 80, 0.3)',

  Rose: 'rgba(163, 63, 81, 1)',
  Rose50: 'rgba(163, 63, 81, 0.5)',
  Rose30: 'rgba(163, 63, 81, 0.3)',

  LightRose: 'rgba(240, 98, 146, 1)',
  LightRose50: 'rgba(240, 98, 146, 0.5)',
  LightRose30: 'rgba(240, 98, 146, 0.3)',

  Magenta: 'rgba(151, 38, 133, 1)',
  Magenta50: 'rgba(151, 38, 133, 0.5)',
  Magenta30: 'rgba(151, 38, 133, 0.3)',

  LightMagenta: 'rgba(177, 58, 119, 1)',
  LightMagenta50: 'rgba(177, 58, 119, 0.5)',
  LightMagenta30: 'rgba(177, 58, 119, 0.3)',

  LightCyan: 'rgba(25, 218, 225, 1)',
  LightCyan50: 'rgba(25, 218, 225, 0.5)',
  LightCyan30: 'rgba(25, 218, 225, 0.3)',

  Cyan: 'rgba(25, 195, 161, 1)',
  Cyan50: 'rgba(25, 195, 161, 0.5)',
  Cyan30: 'rgba(25, 195, 161, 0.3)',

  LightGreen: 'rgba(134, 219, 154, 1)',
  LightGreen50: 'rgba(134, 219, 154, 0.5)',
  LightGreen30: 'rgba(134, 219, 154, 0.3)',

  Green: 'rgba(40, 144, 117, 1)',
  Green50: 'rgba(40, 144, 117, 0.5)',
  Green30: 'rgba(40, 144, 117, 0.3)',

  LightYellow: 'rgba(219, 220, 156, 1)',
  LightYellow50: 'rgba(219, 220, 156, 0.5)',
  LightYellow30: 'rgba(219, 220, 156, 0.3)',

  Yellow: 'rgba(236, 194, 72, 1)',
  Yellow50: 'rgba(236, 194, 72, 0.5)',
  Yellow30: 'rgba(236, 194, 72, 0.3)',

  GradientEnd: 'rgba(39, 41, 43, 0.1)',
};
