import { IPlatformsSlice, IPlatformsState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IPlatform } from '../../types/platform';

export const initialState: IPlatformsState = {
  platforms: [],
  platformsLoaded: false,
};

export const usePlatformsStore = create<IPlatformsSlice>()(
  immer((set) => ({
    ...initialState,
    setPlatforms: (platforms: IPlatform[]) => {
      set((state) => {
        state.platforms = platforms;
        state.platformsLoaded = true;
      });
    },
    setPlatformsLoaded: (loaded: boolean) => {
      set((state) => {
        state.platformsLoaded = loaded;
      });
    },
    disconnect: (platform: IPlatform) => {
      set((state) => {
        const index = state.platforms.findIndex(
          (p: IPlatform) => p.platformName === platform.platformName
        );
        if (index >= 0) {
          state.platforms[index].synced = false;
        }
      });
    },
    setConnected: (platform: IPlatform) => {
      set((state) => {
        const index = state.platforms.findIndex(
          (p: IPlatform) => p.platformName === platform.platformName
        );
        if (index >= 0) {
          state.platforms[index].synced = true;
        }
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
