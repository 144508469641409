import React, { useEffect } from 'react';
import { IBodyComposition } from '../../../../types/wellness';
import { useFetchAthleteBodyComposition } from '../../hooks/useFetchAthleteBodyComposition';
import { useAthleteWellnessStore } from '../../store/slice';
import { useStateFilters } from '../../hooks/useStateFilters';
import { BodyCompositionChart } from '../BodyCompositionChart';

// TODO: Think about wrappers for every chart. Move all charts to a separate folder.
export const BodyCompositionChartWrapper = () => {
  const { fetchBodyComposition } = useFetchAthleteBodyComposition();
  const { filters, handleChangeFilter } = useStateFilters();

  const wellness = useAthleteWellnessStore((s) => s.wellness);
  const bodyComposition: IBodyComposition | null = useAthleteWellnessStore(
    (s) => s.bodyComposition
  );
  const bodyCompositionLoaded: boolean = useAthleteWellnessStore(
    (s) => s.bodyCompositionLoaded
  );

  useEffect(() => {
    // TODO: Need to throttle it somehow.
    fetchBodyComposition();
  }, [wellness?.bodyMass.data, fetchBodyComposition]);

  return (
    <BodyCompositionChart
      data={bodyComposition}
      loading={!bodyCompositionLoaded}
      filters={filters}
      handleChangeFilter={handleChangeFilter}
    />
  );
};
