import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IHeartMeasurement } from '../../../../types/wellness';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import {
  ChartAxisName,
  ChartGroup,
  ChartTrackingNames,
} from '../../../../constants/charts';
import {
  Chart,
  ChartColor,
  chartTooltipFormatter,
  IEChartOption,
} from '../../../../components/Chart';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';
import { format, parse } from 'date-fns';
import { EMPTY } from '../../../../constants';
import { DateRange } from '../../../../types/enums';
import { IHandleChangeFilter } from '@cycling-web/common';

type IProps = {
  data: IHeartMeasurement | null;
  loading: boolean;
  filters: {
    period: DateRange;
  };
  handleChangeFilter: IHandleChangeFilter;
};

export const RhrChart = ({
  data,
  loading,
  filters,
  handleChangeFilter,
}: IProps) => {
  const { t } = useTranslation();

  const source = useMemo(() => {
    const source: (string | number | null)[][] = [
      [ChartAxisName.Date, ChartAxisName.RHR, ChartAxisName.Baseline_RHR],
    ];

    if (!data) {
      return source;
    }

    const dataPoints = data.points || [];

    for (let i = 0; i < dataPoints.length; i++) {
      const p = dataPoints[i];
      const parsedDate = parse(p.startDate, 'yyyy-MM-dd', new Date());
      const formattedDate = format(parsedDate, 'dd/MM/yyyy');
      source.push([
        formattedDate,
        p.data.rhr || null,
        p.data.rhrBaseline || null,
      ]);
    }

    return source;
  }, [data]);

  const onRenderTooltipValue = useCallback(
    (value: number) => {
      return `${value || EMPTY} ${t('units.bpm')}`;
    },
    [t]
  );

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      grid: {
        bottom: '40px',
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
          axisLine: {
            show: false,
          },
          gridIndex: 0,
        },
      ],
      yAxis: [
        {
          type: 'value',
          min: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
        },
      ],
      series: [
        {
          name: ChartAxisName.RHR,
          type: 'line',
          itemStyle: {
            color: ChartColor.LightBlue,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
          connectNulls: true,
        },
        {
          name: ChartAxisName.Baseline_RHR,
          type: 'line',
          itemStyle: {
            color: ChartColor.LightPurple,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
          connectNulls: true,
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
        }),
      },
    };
  }, [onRenderTooltipValue, source]);

  const filtersBarProps = {
    filters: (
      <LastNDaysFilter
        value={filters.period}
        onChange={handleChangeFilter('period')}
        selectProps={{ variant: 'light' }}
      />
    ),
  };

  return (
    <ChartWrapper minHeight="340px">
      <Chart
        headerProps={{
          title: t('label.rhr_statistics'),
          filtersBarProps,
        }}
        option={option}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.AthleteRHR,
          }),
        }}
        group={ChartGroup.HeartMeasurements}
      />
    </ChartWrapper>
  );
};
