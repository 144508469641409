import './index.css';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../hooks/useTitle';
import { Link } from 'react-router';
import { ROUTES } from '../../../../router/routes';

export const Glossary = () => {
  const { t } = useTranslation();
  useTitle(t('label.glossary'));

  return (
    <div className="glossary">
      <p>
        This Glossary sets out the definitions of capitalized terms used in:
      </p>
      <ul>
        <li>
          <p>
            the{' '}
            <Link to={`/${ROUTES.LEGAL}/${ROUTES.TERMS_AND_CONDITIONS}`}>
              Terms & Conditions
            </Link>
          </p>
        </li>
        <li>
          <p>
            the{' '}
            <Link to={`/${ROUTES.LEGAL}/${ROUTES.PRIVACY_NOTICE}`}>
              Privacy Notice
            </Link>
            ; and
          </p>
        </li>
        <li>
          <p>
            the{' '}
            <Link to={`/${ROUTES.LEGAL}/${ROUTES.COOKIES_NOTICE}`}>
              Cookies Notice
            </Link>
            .
          </p>
        </li>
      </ul>
      <table>
        <tbody>
          <tr>
            <td>
              <strong>“Account”</strong>
            </td>
            <td>
              means any account made available to a User to access the Platform
              and Services.
            </td>
          </tr>
          <tr>
            <td>
              <strong>“Account Term”</strong>
            </td>
            <td>
              means, in relation to your Account, the period commencing as at
              your first access to the Platform, and ending as at the deletion
              of your Account.
            </td>
          </tr>
          <tr>
            <td>
              <strong>“Admin”</strong>
            </td>
            <td>
              means, in relation to a Team, the User assigned with an “Admin”
              Role.
            </td>
          </tr>
          <tr>
            <td>
              <strong>“Affiliates”</strong>
            </td>
            <td>
              <p>Our Affiliates are:</p>
              <ul>
                <li>
                  Analog SPV RSC Ltd, a restricted scope company established in
                  the Abu Dhabi Global Market, Abu Dhabi, UAE, with commercial
                  license number 8970;
                </li>
                <li>
                  Analog Technologies LLC, a limited liability company
                  established in Abu Dhabi, UAE, with economic license number
                  CN-4236022; and
                </li>
                <li>
                  Any other companies which may from time to time be
                  subsidiaries of Analog SPV RSC Ltd.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <strong>“AI”</strong>
            </td>
            <td>means artificial intelligence.</td>
          </tr>
          <tr>
            <td>
              <strong>“Analog”</strong>
            </td>
            <td>
              Analog Studios FZ LLC, a free zone limited liability company
              established in the Creative Zone, Abu Dhabi, UAE, with business
              license number B.L. 1412/24 and registered office PO Box 77821,
              Abu Dhabi, UAE.
            </td>
          </tr>
          <tr>
            <td>
              <strong>“Analog Sports”</strong>
            </td>
            <td>means the Platform.</td>
          </tr>
          <tr>
            <td>
              <strong>“Athlete”</strong>
            </td>
            <td>
              means a User who at the relevant time is assigned an “Athlete”
              Role.
            </td>
          </tr>
          <tr>
            <td>
              <strong>“CCPA”</strong>
            </td>
            <td>
              means, collectively, the California Consumer Privacy Act of 2018,
              as amended by the California Privacy Rights Act of 2020.
            </td>
          </tr>
          <tr>
            <td>
              <strong>“CCPA Privacy Notice”</strong>
            </td>
            <td>
              means the section of the Privacy Notice titled “California
              Residents: Notice at Collection and Personal Data Rights.”
            </td>
          </tr>
          <tr>
            <td>
              <strong>“Cookie”</strong>
            </td>
            <td>
              a small text file that includes a small quantity of information
              sent to the browser of website users, by a web server, and stored
              on the hard disk drive of a computer for purposes of archiving,
              collecting navigation data for statistical analysis purposes, and
              offering users services related to their interests or location.
            </td>
          </tr>
          <tr>
            <td>
              <strong>“Cookies Banner”</strong>
            </td>
            <td>
              means the banner that pops up on the Platform from time to time
              through which you select your Cookie preferences.
            </td>
          </tr>
          <tr>
            <td>
              <strong>“Cookies Notice”</strong>
            </td>
            <td>
              means our{' '}
              <Link to={`/${ROUTES.LEGAL}/${ROUTES.COOKIES_NOTICE}`}>
                Cookie Notice
              </Link>{' '}
              (as may be amended from time to time).
            </td>
          </tr>
          <tr>
            <td>
              <strong>“Core Terms & Conditions”</strong>
            </td>
            <td>
              means the section of the{' '}
              <Link to={`/${ROUTES.LEGAL}/${ROUTES.TERMS_AND_CONDITIONS}`}>
                Terms & Conditions
              </Link>{' '}
              titled “Core Terms & Conditions”, which apply to your Account and
              all your Subscriptions.
            </td>
          </tr>
          <tr>
            <td>
              <strong>“EEA”</strong>
            </td>
            <td>
              means the European Economic Area (as constituted from time to
              time).
            </td>
          </tr>
          <tr>
            <td>
              <strong>“Enterprise Subscription”</strong>
            </td>
            <td>
              means an Enterprise Subscription for access to the Platform and
              Services, as linked to a Team and as governed by the{' '}
              <Link to={`/${ROUTES.LEGAL}/${ROUTES.TERMS_AND_CONDITIONS}`}>
                Terms & Conditions
              </Link>{' '}
              as applicable to Enterprise Subscriptions.
            </td>
          </tr>
          <tr>
            <td>
              <strong>“Enterprise Subscription Terms & Conditions”</strong>
            </td>
            <td>
              means the section of the{' '}
              <Link to={`/${ROUTES.LEGAL}/${ROUTES.TERMS_AND_CONDITIONS}`}>
                Terms & Conditions
              </Link>{' '}
              titled “Enterprise Subscription Terms & Conditions”, which apply
              only to Enterprise Subscriptions.
            </td>
          </tr>
          <tr>
            <td>
              <strong>“EU”</strong>
            </td>
            <td>
              means the European Union (as constituted from time to time).
            </td>
          </tr>
          <tr>
            <td>
              <strong>“EU GDPR”</strong>
            </td>
            <td>means EU's General Data Protection Regulation 2016/679.</td>
          </tr>
          <tr>
            <td>
              <strong>“EU SCCs”</strong>
            </td>
            <td>
              means the standard contractual clauses as approved by the European
              Commission by its Implementing Decision (EU) 2021/914 of 04 June
              2021 for the transfer of personal data in the European Union to
              third countries, as updated from time to time, applying the terms
              of Module 1 (Controller to Processor).
            </td>
          </tr>

          <tr>
            <td>
              <strong>“GDPR”</strong>
            </td>
            <td>means the EU GDPR and UK GDPR.</td>
          </tr>

          <tr>
            <td>
              <strong>“Health Data”</strong>
            </td>
            <td>
              means Personal Data related to the physical or mental health of a
              natural person, including the provision of health care services,
              which reveal information about their health status.
            </td>
          </tr>

          <tr>
            <td>
              <strong>“ICO”</strong>
            </td>
            <td>means the UK Information Commissioner’s Office.</td>
          </tr>

          <tr>
            <td>
              <strong>“Integrated Apps”</strong>
            </td>
            <td>
              means the third party applications or platforms which may, with a
              User’s consent, be connected to such User’s Account, pursuant to
              which data or information relating to such User may be pulled into
              their Account. These are listed from time to time here [LINK TO
              DATA SHARING PAGE].
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Integrated Devices”</strong>
            </td>
            <td>
              means the third party devices which may, with a User’s consent, be
              connected to such User’s Account pursuant to which data or
              information relating to such User may be pulled into their
              Account. These are listed from time to time here [LINK TO DATA
              SHARING PAGE].
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Medical Staff”</strong>
            </td>
            <td>
              means any User assigned a Role of “Head Doctor” or “Secondary
              Doctor”.
            </td>
          </tr>

          <tr>
            <td>
              <strong>“License”</strong>
            </td>
            <td>
              means the license granted to you pursuant to the Terms &
              Conditions.
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Permitted Purpose”</strong>
            </td>
            <td>
              means, in relation to an Enterprise Subscription: your conduct of
              activities within your capacity as a member of the Team and within
              the scope of your assigned Role(s) in relation to such Team.
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Personal Data”</strong>
            </td>
            <td>
              means any data which relates to a living individual who can be
              identified from that data or from that data and other information
              which is in the possession of, or is likely to come into the
              possession of, us (or our representatives or service providers).
              In addition to factual information, it includes any expression of
              opinion about an individual and any indication of our intentions
              or the intentions of any other person in respect of an individual.
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Platform”</strong>
            </td>
            <td>
              <p>means the aggregate or any of:</p>
              <ul>
                <p>
                  a) the Analog Sports platform, found{' '}
                  <Link to={`/${ROUTES.SIGN_IN}`}>here</Link>
                </p>
                <p>b) the Analog Sports mobile app, coming soon,</p>
              </ul>
              <p>
                and any other websites and mobile applications owned and/or
                operated by Analog or our Affiliates from time to time under the
                “Analog Sports” brand.
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Privacy Notice”</strong>
            </td>
            <td>
              means our{' '}
              <Link to={`/${ROUTES.LEGAL}/${ROUTES.PRIVACY_NOTICE}`}>
                Privacy Notice
              </Link>{' '}
              for Enterprise Subscriptions (as may be amended from time to
              time).
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Prohibited Person”</strong>
            </td>
            <td>
              means any person: (a) that is the subject or target of any
              Sanctions; (b) listed in the annex to, or otherwise subject to the
              provisions of, the Executive Order; (c) named in any
              Sanctions-related list maintained by OFAC, the U.S. Department of
              State, the U.S. Department of Commerce or the U.S. Department of
              the Treasury, including the following OFAC lists, as maintained
              and amended from time-to-time: the list of Specially Designated
              Nationals and Blocked Persons, the list of Foreign Sanctions
              Evaders, or the Sectoral Sanctions Identification List; (d)
              located, organized or resident in a Prohibited Jurisdiction that
              is, or whose government is, the subject or target of Sanctions;
              (e) which otherwise is, by public designation of the United
              Nations Security Council, the European Union, or Her Majesty’s
              Treasury, the subject or target of any Sanction; (f) with which
              any party to this Agreement is prohibited from dealing or
              otherwise engaging in any transaction by any Sanctions Laws; or
              (g) owned or controlled by any such person or persons described in
              the foregoing clauses (a)-(f).
            </td>
          </tr>

          <tr>
            <td>
              <strong> “Prohibited Territory”</strong>
            </td>
            <td>
              means, at any time, a country, territory or geographical region
              which is itself the subject or target of any Sanctions (including,
              without limitation, the Crimea region of Ukraine, Cuba, Burma
              (Myanmar) (prior to October 7, 2016), Iran, North Korea, Sudan,
              Syria, Crimea, the so-called Donetsk People’s Republic and Luhansk
              People’s Republic regions of Ukraine, Russia, and Belarus.
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Role”</strong>
            </td>
            <td>
              <p>
                means any of the following roles assigned to a User from time to
                time:
              </p>
              <ul>
                <p>a) “Admin”;</p>
                <p>b) “Athlete”;</p>
                <p>c) “Coach”;</p>
                <p>d) “Head Doctor”;</p>
                <p>e) “Management”;</p>
                <p>f) “Mental Coach”;</p>
                <p>g) “Nutritionist”;</p>
                <p>h) “Physiotherapist”;</p>
                <p>i) “Secondary Doctor”; and</p>
                <p>j) “Sports Director”;</p>
              </ul>
              <p>any other role we may make available from time to time.</p>
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Sanctions”</strong>
            </td>
            <td>
              means economic or financial sanctions together with any other law,
              regulation, order, directive, or guidance (with the effect of law)
              imposed by a Sanctions Authority that imposes trade or economic
              restrictive measures against countries, territories, individuals
              or entities.
            </td>
          </tr>
          <tr>
            <td>
              <strong>“Sanctions Authority”</strong>
            </td>
            <td>
              means the Cabinet of the United Arab Emirates Federal Government,
              the United Arab Emirates Executive Office for Control and
              Non-Proliferation, the United Nations Security Council (the
              Council as a whole and not its individual members), the U.S.
              Department of State, the U.S. Department of Commerce Bureau of
              Industry and Security, the U.S. Department of the Treasury Office
              of Foreign Assets Control (“OFAC”), Global Affairs Canada, the
              European Union Council and/or Commission (including any present or
              future member state of the European Union), the Office of
              Financial Sanctions Implementation within His Majesty’s Treasury
              of the United Kingdom, the United Kingdom Department of Business
              and Trade.
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Services”</strong>
            </td>
            <td>
              <p>means:</p>
              <ul>
                <p>
                  a) your establishment of, access to and use of an Account on
                  the Platform; and
                </p>
                <p>
                  b) all services provided by us (or our Affiliates) to you
                  through, pursuant to, or in relation to your Account,
                  including but not limited to the License granted to you.
                </p>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Staff”</strong>
            </td>
            <td>
              means a User who is assigned any Role excluding the Role of
              “Athlete”.
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Subscription”</strong>
            </td>
            <td>
              means any subscription for access to the Platform and our
              Services, as offered by Analog Sports from time to time.
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Subscription Term”</strong>
            </td>
            <td>
              <p>
                means, in relation to an Enterprise Subscription, the period
                commencing at the time of your sign up for the Enterprise
                Subscription, and ending on the earlier of:
              </p>
              <ul>
                <p>
                  a) the date agreed between Analog and the relevant Team; and
                </p>
                <p>
                  b) the date of termination of the Enterprise Subscription in
                  accordance with the{' '}
                  <Link to={`/${ROUTES.LEGAL}/${ROUTES.TERMS_AND_CONDITIONS}`}>
                    Terms & Conditions
                  </Link>{' '}
                  applicable to Enterprise Subscriptions.
                </p>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Team”</strong>
            </td>
            <td>
              <p>means a team registered with Analog Sports.</p>
              <p>
                In relation to an Enterprise Subscription, it means the team to
                which the Enterprise Subscription is linked.
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <strong>“Terms & Conditions”</strong>
            </td>
            <td>
              means our{' '}
              <Link to={`/${ROUTES.LEGAL}/${ROUTES.TERMS_AND_CONDITIONS}`}>
                Terms & Conditions
              </Link>{' '}
              (as may be amended from time to time).
            </td>
          </tr>

          <tr>
            <td>
              <strong>“UAE”</strong>
            </td>
            <td>means the United Arab Emirates.</td>
          </tr>

          <tr>
            <td>
              <strong>“UK”</strong>
            </td>
            <td>means the United Kingdom.</td>
          </tr>

          <tr>
            <td>
              <strong>“UK Addendum”</strong>
            </td>
            <td>
              <p>means:</p>
              <ul>
                <p>
                  a) the standard contractual clauses as approved by the ICO
                  under Section 119A of the Data Protection Act 2018 which came
                  into force on 21 March 2022, being the EU SCCs; and
                </p>
                <p>
                  b) the international data transfer addendum to the EU SCCs
                  issued by the ICO on 21 March 2022 for the transfer of
                  personal data in the United Kingdom to third countries, as
                  updated from time to time.
                </p>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <strong>“UK GDPR”</strong>
            </td>
            <td>
              means UK’s General Data Protection Regulation (as defined in
              section 3(10) of the Data Protection Act 2018, supplemented by
              section 205(4)).
            </td>
          </tr>

          <tr>
            <td>
              <strong>“UCI”</strong>
            </td>
            <td>means the Union Cycliste Internationale.</td>
          </tr>

          <tr>
            <td>
              <strong>“USA”</strong>
            </td>
            <td>means the United States of America.</td>
          </tr>

          <tr>
            <td>
              <strong>“User”</strong>
            </td>
            <td>
              means any permitted user of any Account made available by us from
              time to time on the Platform.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
