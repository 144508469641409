import { useEffect, useMemo, useRef } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { useAthletePerformanceStore } from '../store/slice';
import { format } from 'date-fns';
import { IPeak } from '../../../types/performance';
import { calculateDateRange } from '../../../utils/utils';
import { Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { DateRange } from '../../../types/enums';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchPeak = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const filters = useAthletePerformanceStore((s) => s.filters);
  const setPeak = useAthletePerformanceStore((s) => s.setPeak);
  const setPeakLoaded = useAthletePerformanceStore((s) => s.setPeakLoaded);
  const fetchingRef = useRef<boolean>(false);
  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService());
  }, []);

  useEffect(() => {
    if (fetchingRef.current || !athleteId) {
      return;
    }
    fetchingRef.current = true;

    const period1 = filters.powerProfile.period1;
    const period2 = filters.powerProfile.period2;
    const period3 = filters.shared.period;

    const requests: { period: DateRange; promise: Promise<IPeak[]> }[] = [];

    const range1 = calculateDateRange(period1);
    requests.push({
      period: period1,
      promise: performanceRepository.getPeak({
        startDate: format(range1.startDate, 'yyyy-MM-dd'),
        endDate: format(range1.endDate, 'yyyy-MM-dd'),
        athleteIds: [+athleteId],
        allAthletes: false,
      }),
    });

    const range2 = calculateDateRange(period2);
    requests.push({
      period: period2,
      promise: performanceRepository.getPeak({
        startDate: format(range2.startDate, 'yyyy-MM-dd'),
        endDate: format(range2.endDate, 'yyyy-MM-dd'),
        athleteIds: [+athleteId],
        allAthletes: false,
      }),
    });

    const range3 = calculateDateRange(period3);
    requests.push({
      period: period3,
      promise: performanceRepository.getPeak({
        startDate: format(range3.startDate, 'yyyy-MM-dd'),
        endDate: format(range3.endDate, 'yyyy-MM-dd'),
        athleteIds: [+athleteId],
        allAthletes: false,
      }),
    });

    Promise.all(requests.map(({ promise }) => promise))
      .then((response: IPeak[][]) => {
        response.forEach((peaks, i) => {
          setPeak(requests[i].period.toString(), peaks);
        });
      })
      .catch((error: AxiosError) => {
        setPeakLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_power_profile_title'),
              message: t('error.get_power_profile_message'),
            },
            { toastId: 'get_power_profile' }
          );
        }
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [
    athleteId,
    filters.powerProfile.period1,
    filters.powerProfile.period2,
    filters.shared.period,
    performanceRepository,
    setPeak,
    setPeakLoaded,
    t,
  ]);
};
