import './index.css';
import { Upload, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useUsersStore } from '../../../../../store/users/slice';
import { useCallback, useMemo, useState } from 'react';
import { renameFile } from '../../../../../utils/files';
import { Button, Toast, useBoolean } from '@cycling-web/analog-ui';
import { FileUploadButton, ProfilePictureDialog } from '@cycling-web/common';
import { UsersRepository } from '../../../../../api/users/repository';
import { UsersService } from '../../../../../api/users/service';

export const ImageUploader = () => {
  const userProfile = useUsersStore((s) => s.userProfile);
  const { t } = useTranslation();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [base64, setBase64] = useState<string | undefined>(undefined);

  const {
    value: showUploadPictureModal,
    setTrue: openUploadPictureModal,
    setFalse: dismissUploadPictureModal,
  } = useBoolean(false);

  const onImageChange = useCallback(
    (file: File, base64: string) => {
      setFile(file);
      setBase64(base64);
      openUploadPictureModal();
    },
    [openUploadPictureModal]
  );

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  const onImageUpload = useCallback(
    (picture: File | null, src: string | null): Promise<void> => {
      if (!picture || !userProfile) {
        return Promise.resolve();
      }

      const payload = {
        userId: userProfile.id,
        picture: renameFile(picture),
      };

      return usersRepository
        .updatePicture(payload)
        .then(() => {
          if (src) {
            useUsersStore.getState().updateUserProfile({
              picture: src,
            });
          }
        })
        .catch(() => {
          Toast.error({
            title: t('error.file_too_large_title'),
          });
        });
    },
    [t, userProfile, usersRepository]
  );

  return (
    <div className="image-uploader">
      <div className="image-uploader__image-wrapper">
        {userProfile?.picture ? (
          <img
            className="image-uploader__image"
            src={userProfile.picture}
            alt="User Avatar"
          />
        ) : (
          <User size={42} />
        )}
      </div>

      <div className="image-uploader__details">
        <FileUploadButton
          inputProps={{ accept: 'image/jpeg image/ong' }}
          onChange={onImageChange}
        >
          <Button
            variant="secondary"
            content={t('action.upload_your_picture')}
            size="s"
            endIcon={<Upload />}
          />
        </FileUploadButton>
      </div>

      {showUploadPictureModal && (
        <ProfilePictureDialog
          file={file}
          src={base64}
          onDismiss={dismissUploadPictureModal}
          onSubmit={onImageUpload}
        />
      )}
    </div>
  );
};
