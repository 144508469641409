import { IDictionariesSlice, IDictionariesState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IEnums } from '../../types/dictionaries';
import { UserRole } from '@cycling-web/common';

export const initialState: IDictionariesState = {
  userRoles: [],
  userRolesLoaded: false,
  enums: {
    severity: {},
    side: {},
    illnessType: {},
    modalityUsed: {},
    assessmentTools: {},
    bodyLocation: {},
    type: {},
    reportType: {},
    symptom: {},
    ossicsCode: {},
    bodyArea: {},
    onsetMode: {},
    rehabilitationStage: {},
  },
};

export const useDictionariesStore = create<IDictionariesSlice>()(
  immer((set) => ({
    ...initialState,
    setUserRoles: (userRoles: UserRole[]) => {
      set((state) => {
        state.userRoles = userRoles;
        state.userRolesLoaded = true;
      });
    },
    setEnums: (enums: IEnums) => {
      set((state) => {
        state.enums = enums;
      });
    },
  }))
);
