import { MouseEvent, useCallback, useContext } from 'react';
import { ColumnSortDirection, ITableColumn, ITableContext } from './types';
import { TableCell } from './TableCell';
import { Tooltip } from '../Tooltip';
import { ArrowDown, ArrowUp, Info } from 'lucide-react';
import { Checkbox } from '../Checkbox';
import { TableContext } from './TableContext';
import { clsx } from 'clsx';
import { IconButton } from '../Button/IconButton';
import { Typography } from '../Typography';

export const TableHead = () => {
  const {
    columns,
    checkbox,
    selectAll,
    selectedItems,
    items,
    onSelectAllFromPage,
    sortConfig,
    loading,
    onChangeSortConfig,
  }: ITableContext = useContext(TableContext);

  const handleSort = useCallback(
    (column: ITableColumn) => () => {
      if (!column.allowSort) {
        return;
      }

      onChangeSortConfig(column);
    },
    [onChangeSortConfig]
  );

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const tableHeadJSX = columns.map((c: ITableColumn) => {
    const rootClass = clsx(
      'analog-table__cellHead',
      c.allowSort && 'analog-table__cellHead--clickable'
    );

    const textClass = clsx(
      'analog-table__headText',
      (c.allowSort || c.tooltip) && 'analog-table__headText--withAdornment'
    );
    const tooltipClass = clsx(
      'analog-table__headTooltip',
      c.allowSort && 'analog-table__headTooltip--withSort'
    );

    return (
      <TableCell
        key={c.key}
        className={rootClass}
        minWidth={c.minWidth}
        maxWidth={c.maxWidth}
        grow={c.grow}
        align={c.align}
        onClick={handleSort(c)}
      >
        <div className={textClass}>{c.name || ''}</div>
        {c.tooltip && (
          <div className={tooltipClass} onClick={stopPropagation}>
            <Tooltip
              placement={c.tooltip.placement || 'right'}
              portal={c.tooltip.portal !== undefined ? c.tooltip.portal : true}
              delay={c.tooltip.delay}
              content={
                <Typography
                  as="div"
                  variant="body"
                  weight="regular"
                  className="analog-table__headTooltip"
                  text={c.tooltip.content}
                />
              }
              anchor={<Info size={16} color="var(--analog-text-primary)" />}
            />
          </div>
        )}
        {c.allowSort && (
          <div className="analog-table__headSortIcon">
            {sortConfig?.key === c.key && (
              <IconButton
                size="xs"
                variant="quietNeutral"
                content={
                  sortConfig.direction === ColumnSortDirection.ASC ? (
                    <ArrowDown size={16} />
                  ) : (
                    <ArrowUp size={16} />
                  )
                }
              />
            )}
          </div>
        )}
      </TableCell>
    );
  });

  return (
    <div className="analog-table__tableHead">
      <div className="analog-table__row">
        {checkbox && (
          <TableCell
            className="analog-table__cellHead"
            minWidth={50}
            maxWidth={50}
          >
            <Checkbox
              checked={selectAll || selectedItems.length === items.length}
              indeterminate={
                !selectAll &&
                selectedItems.length > 0 &&
                selectedItems.length < items.length
              }
              disabled={loading}
              onChange={onSelectAllFromPage}
            />
          </TableCell>
        )}
        {tableHeadJSX}
      </div>
    </div>
  );
};
