import './index.css';
import { Switch, Typography } from '@cycling-web/analog-ui';
import { IPlatform } from '../../../types/platform';
import { useTranslation } from 'react-i18next';
import { CheckCircle2 } from 'lucide-react';
import { useMemo } from 'react';
import { add, format, isToday, isYesterday, parseISO } from 'date-fns';

type IProps = {
  platform: IPlatform;
  onChangeConnection: () => void;
  showDetails?: boolean;
};

export const PlatformIntegrationStatus = ({
  platform,
  onChangeConnection,
  showDetails,
}: IProps) => {
  const { t } = useTranslation();

  const connectedJSX = (
    <div className="onboarding__integration-connected">
      <CheckCircle2 size={24} />
      <Typography
        className="onboarding__integration-connected-text"
        text={t('label.connected')}
      />
    </div>
  );

  const created_on = useMemo(() => {
    if (!platform.createdOn) {
      return '';
    }
    const parsedDate = parseISO(platform.createdOn);
    const timeZoneOffsetHours = new Date().getTimezoneOffset() / -60;
    const adjustedDate = add(parsedDate, { hours: timeZoneOffsetHours });

    return format(adjustedDate, 'dd/MM/yyyy');
  }, [platform.createdOn]);

  const disconnected_on = useMemo(() => {
    if (!platform.updatedOn) {
      return '';
    }
    const parsedDate = parseISO(platform.updatedOn);
    const timeZoneOffsetHours = new Date().getTimezoneOffset() / -60;
    const adjustedDate = add(parsedDate, { hours: timeZoneOffsetHours });

    return format(adjustedDate, 'dd/MM/yyyy');
  }, [platform.updatedOn]);

  const last_synced_on = useMemo(() => {
    if (!platform.lastFetched) {
      return '';
    }
    const parsedDate = parseISO(platform.lastFetched);
    const timeZoneOffsetHours = new Date().getTimezoneOffset() / -60;
    const adjustedDate = add(parsedDate, { hours: timeZoneOffsetHours });

    if (isToday(adjustedDate)) {
      return t('label.today_at', { time: format(adjustedDate, 'hh:mm a') });
    } else if (isYesterday(parsedDate)) {
      return t('label.yesterday_at', { time: format(adjustedDate, 'hh:mm a') });
    }
    return `${format(adjustedDate, 'dd/MM/yyyy')} ${format(
      adjustedDate,
      'hh:mm a'
    )}`;
  }, [t, platform?.lastFetched]);

  if (!showDetails) {
    return connectedJSX;
  }

  const statusJSX = !platform.disconnected ? (
    platform.synced ? (
      <>
        {connectedJSX}
        <Typography
          variant="subtitle"
          text={t('label.last_sync', {
            time: last_synced_on,
          })}
        />
      </>
    ) : (
      <>
        <Typography
          variant="subtitle"
          text={t('label.connected_on', { time: created_on })}
        />
        <Typography variant="subtitle" text={t('label.waiting_for_sync')} />
      </>
    )
  ) : (
    <>
      <Typography
        variant="subtitle"
        text={t('label.disconnected_on', { time: disconnected_on })}
      />
      <Typography
        variant="subtitle"
        text={t('label.last_sync', {
          time: last_synced_on,
        })}
      />
    </>
  );

  return (
    <div className="platform-integration_status">
      <div className="platform-integration_status__details">{statusJSX}</div>
      <Switch
        checked={!platform?.disconnected}
        onChange={onChangeConnection}
        variant="primaryDark"
      />
    </div>
  );
};
