import { DateRange, WorkOptions } from '../../../../types/enums';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useRef } from 'react';
import { IFatigueResistance } from '../../../../types/performance';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { useAthletePerformanceStore } from '../../store/slice';
import {
  IAthleteDurabilityFilters,
  IAthletePerformanceFilters,
} from '../../types';
import { AnyValue, DataType } from '../../../../types/common';
import { DataTypeFilter } from '../../../../components/filters/DataTypeFilter';
import { WorkFilter } from '../../../../components/filters/WorkFilter';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import { IHandleChangeFilter } from '@cycling-web/common';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../constants/charts';
import {
  Chart,
  ChartColor,
  ChartLegendItem,
  chartTooltipFormatter,
  IEChartOption,
} from '../../../../components/Chart';
import { curveDisplayValues, curveFormatTime } from '../../../../utils/charts';
import { formatTime } from '../../../../utils/date-time';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';

type IProps = {
  data: {
    absolute: IFatigueResistance | null;
    relative: IFatigueResistance | null;
  };
  filters: {
    period: DateRange;
  };
  handleChangeFilter: IHandleChangeFilter;
  loading?: boolean;
};

export const DurabilityChart = ({
  data: { absolute, relative },
  filters: sharedFilters,
  handleChangeFilter: handleSharedFilters,
  loading,
}: IProps) => {
  const { t } = useTranslation();

  const setFilters = useAthletePerformanceStore((s) => s.setFilters);
  const filters = useAthletePerformanceStore((s) => s.filters.durability);
  const handleChangeFilter = useCallback(
    (key: keyof IAthleteDurabilityFilters) => {
      return (value: AnyValue) => {
        setFilters({
          durability: {
            [key]: value,
          },
        } as Partial<IAthletePerformanceFilters>);
      };
    },
    [setFilters]
  );

  const isAbsolute = filters.dataType === DataType.Absolute;

  const data = useMemo(() => {
    return isAbsolute ? absolute : relative;
  }, [absolute, isAbsolute, relative]);

  const source = useMemo(() => {
    const source: (string | number | null)[][] = [
      [
        ChartAxisName.Date,
        t('label.fresh'),
        t('label.work'),
        ChartAxisName.percent_decline,
      ],
    ];

    if (!data) {
      return source;
    }

    function appendSource(data: IFatigueResistance, i: number) {
      const freshPoints = data.dataPoints || [];
      const workPoints =
        filters.work === WorkOptions.kJ2000
          ? data.dataPointsAfter2kKJ || []
          : data.dataPointsAfter3kKJ || [];
      const workPoint = workPoints ? workPoints[i] || null : null;
      const decline =
        workPoint === null ? null : (1 - workPoint / freshPoints[i]) * 100;

      source.push([Math.log2(i), freshPoints[i], workPoint, decline]);
    }

    const dataPoints = data.dataPoints || [];
    for (let i = 1; i < dataPoints.length; i++) {
      if (i < 4800) {
        appendSource(data, i);
      } else {
        if (i % 60 === 0) {
          appendSource(data, i);
        }
      }
    }

    return source;
  }, [data, t, filters.work]);

  const displayValuesIndexRef = useRef<number>(0);
  const savedXAxisLabels = useRef<string[]>([]);
  const xAxisLabelsSaved = useRef<boolean>(false);

  const axisLabelFormatter = useCallback((value: number, i: number) => {
    if (i === 0 && savedXAxisLabels.current.length > 0) {
      xAxisLabelsSaved.current = true;
    }

    if (xAxisLabelsSaved.current) {
      return savedXAxisLabels.current[i];
    }

    const parsedValue = Math.pow(2, value);
    const tolerance = 0.01 * parsedValue;

    const targetDisplayValue =
      curveDisplayValues[displayValuesIndexRef.current];
    const delta = Math.abs(parsedValue - targetDisplayValue);
    const isPassedMaxTolerance = parsedValue - targetDisplayValue > tolerance;
    const isCloseEnough = delta <= tolerance;

    if (isPassedMaxTolerance) {
      displayValuesIndexRef.current++;
    }

    if (isCloseEnough) {
      const displayValue = curveFormatTime(targetDisplayValue);
      savedXAxisLabels.current.push(displayValue);
      return displayValue;
    }

    savedXAxisLabels.current.push('');
    return '';
  }, []);

  const onRenderTooltipHeader = useCallback((params) => {
    const time = params[0].value[0];
    const parsedValue = Math.round(Math.pow(2, time));
    return formatTime(parsedValue);
  }, []);

  const onRenderTooltipValue = useCallback(
    (value: number, series: string) => {
      if (series === ChartAxisName.percent_decline) {
        return `${Math.round(value * 100) / 100}%`;
      }
      return filters.dataType === DataType.Relative
        ? `${Math.round(value * 100) / 100} ${t('units.w')}/${t('units.kg')}`
        : `${Math.round(value)} ${t('units.w')}`;
    },
    [filters.dataType, t]
  );

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      xAxis: {
        type: 'value',
        splitNumber: source.length,
        axisLabel: {
          hideOverlap: true,
          formatter: axisLabelFormatter,
        },
      },
      yAxis: [
        {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
        },
        {
          type: 'value',
          position: 'right',
          min: 0,
          max: 100,
        },
      ],
      series: [
        {
          name: t('label.fresh'),
          type: 'line',
          large: true,
          smooth: true,
          yAxisIndex: 0,
          itemStyle: {
            color: ChartColor.Red,
          },
          symbolSize: 0,
          encode: {
            x: ChartAxisName.Date,
            y: t('label.fresh'),
          },
        },
        {
          name: t('label.work'),
          type: 'line',
          large: true,
          smooth: true,
          yAxisIndex: 0,
          itemStyle: {
            color: ChartColor.LightBlue,
          },
          symbolSize: 0,
          encode: {
            x: ChartAxisName.Date,
            y: t('label.work'),
          },
        },
        {
          name: ChartAxisName.percent_decline,
          type: 'line',
          large: true,
          smooth: true,
          yAxisIndex: 1,
          itemStyle: {
            color: ChartColor.LightGreen,
          },
          symbolSize: 0,
          encode: {
            x: ChartAxisName.Date,
            y: ChartAxisName.percent_decline,
          },
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
          onRenderHeader: onRenderTooltipHeader,
        }),
      },
    };
  }, [
    t,
    axisLabelFormatter,
    onRenderTooltipHeader,
    onRenderTooltipValue,
    source,
  ]);

  const onRenderLegendItem = (item) => {
    return <ChartLegendItem item={item} />;
  };

  const onFinished = useCallback(() => {
    displayValuesIndexRef.current = 0;
  }, []);

  const filtersBarProps = {
    filters: (
      <>
        <LastNDaysFilter
          value={sharedFilters.period}
          onChange={handleSharedFilters('period')}
          selectProps={{ variant: 'light' }}
        />
        <WorkFilter
          value={filters.work}
          onChange={handleChangeFilter('work')}
          selectProps={{ variant: 'light' }}
        />
        <DataTypeFilter
          value={filters.dataType}
          onChange={handleChangeFilter('dataType')}
          selectProps={{ variant: 'light' }}
        />
      </>
    ),
  };

  return (
    <ChartWrapper minHeight="540px">
      <Chart
        headerProps={{
          title: t('label.durability'),
          filtersBarProps,
        }}
        option={option}
        legendProps={{
          onRenderItem: onRenderLegendItem,
        }}
        loading={loading}
        events={{
          onFinished,
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.Durability,
          }),
        }}
      />
    </ChartWrapper>
  );
};
