import { IconButton, onCopy, Typography } from '@cycling-web/analog-ui';
import { ReactNode, useCallback } from 'react';
import { Copy } from 'lucide-react';

type IProps = {
  title: string;
  children: ReactNode;
  copyContent?: string;
};

export const Widget = ({ title, children, copyContent = '' }: IProps) => {
  const handleCopyClick = useCallback(() => {
    if (typeof children === 'string') {
      onCopy(copyContent || JSON.stringify(children));
    } else {
      onCopy(copyContent);
    }
  }, [children, copyContent]);

  return (
    <>
      <Typography
        as="h4"
        variant="h4"
        className="backend-error-overlay__widget-title"
        text={title}
      />

      <div className="backend-error-overlay__sub-widget">
        <IconButton
          className="backend-error-overlay__copy"
          variant="quietNeutralDark"
          content={<Copy />}
          onClick={handleCopyClick}
        />
        <div className="backend-error-overlay__error-json analog-typography--body">
          {children}
        </div>
      </div>
    </>
  );
};
