import { IPerformanceReportFilters } from '../types';
import { IPerformanceReport } from '../../../types/performance';
import { IAthlete } from '../../../types/athletes';

export const filterReportsDelegate = (
  filters: IPerformanceReportFilters,
  athletesMap: Record<number, IAthlete>
) => {
  return (report: IPerformanceReport) => {
    let valid = true;
    const athlete: IAthlete | undefined = athletesMap[report.athleteId];

    if (!athlete) {
      return false;
    }

    if (
      filters.search &&
      !athlete?.fullName.toLowerCase().includes(filters.search.toLowerCase())
    ) {
      valid = false;
    }

    if (
      filters.athletes.length > 0 &&
      !filters.athletes.includes(report.athleteId)
    ) {
      valid = false;
    }

    return valid;
  };
};
