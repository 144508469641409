import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Page } from '@cycling-web/common';
import { MedicalReportsFilters } from './components/MedicalReportsFilters';
import { MedicalReportsTable } from './components/MedicalReportsTable';
import { useFetchMedicalReports } from './hooks/useFetchMedicalReports';
import { useMedicalReportsStore } from './store/slice';
import { useTitle } from '../../hooks/useTitle';

export const MedicalReports = () => {
  const { t } = useTranslation();
  useTitle(t('label.medical_report'));
  useFetchMedicalReports();
  const reset = useMedicalReportsStore((s) => s.reset);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Page pageHeaderProps={{ title: t('label.medical_report') }}>
      <MedicalReportsFilters />
      <MedicalReportsTable />
    </Page>
  );
};
