import { Controller, useFormContext } from 'react-hook-form';
import { Select } from '@cycling-web/analog-ui';
import { useCallback } from 'react';
import {
  IMultiSelectProps,
  ISelectBaseProps,
  ISelectOption,
} from '@cycling-web/analog-ui';

type ISelectMultiProps = ISelectBaseProps & IMultiSelectProps;
type IProps = Omit<ISelectMultiProps, 'value' | 'onChange' | 'multiple'> & {
  name: string;
};

export const SelectMultiControl = (props: IProps) => {
  const { control, setValue } = useFormContext();
  const { name } = props;

  const handleChange = useCallback(
    (values: ISelectOption[]) => {
      setValue(
        name,
        values.map((v: ISelectOption) => v.id)
      );
    },
    [name, setValue]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => (
        <Select
          {...props}
          onChange={handleChange}
          value={props.options.filter((o: ISelectOption) =>
            value.includes(o.id)
          )}
          multiple={true}
        />
      )}
    />
  );
};
