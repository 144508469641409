import './index.css';
import { Outlet, useLocation } from 'react-router';
import { useFetchEnums } from '../../hooks/useFetchEnums';
import { CircleGauge, FileText, User, Users, Zap } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { SideNavigation } from '../../components/SideNavigation';
import { INavigationItem } from '@cycling-web/common';
import { useMemo } from 'react';
import { ROUTES } from '../../router/routes';
import { useBaseUrl } from '../../hooks/useBaseUrl';
import { useFetchAthletes } from '../../hooks/useFetchAthletes';
import { useFetchGroups } from '../../hooks/useFetchGroups';
import { useUserRole } from '../../hooks/useUserRole';

export const EntryCoach = () => {
  useFetchAthletes();
  useFetchGroups();
  useFetchEnums();

  const { isAdmin } = useUserRole();
  const baseUrl = useBaseUrl();
  const defaultUrl = `${baseUrl}/${ROUTES.COACH}`;
  const { pathname } = useLocation();

  const { t } = useTranslation();
  const items: INavigationItem[] = useMemo(() => {
    const links: INavigationItem[] = [
      {
        url: `${defaultUrl}/${ROUTES.DASHBOARD}`,
        icon: <CircleGauge />,
        text: t('label.team_dashboard'),
      },
      {
        group: 'athletes',
        active:
          pathname.includes(`${defaultUrl}/${ROUTES.ATHLETES}`) ||
          pathname.includes(`${defaultUrl}/${ROUTES.GROUPS}`),
        icon: <Users />,
        text: t('label.athletes'),
        items: [
          {
            url: `${defaultUrl}/${ROUTES.ATHLETES}`,
            text: t('label.all_athletes'),
          },
          {
            url: `${defaultUrl}/${ROUTES.GROUPS}`,
            text: t('label.groups'),
          },
        ],
      },
      {
        group: 'reports',
        active:
          pathname.includes(`${defaultUrl}/${ROUTES.PERFORMANCE_REPORT}`) ||
          pathname.includes(`${defaultUrl}/${ROUTES.MEDICAL_REPORT_TABLE}`),
        icon: <FileText />,
        text: t('label.reports'),
        items: [
          {
            url: `${defaultUrl}/${ROUTES.PERFORMANCE_REPORT}`,
            text: t('label.performance_report'),
          },
          {
            url: `${defaultUrl}/${ROUTES.MEDICAL_REPORT_TABLE}`,
            text: t('label.medical_report'),
          },
        ],
      },
      {
        url: `${defaultUrl}/${ROUTES.SMART_CHARTS}`,
        icon: <Zap />,
        text: t('label.smart_charts'),
      },
    ];

    if (isAdmin) {
      links.push({
        url: `${defaultUrl}/${ROUTES.ADMIN_PANEL}`,
        icon: <User />,
        text: t('label.admin_panel'),
      });
    }

    return links;
  }, [defaultUrl, isAdmin, pathname, t]);

  return (
    <div className="coach">
      <SideNavigation defaultUrl={defaultUrl} items={items} />
      <div className="coach-content">
        <Outlet />
      </div>
    </div>
  );
};
