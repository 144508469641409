import { MouseEvent, useCallback, useContext } from 'react';
import { ITableColumn, ITableContext, ITableItem } from './types';
import { TableCell } from './TableCell';
import { Checkbox } from '../Checkbox';
import { TableContext } from './TableContext';
import { clsx } from 'clsx';

export const TableBody = () => {
  const {
    selectedItems,
    selectAll,
    checkbox,
    onSelect,
    itemsOnCurrentPage,
    columns,
    onRenderCell,
    onRowClick,
    idKey,
  }: ITableContext = useContext(TableContext);
  const ID = (idKey || 'id') as keyof ITableItem;

  const handleRowClick = useCallback(
    (item: ITableItem) => (e: MouseEvent) => {
      if (onRowClick) {
        onRowClick(item, e);
      }
    },
    [onRowClick]
  );

  const stopPropagation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const tableBodyJSX = itemsOnCurrentPage.map((item: ITableItem, i: number) => {
    const itemsJSX = columns.map((c: ITableColumn) => {
      const content = c.key && c.key in item ? (item as any)[c.key] : '';
      return (
        <TableCell
          key={c.key}
          minWidth={c.minWidth}
          maxWidth={c.maxWidth}
          grow={c.grow}
          align={c.align}
        >
          {onRenderCell ? onRenderCell(c, item) : content}
        </TableCell>
      );
    });

    const isSelected = !!selectedItems.find(
      (tableItem: ITableItem) => tableItem[ID] === item[ID]
    );
    const checked: boolean = selectAll || isSelected;

    const rootClass = clsx(
      'analog-table__row',
      onRowClick && 'analog-table__row--clickable',
      i % 2 === 0 ? 'analog-table__row--even' : 'analog-table__row--odd'
    );

    return (
      <div className={rootClass} key={item[ID]} onClick={handleRowClick(item)}>
        {checkbox && (
          <TableCell minWidth={50} maxWidth={50}>
            <Checkbox
              value={item[ID]}
              checked={checked}
              onChange={onSelect(item)}
              onClick={stopPropagation}
            />
          </TableCell>
        )}
        {itemsJSX}
      </div>
    );
  });

  return <div className="analog-table__tableBody">{tableBodyJSX}</div>;
};
