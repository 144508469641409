import * as echarts from 'echarts';
import { ChartColor } from './colors';

type EChartsCoreOption = echarts.EChartsCoreOption;
type XAXisComponentOption = echarts.XAXisComponentOption;
type YAXisComponentOption = echarts.YAXisComponentOption;
type LegendComponentOption = echarts.LegendComponentOption;
type TooltipComponentOption = echarts.TooltipComponentOption;
type SeriesOption = echarts.SeriesOption;
type AxisPointerComponentOption = echarts.AxisPointerComponentOption;

export const defaultChartText = {
  color: ChartColor.Text,
  fontSize: 12,
  fontFamily: 'ESKlarheitKurrent, sans-serif',
};

export const defaultAxisPointer: AxisPointerComponentOption = {
  type: 'cross',
  label: {
    show: false,
  },
};

export const defaultGrid = {
  left: 60,
  right: 60,
  top: 24,
  show: false,
};

const defaultAxis = {
  splitLine: {
    show: false,
  },
  axisPointer: {
    type: 'line',
    label: {
      ...defaultChartText,
      show: false,
    },
    lineStyle: {
      color: ChartColor.PointerLine,
    },
  },
  axisLine: {
    show: true,
    lineStyle: {
      type: 'dashed',
      dashOffset: 8,
      color: ChartColor.AxisLine,
      width: 1,
    },
  },
  axisLabel: {
    show: true,
    ...defaultChartText,
    margin: 12,
  },
  axisTick: {
    show: false,
    alignWithLabel: true,
    lineStyle: {
      color: ChartColor.AxisLine,
      width: 1,
    },
  },
  nameTextStyle: {
    ...defaultChartText,
    opacity: 0,
  },
} as XAXisComponentOption & YAXisComponentOption;

export const defaultXAxis: XAXisComponentOption = {
  ...defaultAxis,
  axisLabel: {
    margin: 10,
  },
};

export const defaultYAxis: YAXisComponentOption = {
  ...defaultAxis,
  axisPointer: { show: false },
  axisLine: { show: false },
};

export const defaultLegend: LegendComponentOption = {
  show: false,
  top: 'bottom',
  right: 0,
  textStyle: {
    ...defaultChartText,
  },
};

export const defaultTooltip: TooltipComponentOption = {
  trigger: 'axis',
  padding: 8,
  borderRadius: 8,
  borderColor: ChartColor.TooltipBorder,
  backgroundColor: ChartColor.TooltipBackground,
  textStyle: {
    ...defaultChartText,
  },
  axisPointer: { type: 'cross' },
};

export const defaultSeries: SeriesOption = {
  barMaxWidth: 24,
  markArea: {
    silent: true,
    animation: false,
  },
  markLine: {
    symbol: ['none', 'none'],
    silent: true,
    animation: false,
    label: {
      color: ChartColor.AxisLabelText,
    },
  },
};

export const defaultOption: EChartsCoreOption = {
  color: [
    ChartColor.LightBlue,
    ChartColor.Blue,
    ChartColor.LightPurple,
    ChartColor.Purple,
    ChartColor.Orange,
    ChartColor.Red,
    ChartColor.LightRed,
    ChartColor.Rose,
    ChartColor.LightRose,
    ChartColor.Magenta,
    ChartColor.LightMagenta,
    ChartColor.LightCyan,
    ChartColor.Cyan,
    ChartColor.LightGreen,
    ChartColor.Green,
    ChartColor.LightYellow,
    ChartColor.Yellow,
  ],
  legend: defaultLegend,
  grid: defaultGrid,
  xAxis: defaultXAxis,
  yAxis: defaultYAxis,
  tooltip: defaultTooltip,
  series: defaultSeries,
  axisPointer: defaultAxisPointer,
};
