export const getInitials = (text: string | undefined | null): string => {
  if (!text) {
    return '';
  }

  return text
    .split(' ')
    .filter((parts: string) => parts.length > 0)
    .map((parts: string) => parts[0].toUpperCase())
    .join('');
};
