import { useCallback, useMemo, MouseEvent } from 'react';
import './index.css';
import { IPaginationProps } from './types';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { getPaginationValues } from './getPaginationValues';
import { clsx } from 'clsx';
import { RowsPerPage } from './RowsPerPage';

export const Pagination = (props: IPaginationProps) => {
  const { page, count, onChange, disabled, rowsPerPage, adornment } = props;

  const onControlsClick = useCallback(
    (n: number) =>
      (e: MouseEvent): void => {
        onChange(page + n, e);
      },
    [page, onChange]
  );

  const onPageClick = useCallback(
    (n: number) =>
      (e: MouseEvent): void => {
        onChange(n, e);
      },
    [onChange]
  );

  const pageButtons: string[] = useMemo(() => {
    return getPaginationValues(count, page);
  }, [count, page]);

  const pageButtonsJSX = pageButtons.map((displayValue: string, i: number) => {
    const dotsClass: string = clsx(
      'analog-pagination__button',
      displayValue === page.toString() && 'analog-pagination__button--active',
      displayValue === '...' && 'analog-pagination__buttonDots',
      'paragraph-1'
    );

    const tabIndex: number = displayValue === '...' ? -1 : 0;

    return (
      <button
        key={displayValue + i}
        disabled={disabled}
        className={dotsClass}
        onClick={onPageClick(+displayValue)}
        tabIndex={tabIndex}
      >
        {displayValue}
      </button>
    );
  });

  return (
    <div className="analog-pagination">
      <div className="analog-pagination__left">
        {rowsPerPage && <RowsPerPage {...props} />}
        {adornment !== undefined && adornment}
      </div>

      <div className="analog-pagination__pages">
        <button
          className="analog-pagination__button"
          disabled={disabled || page === 1}
          onClick={onControlsClick(-1)}
        >
          <ChevronLeft size={16} />
        </button>
        {pageButtonsJSX}
        <button
          className="analog-pagination__button"
          disabled={disabled || page === count}
          onClick={onControlsClick(1)}
        >
          <ChevronRight size={16} />
        </button>
      </div>
    </div>
  );
};
