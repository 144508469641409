import { AthletesService } from './service';

import { IAthlete, IHeightWeightFormData } from '../../types/athletes';
import { AxiosResponse } from 'axios';
import { IUpdateAthleteRequest } from './types';

/** The repository is responsible for data transformation */

export interface IAthletesRepository {
  getAthletes: () => Promise<IAthlete[]>;

  getAthleteById: (id: string) => Promise<IAthlete>;

  setWeight: (p: IHeightWeightFormData) => Promise<AxiosResponse<void>>;

  updateProfile: (p: IUpdateAthleteRequest) => Promise<IAthlete>;
}

export class AthletesRepository implements IAthletesRepository {
  constructor(private readonly service: AthletesService) {}

  async getAthletes(): Promise<IAthlete[]> {
    const response = await this.service.getAthletes();
    return response.data.data;
  }

  async getAthleteById(id: string): Promise<IAthlete> {
    const response = await this.service.getAthleteById({
      id: +id,
    });
    return response.data.data;
  }

  async setWeight(p: IHeightWeightFormData): Promise<AxiosResponse<void>> {
    return await this.service.setWeight({
      ...p,
      weight: +p.weight,
      height: +p.height,
    });
  }

  async updateProfile(p: IUpdateAthleteRequest): Promise<IAthlete> {
    const response = await this.service.updateAthlete(p);
    return response.data;
  }
}
