import { WellnessService } from './service';
import {
  IBodyComposition,
  IHeartMeasurement,
  ISelfReportedWellness,
  ISubjectiveFeedbackFormData,
  IWellness,
} from '../../types/wellness';
import { AxiosResponse } from 'axios';
import {
  IGetBodyCompositionRequest,
  IGetHeartMeasurementsRequest,
  IGetSelfReportedWellnessRequest,
} from './types';

/** The repository is responsible for data transformation */

export interface IWellnessRepository {
  getLastUpdatedWellness: (athleteId: string | number) => Promise<IWellness>;

  getSelfReportedWellness: (
    p: IGetSelfReportedWellnessRequest
  ) => Promise<ISelfReportedWellness>;

  getBodyComposition: (
    p: IGetBodyCompositionRequest
  ) => Promise<IBodyComposition>;

  getHeartMeasurements: (
    p: IGetHeartMeasurementsRequest
  ) => Promise<IHeartMeasurement>;

  reportSelfWellness: (
    p: ISubjectiveFeedbackFormData
  ) => Promise<AxiosResponse<void>>;
}

export class WellnessRepository implements IWellnessRepository {
  constructor(private readonly service: WellnessService) {}

  async getLastUpdatedWellness(athleteId: string | number): Promise<IWellness> {
    const response = await this.service.getLastUpdatedWellness({
      athleteId: +athleteId,
    });
    return response.data;
  }

  async getSelfReportedWellness(
    p: IGetSelfReportedWellnessRequest
  ): Promise<ISelfReportedWellness> {
    const response = await this.service.getSelfReportedWellness(p);
    return response.data;
  }

  async getBodyComposition(
    p: IGetBodyCompositionRequest
  ): Promise<IBodyComposition> {
    const response = await this.service.getBodyComposition(p);
    return response.data;
  }

  async getHeartMeasurements(
    p: IGetHeartMeasurementsRequest
  ): Promise<IHeartMeasurement> {
    const response = await this.service.getHeartMeasurements(p);
    return response.data;
  }

  async reportSelfWellness(
    p: ISubjectiveFeedbackFormData
  ): Promise<AxiosResponse<void>> {
    return await this.service.reportSelfWellness(p);
  }
}
