import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { ApiErrorCode, LS } from '../constants';
import { parseTeamName } from '../utils/teamName';
import { trackError } from '../ms/log-insights';
import { Environment, getEnvironment } from '../utils/getEnvironment';
import { useAppStore } from '../store/app/slice';
import { onRefreshToken } from './onRefreshToken';
import { ROUTES } from '../router/routes';

const getApiUrl = () => {
  if (getEnvironment() === Environment.Production) {
    return import.meta.env.VITE_API_URL_PROD;
  }

  return import.meta.env.VITE_API_URL_DEV;
};

const axiosInstance = axios.create({
  baseURL: getApiUrl(),
});

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  if (config.url === '/v1/preauth/checkRegistration') {
    return config;
  }
  config.headers.Authorization = localStorage.getItem(LS.AccessToken);
  const teamName = localStorage.getItem(LS.Team);
  if (teamName) {
    config.headers['X-team-name'] = parseTeamName(teamName);
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError) => {
  trackError(error);

  if (error?.response?.status === ApiErrorCode.Unauthorized) {
    return onRefreshToken(error)
      .then((config) => {
        return axiosInstance.request(config);
      })
      .catch(() => {
        window.location.href = `/${ROUTES.SIGN_OUT}`;
      });
  }

  if (
    (getEnvironment() === Environment.Local ||
      getEnvironment() === Environment.Development) &&
    error?.response?.status &&
    Number(error.response.status) >= 500
  ) {
    useAppStore.getState().setError(error);
  }

  return Promise.reject(error);
};

const intercept = (): void => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
};

export { axiosInstance, intercept };
