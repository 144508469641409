import {
  ICheckRegistrationRequest,
  ICheckRegistrationResponse,
  IDeleteInvitationRequest,
  IDeleteInvitationResponse,
  IGetTeamMappingsResponse,
  IGetUserProfileResponse,
  IGetUsersResponse,
  IInviteUserRequest,
  IInviteUserResponse,
  IResendInvitationRequest,
  IResendInvitationResponse,
  ISetOnboardingStatusRequest,
  ISetOnboardingStatusResponse,
  IUpdateAthleteMappingsRequest,
  IUpdateAthleteMappingsResponse,
  IUpdatePictureResponse,
  IUpdateStaffRoleRequest,
  IUpdateStaffRoleResponse,
} from './types';
import { axiosInstance } from '../../axios-instance';
import qs from 'qs';
import { R } from '../../types/common';
import { qsOptions } from '../utils/qsOptions';

interface IUsersService {
  inviteUser: (payload: IInviteUserRequest) => R<IInviteUserResponse>;
  resendInvitation: (
    payload: IResendInvitationRequest
  ) => R<IResendInvitationResponse>;
  deleteInvitation: (
    payload: IDeleteInvitationRequest
  ) => R<IDeleteInvitationResponse>;
  checkRegistration: (
    p: ICheckRegistrationRequest
  ) => R<ICheckRegistrationResponse>;
  getUserProfile: () => R<IGetUserProfileResponse>;
  getUsers: () => R<IGetUsersResponse>;
  setOnboardingStatus: (
    p: ISetOnboardingStatusRequest
  ) => R<ISetOnboardingStatusResponse>;

  getTeamMappings: () => R<IGetTeamMappingsResponse>;
  updateAthleteMapping: (
    p: IUpdateAthleteMappingsRequest
  ) => R<IUpdateAthleteMappingsResponse>;
  updateStaffRole: (p: IUpdateStaffRoleRequest) => R<IUpdateStaffRoleResponse>;
  updatePicture: (p: FormData) => R<IUpdatePictureResponse>;
}

export class UsersService implements IUsersService {
  async checkRegistration(
    p: ICheckRegistrationRequest
  ): R<ICheckRegistrationResponse> {
    try {
      return axiosInstance.post(
        `/v1/preauth/checkRegistration`,
        {
          accessToken: p.accessToken,
        },
        {
          headers: {
            Authorization: `Bearer ${p.idToken}`,
          },
        }
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getUserProfile(): R<IGetUserProfileResponse> {
    try {
      return axiosInstance.get('/v1/user/profile');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async inviteUser(payload: IInviteUserRequest): R<IInviteUserResponse> {
    try {
      const params = qs.stringify(payload, qsOptions);
      return axiosInstance.post(`/v1/user/inviteUser?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async resendInvitation(
    payload: IResendInvitationRequest
  ): R<IResendInvitationResponse> {
    try {
      const params = qs.stringify(payload);
      return axiosInstance.post(`/v1/user/resendInvite?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteInvitation(
    payload: IDeleteInvitationRequest
  ): R<IDeleteInvitationResponse> {
    try {
      const params = qs.stringify(payload);
      return axiosInstance.delete(`/v1/user/deleteUserInvite?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getUsers(): R<IGetUsersResponse> {
    try {
      return axiosInstance.get('/v1/user/allUsers');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async setOnboardingStatus(
    p: ISetOnboardingStatusRequest
  ): R<ISetOnboardingStatusResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.put(`/v1/user/onboardingStatus?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getTeamMappings(): R<IGetTeamMappingsResponse> {
    try {
      return axiosInstance.get('/v1/athleteStaff/allMappings');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateAthleteMapping(
    p: IUpdateAthleteMappingsRequest
  ): R<IUpdateAthleteMappingsResponse> {
    try {
      return axiosInstance.post('/v1/athleteStaff/mappings', p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateStaffRole(
    p: IUpdateStaffRoleRequest
  ): R<IUpdateStaffRoleResponse> {
    try {
      return axiosInstance.post('/v1/athleteStaff/roles', p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updatePicture(p: FormData): R<IUpdatePictureResponse> {
    const userId = p.get('userId');
    let params = '';

    if (userId) {
      params = `?${qs.stringify({
        userId,
      })}`;
    }

    try {
      return axiosInstance.put(
        `/v1/user/updateProfilePicture${params}`,
        {
          picture: p.get('picture'),
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
