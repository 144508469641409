import { forwardRef, useCallback, useMemo } from 'react';
import { Input } from './';
import { IInputNumberProps } from './types';

export const InputNumber = forwardRef<HTMLInputElement, IInputNumberProps>(
  (props, ref) => {
    const {
      onChange,
      decimalPlaces,
      decimalSeparator = '.',
      allowChars = '',
    } = props;

    const handleChange = useCallback(
      (value: string) => {
        const isDelimiter = value.at(-1) === decimalSeparator;

        if (isDelimiter && (!decimalPlaces || value.length === 1)) {
          return;
        }

        let delimitersCount = 0;

        for (let i = 0; i < value.length; i++) {
          if (value[i] === decimalSeparator) {
            delimitersCount++;
          }
        }

        if (delimitersCount > 1 && isDelimiter) {
          return;
        }

        let result = value.replace(
          new RegExp(`[^0-9${decimalSeparator}${allowChars}]`, 'g'),
          ''
        );
        const [int, decimal] = result.split(decimalSeparator);

        if (!decimalPlaces) {
          if (onChange) {
            onChange(int);
          }
          return;
        }

        if (decimal) {
          const truncatedDecimal = decimal.substring(0, decimalPlaces);
          result = `${int}${decimalSeparator}${truncatedDecimal}`;
        }

        if (onChange) {
          onChange(result);
        }
      },
      [onChange, decimalPlaces, decimalSeparator, allowChars]
    );

    const nativeProps = useMemo(() => {
      const nativeProps = { ...props };
      delete nativeProps.decimalPlaces;
      delete nativeProps.decimalSeparator;
      return nativeProps;
    }, [props]);

    return <Input {...nativeProps} onChange={handleChange} ref={ref} />;
  }
);

InputNumber.displayName = 'InputNumber';
