import './index.css';
import { EmptyState, IEmptyStateProps } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { User } from 'lucide-react';
import { IAthlete } from '../../types/athletes';
import { useBaseUrl } from '../../hooks/useBaseUrl';
import { AthleteCard } from '../AthleteCard';
import { ROUTES } from '../../router/routes';
import { clsx } from 'clsx';

type IProps = {
  athletes: IAthlete[];
  loaded?: boolean;
  emptyStateProps?: IEmptyStateProps;
};

export const AthleteList = ({ athletes, loaded, emptyStateProps }: IProps) => {
  const { t } = useTranslation();
  const baseUrl = useBaseUrl();
  const ITEMS_THRESHOLD = 4;

  const athletesJSX = athletes.map((a: IAthlete) => {
    return (
      <Link
        key={a.id}
        to={`${baseUrl}/${ROUTES.COACH}/${ROUTES.ATHLETES}/${a.id}`}
        className="athlete-list__card-link"
      >
        <AthleteCard athlete={a} />
      </Link>
    );
  });

  const listItemsClass = clsx(
    'athlete-list__items',
    athletes.length < ITEMS_THRESHOLD && 'athlete-list__items--fixed'
  );

  return (
    <div className="athlete-list">
      {loaded ? (
        athletes.length === 0 ? (
          <EmptyState
            {...emptyStateProps}
            icon={emptyStateProps?.icon || <User size={32} />}
            title={emptyStateProps?.title || t('banner.empty_athletes_title')}
            text={emptyStateProps?.text || t('banner.empty_athletes_message')}
          />
        ) : (
          <div className={listItemsClass}>{athletesJSX}</div>
        )
      ) : (
        <div className="athlete-list__items">
          <div className="athlete-list__card-link">
            <AthleteCard athlete={{} as IAthlete} loading />
          </div>
          <div className="athlete-list__card-link">
            <AthleteCard athlete={{} as IAthlete} loading />
          </div>
          <div className="athlete-list__card-link">
            <AthleteCard athlete={{} as IAthlete} loading />
          </div>
        </div>
      )}
    </div>
  );
};
