import './index.css';
import { Footer } from '../Footer';
import { WelcomeBanner } from '../WelcomeBanner';
import React from 'react';
import { useSmartChartsContext } from '../../context';
import { ChatBody } from '../ChatBody';
import { useParams } from 'react-router';
import { useUserRole } from '../../../../hooks/useUserRole';

export const Chat = () => {
  const { chartId } = useParams();
  const { isAthlete } = useUserRole();
  const { selectedAthletes } = useSmartChartsContext();

  return (
    <div className="sc2__chat">
      {!isAthlete && !chartId && selectedAthletes.length === 0 ? (
        <WelcomeBanner />
      ) : (
        <>
          <ChatBody />
          <Footer />
        </>
      )}
    </div>
  );
};
