import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import { useAthleteWellnessStore } from '../../AthleteWellness/store/slice';
import { WellnessRepository } from '../../../api/wellness/repository';
import { WellnessService } from '../../../api/wellness/service';
import { IWellness } from '../../../types/wellness';

export const useFetchLastUpdatedWellness = () => {
  const fetchingRef = useRef<boolean>(false);
  const { athleteId } = useParams();
  const setWellness = useAthleteWellnessStore((s) => s.setWellness);
  const setWellnessLoaded = useAthleteWellnessStore((s) => s.setWellnessLoaded);

  const wellnessRepository = useMemo(() => {
    return new WellnessRepository(new WellnessService());
  }, []);

  const fetchLastUpdatedWellness = useCallback(() => {
    if (!athleteId || fetchingRef.current) {
      return;
    }
    fetchingRef.current = true;

    wellnessRepository
      .getLastUpdatedWellness(athleteId)
      .then((wellness: IWellness) => {
        setWellness(wellness);
      })
      .catch((e) => {
        setWellnessLoaded(true);
        console.log(e);
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [wellnessRepository, athleteId, setWellness, setWellnessLoaded]);

  useEffect(() => {
    fetchLastUpdatedWellness();
  }, [fetchLastUpdatedWellness]);

  return {
    fetchLastUpdatedWellness,
  };
};
