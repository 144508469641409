import { forwardRef, MouseEvent } from 'react';
import './index.css';
import { Spinner } from '../Spinner';
import { clsx } from 'clsx';
import { ISwitchProps } from './types';

export const Switch = forwardRef<HTMLButtonElement, ISwitchProps>(
  (
    {
      checked,
      onChange,
      label,
      labelOff,
      variant = 'primary',
      direction = 'default',
      disabled = false,
      loading = false,
      className,
      size = 's',
    },
    ref
  ) => {
    const checkedClass: string = checked ? 'analog-switch--checked' : '';
    const loadingClass: string = loading ? 'analog-switch--ChartSpinner' : '';
    const directionClass: string =
      direction === 'reverse' ? 'analog-switch--reverse' : '';
    const rootClass: string = clsx(
      'analog-switch',
      `analog-switch--${variant}`,
      `analog-switch--${size}`,
      checkedClass,
      directionClass,
      loadingClass,
      className
    );

    const labelClass = clsx('analog-switch__text', {
      'analog-typography--caption': size === 'xs',
      'analog-typography--body': size === 's' || size === 'm',
    });

    const spinnerVariant = variant === 'success' ? 'success' : 'primary';
    const spinnerSize = size === 'xs' || size === 's' ? 'xs' : 's';

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      onChange();
      e.currentTarget.blur();
    };

    return (
      <button
        className={rootClass}
        onClick={handleClick}
        disabled={disabled}
        ref={ref}
      >
        {labelOff && <div className={labelClass}>{labelOff}</div>}

        <div className="analog-switch__toggle">
          <div className="analog-switch__toggle-inner">
            {!disabled && loading && (
              <Spinner size={spinnerSize} variant={spinnerVariant} />
            )}
          </div>
        </div>
        {label && <div className={labelClass}>{label}</div>}
      </button>
    );
  }
);

Switch.displayName = 'Switch';
