import './index.css';
import { Table } from '@cycling-web/analog-ui';
import { useViewModel } from './useViewModel';
import { MedicalReportsTableProps } from './types';

export const MedicalReportsTableView = (props: MedicalReportsTableProps) => {
  const { columns, items, loading, emptyStateProps, onRenderCell, onRowClick } =
    useViewModel(props);

  return (
    <Table
      columns={columns}
      items={items}
      loading={loading}
      skeletonCount={4}
      emptyState={emptyStateProps}
      onRenderCell={onRenderCell}
      onRowClick={onRowClick}
    />
  );
};
