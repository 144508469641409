import './index.css';
import { ChevronRight } from 'lucide-react';
import { Switch, Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { MouseEvent, ReactNode, useCallback, useState } from 'react';
import { clsx } from 'clsx';

type IProps = {
  type: 'essential' | 'optional';
  title: string;
  text: string;
  showToggle: boolean;
  children?: ReactNode;
  checked?: boolean;
  onChange?: () => void;
};

export const CookiesSection = ({
  title,
  text,
  showToggle,
  children,
  checked,
  onChange,
  type,
}: IProps) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<boolean>(false);
  const toggleExpand = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const handleChange = useCallback(() => {
    if (onChange) {
      onChange();
    }
  }, [onChange]);

  return (
    <div className="cookies-section__wrapper">
      <div className="cookies-section" onClick={toggleExpand}>
        <div className="cookies-section__main">
          <div className="cookies-section__icon">
            <ChevronRight
              className={clsx(
                'cookies-section__icon-svg',
                expanded && 'cookies-section__icon-svg--rotate'
              )}
            />
          </div>
          <div className="cookies-section__details">
            <Typography
              text={title}
              className="cookies-section__details-title"
            />
            <Typography text={text} className="cookies-section__details-text" />
          </div>
        </div>
        <div className="cookies-section__actions" onClick={stopPropagation}>
          {showToggle ? (
            type === 'essential' ? (
              <Typography
                variant="subtitle"
                weight="bold"
                text={t('legal.always_active')}
                className="cookies-section__details-status"
              />
            ) : (
              <Switch
                checked={!!checked}
                onChange={handleChange}
                labelOff={t('action.accept')}
              />
            )
          ) : null}
        </div>
      </div>
      {expanded && children && (
        <div className="cookies-section__cookies">{children}</div>
      )}
    </div>
  );
};
