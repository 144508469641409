import { useEffect } from 'react';
import { useBaseUrl } from '../../hooks/useBaseUrl';
import { ROUTES } from '../../router/routes';
import { useAthleteStore } from '../../store/athlete/slice';
import { useUserRole } from '../../hooks/useUserRole';
import { useAthletePerformanceStore } from '../AthletePerformance/store/slice';
import { useTrainingLoadStore } from '../AthleteTrainingLoad/store/slice';
import { useAthleteMedicalReportsStore } from '../AthleteMedicalReports/store/slice';
import { useAthleteWellnessStore } from '../AthleteWellness/store/slice';
import { useTitle } from '../../hooks/useTitle';
import { useFetchAthlete } from './hooks/useFetchAthlete';
import { useFetchPinnedTabs } from './hooks/useFetchPinnedTabs';
import { usePinnedTabStore } from '../../store/pinned-tabs/slice';

export const useViewModel = () => {
  useFetchAthlete();
  useFetchPinnedTabs();

  const { isAthlete } = useUserRole();
  const baseUrl = useBaseUrl();
  const backUrl = isAthlete
    ? ''
    : `${baseUrl}/${ROUTES.COACH}/${ROUTES.ATHLETES}`;

  const athlete = useAthleteStore((s) => s.athlete);

  useTitle(athlete?.fullName);

  useEffect(() => {
    return () => {
      useTrainingLoadStore.getState().reset();
      useAthletePerformanceStore.getState().reset();
      useAthleteMedicalReportsStore.getState().reset();
      useAthleteWellnessStore.getState().reset();
      usePinnedTabStore.getState().setPinnedTabsLoaded(false);
      usePinnedTabStore.getState().setPinnedTabs([]);
      useAthleteStore.getState().reset();
    };
  }, []);

  return {
    backUrl,
  };
};
