export enum Environment {
  Local = 'local',
  Development = 'development',
  Production = 'production',
}

export function getEnvironment(): Environment {
  if (
    window.origin.includes('localhost') ||
    window.origin.includes('127.0.0.1')
  ) {
    return Environment.Local;
  }

  if (window.origin.includes('https://sports.analog.io')) {
    return Environment.Production;
  }

  return Environment.Development;
}
