import { PageFilters } from '@cycling-web/common';
import { useCallback } from 'react';
import { initialFilters, useMedicalReportsStore } from '../../store/slice';
import { IMedicalReportsFilters } from '../../types';
import { AnyValue } from '../../../../types/common';
import { useURLFilters } from '../../../../hooks/useURLFilters';

export const MedicalReportsFilters = () => {
  const filters = useMedicalReportsStore((s) => s.filters);
  const setFilters = useMedicalReportsStore((s) => s.setFilters);

  const initFilters = useCallback(() => {
    const params = new URLSearchParams(window.location.search);

    useMedicalReportsStore.getState().setFilters({
      search: params.get('search') || initialFilters.search,
    });
  }, []);

  useURLFilters({ filters, initFilters });

  const handleChangeFilter = useCallback(
    (key: keyof IMedicalReportsFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  );

  const handleSearchClear = useCallback(() => {
    setFilters({ search: '' });
  }, [setFilters]);

  return (
    <PageFilters
      searchInputProps={{
        value: filters.search,
        onChange: handleChangeFilter('search'),
        onClear: handleSearchClear,
      }}
    />
  );
};
