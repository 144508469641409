import './index.css';
import { TeamMappingTable } from './components/TeamMappingTable';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../hooks/useTitle';
import { useFetchAdminTeamMappings } from './hooks/useFetchAdminTeamMappings';

export const AdminTeamMapping = () => {
  const { t } = useTranslation();
  useFetchAdminTeamMappings();
  useTitle(`${t('label.admin_panel')} - ${t('label.team_mapping')}`);

  return (
    <div className="admin-team-mapping-page">
      <TeamMappingTable />
    </div>
  );
};
