import './index.css';
import { Select } from '@cycling-web/analog-ui';
import { IExtendSelectProps, ISelectOption } from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateGroupBy } from '../../../types/enums';

type IProps = {
  options?: DateGroupBy[];
  value: DateGroupBy;
  onChange: (value: DateGroupBy) => void;
  selectProps?: IExtendSelectProps;
};

export const GroupByPeriodFilter = ({
  value,
  onChange,
  options = [DateGroupBy.Day, DateGroupBy.Week, DateGroupBy.Month],
  selectProps,
}: IProps) => {
  const { t } = useTranslation();

  const labelMap: Record<string, string> = useMemo(() => {
    return {
      [DateGroupBy.Day]: t('label.days'),
      [DateGroupBy.Week]: t('label.weeks'),
      [DateGroupBy.Month]: t('label.months'),
    };
  }, [t]);

  const selectOptions: ISelectOption[] = useMemo(() => {
    return options.map((o: string) => {
      return {
        id: o,
        text: labelMap[o],
      };
    });
  }, [options, labelMap]);

  const handleChange = useCallback(
    (option: ISelectOption) => {
      onChange(option.id as DateGroupBy);
    },
    [onChange]
  );

  const formatDisplayValue = useCallback(
    (value: string) => {
      return `${t('label.group_by')}: ${value}`;
    },
    [t]
  );

  return (
    <div className="group-by-period-filter">
      <Select
        options={selectOptions}
        value={selectOptions.find((o: ISelectOption) => o.id === value)}
        onChange={handleChange}
        placeholder={t('placeholder.group_by')}
        formatDisplayValue={formatDisplayValue}
        {...selectProps}
      />
    </div>
  );
};
