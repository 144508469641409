import { DateRange } from '../../../../types/enums';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import {
  IPowerDerivative,
  ITorqueDurationPointData,
} from '../../../../types/performance';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import { IHandleChangeFilter } from '@cycling-web/common';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../constants/charts';
import { IAthlete } from '../../../../types/athletes';
import {
  Chart,
  ChartColor,
  chartTooltipFormatter,
  IEChartOption,
} from '../../../../components/Chart';
import { formatTime } from '../../../../utils/date-time';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';

type IProps = {
  data: IPowerDerivative | null;
  filters: {
    period: DateRange;
  };
  handleChangeFilter: IHandleChangeFilter;
  loading?: boolean;
  athlete: IAthlete | null;
};

export const TorqueDurationCurveChart = ({
  data,
  filters,
  handleChangeFilter,
  loading,
  athlete,
}: IProps) => {
  const { t } = useTranslation();
  const dataPoints: ITorqueDurationPointData[] = useMemo(() => {
    return data?.torqueDurationPoints?.data || [];
  }, [data]);

  const bodyMass = useMemo(() => {
    return athlete?.weight || 70;
  }, [athlete?.weight]);

  const source = useMemo(() => {
    const source: (string | number)[][] = [
      [ChartAxisName.Date, ChartAxisName.nm, ChartAxisName.nm_kg],
    ];

    for (let i = 0; i < dataPoints.length; i++) {
      const { seconds, value } = dataPoints[i];
      source.push([formatTime(seconds), value, value / bodyMass]);
    }

    return source;
  }, [bodyMass, dataPoints]);

  const onRenderTooltipValue = useCallback((value: number, _: string) => {
    return `${Math.round(value * 100) / 100}`;
  }, []);

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLabel: {
          interval: 0,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ChartColor.GridLine,
            type: 'dashed',
            width: 1,
          },
        },
        axisLine: {
          show: true,
        },
      },
      yAxis: [
        {
          type: 'value',
          position: 'left',
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
        },
        {
          type: 'value',
          position: 'right',
        },
      ],
      series: [
        {
          name: ChartAxisName.nm,
          type: 'line',
          yAxisIndex: 0,
          itemStyle: {
            color: ChartColor.LightRed,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.nm_kg,
          type: 'line',
          yAxisIndex: 1,
          itemStyle: {
            color: ChartColor.LightRed,
          },
          lineStyle: {
            width: 1,
            type: 'dashed',
          },
          symbolSize: 2,
          smooth: true,
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
        }),
      },
    };
  }, [onRenderTooltipValue, source]);

  const filtersBarProps = {
    filters: (
      <LastNDaysFilter
        value={filters.period}
        onChange={handleChangeFilter('period')}
        selectProps={{ variant: 'light' }}
      />
    ),
  };

  return (
    <ChartWrapper>
      <Chart
        headerProps={{
          title: t('label.torque_duration_curve'),
          filtersBarProps,
        }}
        option={option}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.TorqueDurationCurve,
          }),
        }}
      />
    </ChartWrapper>
  );
};
