import './index.css';
import { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { ITabsItem, Tabs } from '@cycling-web/analog-ui';

type IProps = {
  tabs: ITabsItem[];
  actions?: ReactNode;
};

export const PageTabs = ({ tabs, actions }: IProps) => {
  const navigate = useNavigate();

  const onTabChange = useCallback(
    (index: number) => {
      if (tabs[index].path) {
        const search = tabs[index].preserveSearchParams
          ? window.location.search
          : '';
        navigate(`${tabs[index].path}${search}`);
      }
    },
    [navigate, tabs]
  );

  return (
    <div className="page-tabs">
      <Tabs items={tabs} onChange={onTabChange} />
      <div className="page-tabs__actions">{actions}</div>
    </div>
  );
};
