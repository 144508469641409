import './index.css';
import { Skeleton } from '@cycling-web/analog-ui';
import { IAthlete } from '../../../../../../types/athletes';
import { Parameter } from '../Parameter';
import { McpUtils } from '../../../../../../utils/mcp';
import { useTranslation } from 'react-i18next';
import { EMPTY } from '../../../../../../constants';

type IProps = {
  athlete: IAthlete | null | undefined;
  loading?: boolean;
  direction?: 'row' | 'column';
};

export const MCP = ({ athlete, loading, direction }: IProps) => {
  const { t } = useTranslation();
  const getMcpVariance = (value: number | undefined) => {
    if (value === undefined || value === null) {
      return null;
    }

    const { color } = McpUtils.getAbsoluteColor(value);

    const sign = value > 0 ? '+' : '';
    const signValue = `${sign}${value.toFixed(0)}`;
    return (
      <span className="analog-typography--subtitle regular" style={{ color }}>
        ({signValue})
      </span>
    );
  };

  const getMcpVariancePercentage = (value: number | undefined) => {
    if (value === undefined || value === null) {
      return null;
    }

    const { color } = McpUtils.getRelativeColor(value);
    const sign = value > 0 ? '+' : '';
    const signValue = `${sign}${value.toFixed(0)}%`;
    return (
      <span className="analog-typography--subtitle regular" style={{ color }}>
        ({signValue})
      </span>
    );
  };

  const label = 'mCP';

  const variance = getMcpVariance(athlete?.mCPVariance);
  const units = t('units.w');
  const value = athlete?.mcp ? Math.round(athlete.mcp) : `${EMPTY}${EMPTY}`;

  const varianceRelative = getMcpVariancePercentage(
    athlete?.mCPRelativeVariancePercentage
  );
  const valueRelative = athlete?.mCPRelative
    ? athlete.mCPRelative.toFixed(2)
    : `${EMPTY}${EMPTY}`;

  const unitsRelative = `${t('units.w')}/${t('units.kg')}`;

  if (loading) {
    return <Skeleton width="140px" height="24px" />;
  }

  return (
    <div className="mcp">
      <Parameter
        label={label}
        variance={variance}
        value={value}
        units={units}
        direction={direction}
      />
      <Parameter
        variance={varianceRelative}
        value={valueRelative}
        units={unitsRelative}
        direction={direction}
      />
    </div>
  );
};
