import { useEffect, useState } from 'react';

export const useIsScrolledToBottom = (element: HTMLElement | null) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const VALID_GAP = 30;

  useEffect(() => {
    if (!element) {
      return;
    }

    const handleScroll = () => {
      if (element) {
        const { scrollTop, scrollHeight, clientHeight } = element;
        setIsScrolledToBottom(
          scrollTop + clientHeight + VALID_GAP >= scrollHeight
        );
      }
    };

    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    handleScroll();

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [element]);

  return isScrolledToBottom;
};
