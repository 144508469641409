import { MedicalReportsTableView } from '../../../../components/MedicalReportsTableView';
import { useAthleteMedicalReportsStore } from '../../store/slice';

export const MedicalReportsTable = () => {
  const reports = useAthleteMedicalReportsStore((s) => s.reports);
  const reportsLoaded = useAthleteMedicalReportsStore((s) => s.reportsLoaded);

  return (
    <MedicalReportsTableView
      items={reports}
      loaded={reportsLoaded}
      showAthleteColumn={false}
    />
  );
};
