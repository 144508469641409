import { Skeleton } from '@cycling-web/analog-ui';
import { IAthlete } from '../../../../../../types/athletes';
import { Parameter } from '../Parameter';

type IProps = {
  athlete: IAthlete | null | undefined;
  loading?: boolean;
  direction?: 'row' | 'column';
};

export const VO2Max = ({ athlete, loading, direction }: IProps) => {
  const label = 'VO2max';
  const units = 'ml/kg';
  const value = athlete?.vo2Max?.toFixed(1) ?? '--';

  if (loading) {
    return <Skeleton width="140px" height="24px" />;
  }

  return (
    <Parameter
      label={label}
      value={value}
      units={units}
      direction={direction}
    />
  );
};
