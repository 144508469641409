import {
  IGetBodyCompositionRequest,
  IGetBodyCompositionResponse,
  IGetHeartMeasurementsRequest,
  IGetHeartMeasurementsResponse,
  IGetLastUpdatedWellnessRequest,
  IGetLastUpdatedWellnessResponse,
  IGetSelfReportedWellnessRequest,
  IGetSelfReportedWellnessResponse,
  IReportSelfWellnessRequest,
  IReportSelfWellnessResponse,
} from './types';
import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';
import qs from 'qs';
import { qsOptions } from '../utils/qsOptions';

interface IWellnessService {
  getLastUpdatedWellness: (
    p: IGetLastUpdatedWellnessRequest
  ) => R<IGetLastUpdatedWellnessResponse>;
  getSelfReportedWellness: (
    p: IGetSelfReportedWellnessRequest
  ) => R<IGetSelfReportedWellnessResponse>;
  getBodyComposition: (
    p: IGetBodyCompositionRequest
  ) => R<IGetBodyCompositionResponse>;
  getHeartMeasurements: (
    p: IGetHeartMeasurementsRequest
  ) => R<IGetHeartMeasurementsResponse>;
  reportSelfWellness: (
    p: IReportSelfWellnessRequest
  ) => IReportSelfWellnessResponse;
}

export class WellnessService implements IWellnessService {
  async getLastUpdatedWellness(
    p: IGetLastUpdatedWellnessRequest
  ): R<IGetLastUpdatedWellnessResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.get(`/v1/wellness/overall/lastUpdated?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getSelfReportedWellness(
    p: IGetSelfReportedWellnessRequest
  ): R<IGetSelfReportedWellnessResponse> {
    try {
      const params = qs.stringify(
        {
          athleteId: p.athleteId,
        },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/wellness/selfReportedWellness/${p.startTime}/${p.endTime}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getBodyComposition(
    p: IGetBodyCompositionRequest
  ): R<IGetBodyCompositionResponse> {
    try {
      const params = qs.stringify(
        {
          athleteId: p.athleteId,
        },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/wellness/bodyComposition/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getHeartMeasurements(
    p: IGetHeartMeasurementsRequest
  ): R<IGetHeartMeasurementsResponse> {
    try {
      const params = qs.stringify(
        {
          unit: p.unit,
          athleteId: p.athleteId,
        },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/wellness/heartRateMeasurement/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async reportSelfWellness(
    p: IReportSelfWellnessRequest
  ): R<IReportSelfWellnessResponse> {
    try {
      return axiosInstance.post('/v1/wellness/reportSelfWellness', p);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
