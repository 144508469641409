import './index.css';
import { ReactNode } from 'react';
import { clsx } from 'clsx';

type IProps = {
  children: ReactNode;
  minHeight?: string;
  className?: string;
};

export const ChartWrapper = ({ children, minHeight, className }: IProps) => {
  const rootClass = clsx('chart-wrapper', className);

  return (
    <div className={rootClass} style={{ minHeight: minHeight || '450px' }}>
      {children}
    </div>
  );
};
