import type {
  IMenuBaseOption,
  IMenuContentProps,
  IMenuDividerOption,
  IMenuOption,
} from './types';
import { MenuOption } from './MenuOption';
import { MouseEvent, useContext } from 'react';
import { MenuContext } from './context';
import { DropdownContext } from '../Dropdown/context';
import { FloatingFocusManager } from '@floating-ui/react';

function isDivider(
  option: IMenuBaseOption | IMenuDividerOption
): option is IMenuDividerOption {
  return (option as IMenuDividerOption).type === 'divider';
}

export const MenuContent = ({ options }: IMenuContentProps) => {
  const { onChange, onRenderOption } = useContext(MenuContext);
  const { context: floatingContext } = useContext(DropdownContext);

  const stopPropagation = (e: MouseEvent): void => {
    e.stopPropagation();
  };

  const optionsJSX = options.map((option: IMenuOption, i: number) => {
    if (isDivider(option)) {
      return <div key={i} className="analog-menu__divider" />;
    }

    return (
      <MenuOption
        key={i}
        index={i}
        option={option}
        onChange={onChange}
        onRenderOption={onRenderOption}
      />
    );
  });

  return (
    <FloatingFocusManager context={floatingContext}>
      <div className="analog-menu__content" onClick={stopPropagation}>
        {optionsJSX}
      </div>
    </FloatingFocusManager>
  );
};
