import './index.css';
import { Picture } from './components/Picture';
import { Stats } from './features/Stats';
import { AthleteCardContext } from './context';
import { IAthleteCardProps } from './types';
import { MouseEvent, useCallback, useMemo } from 'react';
import { IconButton, useBoolean } from '@cycling-web/analog-ui';
import { BodyMap } from '../body-map/body-map';
import { X } from 'lucide-react';

export const AthleteCard = (props: IAthleteCardProps) => {
  const { value: showBodyMap, toggle } = useBoolean(false);
  const { athlete } = props;

  const toggleBodyMap = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      toggle();
    },
    [toggle]
  );

  const context = useMemo(() => {
    return {
      ...props,
      showBodyMap,
      toggleBodyMap,
    };
  }, [props, showBodyMap, toggleBodyMap]);

  const preventDefault = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <AthleteCardContext.Provider value={context}>
      <div className="athlete-card">
        {showBodyMap && (
          <div className="athlete-card__body-map-wrapper">
            <div className="athlete-card__body-map" onClick={preventDefault}>
              <IconButton
                className="athlete-card__close-bodyMap"
                size="s"
                variant="quietNeutral"
                content={<X />}
                onClick={toggleBodyMap}
              />
              <BodyMap
                recordType={athlete?.medicalReport?.recordType || ''}
                selectedSide={athlete?.medicalReport?.side || 'BOTH'}
                selectedBodyLocation={
                  athlete?.medicalReport?.bodyLocation || ''
                }
                selectedArea={athlete?.medicalReport?.area || ''}
                selectedSymptom={athlete?.medicalReport?.symptom || ''}
                selectedSeverity={athlete?.medicalReport?.severity || ''}
                isAthleteCard={true}
              />
            </div>
          </div>
        )}
        <Picture />
        <Stats />
      </div>
    </AthleteCardContext.Provider>
  );
};
