import { useEffect, useMemo, useRef } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { useAthletePerformanceStore } from '../store/slice';
import { format } from 'date-fns';
import { IPowerDerivative } from '../../../types/performance';
import { calculateDateRange } from '../../../utils/utils';
import { Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchPowerDerivatives = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const filters = useAthletePerformanceStore((s) => s.filters);
  const setPowerDerivatives = useAthletePerformanceStore(
    (s) => s.setPowerDerivatives
  );
  const setPowerDerivativesLoaded = useAthletePerformanceStore(
    (s) => s.setPowerDerivativesLoaded
  );
  const fetchingRef = useRef<boolean>(false);
  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService());
  }, []);

  useEffect(() => {
    if (fetchingRef.current || !athleteId) {
      return;
    }
    fetchingRef.current = true;

    const { startDate, endDate } = calculateDateRange(filters.shared.period);

    performanceRepository
      .getPowerDerivatives({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteIds: [+athleteId],
        unit: filters.mmp.dateGroupBy,
      })
      .then((response: IPowerDerivative) => {
        setPowerDerivatives(response);
      })
      .catch((error: AxiosError) => {
        setPowerDerivativesLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_power_derivatives_title'),
              message: t('error.get_power_derivatives_message'),
            },
            { toastId: 'get_power_derivatives' }
          );
        }
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [
    athleteId,
    filters.shared.period,
    filters.mmp.dateGroupBy,
    performanceRepository,
    setPowerDerivatives,
    setPowerDerivativesLoaded,
    t,
  ]);
};
