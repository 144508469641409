import { RhrHrvStatus } from '../types/wellness';
import { t } from 'i18next';
import { ChartColor } from '../components/Chart';
import { ITagVariant } from '@cycling-web/analog-ui';

type IProps = {
  rhrVariance: number;
  hrvVariance: number;
};

type IResult = {
  title: string;
  text: string;
  color: string;
  tagVariant: ITagVariant;
};

export const HrvRhrUtils = {
  getStatus({ hrvVariance, rhrVariance }: IProps): RhrHrvStatus | undefined {
    const absRhrValue = Math.abs(rhrVariance);
    const absHrvValue = Math.abs(hrvVariance);

    if (
      hrvVariance > 0 &&
      absHrvValue >= 10 &&
      rhrVariance < 0 &&
      absRhrValue >= 4
    ) {
      return RhrHrvStatus.OptimalRecovery;
    }

    if (
      hrvVariance > 0 &&
      absHrvValue > 5 &&
      rhrVariance < 0 &&
      absRhrValue > 2
    ) {
      return RhrHrvStatus.RecoveryReady;
    }

    if (
      hrvVariance < 0 &&
      absHrvValue >= 10 &&
      rhrVariance > 0 &&
      absRhrValue >= 4
    ) {
      return RhrHrvStatus.HighStress;
    }

    if (
      hrvVariance < 0 &&
      absHrvValue > 5 &&
      rhrVariance > 0 &&
      absRhrValue > 2
    ) {
      return RhrHrvStatus.MildFatigue;
    }

    if (
      hrvVariance > 0 &&
      absHrvValue > 5 &&
      rhrVariance > 0 &&
      absRhrValue > 2
    ) {
      return RhrHrvStatus.MixedSignals;
    }

    if (
      hrvVariance < 0 &&
      absHrvValue > 5 &&
      rhrVariance < 0 &&
      absRhrValue > 2
    ) {
      return RhrHrvStatus.Overreaching;
    }

    return undefined;
  },

  getContent(status: RhrHrvStatus) {
    const map: Record<RhrHrvStatus, IResult> = {
      [RhrHrvStatus.OptimalRecovery]: {
        title: t(`label.optimal_recovery`),
        text: t(`label.optimal_recovery_description`),
        color: ChartColor.Green,
        tagVariant: 'green',
      },
      [RhrHrvStatus.RecoveryReady]: {
        title: t(`label.recovery_ready`),
        text: t(`label.recovery_ready_description`),
        color: ChartColor.LightGreen,
        tagVariant: 'green-light',
      },
      [RhrHrvStatus.HighStress]: {
        title: t(`label.high_stress`),
        text: t(`label.high_stress_description`),
        color: ChartColor.Red,
        tagVariant: 'red',
      },
      [RhrHrvStatus.MildFatigue]: {
        title: t(`label.mild_fatigue`),
        text: t(`label.mild_fatigue_description`),
        color: ChartColor.LightYellow,
        tagVariant: 'yellow-light',
      },
      [RhrHrvStatus.MixedSignals]: {
        title: t(`label.mixed_signals`),
        text: t(`label.mixed_signals_description`),
        color: ChartColor.Yellow,
        tagVariant: 'yellow',
      },
      [RhrHrvStatus.Overreaching]: {
        title: t(`label.overreaching`),
        text: t(`label.overreaching_description`),
        color: ChartColor.Orange,
        tagVariant: 'orange',
      },
    };

    return map[status];
  },
};
