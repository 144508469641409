import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ITableColumn } from '@cycling-web/analog-ui';

export const useTableColumns = () => {
  const { t } = useTranslation();

  return useMemo((): ITableColumn[] => {
    return [
      {
        key: 'user',
        name: t('label.user'),
        minWidth: 240,
      },
      {
        key: 'coach',
        name: t('label.coach'),
        minWidth: 176,
        align: 'center',
      },
      {
        key: 'sports_director',
        name: t('label.sports_director'),
        minWidth: 176,
        align: 'center',
      },
      {
        key: 'doctor',
        name: t('label.doctor'),
        minWidth: 176,
        align: 'center',
      },
      {
        key: 'secondary_doctor',
        name: t('label.secondary_doctor'),
        minWidth: 176,
        align: 'center',
      },
      {
        key: 'physiotherapist',
        name: t('label.physiotherapist'),
        minWidth: 176,
        align: 'center',
      },
      {
        key: 'nutritionist',
        name: t('label.nutritionist'),
        minWidth: 176,
        align: 'center',
      },
      {
        key: 'mental_coach',
        name: t('label.mental_coach'),
        minWidth: 176,
        align: 'center',
      },
    ];
  }, [t]);
};
