import './index.css';
import {
  ChangeEvent,
  cloneElement,
  HTMLProps,
  isValidElement,
  ReactHTMLElement,
  ReactNode,
  useCallback,
  useRef,
} from 'react';
import { Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';

type IProps = {
  inputProps: HTMLProps<HTMLInputElement>;
  children: ReactNode;
  onChange: (file: File, base64: string) => void;
  maxSize?: number;
};

export const FileUploadButton = ({
  inputProps,
  children,
  onChange,
  maxSize = 10,
}: IProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    inputRef.current?.click();
  }, []);

  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        if (file.size > maxSize * 1024 * 1024) {
          Toast.error({
            title: t('error.file_too_large_title'),
            message: t('error.file_too_large_message', { sizeInMb: maxSize }),
          });
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          onChange(file, reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    },
    [maxSize, onChange, t]
  );

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        accept={inputProps.accept}
        className="analog__file-upload-button-input"
        onChange={handleFileChange}
      />
      {isValidElement(children) &&
        cloneElement(children as ReactHTMLElement<HTMLElement>, {
          onClick: handleUploadClick,
        })}
    </>
  );
};
