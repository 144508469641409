import { useCallback, useEffect, useMemo, useRef } from 'react';
import { SmartChartsRepository } from '../../../api/smart-charts2/repository';
import { SmartChartsService } from '../../../api/smart-charts2/service';
import { ISmartChartConfigBase } from '../../../types/smart-charts';
import { useSmartChartsStore } from '../store/slice';

export const useFetchHistory = () => {
  const fetching = useRef<boolean>(false);
  const setHistory = useSmartChartsStore((s) => s.setHistory);
  const setHistoryLoaded = useSmartChartsStore((s) => s.setHistoryLoaded);

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  const fetchHistory = useCallback(() => {
    if (fetching.current) {
      return;
    }

    fetching.current = true;
    smartChartsRepository
      .getHistory()
      .then((history: ISmartChartConfigBase[]) => {
        setHistory(history.reverse());
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setHistoryLoaded(true);
        fetching.current = false;
      });
  }, [setHistory, setHistoryLoaded, smartChartsRepository]);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  return {
    fetchHistory,
  };
};
