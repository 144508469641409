import {
  IGetAthleteByIdRequest,
  IGetAthleteByIdResponse,
  IGetAthletesResponse,
  ISetWeightRequest,
  ISetWeightResponse,
  IUpdateAthleteRequest,
  IUpdateAthleteResponse,
} from './types';
import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';
import qs from 'qs';

interface IAthletesService {
  getAthletes: () => R<IGetAthletesResponse>;
  getAthleteById: (p: IGetAthleteByIdRequest) => R<IGetAthleteByIdResponse>;
  setWeight: (p: ISetWeightRequest) => R<ISetWeightResponse>;
  updateAthlete: (p: IUpdateAthleteRequest) => R<IUpdateAthleteResponse>;
}

export class AthletesService implements IAthletesService {
  async getAthletes(): R<IGetAthletesResponse> {
    try {
      return axiosInstance.get(`/v1/athletes`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getAthleteById(p: IGetAthleteByIdRequest): R<IGetAthleteByIdResponse> {
    try {
      return axiosInstance.get(`/v1/athletes/${p.id}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async setWeight(p: ISetWeightRequest): R<ISetWeightResponse> {
    try {
      const params = qs.stringify({
        weight: p.weight,
        date: p.date,
      });
      return axiosInstance.post(`/v1/athletes/${p.athleteId}/weight?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateAthlete(p: IUpdateAthleteRequest): R<IUpdateAthleteResponse> {
    try {
      return axiosInstance.put(`/v1/athletes/updateProfile`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
