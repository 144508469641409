import { msalInstance } from '../main';
import { UsersRepository } from '../api/users/repository';
import { UsersService } from '../api/users/service';
import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { LS } from '../constants';

let isRefreshing = false;
let failedRequestsQueue: Array<{
  config: InternalAxiosRequestConfig;
  resolve: (config: InternalAxiosRequestConfig) => void;
  reject: (error: AxiosError) => void;
}> = [];

export const onRefreshToken = (
  error: AxiosError
): Promise<InternalAxiosRequestConfig> => {
  const originalRequest = error.config as InternalAxiosRequestConfig;

  return new Promise((resolve, reject) => {
    const hasCheckRegistrationFailed = failedRequestsQueue.some(
      ({ config }) => {
        return config.url === '/v1/preauth/checkRegistration';
      }
    );

    if (hasCheckRegistrationFailed) {
      failedRequestsQueue.forEach(({ reject }) => reject(error));
      failedRequestsQueue = [];
      isRefreshing = false;
      return reject(error);
    }

    failedRequestsQueue.push({ config: originalRequest, resolve, reject });

    if (!isRefreshing) {
      isRefreshing = true;
      const account = msalInstance.getActiveAccount();

      if (account) {
        const request = {
          scopes: ['openid'],
          account,
          forceRefresh: true,
        };
        const usersRepository = new UsersRepository(new UsersService());

        msalInstance
          .acquireTokenSilent(request)
          .then((result) => {
            if (!result) {
              throw new Error('Token refresh failed');
            }
            const token = `Bearer ${result.idToken}`;
            localStorage.setItem(LS.AccessToken, token);

            return usersRepository.checkRegistration({
              idToken: result.idToken,
              accessToken: result.accessToken,
            });
          })
          .then(() => {
            failedRequestsQueue.forEach(({ config, resolve }) => {
              resolve(config);
            });
          })
          .catch((refreshError) => {
            failedRequestsQueue.forEach(({ reject }) => reject(refreshError));
          })
          .finally(() => {
            failedRequestsQueue = [];
            isRefreshing = false;
          });
      } else {
        failedRequestsQueue.forEach(({ reject }) => reject(error));
        failedRequestsQueue = [];
        isRefreshing = false;
        reject(error);
      }
    }
  });
};
