import './index.css';
import { PerformanceReportCommunication } from './components/PerformanceReportCommunication';

export const AdminTeamCommunicationSettings = () => {
  return (
    <div className="admin-team-communication-settings-page">
      <PerformanceReportCommunication />
    </div>
  );
};
