import './index.css';
import { Outlet } from 'react-router';
import AnalogSportLogoDark from '../../assets/illustrations/analog-sport-logo-lg.svg?react';
import { useViewModel } from './useViewModel';
import { OnboardingContext } from './context';

export const Onboarding = () => {
  const { context } = useViewModel();

  return (
    <OnboardingContext.Provider value={context}>
      <div className="onboarding__underlay">
        <div className="onboarding">
          <header className="onboarding__header">
            <AnalogSportLogoDark width={230} />
          </header>
          <div className="onboarding__main">
            <Outlet />
          </div>
        </div>
      </div>
    </OnboardingContext.Provider>
  );
};
