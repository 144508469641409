import { useEffect } from 'react';
import { appInsights } from '../../../../../../ms/app-insights';
import { useAppStore } from '../../../../../../store/app/slice';

export const CookieManager = () => {
  const cookies = useAppStore((s) => s.cookies);

  useEffect(() => {
    if (!cookies || !appInsights) {
      return;
    }

    const appInsightsCookieManager = appInsights.getCookieMgr();
    appInsightsCookieManager.setEnabled(cookies.optional.analytical);
  }, [cookies]);

  return null;
};
