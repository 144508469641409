const ONE_DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

export enum DateRange {
  FourDays = 4 * ONE_DAY_MILLISECONDS,
  Week = 7 * ONE_DAY_MILLISECONDS,
  TwoWeeks = 14 * ONE_DAY_MILLISECONDS,
  EightDays = 8 * ONE_DAY_MILLISECONDS,
  Month = 30 * ONE_DAY_MILLISECONDS,
  TwoMonth = 60 * ONE_DAY_MILLISECONDS,
  ThreeMonth = 90 * ONE_DAY_MILLISECONDS,
  Year = 365 * ONE_DAY_MILLISECONDS,
}

export enum TimeRange {
  TenSeconds = '10Seconds',
  ThirtySeconds = '30Seconds',
  OneMinute = '1Minutes',
  FiveMinutes = '5Minutes',
  TenMinutes = '10Minutes',
  TwentyMinutes = '20Minutes',
  ThirtyMinutes = '30Minutes',
  SixtyMinutes = '60Minutes',
}

export interface WeekReportRange {
  label: string;
  startDate: string;
  endDate: string;
}

export enum WorkOptions {
  kJ2000 = 2000,
  kJ3000 = 3000,
  kJ1900 = 1900,
  kJ2800 = 2800,
}

export enum DateGroupBy {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
}

export enum BarColors {
  Default = '#8C93A6',
  Old = '#EB545A',
  Average = '#FFE37F',
  New = '#008B32',
}

export enum ToolTipDataType {
  TIMESTAMP = 'TIMESTAMP',
}

export enum UnitsType {
  STRING = 'STRING',
  SVG = 'SVG',
  TIMESTAMP = 'TIMESTAMP',
}
