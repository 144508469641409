import { useTranslation } from 'react-i18next';
import { ReactNode, useCallback, useMemo } from 'react';
import { User } from 'lucide-react';
import {
  Avatar,
  IEmptyStateProps,
  ITableColumn,
  ITableItem,
} from '@cycling-web/analog-ui';
import { useTableColumns } from '../../hooks/useTableColumns';
import { IUserBase } from '../../../../types/users';
import { UserStatus } from '../../../../components/columns/UserStatus';
import { useNavigate } from 'react-router';
import { RoleTag } from '../../../../components/columns/RoleTag';
import { filterUsersDelegate } from '../../utils/filterUsersDelegate';
import { useAdminTeamMemberStore } from '../../../AdminPanel/store/slice';
import { Actions } from './Actions';
import { ROUTES } from '../../../../router/routes';

export const useViewModel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const items = useAdminTeamMemberStore((s) => s.users);
  const loaded = useAdminTeamMemberStore((s) => s.usersLoaded);
  const filters = useAdminTeamMemberStore((s) => s.filters);

  const filteredItems = useMemo(() => {
    return items.filter(filterUsersDelegate(filters));
  }, [filters, items]);

  const columns: ITableColumn[] = useTableColumns();

  const onRenderCell = (column: ITableColumn, item: ITableItem) => {
    const castedItem = item as IUserBase;

    const renderMap: Record<string, ReactNode> = {
      user: (
        <Avatar
          size="s"
          text={castedItem.name || castedItem.email}
          src={castedItem.picture}
          direction="default"
        />
      ),
      role: <RoleTag role={castedItem.roles} />,
      status: <UserStatus user={castedItem} />,
      actions: <Actions user={castedItem} />,
    };

    return renderMap[column.key];
  };

  const emptyStateProps: IEmptyStateProps | undefined =
    loaded && filteredItems.length === 0
      ? {
          icon: <User size={32} />,
          title: t('banner.empty_users_title'),
          text: t('banner.empty_users_message'),
        }
      : undefined;

  const onRowClick = useCallback(
    (item: ITableItem) => {
      const castedItem = item as IUserBase;
      if (castedItem.athleteId) {
        navigate(`${ROUTES.ATHLETE}/${castedItem.athleteId}`);
      } else if (castedItem.staffId) {
        navigate(`${ROUTES.STAFF}/${castedItem.staffId}`);
      } else {
        console.log('User has no id', castedItem);
      }
    },
    [navigate]
  );

  return {
    columns,
    items: filteredItems,
    loading: !loaded,
    emptyStateProps,
    onRenderCell,
    onRowClick,
  };
};
