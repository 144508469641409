import { Controller, useFormContext } from 'react-hook-form';
import { Datepicker } from '@cycling-web/analog-ui';
import { IDatepickerProps } from '@cycling-web/analog-ui';
import { useCallback } from 'react';

type IProps = Omit<IDatepickerProps, 'value' | 'onChange'> & {
  name: string;
  setDisplayValue?: (value: Date) => string;
  formatDate?: (date: Date) => string;
};

export const DatepickerControl = (props: IProps) => {
  const { control } = useFormContext();
  const { name, formatDate } = props;

  const handleChange = useCallback(
    (onChange: (...event: any[]) => void) => {
      return (date: Date) => {
        onChange(formatDate ? formatDate(date) : date);
      };
    },
    [formatDate]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <Datepicker
            {...props}
            onChange={handleChange(onChange)}
            value={value ? new Date(value) : undefined}
            range={false}
            setDisplayValue={props.setDisplayValue}
          />
        );
      }}
    />
  );
};
