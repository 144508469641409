import './index.css';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAthletesStore } from '../../store/athletes/slice';
import { Avatar, Checkbox, Dialog } from '@cycling-web/analog-ui';
import { IAthlete } from '../../types/athletes';

type IProps = {
  value: number[];
  onChange: (value: number[]) => void;
  onDismiss: () => void;
  loading?: boolean;
};

export const AthleteDialog = ({
  value,
  onChange,
  onDismiss,
  loading,
}: IProps) => {
  const { t } = useTranslation();
  const athletes = useAthletesStore((s) => s.athletes);

  const [checkedAthletes, setCheckedAthletes] = useState<number[]>(
    value.map(Number)
  );

  const handleSubmit = useCallback(() => {
    onChange(checkedAthletes);
    onDismiss();
  }, [onChange, checkedAthletes, onDismiss]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCheckedAthletes((athleteIds: number[]) => {
      const value = +e.target.value;
      const index = athleteIds.findIndex((id: number) => id === value);
      if (index < 0) {
        return [...athleteIds, value];
      }
      return athleteIds.filter((id: number) => id !== value);
    });
  }, []);

  const athletesJSX = athletes.map((a: IAthlete) => {
    return (
      <Checkbox
        key={a.id}
        value={a.id}
        checked={checkedAthletes.includes(a.id)}
        onChange={handleChange}
        onRenderLabel={() => (
          <Avatar
            direction="default"
            size="m"
            src={a.picture}
            text={a.fullName}
          />
        )}
      />
    );
  });

  return (
    <Dialog
      onDismiss={onDismiss}
      title={t('label.athletes')}
      preventDefaultOnClick={false}
      submitButtonProps={{
        content: t('action.save'),
        onClick: handleSubmit,
        loading: loading,
      }}
    >
      <div className="add-athletes__list">{athletesJSX}</div>
    </Dialog>
  );
};
