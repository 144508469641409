import { useViewModel } from './useViewModel';
import { Table } from '@cycling-web/analog-ui';

export const GroupOverviewTable = () => {
  const { columns, items, loading, emptyStateProps, onRenderCell } =
    useViewModel();

  return (
    <Table
      columns={columns}
      items={items}
      loading={loading}
      skeletonCount={4}
      emptyState={emptyStateProps}
      onRenderCell={onRenderCell}
    />
  );
};
