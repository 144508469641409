import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ITableColumn } from '@cycling-web/analog-ui';
import { sortByAthleteDelegate } from '../../../utils/sortByAthleteDelegate';
import { useAthletesStore } from '../../../store/athletes/slice';
import { sortByDelegate } from '../../../utils/sortByDelegate';

export const useTableColumns = (): ITableColumn[] => {
  const { t } = useTranslation();
  const athletesMap = useAthletesStore((s) => s.athletesMap);

  return useMemo(() => {
    return [
      {
        key: 'athlete',
        name: t('label.athlete'),
        minWidth: 210,
        allowSort: true,
        onSort: (direction) => {
          return sortByAthleteDelegate(athletesMap, direction);
        },
      },
      {
        key: 'total_duration',
        name: t('label.total_duration'),
        minWidth: 140,
        maxWidth: 140,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'durationInHour');
        },
      },
      {
        key: 'elevation_gain',
        name: `${t('label.elevation_gain')} (${t('units.m')})`,
        minWidth: 160,
        maxWidth: 160,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'climbInMeter');
        },
      },
      {
        key: 'work',
        name: `${t('label.work')} (${t('units.kj')})`,
        minWidth: 130,
        maxWidth: 130,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'workInKj');
        },
      },
      {
        key: 'distance',
        name: `${t('label.distance')} (${t('units.km')})`,
        minWidth: 130,
        maxWidth: 130,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'distanceInKm');
        },
      },
      {
        key: 'tss',
        name: t('label.tss'),
        minWidth: 100,
        maxWidth: 100,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'tss');
        },
      },
      {
        key: 'ctl',
        name: t('label.ctl'),
        minWidth: 100,
        maxWidth: 100,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'ctl');
        },
      },
      {
        key: 'tsb',
        name: t('label.tsb'),
        minWidth: 100,
        maxWidth: 100,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'tsb');
        },
      },
      {
        key: 'coach_rating',
        name: t('label.coach_rating'),
        minWidth: 160,
        maxWidth: 160,
      },
      {
        key: 'coach_comment',
        name: t('label.coach_comment'),
        minWidth: 180,
        maxWidth: 180,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'coachRating');
        },
      },
      {
        key: 'next_race',
        name: t('label.next_race'),
        minWidth: 140,
        maxWidth: 140,
      },
      {
        key: 'mcp',
        name: `${t('label.mcp')} (${t('units.w')}/${t('units.kg')})`,
        minWidth: 160,
        maxWidth: 160,
        tooltip: {
          content: t('label.last_n_days', { d: 90 }),
          placement: 'bottom-end',
        },
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'mCPRelative');
        },
      },
      {
        key: 'coach',
        name: t('label.coach'),
        minWidth: 160,
        maxWidth: 160,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'coachName');
        },
      },
    ];
  }, [athletesMap, t]);
};
