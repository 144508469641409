import { Tag } from '@cycling-web/analog-ui';
import { EMPTY } from '../../../constants';
import { useDictionaryValue } from '../../../hooks/useDictionaryValue';

type IProps = {
  severity: string | undefined;
};

export const SeverityTag = ({ severity }: IProps) => {
  const getDictionaryValue = useDictionaryValue();

  if (!severity) {
    return EMPTY;
  }

  const text = getDictionaryValue({
    container: 'severity',
    key: severity,
  });

  switch (severity) {
    case 'MILD':
      return <Tag text={text} variant="success" />;
    case 'MODERATE':
      return <Tag text={text} variant="warning" />;
    case 'SEVERE':
      return <Tag text={text} variant="error" />;
    default:
      return EMPTY;
  }
};
