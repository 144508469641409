import './index.css';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../hooks/useTitle';
import { Link } from 'react-router';
import { ROUTES } from '../../../../router/routes';

export const TermsAndConditions = () => {
  const { t } = useTranslation();
  useTitle(t('label.terms_and_conditions'));
  const url = `${window.location.origin}${window.location.pathname}`;

  return (
    <div className="terms-and-conditions">
      <ol>
        <div>
          <p>These Terms & Conditions are split into:</p>
          <ul>
            <li>
              <a href={`${url}#core-terms-and-conditions`}>
                Part I: Core Terms & Conditions
              </a>
              , applicable to all Accounts and Subscriptions; and
            </li>
            <li>
              <a href={`${url}#enterprise-subscription`}>
                Part II: Enterprise Subscription Terms & Conditions
              </a>
              , applicable only to Enterprise Subscriptions.
            </li>
          </ul>
        </div>

        <div className="section-divider" id="core-terms-and-conditions">
          <h2 id="core-terms-and-conditions">
            <strong>PART I: CORE TERMS & CONDITIONS</strong>
          </h2>
          <div>
            <p>This Part I: Core Terms & Conditions is applicable to:</p>
            <ul>
              <li>
                your Account, regardless of whether you have any active
                Subscriptions; and
              </li>
              <li>all of your Subscriptions.</li>
            </ul>
          </div>
        </div>

        <h3>
          <strong>
            YOUR ACCESS TO YOUR ACCOUNT, THE PLATFORM AND OUR SERVICES IS
            SUBJECT TO THESE TERMS & CONDITIONS
          </strong>
        </h3>

        <li>
          <div>
            <strong>Who we are and how to contact us.</strong>
          </div>
          <ol>
            <li>
              <div>
                Analog Sports and the Platform are operated by or on behalf of
                Analog Studios FZ LLC and our Affiliates (together “we”, “our”,
                “us”).
              </div>
            </li>
            <li>
              <div>To contact us in relation to:</div>
              <ol>
                <li>
                  any help required in using the Platform or our Services,
                  please email support-sports@analog.io;
                </li>
                <li>
                  any questions or requests in relation to your Personal Data or
                  data privacy, please email dataprivacy-sports@analog.io; and
                </li>
                <li>
                  these{' '}
                  <Link to={`/${ROUTES.LEGAL}/${ROUTES.TERMS_AND_CONDITIONS}`}>
                    Terms & Conditions
                  </Link>
                  , please email legal-sports@analog.io.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <div>
            <strong>
              By accessing or using the Platform, you confirm that you accept
              and agree to comply with these{' '}
              <Link to={`/${ROUTES.LEGAL}/${ROUTES.TERMS_AND_CONDITIONS}`}>
                Terms & Conditions
              </Link>
              .
            </strong>
          </div>
          <p>
            You can find the definitions of all capitalized terms in the{' '}
            <Link to={`/${ROUTES.LEGAL}/${ROUTES.GLOSSARY}`}>Glossary</Link>.
          </p>
        </li>

        <li>
          <div>
            <strong>
              By accessing or using the Platform, you warrant and confirm during
              the Account Term that:
            </strong>
          </div>
          <ol>
            <li>
              You are not located in a Prohibited Territory and are not a
              Prohibited Person. If you are located in a Prohibited Territory or
              are or become a Prohibited Person, you must immediately
              discontinue use of the Platform and Services.
            </li>
            <li>
              You are at least 18 years of age and have the legal authority to
              accept these Terms & Conditions on your behalf or on behalf of any
              party you represent.
            </li>
            <li>
              All data or information (including without limitation any Personal
              Data relating to you or another User) submitted by you as part of
              your registration or use of the Platform or Services is current,
              accurate, and complete. You are responsible for, and undertake to,
              keep any information you provide to us or input into the Platform
              and Services (including, without limitation, through any
              Integrated Apps or Integrated Devices connected to your Account)
              current, accurate and complete during the Account Term.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>
              These Terms & Conditions constitute a legally binding agreement.
            </strong>
          </div>
          <ol>
            <li>
              These Terms & Conditions constitute a legally binding agreement
              between you (“you”, “your”) and us with effect for the duration of
              the Account Term, entered into in consideration for the
              obligations of each party under these Terms & Conditions.
            </li>
            <li>
              These Terms & Conditions govern your access to your Account, the
              Platform and Services. We are providing you with such access only
              if, and to the extent you continue to, agree to these Terms &
              Conditions as may be amended by us from time to time.
            </li>
            <li>
              By accessing or using your Account, the Platform and Services in
              any manner, you agree to be bound by the Terms & Conditions
              prevailing at such time.
            </li>
            <li>
              If you do not or cease to agree to these Terms & Conditions, you
              must immediately cease using your Account, the Platform and
              Services.
            </li>
            <li>
              <div>
                These Terms & Conditions will have effect for the duration of
                the Account Term, except any:
              </div>
              <ol>
                <li>
                  any provisions specified to have effect only for the duration
                  of a Subscription Term; and
                </li>
                <li>
                  any provisions specified to survive termination or expiry of
                  these Terms & Conditions.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>We may make changes to these Terms & Conditions.</strong>
          </div>
          <ol>
            <li>
              You acknowledge that we may amend these Terms & Conditions from
              time to time. Every time you wish to use any of the Platform,
              please check these Terms & Conditions to ensure you understand the
              Terms & Conditions that apply at that time.
            </li>
            <li>
              You acknowledge that we may update and change the Platform and
              Services from time to time to reflect changes to our products or
              services, our users' needs, our business priorities and/or
              regulatory requirements.
            </li>
            <li>
              If we make a material change to these Terms & Conditions, the
              Platform or Services, we will notify you through the Platform.
            </li>
            <li>
              We will not impose any new or increased fees or other charges
              without your prior consent.
            </li>
            <li>
              We may transfer some or all of our rights and/or obligations under
              these Terms & Conditions to another company and will notify you of
              any such transfer. We will ensure that the transfer will not
              affect your rights under the contract. You may not transfer your
              rights or obligations under these Terms & Conditions to another
              person without our prior written consent.
            </li>
          </ol>
        </li>

        <h3>
          <strong>YOUR ACCOUNT AND YOUR SUBSCRIPTIONS</strong>
        </h3>

        <li>
          <div>
            <strong>
              Your Account can be accessed through one or more Subscriptions
              offered by Analog Sports from time to time.
            </strong>
          </div>
          <p>
            Certain sections of these Terms & Conditions only apply to certain
            types of Subscriptions.{' '}
          </p>
        </li>

        <h3>
          <strong>LICENSE TO ACCESS AND USE THE PLATFORM AND SERVICES</strong>
        </h3>

        <li>
          <div>
            <strong>
              Subject to your acceptance and continuing compliance with these
              Terms & Conditions, and subject to you having an active
              Subscription, we grant you a limited License to access and use the
              Platform and our Services via your Account.
            </strong>
          </div>
          <ol>
            <li>
              This License is granted to you in relation to a Subscription.
            </li>
            <li>
              The License is granted to you on a non-exclusive, non-transferable
              and non-sublicensable basis, for the duration of the Subscription
              Term.
            </li>
            <li>
              This License is granted to you solely for the Permitted Purpose.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>
              We may suspend or withdraw the Platform or Services.
            </strong>
          </div>
          <ol>
            <li>
              We do not guarantee that any of the Platform, or any content on or
              services provided through the Platform (including the Services),
              will always be available or be uninterrupted.
            </li>
            <li>
              We may suspend or withdraw or restrict the availability of all or
              any part of any of the Platform for business, operational,
              regulatory and/or ethical reasons. We will give you reasonable
              notice of any suspension or withdrawal where possible.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>
              We may use third party providers and subcontractors in giving you
              access and usage of your Account, the Platform and Services.
            </strong>
          </div>
        </li>

        <h3>
          <strong>YOUR RESPONSIBILITIES</strong>
        </h3>

        <li>
          <div>
            <strong>You must keep your Account details safe.</strong>
          </div>
          <ol>
            <li>
              If you choose, or you are provided with, a user identification
              code, password or any other piece of information as part of our
              security procedures, you must treat such information as
              confidential. You must not disclose it to any third party.
            </li>
            <li>
              We have the right to disable any user identification code or
              password, whether chosen by you or allocated by us, at any time,
              if in our reasonable opinion you have failed to comply with any of
              the provisions of these Terms & Conditions.
            </li>
            <li>
              You must use all reasonable endeavours to prevent any unauthorized
              access to your Account, or use of, the Platform or Services
              through your Account. If you know or suspect that anyone other
              than you knows your user identification code or password, you must
              promptly notify us at support-sports@analog.io.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>
              You are responsible for ensuring that you have the appropriate
              hardware, internet access, security, back up devices and any other
              requirements necessary for safely accessing and using the Platform
              and Services.
            </strong>
          </div>
        </li>

        <li>
          <div>
            <strong>No text or data mining, or web scraping.</strong>
          </div>
          <ol>
            <li>
              <div>
                You shall not conduct, facilitate, authorize or permit any text
                or data mining or web scraping in relation to the Platform or
                Services for any purpose, including without limitation the
                development, training, fine-tuning or validation of AI systems
                or models. This includes using (or permitting, authorising or
                attempting the use of):
              </div>
              <ol>
                <li>
                  Any "robot", "bot", "spider", "scraper" or other automated
                  device, program, tool, algorithm, code, process or methodology
                  to access, obtain, copy, monitor or republish any portion of
                  the Platform or any data, content, information or services
                  accessed via the same.
                </li>
                <li>
                  Any automated analytical technique aimed at analysing text and
                  data in digital form to generate information or develop,
                  train, fine-tune or validate AI systems or models which
                  includes but is not limited to patterns, trends and
                  correlations.
                </li>
              </ol>
            </li>
            <li>
              The provisions in this Section 12 should be treated as an express
              reservation of our rights in this regard, including for the
              purposes of Article 4(3) of the Digital Copyright Directive ((EU)
              2019/790).
            </li>
            <li>
              You shall not use, and we do not consent to the use of, the
              Platform, or any data published by, or contained in, or accessible
              via, the Platform or any services provided via, or in relation to,
              the Platform for the purposes of developing, training, fine-tuning
              or validating any AI system or model or for any other purposes not
              explicitly set out in these Terms & Conditions.
            </li>
            <li>
              This Section 12 will not apply insofar as (but only to the extent
              that) we are unable to exclude or limit text or data mining or web
              scraping activity by contract under the laws which are applicable
              to us.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>You must not introduce viruses.</strong>
          </div>
          <ol>
            <li>
              You must not misuse the Platform by knowingly introducing viruses,
              trojans, worms, logic bombs or other material that is malicious or
              technologically harmful, or otherwise harmfully interacting with
              the Platform or any part of them.
            </li>
            <li>
              You must not attempt to gain unauthorized access to the Platform,
              the server on which the Platform are stored or any server,
              computer or database connected to the Platform or any other
              equipment or network connected with the Platform. You must not
              interfere with, damage or disrupt any software used in the
              provision of the Platform or any equipment or network or software
              owned or used by any third party on which the Platform rely in any
              way.
            </li>
            <li>
              You must not attack the Platform via a denial-of-service attack or
              a distributed denial-of-service attack. By breaching this
              provision, you may also commit a criminal offence under applicable
              law.
            </li>
            <li>
              We will report any such breach to the relevant law enforcement
              authorities and we will co-operate with those authorities by
              disclosing your identity to them. In the event of such a breach,
              your right to use the Platform will cease immediately.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>
              You must not link to the Platform in any website or mobile
              application without our prior written consent
            </strong>
            . If you wish to link to the Platform, please contact
            support-sports@analog.io. Our consent may be withdrawn by way of
            written notice at any time.
          </div>
        </li>

        <li>
          <div>
            <strong>In relation to data:</strong>
          </div>
          <ol>
            <li>
              If you need any data provided to us to be updated or corrected,
              you can contact us at dataprivacy-sports@analog.io.
            </li>
            <li>
              You must keep confidential the Personal Data of, or any other
              information about, other Users, as may be accessible to you
              through your Account or the Services. You must not disclose such
              Personal Data or other information without the prior informed
              consent of (as applicable) such other User or your Team(s).
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>Additional obligations:</strong>
          </div>
          <ol>
            <li>
              You must not use the Platform or Services to provide products or
              services to any person, except as permitted by these Terms &
              Conditions.
            </li>
            <li>
              You must not license, sublicense, sell, rent, lease, transfer,
              assign, distribute, reproduce, disseminate, publish, broadcast,
              circulate, display, disclose, commercially exploit, or otherwise
              make available the Platform, Services or any content made
              available to you through the Platform or Services to any person.
            </li>
            <li>
              You must access and use the Platform and Services at all times in
              accordance with UAE law, the laws of your jurisdiction of
              residence, and any other applicable laws.
            </li>
            <li>
              You must not access or use the Platform or Services in order to
              build a product or service which competes in any way with the
              Platform and/or Services.
            </li>
          </ol>
        </li>

        <h3>
          <strong>INTELLECTUAL PROPERTY</strong>
        </h3>

        <li>
          <div>
            <strong>Our trade marks are protected.</strong>
          </div>
          <p>
            “ANALOG” is a registered word mark of Analog SPV RSC Ltd and
            additional trademarks are under registration. You are not permitted
            to use “ANALOG”, “ANALOG SPORTS” or any of the related logos or
            branding displayed on our Platform in any circumstances without our
            prior written consent.{' '}
          </p>
        </li>

        <li>
          <div>
            <strong>
              We are the owner or the licensee of all intellectual property
              rights in the Platform and Services, the technology underlying the
              Platform and Services, and the material published on it (excluding
              your Personal Data).
            </strong>
          </div>
          <ol>
            <li>
              Those works are protected by copyright and other intellectual
              property laws and treaties around the world. All such rights are
              reserved.
            </li>
            <li>
              Where you provide us with any comments, suggestions or other
              feedback on any aspect of the Platform or Services (whether or not
              solicited by us, and in any medium whatsoever), you agree and
              acknowledge: (i) that we shall have the right to use such
              comments, suggestions or feedback without payment or other
              consideration to you; and (ii) all intellectual property rights
              arising from any changed or new aspects of the Platform or
              Services which may have been developed from such comments,
              suggestions or feedback will belong solely to us.
            </li>
            <li>
              No part of these Terms & Conditions or our granting to you of
              access to and use of the Platform and Services grants to you any
              rights to, under, or in, any intellectual property rights in
              respect of the Platform or Services.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>How you may use material on the Platform:</strong>
          </div>
          <ol>
            <li>
              You may (if permitted by the Platform) print off or download pages
              from the Platform of our Services solely for the Permitted
              Purpose.
            </li>
            <li>
              You must not modify the paper or digital copies of any materials
              you have printed off or downloaded in any way, and you must not
              use any diagrams, graphs, illustrations, photographs, video or
              audio sequences or any graphics separately from any accompanying
              text.
            </li>
            <li>
              Our status (and that of any identified contributors) as the
              authors of content on the Platform must always be acknowledged
              (except where the content is solely User- or third
              party-generated).
            </li>
          </ol>
        </li>

        <h3>
          <strong>LIMITATIONS OF LIABILITY</strong>
        </h3>

        <li>
          <div>
            <strong>User-generated content is not approved by us.</strong>
          </div>
          <p>
            The Platform include and rely on data (including Personal Data),
            information and materials uploaded by other Users. These data,
            information and materials have not been verified or approved by us.
            The views expressed by other Users on the Platform do not represent
            our views or values.
          </p>
        </li>

        <li>
          <div>
            <strong>Information on the Platform</strong>
          </div>
          <ol>
            <li>
              The content on the Platform is provided for general information
              only. It is not intended to amount to advice on which you should
              rely. You must obtain professional or specialist advice before
              taking, or refraining from, any action on the basis of the content
              on the Platform.
            </li>
            <li>
              We make no promises, representations, warranties or guarantees,
              whether express or implied, that by following the analysis or
              recommendations generated by the Platform or our Services your
              performance will be improved or that you will achieve any
              performance, health or other metrics.
            </li>
            <li>
              Although we make reasonable efforts to update the information on
              the Platform, we make no promises, representations, warranties or
              guarantees, whether express or implied, that the content on the
              Platform is accurate, complete or up to date.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>
              Our use of artificial intelligence and machine learning:
            </strong>
          </div>
          <ol>
            <li>
              <div>
                We use artificial intelligence and machine learning to provide
                the Platform and Services, which are rapidly evolving fields of
                study. We are constantly working to improve the Platform and
                Services to make them more accurate, reliable, safe, and
                beneficial. Given the probabilistic nature of artificial
                intelligence and machine learning, when you use the Platform or
                Services you understand and agree:
              </div>
              <ol>
                <li>
                  any output generated may not always be accurate. You should
                  not rely on output or recommendations from the Platform or
                  Services as a sole source of truth or factual information, or
                  as a substitute for specialist or professional advice;
                </li>
                <li>
                  you must evaluate output for accuracy and appropriateness for
                  your use case, including using human and specialist or
                  professional review as appropriate, before using or sharing
                  output or recommendations generated from the Platform or
                  Services;
                </li>
                <li>
                  you must not use any output relating to a User for any purpose
                  that could have a legal or material impact on that User,
                  including making medical decisions about any User;
                </li>
                <li>
                  if any output references any third party products or services,
                  it does not mean that the third party endorses or is
                  affiliated with Analog, or that Analog endorses such third
                  party.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>
              We are not responsible for third party websites or mobile
              applications we link to.
            </strong>
          </div>
          <ol>
            <li>
              Where the Platform contain links to other websites, mobile
              applications and resources provided by third parties (including
              but not limited to the Integrated Apps or Integrated Devices) such
              links should not be interpreted as approval by us of those linked
              websites, mobile applications or information you may obtain from
              them.
            </li>
            <li>
              We have no control or responsibility over the contents of those
              third party websites, mobile applications, or resources.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>We are not responsible for viruses.</strong>
          </div>
          <ol>
            <li>
              We do not guarantee that the Platform will be secure or free from
              bugs or viruses.
            </li>
            <li>
              You are responsible for configuring your information technology,
              computer programs and platform to access the Platform. You should
              use your own virus protection software.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>Services are provided “as is”.</strong>
          </div>
          <p>You acknowledge and agree:</p>
          <ol>
            <li>
              <div>The Platform and Services:</div>
              <ol>
                <li>
                  have not been tailored to your or your Team’s specific
                  requirements or circumstances and they are provided on an “as
                  is” and “as available” basis, without any warranties or
                  promises of any kind, whether express, implied, or statutory;
                  and
                </li>
                <li>
                  rely on a number of things working properly to enable you to
                  enjoy all of its features. Many of these, such as your
                  internet connection and network, are entirely outside of our
                  control. Although we will do what we reasonably can to resolve
                  issues where possible, it may not always be possible to do so
                  (or do so quickly).
                </li>
              </ol>
            </li>
            <li>
              Whilst we try to make sure that the Platform and Services are
              available, accurate, up-to-date and free from bugs or viruses, we
              cannot promise or guarantee that they will be, or that you will
              always be able to use them without delay or restriction. Any
              reliance that you may place on the information on the Platform and
              Services is at your own risk.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>
              Our responsibility for loss or damage suffered by you.
            </strong>
          </div>
          <ol>
            <li>
              We do not exclude or limit in any way our liability to you where
              it would be unlawful to do so. This includes liability for death
              or personal injury caused by our negligence or the negligence of
              our employees, agents or subcontractors and for fraud or
              fraudulent misrepresentation.
            </li>
            <li>
              To the extent possible under applicable law, we exclude all
              implied conditions, warranties, representations or other terms
              that may apply to the Platform, Services or any content on it.
            </li>
            <li>
              <div>
                We will not be liable to you for any loss or damage, whether in
                contract, tort (including negligence), breach of statutory duty,
                or otherwise, even if foreseeable, arising under or in
                connection with:
              </div>
              <ol>
                <li>use of, or inability to use, the Platform;</li>
                <li>
                  anything that is out of our control or it would not be
                  reasonable to expect us to control; or
                </li>
                <li>
                  use of or reliance on any content displayed on the Platform.
                </li>
              </ol>
            </li>
            <li>
              <div>In particular, we will not be liable for:</div>
              <ol>
                <li>loss of or damage to data;</li>
                <li>loss of profits, sales, business, or revenue;</li>
                <li>business interruption;</li>
                <li>loss of anticipated savings;</li>
                <li>
                  loss of business opportunity, goodwill or reputation; or
                </li>
                <li>
                  any unforeseeable, indirect or consequential loss or damage.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>No medical advice.</strong>
          </div>
          <ol>
            <li>
              Nothing contained in or on the Platform or Services constitutes
              medical advice or any other health related advice, and it should
              not be relied on for any of these purposes. This includes, without
              limitation, any content provided by or generated from information
              provided by any Users performing a medical or other health-related
              function in their profession or with respect to a Team. Our
              Services are not intended to be, and should not be used in
              replacement of, (a) the advice or diagnosis of, or (b) a visit,
              call or consultation with, a physician or other medical
              professional.
            </li>
            <li>
              You must always use your own independent judgement (or, if
              necessary, obtain your own independent specialist advice) when
              using the Platform or Services.
            </li>
            <li>
              We are not responsible for any health or medical consequences
              which may result from actions or omissions you take using
              information generated by or found on the Platform or from our
              Services.
            </li>
            <li>
              We do not verify, and are not responsible for verifying, that
              Users are appropriately qualified, under applicable law or
              otherwise, to use the features or perform the functions available
              to them pursuant to their assigned Roles. Nor do we verify, or are
              we responsible for, their provision of any health, medical,
              training, nutrition, therapeutic or other advice using their
              Accounts. We are not responsible for any advice or recommendations
              provided by any Staff or third parties (including, without
              limitation, doctors, nurses physiotherapist, mental health
              specialists or other medical professionals) whether or not they
              have used any outputs generated by the Platform and Services.
            </li>
          </ol>
        </li>

        <h3>
          <strong>REIMBURSING US</strong>
        </h3>

        <li>
          <div>
            <strong>Circumstances when you would need to reimburse us.</strong>
          </div>
          <p>
            To the extent permitted by applicable law, you agree to reimburse
            us, our Affiliates, officers, directors, employees and agents, from
            and against any and all claims, damages, obligations, losses,
            liabilities, costs or debt, and expenses (including but not limited
            to reasonable legal fees) arising from: (i) your unlawful use of and
            access to the Platform, Account or Services; (ii) your breach of any
            term of these Terms & Conditions; and (iii) your breach of any third
            party right (including without limitation any intellectual property
            or privacy right).
          </p>
        </li>

        <h3>
          <strong>DELETION OR SUSPENSION OF YOUR ACCOUNT</strong>
        </h3>

        <li>
          <div id="section29">
            <strong>
              You may request deletion of your Account at any time.{' '}
            </strong>
          </div>
          <ol>
            <li>
              You can request deletion of your Account in your Settings page.
            </li>
            <li>
              <div>If you request to delete your Account:</div>
              <ol>
                <li>
                  All Subscriptions you may have with Analog Sports will
                  terminate immediately.
                </li>
                <li>
                  Your access to the Platform and Services will cease
                  immediately.
                </li>
              </ol>
            </li>
            <li>
              <div>After you submit a request to delete your Account:</div>
              <ol>
                <li>
                  We will delete your Account within 10 UAE business days of
                  your deletion request.
                </li>
                <li>
                  Your Personal Data will be erased in accordance with
                  applicable law, unless we are otherwise permitted by
                  applicable law to retain it.
                </li>
              </ol>
            </li>
            <li>
              <div>After Account deletion:</div>
              <ol>
                <li>
                  You will not be able to recover from Analog or the Platform
                  any data or information you, other Users or any Integrated
                  Apps or Integrated Devices had provided to us through your
                  deleted Account.
                </li>
                <li>
                  You will not be able to recover from Analog or the Platform
                  any data, information or Services which had been provided to
                  you by Analog, the Platform, the Services or other Users
                  through your deleted Account.
                </li>
                <li>
                  If you subscribe for a new Account, you will need to newly
                  provide all required data and information, and any Services
                  provided to you pursuant to such new Account will be based
                  only on such new data and information.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>
              If you have no active Subscriptions, your Account will be
              suspended.
            </strong>{' '}
            When your Account is suspended:
          </div>
          <ol>
            <li>
              You will not be able to access the Platform or any Services until
              you have signed up for a new Subscription with Analog Sports.
            </li>
            <li>
              You will be able to access your Account only to sign up for a new
              Subscription, or to request deletion of your Account (in which
              case Sections 29.c and 29.d will apply.)
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>
              If your Account remains suspended for 12 months (or such shorter
              period of time as we may be permitted to retain your Personal Data
              under applicable law)
            </strong>
            , we will delete your Account (in which case Sections 29.c and 29.d
            will apply.)
          </div>
        </li>

        <h3>
          <strong>GOVERNING LAW & DISPUTE RESOLUTION</strong>
        </h3>

        <li>
          <div>
            <strong>
              These Terms & Conditions are governed by the laws of the Abu Dhabi
              Global Market, Abu Dhabi, United Arab Emirates.
            </strong>
          </div>
        </li>

        <li>
          <div>
            <strong>
              We both agree that the courts of the Abu Dhabi Global Market, Abu
              Dhabi, United Arab Emirates will have exclusive jurisdiction.
            </strong>
          </div>
        </li>

        <div className="section-divider" id="enterprise-subscription">
          <h2>
            <strong>PART II: ENTERPRISE SUBSCRIPTION TERMS & CONDITIONS</strong>
          </h2>

          <p>
            This Part II: Enterprise Subscription Terms & Conditions is
            applicable only to Enterprise Subscriptions, for the duration of the
            Subscription Term. These Enterprise Subscription Terms & Conditions
            are additional to, and not in replacement of, the Core Terms &
            Conditions.
          </p>
        </div>

        <h3>
          <strong>ENTERPRISE SUBSCRIPTION</strong>
        </h3>

        <li>
          <div>
            <strong>You are signing up for an Enterprise Subscription. </strong>
          </div>
          <ol>
            <li>Enterprise Subscriptions are always linked to one Team.</li>
            <li>
              You have been invited to this Enterprise Subscription by a Team,
              to which this Enterprise Subscription is linked.
            </li>
            <li>
              The Subscription Term of this Enterprise Subscription will be as
              agreed between us and the Team, subject to our rights under these
              Terms & Conditions to terminate your Subscription and/or your
              Account.
            </li>
            <li>
              You may have more than one Enterprise Subscription, with each
              Enterprise Subscription linked to a different Team.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>
              These Enterprise Subscription Terms & Conditions apply to your
              Enterprise Subscription(s) only
            </strong>
            . Analog Sports may from time to time offer other types of
            subscriptions. These other subscription packages will be subject to
            other terms and conditions.
          </div>
        </li>

        <li>
          <div>
            <strong>
              If you have signed up to more than one Enterprise Subscription
            </strong>
            : You are responsible for ensuring that you do not breach any
            confidentiality or other legal obligations you may owe to each Team,
            pursuant to your contractual or other relationship with such Teams.
          </div>
        </li>

        <h3>
          <strong>YOUR TEAM AND ROLES</strong>
        </h3>

        <li>
          <div>
            <strong>
              You will be assigned one or more Roles in relation to the Team.
            </strong>
          </div>
          <ol>
            <li>
              Roles govern the parts of the Platform and scope of our Services
              accessible through your Account, in relation to the Team.
            </li>
            <li>
              Certain features and functionalities of the Platform and certain
              of our Services are only accessible to certain Roles.
            </li>
            <li>
              These{' '}
              <Link to={`/${ROUTES.LEGAL}/${ROUTES.TERMS_AND_CONDITIONS}`}>
                Terms & Conditions
              </Link>{' '}
              and our{' '}
              <Link to={`/${ROUTES.LEGAL}/${ROUTES.PRIVACY_NOTICE}`}>
                Privacy Notice
              </Link>{' '}
              may also apply differently to different Roles.
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>If you are the Team’s Admin</strong>: you will be invited by
            Analog Sports to join your Team with the Role of “Admin”.
          </div>
        </li>

        <li>
          <div>
            <strong>
              If you are an Athlete or Staff (other than an Admin):{' '}
            </strong>
          </div>
          <ol>
            <li>
              You will be invited by the Team’s Admin to join the Team on the
              Platform. The Admin will assign you with one or more Athlete
              and/or Staff Role(s) in relation to the Team.
            </li>
            <li>
              <div>In relation to the Team:</div>
              <ol>
                <li>
                  You may be assigned additional Roles from time to time by the
                  Admin, provided that you first agree to these Terms &
                  Conditions and the{' '}
                  <Link to={`/${ROUTES.LEGAL}/${ROUTES.PRIVACY_NOTICE}`}>
                    Privacy Notice
                  </Link>{' '}
                  as applied to such additional Roles.
                </li>
                <li>
                  The Admin may, from time to time with or without notice to
                  you, remove any assigned Roles, in which case features,
                  functionalities or Services specific to the removed Roles will
                  cease to be accessible to you.
                </li>
                <li>
                  We are not responsible for the assignment or removal by the
                  Admin of Roles (or the consequences to you or the Team of such
                  assignment or removal).
                </li>
                <li>
                  The Admin is solely responsible for ensuring that Roles are
                  assigned to appropriate members of the Team from time to time,
                  having due consideration of the features, functionalities or
                  Services available to such Roles.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <h3>
          <strong>USER INFORMATION AND PERSONAL DATA</strong>
        </h3>

        <li>
          <div>
            <strong>How we may use your Personal Data</strong>
            <p>
              We will only use your Personal Data as set out in our{' '}
              <Link to={`/${ROUTES.LEGAL}/${ROUTES.PRIVACY_NOTICE}`}>
                Privacy Notice
              </Link>
              , which explains how we collect, use and store your Personal Data.
            </p>
          </div>
        </li>

        <li>
          <div>
            <strong>
              You hereby grant to Analog Studios FZ LLC and each of our
              Affiliates
            </strong>{' '}
            an irrevocable, worldwide, royalty-free, sublicensable, perpetual
            license to use, store, analyze, and distribute any information or
            data you input into the Platform or Services (or which you otherwise
            provide to us in any medium), for the purposes of our delivery of
            the Platform, Services and Accounts to you and other Users.
          </div>
        </li>

        <li>
          <div>
            <strong>
              Where you provide us with Personal Data relating to other Users or
              individuals
            </strong>
          </div>
          <ol>
            <li>
              Users must not input into the Platform or Services any Personal
              Data relating to any other person except as expressly permitted in
              these Terms & Conditions and the{' '}
              <Link to={`/${ROUTES.LEGAL}/${ROUTES.PRIVACY_NOTICE}`}>
                Privacy Notice
              </Link>
              .
            </li>
            <li>
              <div>
                Personal Data relating to a User may only be inputted into the
                Platform and Services by that User. By way of limited exceptions
                to this requirement:
              </div>
              <ol>
                <li>
                  Admins may input Personal Data relating to any User on their
                  Team into the Platform or Services.
                </li>
                <li>
                  Medical Staff may input Health Data relating to an Athlete on
                  their Team into the Platform or Services.
                </li>
                <li>
                  Personal Data (including Health Data and training-related
                  geolocation data) relating to an Athlete may be inputted into
                  the Platform and Services by way of their Integrated App or
                  Integrated Device, where consented to by the Athletes.
                  Athletes can select or deselect their Integrated Apps or
                  Integrated Devices from time to time in our Data Sharing page.
                </li>
              </ol>
            </li>
            <p>
              We may permit additional exceptions from this requirement from
              time to time by way of amendment or addendum to these Terms &
              Conditions.
            </p>
            <p>
              Unless permitted by these Terms & Conditions, you must not input,
              or procure that any other person inputs, any Personal Data
              relating to another User (or any other individual) into the
              Platform or Services (whether through your Account or another
              User’s Account).
            </p>
            <li>
              <div>
                In respect of any Personal Data relating to an individual (other
                than yourself) (the “Relevant Individual”) provided by you to us
                (for example where you are Medical Staff and you provide us with
                Personal Data relating to an Athlete to the extent permitted by
                these Terms & Conditions), you must:
              </div>
              <ol>
                <p>
                  (a) have satisfied a statutory ground under Data Protection
                  Law permitting you to transfer the relevant Personal Data to
                  us to use in accordance with our{' '}
                  <Link to={`/${ROUTES.LEGAL}/${ROUTES.PRIVACY_NOTICE}`}>
                    Privacy Notice
                  </Link>
                  ;
                </p>
                <p>
                  (b) have notified the Relevant Individual that you are
                  providing their Personal Data to us and explained the reasons
                  for this;
                </p>
                <p>
                  (c) draw the attention of the Relevant Individual to our{' '}
                  <Link to={`/${ROUTES.LEGAL}/${ROUTES.PRIVACY_NOTICE}`}>
                    Privacy Notice
                  </Link>
                  ; and
                </p>
                <p>
                  (d) promptly notify the Relevant Individual of any changes to
                  our{' '}
                  <Link to={`/${ROUTES.LEGAL}/${ROUTES.PRIVACY_NOTICE}`}>
                    Privacy Notice
                  </Link>{' '}
                  that we notify to you.
                </p>
              </ol>
            </li>
            <li>
              <div>
                Additionally, when you provide us with Personal Data relating to
                an individual (other than yourself) through an Account from
                within the UK and / or the EU, such Personal Data may be
                transferred out of the UK and / or EU and additional terms that
                safeguard that Personal Data will apply, namely:
              </div>
              <ol>
                <p>
                  (a) where such Personal Data is transferred to us from the EU,
                  the EU SCCs; and
                </p>
                <p>
                  (b) where such Personal Data is transferred to us from the UK,
                  the UK Addendum.
                </p>
              </ol>
              <p>
                The EU SCCs and UK Addendum are hereby incorporated by
                reference, and the details required by these terms that apply to
                transfers of Personal Data to us in the manner described above
                are set out in Appendix 1. By agreeing to these Terms, you agree
                to be bound by the EU SCCs and / or the UK Addendum (as
                applicable), including the details set out Appendix 1, as if
                they were set out in these Terms & Conditions when you transfer
                Personal Data to us in the manner described above.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <div>
            <strong>
              Erasure of your data will require deletion of your Account.
            </strong>
          </div>
          <p>
            If you wish to erase your data from the Platform, this will require
            the deletion of your Account. See Section 29 above for more
            information on deleting your Account.
          </p>
        </li>

        <h3>
          <strong>LIABILITY</strong>
        </h3>

        <p>
          Without prejudice to <a href={`${url}#section26`}>Section 26.a</a>, if
          we are liable to you under these Terms & Conditions then the amount we
          are liable to you for all claims will not be more than one thousand
          United States Dollars (or an equivalent amount in another currency).
        </p>

        <h3>
          <strong>SUPPORT AND MAINTENANCE</strong>
        </h3>

        <li>
          <div>
            <strong>
              If you need help with any aspect of the Platform or Services, or
              have any complaints or feedback, you can contact us at
              support-sports@analog.io
            </strong>
            . We will use commercially reasonable endeavours to provide support
            for the Services, but do not provide any undertaking, warranty or
            undertaking with respect to the delivery of such support.
          </div>
        </li>

        <li>
          <div>
            <strong>
              Part, or all, of the Services may from time to time be
              inaccessible or unavailable for any reason whatsoever.
            </strong>
          </div>
          <p>
            This includes without limitation planned or emergency maintenance
            procedures, which we may undertake without prior notice to you.
          </p>
        </li>

        <h3>
          <strong>TERMINATION OF YOUR SUBSCRIPTION</strong>
        </h3>

        <li>
          <div>
            <strong>
              We may terminate your Subscription at any time without notice to
              you
            </strong>
            , without reimbursement or other compensation to you (unless
            required by applicable law), where you breach any part of the Terms
            & Conditions.
          </div>
        </li>

        <li>
          <div>
            <strong>
              You may terminate your Subscription at any time by giving us 5
              days’ notice by email to legal-sports@analog.io
            </strong>
            . Termination of a Subscription does not automatically result in the
            deletion of your Account or the erasure of your data. See{' '}
            <a href={`${url}#section29`}>Section 29</a> above for more
            information on deleting your Account.
          </div>
        </li>
      </ol>
    </div>
  );
};
