import { useTranslation } from 'react-i18next';
import './index.css';
import { Progress } from './Progress';
import { Skeleton, Tooltip, Typography } from '@cycling-web/analog-ui';
import { EMPTY } from '../../constants';
import { IWellness } from '../../types/wellness';
import { MessageSquare } from 'lucide-react';
import { GaugeScore } from '../../pages/AthleteWellness/components/GaugeScore';

type IProps = {
  wellness: IWellness | null;
  wellnessLoaded: boolean;
  showGauge?: boolean;
};

export const WellnessScores = ({
  wellness,
  wellnessLoaded,
  showGauge,
}: IProps) => {
  const { t } = useTranslation();

  const scores = [
    {
      text: t('label.general'),
      value: wellness?.wellness?.data.generalScore || 0,
    },
    {
      text: t('label.fatigue'),
      value: wellness?.wellness?.data.fatigueScore || 0,
    },
    {
      text: t('label.stress'),
      value: wellness?.wellness?.data.stressScore || 0,
    },
    {
      text: t('label.mood'),
      value: wellness?.wellness?.data.moodScore || 0,
    },
    {
      text: t('label.sleep'),
      value: wellness?.wellness?.data.sleepScore || 0,
    },
  ];

  const scoresJSX = scores.map((score, i) => {
    return (
      <Progress
        key={i}
        text={score.text}
        value={score.value}
        loaded={wellnessLoaded}
      />
    );
  });

  const lastUpdated = wellness?.wellness
    ? new Date(wellness.wellness.timeSinceEpoch)
    : undefined;

  return (
    <div className="wellness-widget__stats-card">
      {!showGauge && (
        <header className="wellness-widget__stats-header">
          {wellnessLoaded ? (
            <>
              <Typography
                text={wellness?.wellness?.data.totalScore || EMPTY}
                variant="h1"
                weight="bold"
                className="wellness-widget__stats-header-textPrimary"
              />
              <Typography
                text="/100"
                variant="h4"
                className="wellness-widget__stats-header-textSecondary"
              />
            </>
          ) : (
            <Skeleton width="160px" height="43px" />
          )}
        </header>
      )}

      {wellnessLoaded && wellness?.wellness?.data?.comment && (
        <div className="wellness-widget__message-tooltip">
          <Tooltip
            anchor={<MessageSquare size={18} />}
            content={wellness?.wellness?.data?.comment}
            placement="bottom-end"
          />
        </div>
      )}
      <div className="wellness-widget__stats-wrapper">
        <div className="wellness-widget__stats-scores">{scoresJSX}</div>
        {showGauge && (
          <GaugeScore
            value={wellness?.wellness?.data.totalScore || 0}
            lastUpdated={lastUpdated}
          />
        )}
      </div>
    </div>
  );
};
