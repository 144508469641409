import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 18.0003V20.0003H3V18.0003H21ZM17.404 3.9043L22 8.5003L17.404 13.0963L15.99 11.6823L19.172 8.5003L15.99 5.3183L17.404 3.9043ZM12 11.0003V13.0003H3V11.0003H12ZM12 4.0003V6.0003H3V4.0003H12Z"
        fill="currentColor"
      />
    </svg>
  );
};
