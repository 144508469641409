import './index.css';
import { IAvatarStackAvatar, IAvatarStackProps } from './types';
import { clsx } from 'clsx';
import { Avatar } from '../Avatar';
import { CSSProperties, useMemo } from 'react';

export const AvatarStack = ({
  avatars = [],
  size = 's',
  shape = 'round',
  className,
  loading,
  loadingCount = 5,
  maxVisible = 5,
}: IAvatarStackProps) => {
  const rootClass = clsx('analog-avatar-stack', className);
  const offset = 10;

  const { visibleList, hiddenList } = useMemo(() => {
    const visibleList: IAvatarStackAvatar[] = [];
    const hiddenList: IAvatarStackAvatar[] = [];
    const d = avatars.length <= maxVisible + 1 ? 1 : 0;

    for (let i = 0; i < avatars.length; i++) {
      if (i < maxVisible + d) {
        visibleList.push(avatars[i]);
      } else {
        hiddenList.push(avatars[i]);
      }
    }

    return {
      visibleList,
      hiddenList,
    };
  }, [avatars, maxVisible]);

  const list: IAvatarStackAvatar[] = loading
    ? Array(loadingCount).fill({})
    : visibleList;

  const avatarsJSX = list.map((avatar: IAvatarStackAvatar, i: number) => {
    const style = {
      left: `-${i * offset}px`,
    };
    return (
      <div className="analog-avatar-stack__item" style={style} key={i}>
        <Avatar
          {...avatar}
          size={size}
          shape={shape}
          loading={loading}
          showDetails={false}
        />
      </div>
    );
  });

  const style: CSSProperties = useMemo(() => {
    const sizeMap = {
      xs: 24,
      s: 32,
      m: 40,
      l: 48,
      xl: 56,
      xxl: 64,
    };
    const w = sizeMap[size];
    const d = hiddenList.length > 0 ? 0 : 1;
    const width = w + (list.length - d) * (w - offset);

    return {
      width: `${width}px`,
    };
  }, [hiddenList.length, list.length, size]);

  return (
    <div className={rootClass} style={style}>
      {avatarsJSX}
      {hiddenList.length > 0 && (
        <div
          className="analog-avatar-stack__item"
          style={{ left: `-${maxVisible * offset}px` }}
        >
          <Avatar
            initials={`+${hiddenList.length}`}
            size={size}
            shape={shape}
            loading={loading}
            showDetails={false}
          />
        </div>
      )}
    </div>
  );
};
