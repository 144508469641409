import { DateGroupBy, DateRange } from '../../../types/enums';

type IFilters = {
  dateGroupBy?: DateGroupBy;
  period: DateRange;
};

export const getDateAxisInterval = ({
  period,
  dateGroupBy,
}: IFilters): string | number => {
  if (dateGroupBy === DateGroupBy.Day) {
    switch (period) {
      case DateRange.Week:
        return 0;
      case DateRange.Month:
        return 1;
      case DateRange.TwoMonth:
        return 3;
      case DateRange.ThreeMonth:
        return 6;
      case DateRange.Year:
        return 30;
      default:
        return 'auto';
    }
  } else if (dateGroupBy === DateGroupBy.Week) {
    switch (period) {
      case DateRange.Week:
        return 0;
      case DateRange.Month:
        return 0;
      case DateRange.TwoMonth:
        return 0;
      case DateRange.ThreeMonth:
        return 0;
      case DateRange.Year:
        return 1;
      default:
        return 'auto';
    }
  } else if (dateGroupBy === DateGroupBy.Month) {
    switch (period) {
      case DateRange.Week:
        return 0;
      case DateRange.Month:
        return 0;
      case DateRange.TwoMonth:
        return 0;
      case DateRange.ThreeMonth:
        return 0;
      case DateRange.Year:
        return 0;
      default:
        return 'auto';
    }
  }
  return 'auto';
};
