import { IMenuOptionProps } from './types';
import { clsx } from 'clsx';
import { Dropdown } from '../Dropdown';
import { MenuContent } from './MenuContent';
import { ChevronRight } from 'lucide-react';
import { useContext } from 'react';
import { DropdownContext } from '../Dropdown/context';

export const MenuOption = ({
  option,
  onChange,
  onRenderOption,
  index,
}: IMenuOptionProps) => {
  const { navigationRef, getItemProps, activeIndex } =
    useContext(DropdownContext);

  if (onRenderOption) {
    return <>{onRenderOption(option)}</>;
  }

  const isActive = activeIndex === index;

  const rootClass = clsx(
    'analog-menu__option analog-typography--body regular',
    option.disabled && 'analog-menu__option--disabled',
    isActive && 'analog-menu__option--active'
  );

  const anchor = (
    <button
      className={rootClass}
      ref={navigationRef(index)}
      {...getItemProps()}
      onClick={onChange(option)}
      tabIndex={isActive ? 0 : -1}
    >
      {option.icon !== undefined && (
        <div className="analog-menu__option-startAdornment">{option.icon}</div>
      )}
      {option.text}
      {option.options && (
        <div className="analog-menu__option-endAdornment">
          <ChevronRight size="16" />
        </div>
      )}
    </button>
  );

  return (
    <div className="analog-menu__option-wrapper">
      {option.options ? (
        <Dropdown
          className="analog-menu__option-dropdown"
          anchor={anchor}
          content={<MenuContent options={option.options} />}
          placement="right-start"
          interactions={['hover', 'listNavigation', 'dismiss']}
          nested={true}
        />
      ) : (
        anchor
      )}
    </div>
  );
};
