import {
  ColumnSortDirection,
  ISortConfig,
  ITableColumn,
  ITableContext,
  ITableItem,
  ITableProps,
} from './types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { defaultRowsPerPageOptions } from '../Pagination/constants';

export const useViewModel = (props: ITableProps): ITableContext => {
  const { items, onRowsSelect, idKey, pagination, loading }: ITableProps =
    props;
  const ID = (idKey || 'id') as keyof ITableItem;

  const itemsWithID = useMemo(() => {
    if (idKey !== undefined) {
      return [...items];
    }

    const nextItems: any[] = [];

    for (let i = 0; i < items.length; i++) {
      if (items[i]['id'] === undefined) {
        nextItems.push({
          ...items[i],
          id: `${i}`,
        });
      } else {
        nextItems.push(items[i]);
      }
    }

    return nextItems;
  }, [items, idKey]);

  const [uncontrolledSortConfig, setUncontrolledSortConfig] = useState<
    ISortConfig | undefined
  >(undefined);

  const onUncontrolledSort = useCallback((column: ITableColumn) => {
    setUncontrolledSortConfig((sortConfig: ISortConfig | undefined) => {
      if (!sortConfig) {
        return {
          key: column.key,
          direction: ColumnSortDirection.ASC,
          onSort: column.onSort,
        };
      }

      if (sortConfig.key !== column.key) {
        return {
          key: column.key,
          direction: ColumnSortDirection.ASC,
          onSort: column.onSort,
        };
      }

      if (sortConfig.direction === ColumnSortDirection.ASC) {
        return {
          key: column.key,
          direction: ColumnSortDirection.DESC,
          onSort: column.onSort,
        };
      }

      return undefined;
    });
  }, []);

  const { sort: controlledSortConfig, onSort: onControlledSort } = props;

  const [sortConfig, onChangeSortConfig] = useMemo(() => {
    return [
      controlledSortConfig || uncontrolledSortConfig,
      onControlledSort || onUncontrolledSort,
    ];
  }, [
    controlledSortConfig,
    onControlledSort,
    onUncontrolledSort,
    uncontrolledSortConfig,
  ]);

  const sortedItems: ITableItem[] = useMemo<ITableItem[]>(() => {
    if (props.onSort || !sortConfig) return itemsWithID;

    const onSort = (sortConfig as ISortConfig).onSort;

    if (onSort) {
      return [...itemsWithID].sort(onSort(sortConfig.direction));
    }

    return [...itemsWithID].sort((a: any, b: any) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === ColumnSortDirection.ASC ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === ColumnSortDirection.ASC ? 1 : -1;
      }
      return 0;
    });
  }, [props.onSort, sortConfig, itemsWithID]);

  const [uncontrolledPage, setUncontrolledPage] = useState<number>(1);

  const uncontrolledPageChange = useCallback((page: number) => {
    setUncontrolledPage(page);
  }, []);

  const [page, setPage] = useMemo(() => {
    return [
      pagination?.page || uncontrolledPage,
      pagination?.onChange || uncontrolledPageChange,
    ];
  }, [
    pagination?.page,
    pagination?.onChange,
    uncontrolledPage,
    uncontrolledPageChange,
  ]);

  const onPageChange = useCallback(
    (page: number): void => {
      setPage(page);
    },
    [setPage]
  );

  const [uncontrolledRowsPerPage, setUncontrolledRowsPerPage] =
    useState<number>(defaultRowsPerPageOptions[0]);

  const uncontrolledRowsPerPageChange = useCallback((page: number) => {
    setUncontrolledRowsPerPage(page);
  }, []);

  const [rowsPerPage, setRowsPerPage] = useMemo(() => {
    return [
      pagination?.rowsPerPage || uncontrolledRowsPerPage,
      pagination?.onChangeRowsPerPage || uncontrolledRowsPerPageChange,
    ];
  }, [
    pagination?.rowsPerPage,
    pagination?.onChangeRowsPerPage,
    uncontrolledRowsPerPage,
    uncontrolledRowsPerPageChange,
  ]);

  const onChangeRowsPerPage = useCallback(
    (value: number) => {
      if (pagination?.onChangeRowsPerPage) {
        pagination.onChangeRowsPerPage(value);
      } else {
        setRowsPerPage(value);
      }
      setRowsPerPage(value);
    },
    [pagination, setRowsPerPage]
  );

  const [selectedItems, setSelectedItems] = useState<ITableItem[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    if (onRowsSelect) {
      onRowsSelect(selectedItems);
    }
  }, [selectedItems, onRowsSelect]);

  const onSelectAll = () => {
    setSelectAll((selectAll: boolean) => !selectAll);
    setSelectedItems([...sortedItems]);
  };

  const itemsOnCurrentPage: ITableItem[] = useMemo(() => {
    return props.pagination && !props.pagination.server
      ? sortedItems.slice(
          (page - 1) * rowsPerPage,
          (page - 1) * rowsPerPage + rowsPerPage
        )
      : sortedItems;
  }, [page, rowsPerPage, sortedItems, props.pagination]);

  const onSelectAllFromPage = useCallback(() => {
    setSelectAll(false);
    setSelectedItems((selectedItems: ITableItem[]) =>
      selectedItems.length > 0 ? [] : [...itemsOnCurrentPage]
    );
  }, [itemsOnCurrentPage]);

  const lastSelectedIndex = useRef<number>(-1);

  const onSelect = useCallback(
    (item: ITableItem) => () => {
      setSelectedItems((selectedItems: ITableItem[]) => {
        const nextSelectedItems: ITableItem[] = [...selectedItems];
        const index = selectedItems.findIndex(
          (tableItem: ITableItem) => tableItem[ID] === item[ID]
        );

        if (lastSelectedIndex.current === -1) {
          lastSelectedIndex.current = index;
        }

        if (index >= 0) {
          nextSelectedItems.splice(index, 1);
          setSelectAll(false);
        } else {
          nextSelectedItems.push(item);
        }

        return nextSelectedItems;
      });
    },
    [ID]
  );

  const pageCount = props.pagination
    ? Math.ceil((props.pagination.total || itemsWithID.length) / rowsPerPage)
    : 0;

  useEffect(() => {
    if (loading) {
      return;
    }

    if (page > pageCount) {
      setPage(Math.max(1, pageCount));
    }
  }, [pageCount, page, setPage, loading]);

  return {
    ...props,
    items: itemsWithID,
    page,
    onPageChange,
    onSelect,
    onSelectAllFromPage,
    selectedItems,
    selectAll,
    pageCount,
    itemsOnCurrentPage,
    onSelectAll,
    rowsPerPage,
    onChangeRowsPerPage,
    sortedItems,
    sortConfig,
    onChangeSortConfig,
  };
};
