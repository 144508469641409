import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useAthleteStore } from '../../store/athlete/slice';
import { ROUTES } from '../../router/routes';
import { useFetchAthlete } from '../AthleteDetails/hooks/useFetchAthlete';
import { useFetchLastUpdatedWellness } from './hooks/useFetchLastUpdatedWellness';

export const useViewModel = () => {
  useFetchAthlete();
  const { fetchLastUpdatedWellness } = useFetchLastUpdatedWellness();
  const athlete = useAthleteStore((s) => s.athlete);
  const athleteLoaded = useAthleteStore((s) => s.athleteLoaded);
  const navigate = useNavigate();

  const handleSingOut = useCallback(() => {
    navigate(`/${ROUTES.SIGN_OUT}`);
  }, [navigate]);

  return {
    handleSingOut,
    fetchLastUpdatedWellness,
    athlete,
    athleteLoaded,
  };
};
