import './index.css';
import { IconButton, Typography } from '@cycling-web/analog-ui';
import { Send } from 'lucide-react';
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSmartChartsContext } from '../../context';
import { useSmartChartsStore } from '../../store/slice';

export const Footer = () => {
  const { t } = useTranslation();
  const { sendMessage } = useSmartChartsContext();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const processingBufferMessage = useSmartChartsStore(
    (s) => s.processingBufferMessage
  );
  const isProcessing = !!processingBufferMessage;

  const onInputChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  }, []);

  const handleSendMessage = useCallback(() => {
    if (!inputValue) {
      return;
    }

    sendMessage(inputValue);
    setInputValue('');
  }, [sendMessage, inputValue]);

  useEffect(() => {
    setTimeout(() => {
      if (textareaRef.current) {
        const MAX_HEIGHT = 96;
        textareaRef.current.style.height = '48px';
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = `${Math.min(
          scrollHeight,
          MAX_HEIGHT
        )}px`;
        textareaRef.current.style.overflowY =
          scrollHeight > MAX_HEIGHT ? 'scroll' : 'hidden';
      }
    });
  }, [inputValue]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (isProcessing) {
        return;
      }

      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage, isProcessing]
  );

  return (
    <div className="sc2__footer">
      <div className="sc2__footer-textarea-wrapper">
        <textarea
          className="sc2__footer-textarea analog-typography--body"
          value={inputValue}
          onChange={onInputChange}
          ref={textareaRef}
          onKeyDown={handleKeyDown}
          placeholder={t('placeholder.message')}
        />
        <div className="sc2__footer-actions">
          <IconButton
            variant="quietNeutral"
            content={<Send />}
            disabled={isProcessing}
            onClick={handleSendMessage}
          />
        </div>
      </div>
      <div className="sc2__footer-note">
        <Typography
          variant="caption"
          weight="light"
          text={t('smart_charts.label.footer_text')}
        />
      </div>
    </div>
  );
};
