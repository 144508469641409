import { Tag } from '@cycling-web/analog-ui';
import { HrvRhrUtils } from '../../../utils/hrv-rhr';
import { EMPTY } from '../../../constants';

type IProps = {
  rhrVariance: number;
  hrvVariance: number;
};

export const RhrHrvTag = ({ rhrVariance, hrvVariance }: IProps) => {
  const status = HrvRhrUtils.getStatus({ hrvVariance, rhrVariance });
  if (!status) {
    return EMPTY;
  }

  const { title, tagVariant } = HrvRhrUtils.getContent(status);

  return <Tag variant={tagVariant} text={title} />;
};
