import { msalConfig } from '../ms/msal-config';

class ComplianceModule {
  private static instance: ComplianceModule;
  private msalServerTelemetryConsent = false;
  private appsInsightsConsent = false;

  private constructor() {}

  public static getInstance(): ComplianceModule {
    if (!ComplianceModule.instance) {
      ComplianceModule.instance = new ComplianceModule();
    }
    return ComplianceModule.instance;
  }

  public getMSALServerTelemetryConsent(): boolean {
    return this.msalServerTelemetryConsent;
  }

  // NOTE: We can't turn off the MSAL server telemetry. We can only remove the key programmatically.
  public setMSALServerTelemetryConsent(consent: boolean): void {
    this.msalServerTelemetryConsent = consent;
    if (!consent) {
      this.removeMSALServerTelemetryKeys();
    }
  }

  public getAppsInsightsConsent(): boolean {
    return this.appsInsightsConsent;
  }

  public setAppsInsightsConsent(consent: boolean): void {
    this.appsInsightsConsent = consent;
    if (!consent) {
      this.removeAppsInsightsKeys();
    }
  }

  public removeAllCookies(): void {
    const cookies = document.cookie.split(';');

    cookies.forEach((cookie) => {
      const cookieName = cookie.split('=')[0].trim();
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  }

  private removeMSALServerTelemetryKeys(): void {
    const telemetryKey = `server-telemetry-${msalConfig.auth.clientId}`;
    localStorage.removeItem(telemetryKey);
  }

  private removeAppsInsightsKeys(): void {
    localStorage.removeItem('AI_sentBuffer');
  }
}

export const complianceModuleInstance = ComplianceModule.getInstance();
