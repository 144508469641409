import {
  IAthleteMedicalReportSlice,
  IAthleteMedicalReportState,
} from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  IMedicalFile,
  IMedicalNote,
  IMedicalReport,
} from '../../../types/medical';

export const initialState: IAthleteMedicalReportState = {
  report: null,
  reportLoaded: false,
  reportNotes: [],
  reportFiles: [],
};

export const useAthleteMedicalReportStore =
  create<IAthleteMedicalReportSlice>()(
    immer((set) => ({
      ...initialState,
      setReport: (report: IMedicalReport | null) => {
        set((state) => {
          state.report = report;
          state.reportLoaded = true;
        });
      },
      setReportLoaded: (loaded: boolean) => {
        set((state) => {
          state.reportLoaded = loaded;
        });
      },
      setReportNotes: (notes: IMedicalNote[]) => {
        set((state) => {
          state.reportNotes = notes;
        });
      },
      setReportFiles: (files: IMedicalFile[]) => {
        set((state) => {
          state.reportFiles = files;
        });
      },
      appendNote: (note: IMedicalNote) => {
        set((state) => {
          state.reportNotes.push(note);
        });
      },
      appendFile: (file: IMedicalFile) => {
        set((state) => {
          state.reportFiles.push(file);
        });
      },
      deleteFile: (fileId: number) => {
        set((state) => {
          state.reportFiles = state.reportFiles.filter((file: IMedicalFile) => {
            return file.id !== fileId;
          });
        });
      },
      reset: () => {
        set(initialState);
      },
    }))
  );
