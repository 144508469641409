import './index.css';
import { OnboardingFooter } from '../Footer';
import {
  Button,
  Divider,
  Typography,
  useBoolean,
} from '@cycling-web/analog-ui';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { parseTeamName } from '../../../../utils/teamName';
import { useCallback, useMemo } from 'react';
import { CheckCircle2, ChevronRight } from 'lucide-react';
import { useUserRole } from '../../../../hooks/useUserRole';
import { PrivacyNoticeDialog } from '../PrivacyNoticeDialog';
import { TermsAndConditionsDialog } from '../TermsAndConditionsDialog';
import { useOnboardingContext } from '../../context';

export const OnboardingPrivacyNotice = () => {
  const { t } = useTranslation();
  const { teamId } = useParams();
  const team = teamId ? parseTeamName(teamId) : t('label.team');
  const { isAthlete, roles } = useUserRole();
  const rolesText = useMemo(() => {
    if (!roles.length) return '';
    if (roles.length === 1) return roles[0];
    return `${roles.slice(0, -1).join(', ')}, ${t('prepositions.and')} ${
      roles[roles.length - 1]
    }`;
  }, [t, roles]);

  const {
    termsAndConditionsConfirmed,
    setTermsAndConditionsConfirmed,
    privacyNoticeConfirmed,
    setPrivacyNoticeConfirmed,
  } = useOnboardingContext();

  const {
    value: showPrivacyNoticeDialog,
    setTrue: openPrivacyNoticeDialog,
    setFalse: dismissPrivacyNoticeDialog,
  } = useBoolean(false);

  const {
    value: showTermsAndConditionsDialog,
    setTrue: openTermsAndConditionsDialog,
    setFalse: dismissTermsAndConditionsDialog,
  } = useBoolean(false);

  const onConfirmTermsAndConditions = useCallback(() => {
    setTermsAndConditionsConfirmed(true);
    dismissTermsAndConditionsDialog();
  }, [setTermsAndConditionsConfirmed, dismissTermsAndConditionsDialog]);

  const onDeclineTermsAndConditions = useCallback(() => {
    setTermsAndConditionsConfirmed(false);
    dismissTermsAndConditionsDialog();
  }, [setTermsAndConditionsConfirmed, dismissTermsAndConditionsDialog]);

  const onConfirmPrivacyNotice = useCallback(() => {
    setPrivacyNoticeConfirmed(true);
    dismissPrivacyNoticeDialog();
  }, [setPrivacyNoticeConfirmed, dismissPrivacyNoticeDialog]);

  const onDeclinePrivacyNotice = useCallback(() => {
    setPrivacyNoticeConfirmed(false);
    dismissPrivacyNoticeDialog();
  }, [setPrivacyNoticeConfirmed, dismissPrivacyNoticeDialog]);

  const termsAndConditionsText = (
    <Trans i18nKey="legal.onboarding_welcome_acknowledgement_1">
      You confirm that you have read and agree to the{' '}
      <span
        className="onboarding-privacy__link"
        onClick={openTermsAndConditionsDialog}
      >
        Terms & Conditions
      </span>{' '}
      for Enterprise Subscriptions.
    </Trans>
  );

  const privacyNoticeText = isAthlete ? (
    <Trans i18nKey="legal.onboarding_welcome_acknowledgement_athlete_1">
      You:
      <ul>
        <li>acknowledge that your personal data will be processed, and</li>
        <li>
          consent to our use and sharing of your health-related data with the
          coaches and other professionals connected with your Account
        </li>
      </ul>
      in accordance with our{' '}
      <span
        className="onboarding-privacy__link"
        onClick={openPrivacyNoticeDialog}
      >
        Privacy Notice
      </span>{' '}
      for Enterprise Subscriptions
    </Trans>
  ) : (
    <Trans i18nKey="legal.onboarding_welcome_acknowledgement_staff_1">
      You acknowledge that your personal data will be processed in accordance
      with our{' '}
      <span
        className="onboarding-privacy__link"
        onClick={openPrivacyNoticeDialog}
      >
        Privacy Notice
      </span>{' '}
      for Enterprise Subscriptions.
    </Trans>
  );

  const confirmedJSX = (
    <div className="onboarding-privacy__confirmed">
      <CheckCircle2 size={20} />
      <Typography variant="button-s" text={t('label.confirmed')} />
    </div>
  );

  return (
    <>
      <header className="onboarding-privacy__header">
        <Typography
          variant="h4"
          weight="bold"
          className="onboarding-platforms__header-title"
          text={t('label.onboarding_welcome_to_team', {
            team,
          })}
        />
      </header>
      <div className="onboarding-privacy__body">
        <Typography
          as="p"
          text={
            isAthlete
              ? t('label.onboarding_welcome_athlete_text', { team })
              : t('label.onboarding_welcome_staff_text', {
                  team,
                  roles: rolesText,
                })
          }
        />
        <Typography
          as="p"
          variant="body"
          weight="bold"
          text={t('label.onboarding_welcome_p1')}
        />

        <div className="onboarding-privacy__acknowledgement">
          <Typography
            className="onboarding-privacy__acknowledgement-text"
            text={termsAndConditionsText}
          />
          {termsAndConditionsConfirmed ? (
            confirmedJSX
          ) : (
            <Button
              variant="quiet"
              size="s"
              endIcon={<ChevronRight />}
              content={t('action.read_and_confirm')}
              onClick={openTermsAndConditionsDialog}
            />
          )}
        </div>

        <Divider />

        <div className="onboarding-privacy__acknowledgement">
          <Typography
            className="onboarding-privacy__acknowledgement-text"
            text={privacyNoticeText}
          />
          {privacyNoticeConfirmed ? (
            confirmedJSX
          ) : (
            <Button
              variant="quiet"
              size="s"
              endIcon={<ChevronRight />}
              content={t('action.read_and_confirm')}
              onClick={openPrivacyNoticeDialog}
            />
          )}
        </div>
      </div>
      <OnboardingFooter
        nextText={t('action.accept_and_continue')}
        stepCompleted={true}
        disabled={!termsAndConditionsConfirmed || !privacyNoticeConfirmed}
      />

      {showTermsAndConditionsDialog && (
        <TermsAndConditionsDialog
          onDecline={onDeclineTermsAndConditions}
          onConfirm={onConfirmTermsAndConditions}
        />
      )}
      {showPrivacyNoticeDialog && (
        <PrivacyNoticeDialog
          onDecline={onDeclinePrivacyNotice}
          onConfirm={onConfirmPrivacyNotice}
        />
      )}
    </>
  );
};
