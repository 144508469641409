import './index.css';
import { GroupOverviewTable } from './components/GroupOverviewTable';
import { GroupOverviewFilters } from './components/Filters';

export const GroupOverview = () => {
  return (
    <div className="overview-page">
      <GroupOverviewFilters />
      <GroupOverviewTable />
    </div>
  );
};
