import { PerformanceService } from './service';
import { format } from 'date-fns';
import {
  ICriticalPower,
  IFatigueResistance,
  IFatigueResistanceRelative,
  IFitnessFatigue,
  IITrimpTss,
  ILastSFT,
  ILatestTrainingLoad,
  IPdCurve,
  IPdCurveRelative,
  IPeak,
  IPerformanceReport,
  IPowerDerivative,
  IPowerHeldFormData,
  IRPEFormData,
  ITHRFormData,
  ITrainingLoad,
  ITTEFormData,
  IWorkoutDetail,
} from '../../types/performance';
import {
  IGetCriticalPowerRequest,
  IGetFatigueResistanceRelativeRequest,
  IGetFatigueResistanceRequest,
  IGetFitnessFatigueRequest,
  IGetITRIMPTSSRequest,
  IGetLastSFTRequest,
  IGetLatestTrainingLoadRequest,
  IGetPdCurveRelativeRequest,
  IGetPdCurveRequest,
  IGetPeakRequest,
  IGetPowerDerivativesRequest,
  IGetTrainingLoadRequest,
  IGetWorkoutDetailsRequest,
} from './types';

/** The repository is responsible for data transformation */

export interface IPerformanceRepository {
  setRPE: (p: IRPEFormData) => Promise<void>;
  setTTE: (p: ITTEFormData) => Promise<void>;
  setTHR: (p: ITHRFormData) => Promise<void>;
  setPowerHeld: (p: IPowerHeldFormData) => Promise<void>;

  getPerformanceReport: (week_year: string) => Promise<IPerformanceReport[]>;

  getTrainingLoad: (p: IGetTrainingLoadRequest) => Promise<ITrainingLoad>;
  getLatestTrainingLoad: (
    p: IGetLatestTrainingLoadRequest
  ) => Promise<ILatestTrainingLoad[]>;
  getWorkoutDetails: (
    p: IGetWorkoutDetailsRequest
  ) => Promise<IWorkoutDetail[]>;
  getPowerDerivatives: (
    p: IGetPowerDerivativesRequest
  ) => Promise<IPowerDerivative>;
  getPeak: (p: IGetPeakRequest) => Promise<IPeak[]>;
  getPdCurveRelative: (
    p: IGetPdCurveRelativeRequest
  ) => Promise<IPdCurveRelative>;
  getPdCurve: (p: IGetPdCurveRequest) => Promise<IPdCurve>;
  getLastSFT: (p: IGetLastSFTRequest) => Promise<ILastSFT>;
  getITRIMPTSS: (p: IGetITRIMPTSSRequest) => Promise<IITrimpTss>;
  getFitnessFatigue: (p: IGetFitnessFatigueRequest) => Promise<IFitnessFatigue>;
  getFatigueResistanceRelative: (
    p: IGetFatigueResistanceRelativeRequest
  ) => Promise<IFatigueResistanceRelative>;
  getFatigueResistance: (
    p: IGetFatigueResistanceRequest
  ) => Promise<IFatigueResistance>;
  getCriticalPower: (p: IGetCriticalPowerRequest) => Promise<ICriticalPower[]>;
}

export class PerformanceRepository implements IPerformanceRepository {
  constructor(private readonly service: PerformanceService) {}

  async setRPE(p: IRPEFormData): Promise<void> {
    return await this.service.setRPE({
      rpe: p.rpe,
      athleteId: p.athleteId,
      date: format(new Date(), 'yyyy-MM-dd'),
      inputBy: 1,
    });
  }

  async setTTE(p: ITTEFormData): Promise<void> {
    return await this.service.setTTE({
      tte: +p.tte,
      athleteId: p.athleteId,
      inputBy: 0,
    });
  }

  async setTHR(p: ITHRFormData): Promise<void> {
    return await this.service.setTHR({
      thr: +p.thr,
      athleteId: p.athleteId,
      date: format(new Date(), 'yyyy-MM-dd'),
      inputBy: 1,
    });
  }

  async setPowerHeld(p: IPowerHeldFormData): Promise<void> {
    return await this.service.setPowerHeld({
      power: +p.power,
      athleteId: p.athleteId,
      date: format(new Date(), 'yyyy-MM-dd'),
      inputBy: 1,
    });
  }

  async getPerformanceReport(week_year: string): Promise<IPerformanceReport[]> {
    const [week, year] = week_year.split('_');
    const response = await this.service.getPerformanceReport({
      week,
      year,
    });
    return response.data;
  }

  async getTrainingLoad(p: IGetTrainingLoadRequest): Promise<ITrainingLoad> {
    const response = await this.service.getTrainingLoad(p);
    return response.data;
  }

  async getLatestTrainingLoad(
    p: IGetLatestTrainingLoadRequest
  ): Promise<ILatestTrainingLoad[]> {
    const response = await this.service.getLatestTrainingLoad(p);
    return response.data;
  }

  async getWorkoutDetails(
    p: IGetWorkoutDetailsRequest
  ): Promise<IWorkoutDetail[]> {
    const response = await this.service.getWorkoutDetails(p);
    return response.data;
  }

  async getPowerDerivatives(
    p: IGetPowerDerivativesRequest
  ): Promise<IPowerDerivative> {
    const response = await this.service.getPowerDerivatives(p);
    return response.data;
  }

  async getPeak(p: IGetPeakRequest): Promise<IPeak[]> {
    const response = await this.service.getPeak(p);
    return response.data;
  }

  async getPdCurveRelative(
    p: IGetPdCurveRelativeRequest
  ): Promise<IPdCurveRelative> {
    const response = await this.service.getPdCurveRelative(p);
    return response.data;
  }

  async getPdCurve(p: IGetPdCurveRequest): Promise<IPdCurve> {
    const response = await this.service.getPdCurve(p);
    return response.data;
  }

  async getLastSFT(p: IGetLastSFTRequest): Promise<ILastSFT> {
    const response = await this.service.getLastSFT(p);
    return response.data;
  }

  async getITRIMPTSS(p: IGetITRIMPTSSRequest): Promise<IITrimpTss> {
    const response = await this.service.getITRIMPTSS(p);
    return response.data;
  }

  async getFitnessFatigue(
    p: IGetFitnessFatigueRequest
  ): Promise<IFitnessFatigue> {
    const response = await this.service.getFitnessFatigue(p);
    return response.data;
  }

  async getFatigueResistanceRelative(
    p: IGetFatigueResistanceRelativeRequest
  ): Promise<IFatigueResistanceRelative> {
    const response = await this.service.getFatigueResistanceRelative(p);
    return response.data;
  }

  async getFatigueResistance(
    p: IGetFatigueResistanceRequest
  ): Promise<IFatigueResistance> {
    const response = await this.service.getFatigueResistance(p);
    return response.data;
  }

  async getCriticalPower(
    p: IGetCriticalPowerRequest
  ): Promise<ICriticalPower[]> {
    const response = await this.service.getCriticalPower(p);
    return response.data;
  }
}
