import { useEffect } from 'react';
import { LS } from '../../constants';

export const Integrations = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const backUrl = localStorage.getItem(LS.PreviousPage) || '/';
    const url = `${backUrl}&${params.toString()}`;
    window.location.replace(url);
  }, []);

  return <div />;
};
