import { IGetEnumsResponse, IGetUserRolesResponse } from './types';
import { axiosInstance } from '../../axios-instance';
import { AxiosResponse } from 'axios';

interface IDictionariesService {
  getUserRoles: () => Promise<IGetUserRolesResponse>;
  getEnums: () => Promise<AxiosResponse<IGetEnumsResponse>>;
}

export class DictionariesService implements IDictionariesService {
  async getUserRoles(): Promise<IGetUserRolesResponse> {
    try {
      return axiosInstance.get(`/v1/debug/userRoles`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getEnums(): Promise<AxiosResponse<IGetEnumsResponse>> {
    try {
      return axiosInstance.get(`/v1/medical/enums`);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
