import { Button } from '@cycling-web/analog-ui';
import { Plus } from 'lucide-react';
import { DEFAULT_GROUP, GroupsContext } from '../../context';
import { PageFilters } from '@cycling-web/common';
import { initialFilters, useGroupsStore } from '../../../../store/groups/slice';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext } from 'react';
import { IGroupsFilters } from '../../../../types/groups';
import { AnyValue } from '../../../../types/common';
import { useURLFilters } from '../../../../hooks/useURLFilters';

export const GroupFilters = () => {
  const { t } = useTranslation();
  const { openGroupFormModal } = useContext(GroupsContext);
  const filters = useGroupsStore((s) => s.filters);
  const setFilters = useGroupsStore((s) => s.setFilters);

  const initFilters = useCallback(() => {
    const params = new URLSearchParams(window.location.search);

    useGroupsStore.getState().setFilters({
      search: params.get('search') || initialFilters.search,
    });
  }, []);

  const resetFilters = useCallback(() => {
    useGroupsStore.getState().setFilters(initialFilters);
  }, []);

  useURLFilters({ filters, initFilters, resetFilters });

  const handleChangeFilter = useCallback(
    (key: keyof IGroupsFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  );

  const handleSearchClear = useCallback(() => {
    setFilters({ search: '' });
  }, [setFilters]);

  return (
    <PageFilters
      searchInputProps={{
        value: filters.search,
        onChange: handleChangeFilter('search'),
        onClear: handleSearchClear,
      }}
      actionElements={
        <Button
          startIcon={<Plus />}
          content={t('banner.empty_groups_action')}
          onClick={openGroupFormModal(DEFAULT_GROUP)}
        />
      }
    />
  );
};
