import { IAcceptTeamInvitationRequest, IGetUserTeamsResponse } from './types';
import { axiosInstance } from '../../axios-instance';
import qs from 'qs';
import { R } from '../../types/common';

interface ITeamsService {
  getUserTeams: () => R<IGetUserTeamsResponse>;
  acceptInvitation: (p: IAcceptTeamInvitationRequest) => R<void>;
}

export class TeamsService implements ITeamsService {
  async getUserTeams(): R<IGetUserTeamsResponse> {
    try {
      return axiosInstance.get('/v1/preauth/userTeams');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async acceptInvitation(p: IAcceptTeamInvitationRequest): R<void> {
    try {
      const params = qs.stringify({
        tenantName: p.teamName,
      });
      return axiosInstance.put(`/v1/preauth/acceptTeamInvite?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
