import {
  Dialog,
  FormElement,
  ISelectOption,
  Select,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ISendMessageFeedbackRequest } from '../../../../api/ai/types';
import { useAthleteStore } from '../../../../store/athlete/slice';
import { IChatbotFeedbackType } from '../../../../types/chatbot';
import { useAiAssistantStore } from '../../store/slice';
import { FormColumn, TextareaControl } from '@cycling-web/common';
import { AIRepository } from '../../../../api/ai/repository';
import { AIService } from '../../../../api/ai/service';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

type IProps = {
  onDismiss: () => void;
  messageId?: string;
  onSuccess?: () => void;
};

export const FeedbackModal = ({ messageId, onDismiss, onSuccess }: IProps) => {
  const { t } = useTranslation();
  const athlete = useAthleteStore((s) => s.athlete);
  const config = useAiAssistantStore((s) => s.config);
  const [loading, setLoading] = useState<boolean>(false);

  const aiRepository = useMemo(() => {
    return new AIRepository(new AIService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        body: z
          .object({
            user_message: z.string().min(1, t('validation.required')),
          })
          .passthrough(),
      })
      .passthrough();
  }, [t]);

  const form = useForm<ISendMessageFeedbackRequest>({
    defaultValues: {
      athleteId: athlete?.id,
      body: {
        message_id: messageId || '',
        thread_id: config?.thread_id || '',
        feedback_type: IChatbotFeedbackType.Bad,
        tags: [],
        user_message: '',
      },
    },
    resolver: zodResolver(schema),
  });
  const { setValue, watch, handleSubmit, formState } = form;
  const [tags] = watch(['body.tags']);

  const options: ISelectOption[] = [
    {
      id: t('label.irrelevant_response'),
      text: t('label.irrelevant_response'),
    },
    {
      id: t('label.inaccurate_information_or_data'),
      text: t('label.inaccurate_information_or_data'),
    },
    { id: t('label.calculation_error'), text: t('label.calculation_error') },
    {
      id: t('label.scientific_inaccuracy'),
      text: t('label.scientific_inaccuracy'),
    },
    { id: t('label.not_enough_details'), text: t('label.not_enough_details') },
    { id: t('label.confusing_response'), text: t('label.confusing_response') },
  ];

  const onChange = useCallback(
    (option: ISelectOption) => {
      setValue('body.tags', [option.id]);
    },
    [setValue]
  );

  const handleSave = useCallback(
    (formData: ISendMessageFeedbackRequest) => {
      setLoading(true);
      aiRepository
        .sendMessageFeedback(formData)
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
          onDismiss();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [aiRepository, onDismiss, onSuccess]
  );

  const onSubmit = useCallback(() => {
    handleSubmit((formData: ISendMessageFeedbackRequest) => {
      handleSave(formData);
    })();
  }, [handleSave, handleSubmit]);

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.feedback')}
        onDismiss={onDismiss}
        submitButtonProps={{ onClick: onSubmit, loading }}
        stopPropagationOnClick={true}
      >
        <FormColumn>
          {messageId && (
            <FormElement label={t('label.feedback')}>
              <Select
                options={options}
                onChange={onChange}
                value={options.find((o: ISelectOption) => o.id === tags[0])}
                placeholder={t('placeholder.feedback')}
              />
            </FormElement>
          )}
          <FormElement
            label={t('label.feedback')}
            message={formState.errors?.body?.user_message?.message}
          >
            <TextareaControl
              name="body.user_message"
              placeholder={t('placeholder.feedback')}
              invalid={!!formState.errors?.body?.user_message}
            />
          </FormElement>
        </FormColumn>
      </Dialog>
    </FormProvider>
  );
};
