import './index.css';
import { Tooltip, Typography } from '@cycling-web/analog-ui';
import { Info } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { EMPTY } from '../../../../constants';

interface IProps {
  lastUpdated?: Date | undefined;
  source?: string;
}

export const LastUpdatedTooltip = ({ lastUpdated, source }: IProps) => {
  const { t } = useTranslation();
  const lastUpdatedValue = lastUpdated
    ? format(lastUpdated, 'dd/MM/yyyy')
    : EMPTY;

  return (
    <div className="wellness-widget__tooltip">
      <Tooltip
        anchor={<Info className="wellness-widget__tooltip-icon" size={18} />}
        content={
          <div className="wellness-widget__tooltip-content">
            <div>
              <Typography
                className="wellness-widget__tooltip-label"
                text={t('label.last_updated') + ': '}
              />
              <Typography
                className="wellness-widget__tooltip-value"
                text={lastUpdatedValue}
              />
            </div>
            <Typography
              variant="subtitle"
              className="wellness-widget__tooltip-source"
              text={source}
            />
          </div>
        }
        placement="bottom-end"
      />
    </div>
  );
};
