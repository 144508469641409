import { IUserProfileBase, UserRole } from '@cycling-web/common';

export type IUserProfile = IUserProfileBase;

export type IInviteUserForm = {
  fullName: string;
  userEmail: string;
  userRoles: string[];
};

export type IInvitedUser = {
  email: string;
  name: string;
  roles: UserRole[];
  status: UserStatus;
};

export type IUserBase = IInvitedUser & {
  id: number;
  staffId?: number;
  athleteId?: number;
  picture: string;
};

export enum UserStatus {
  Accepted = 'Accepted',
  Pending = 'Pending',
}

export type ITeamMapping = {
  athleteId: number;
  roleAndStaffIdMapping: Record<number, UserRole[]>;
};

export type IAthleteMappingForm = {
  athleteId: number;
  roleStaffIdMap: {
    coach: number | undefined;
    sports_director: number | undefined;
    nutritionist: number | undefined;
    physiotherapist: number | undefined;
    mental_coach: number | undefined;
    doctor: number | undefined;
    secondary_doctor: number | undefined;
  };
};
