import './index.css';
import { List } from './components/List';
import { GroupProfilesContext } from './context';
import { AthleteDialog } from '../../components/AthletesDialog';
import { useViewModel } from './useViewModel';

export const GroupProfiles = () => {
  const { context, showAthleteDialog, group, onChange, dismissAthleteDialog } =
    useViewModel();

  return (
    <GroupProfilesContext.Provider value={context}>
      <div className="profiles-page">
        <List />
        {showAthleteDialog && (
          <AthleteDialog
            value={group?.athleteIds || []}
            onChange={onChange}
            onDismiss={dismissAthleteDialog}
          />
        )}
      </div>
    </GroupProfilesContext.Provider>
  );
};
