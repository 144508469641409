import './index.css';
import { Page } from '@cycling-web/common';
import { useTranslation } from 'react-i18next';
import { useViewModel } from './useViewModel';
import { TeamPowerProfileChart } from '../GroupCharts/components/TeamPowerProfileChart';
import { TssAndIfChart } from '../AthleteTrainingLoad/components/TssAndIfChart';
import { DurationAndDistanceChart } from '../AthleteTrainingLoad/components/DurationAndDistanceChart';
import { DurationAndElevationChart } from '../AthleteTrainingLoad/components/DurationAndElevationChart';
import { WorkAndIfChart } from '../AthleteTrainingLoad/components/WorkAndIfChart';
import { CriticalPowerChart } from '../GroupCharts/components/CriticalPowerChart';
import { DashboardFilters } from './components/Filters';
import { TsbChart } from '../PerformanceReportCharts/components/TsbChart';
import { Summary } from './components/Summary';

export const Dashboard = () => {
  const { t } = useTranslation();
  const {
    workoutDetailsSources,
    workoutDetailsLoading,
    criticalPowerSource,
    criticalPowerLoading,
    peaksSource,
    peaksLoading,
    trainingLoadSources,
    trainingLoadLoading,
    filters,
    handleChangeFilter,
  } = useViewModel();

  return (
    <Page
      pageHeaderProps={{
        title: t('label.team_dashboard'),
      }}
    >
      <div className="dashboard-charts">
        <Summary />
        <DashboardFilters />
        <div className="dashboard-charts__charts">
          <TsbChart
            source={trainingLoadSources.tsb}
            loading={trainingLoadLoading}
          />
          <TeamPowerProfileChart
            source={peaksSource}
            filters={filters}
            handleChangeFilter={handleChangeFilter}
            loading={peaksLoading}
          />
          <TssAndIfChart
            source={workoutDetailsSources.tssAndIf}
            filters={filters}
            handleChangeFilter={handleChangeFilter}
            loading={workoutDetailsLoading}
          />
          <DurationAndDistanceChart
            source={workoutDetailsSources.durationAndDistance}
            filters={filters}
            handleChangeFilter={handleChangeFilter}
            loading={workoutDetailsLoading}
          />
          <DurationAndElevationChart
            source={workoutDetailsSources.durationAndElevation}
            filters={filters}
            handleChangeFilter={handleChangeFilter}
            loading={workoutDetailsLoading}
          />
          <WorkAndIfChart
            source={workoutDetailsSources.workAndIf}
            filters={filters}
            handleChangeFilter={handleChangeFilter}
            loading={workoutDetailsLoading}
          />
          <CriticalPowerChart
            source={criticalPowerSource}
            loading={criticalPowerLoading}
            filters={filters}
            handleChangeFilter={handleChangeFilter}
          />
        </div>
      </div>
    </Page>
  );
};
