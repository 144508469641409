import { CSSProperties, MouseEvent, ReactNode } from 'react';
import { ITableColumnAlign } from './types';
import { clsx } from 'clsx';

interface IProps {
  className?: string;
  children: ReactNode;
  minWidth?: string | number;
  maxWidth?: string | number;
  grow?: number;
  align?: ITableColumnAlign;
  onClick?: (e?: MouseEvent) => void;
}

export const TableCell = ({
  className,
  children,
  minWidth,
  maxWidth,
  grow,
  align,
  onClick,
}: IProps) => {
  const minWidthValue: string | undefined =
    minWidth !== undefined
      ? typeof minWidth === 'string'
        ? minWidth
        : `${minWidth}px`
      : undefined;
  const maxWidthValue: string | undefined =
    maxWidth !== undefined
      ? typeof maxWidth === 'string'
        ? maxWidth
        : `${maxWidth}px`
      : undefined;

  const style: CSSProperties = {
    minWidth: minWidthValue,
    maxWidth: maxWidthValue,
    flexGrow: grow || undefined,
  };

  const rootClass = clsx(
    'analog-table__cell',
    'analog-typography--body',
    'regular',
    align && `analog-table__cell--${align}`,
    className
  );

  return (
    <div className={rootClass} style={style} onClick={onClick}>
      {children}
    </div>
  );
};
