import './index.css';
import { Outlet } from 'react-router';
import { AthleteTabs } from './components/AthleteTabs';
import { Page } from '@cycling-web/common';
import { useViewModel } from './useViewModel';
import { IconButton } from '@cycling-web/analog-ui';
import { Plus } from 'lucide-react';
import { HeartMeasurements } from './components/HeartMeasurements';
import { AddDataMenu } from './components/AddDataMenu';
import { AiAssistant } from '../../components/AiAssistant';
import React from 'react';
import { useMediaMatch } from 'rooks';
import { AthleteDetailsPageTitle } from './components/PageTitle';

export const AthleteDetails = () => {
  const { backUrl } = useViewModel();

  const shrinkButton = useMediaMatch('(max-width: 1400px)');

  return (
    <Page
      pageHeaderProps={{
        title: <AthleteDetailsPageTitle />,
        backUrl: backUrl,
      }}
    >
      <header className="athlete-details__header">
        <HeartMeasurements />
        <AddDataMenu
          anchor={shrinkButton && <IconButton content={<Plus />} />}
        />
      </header>
      <AthleteTabs />
      <Outlet />
      <AiAssistant />
    </Page>
  );
};
