import { Controller, useFormContext } from 'react-hook-form';
import {
  ISelectBaseProps,
  ISelectOption,
  ISingleSelectProps,
  Select,
} from '@cycling-web/analog-ui';
import { useCallback } from 'react';

type ISelectSingleProps = ISelectBaseProps & ISingleSelectProps;
type IProps = Omit<ISelectSingleProps, 'value' | 'onChange' | 'multiple'> & {
  name: string;
};

export const SelectSingleControl = (props: IProps) => {
  const { control, setValue } = useFormContext();
  const { name } = props;

  const handleChange = useCallback(
    (value: ISelectOption) => {
      setValue(name, value.id);
    },
    [name, setValue]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => (
        <Select
          {...props}
          onChange={handleChange}
          value={props.options.find(
            (o: ISelectOption) => value && o.id === value.toString()
          )}
          multiple={false}
        />
      )}
    />
  );
};
