import { IAthlete } from '../../../types/athletes';
import { IMedicalReportsFilters } from '../types';
import { IMedicalReportRecord } from '../../../types/medical';

export const filterReportsDelegate = (
  filters: IMedicalReportsFilters,
  athletesMap: Record<number, IAthlete>
) => {
  return (report: IMedicalReportRecord) => {
    let valid = true;

    const athlete = athletesMap[report.athleteId];

    if (
      filters.search &&
      !athlete?.fullName.toLowerCase().includes(filters.search.toLowerCase())
    ) {
      valid = false;
    }

    return valid;
  };
};
