export const generateSmoothCurveForSmartCharts = (
  data: [number, number][],
  maxWindowSize = 1
): [number, number][] => {
  const smoothData = data.map((point, index) => {
    const windowSize = Math.max(maxWindowSize, Math.floor(index / 3) + 3);
    const start = Math.max(0, index - Math.floor(windowSize / 2));
    const end = Math.min(data.length, index + Math.floor(windowSize / 2) + 1);

    let sum = 0;
    for (let i = start; i < end; i++) {
      sum += data[i][1];
    }
    const average = sum / (end - start);

    return [point[0], average] as [number, number];
  });

  return smoothData as [number, number][];
};
