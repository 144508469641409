import { useMediaMatch } from 'rooks';

export function useBreakpoints() {
  const isMobile = useMediaMatch('(max-width: 767px)');
  const isSmallTablet = useMediaMatch('(max-width: 1024px)');
  const isTablet = useMediaMatch('(max-width: 1365px)');
  const isLaptop = useMediaMatch('(max-width: 1366px)');

  const isDesktop = !(isTablet || isMobile);

  return {
    isMobile,
    isSmallTablet,
    isTablet,
    isLaptop,
    isDesktop,
  };
}
