import { CSSProperties, HTMLProps, MouseEvent, ReactNode } from 'react';
import { ITagProps } from '../Tag/types';
import { ITooltipProps } from '../Tooltip/types';
import type { IDropdownProps, OmitDropdownProps } from '../Dropdown/types';

export type ISelectOption = ISelectBaseOption &
  (
    | ISelectOptionDefault
    | ISelectOptionDivider
    | ISelectOptionHeading
    | ISelectOptionMessage
  );

export type ISelectBaseOption = {
  id: string;
  text: string;
};

export type ISelectOptionDefault = {
  type?: `${OptionType.Option}`;
  description?: ISelectOptionDescription;
  icon?: ReactNode;
  tooltip?: ISelectOptionTooltip;
  tag?: ITagProps;
};

type ISelectOptionDivider = {
  type: `${OptionType.Divider}`;
};

type ISelectOptionHeading = {
  type: `${OptionType.Heading}`;
  tooltip?: ReactNode;
};

type ISelectOptionMessage = {
  type: `${OptionType.Message}`;
  tooltip?: ReactNode;
};

export enum OptionType {
  Option = 'option',
  Divider = 'divider',
  Heading = 'heading',
  Message = 'message',
}

export type IDescriptionVariant =
  | 'neutral'
  | 'success'
  | 'warning'
  | 'error'
  | 'info';

export type ISelectOptionDescription = {
  text: string;
  variant?: IDescriptionVariant;
};

export type ISelectBaseProps = {
  options: ISelectOption[];
  size?: ISelectSize;
  variant?: ISelectVariant;
  onClear?: () => void;
  placeholder?: string;
  virtualScroll?: boolean;
  search?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  invalid?: boolean;
  disabled?: boolean;
  onRenderOption?: (option: ISelectOption) => ReactNode;
  onRenderAnchor?: (open?: boolean) => ReactNode;
  formatDisplayValue?: (value: string) => string;
  className?: string;
  loading?: boolean;
  truncateTimeout?: number;
  onLazyLoad?: () => Promise<any>;
  showClearIcon?: boolean;
  dropdownProps?: Omit<
    IDropdownProps,
    OmitDropdownProps | 'anchor' | 'content'
  >;
  translations?: ISelectTranslations;
};

export type ISelectProps = ISelectBaseProps &
  (ISingleSelectProps | IMultiSelectProps);

export type ISingleSelectProps = {
  multiple?: false;
  value: ISelectOption | undefined;
  onChange: ISingleSelectOnChange;
};

export type IMultiSelectProps = {
  multiple: true;
  value: ISelectOption[];
  onChange: IMultiSelectOnChange;
  tags?: boolean;
};

export type ISingleSelectOnChange = (option: ISelectOption) => void;

export type IMultiSelectOnChange = (
  options: ISelectOption[],
  selectedOption?: ISelectOption
) => void;

export type ISelectContext = ISelectBaseContext & ISelectProps;

export type ISelectBaseContext = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  toggleOpen: () => void;
  localValue: ISelectOption[];
  handleClear?: (e?: MouseEvent) => void;
  search?: boolean;
  footer?: ReactNode;

  onLazyLoad?: () => Promise<any>;
};

export type ISelectContentViewModel = {
  getContent: () => ReactNode;
  searchValue: string;
  handleSearch: (value: string) => void;
  handleClearSearch: () => void;
};

export type ISelectOptionTooltip = Omit<ITooltipProps, 'anchor'>;

export type ISelectOptionProps = {
  option: ISelectOption;
  index: number;
  active: boolean;
  selected: boolean;
  handleSelect: (index: number) => void;
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => Record<string, unknown>;
  style?: CSSProperties;
  onRenderOption?: (option: ISelectOption) => ReactNode;
};

export type ISelectSize = 's' | 'm' | 'l';

export type ISelectVariant = 'light' | 'dark';

export type ISelectTranslations = {
  emptyContent?: string;
};

export type IExtendSelectProps = Omit<
  ISelectProps,
  'value' | 'options' | 'onChange' | 'multiple'
>;
