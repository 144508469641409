import './index.css';
import { IBadgeProps, IBadgeSize } from './types';
import { clsx } from 'clsx';
import { Typography } from '../Typography';
import { ITypographyVariant } from '../Typography/types';

export const Badge = (props: IBadgeProps) => {
  const {
    ariaLabel,
    badge,
    content,
    className,
    color = 'error',
    size = 's',
    hide = false,
  } = props;

  const rootClass = clsx(
    'analog-badge__container',
    `analog-badge__badge--${color}`,
    `analog-badge__badge--${size}`,
    className
  );

  const textSizeMap: Record<IBadgeSize, ITypographyVariant> = {
    xs: 'subtitle',
    s: 'subtitle',
    m: 'subtitle',
    l: 'subtitle',
  };

  return (
    <div className={rootClass}>
      {content}
      {!hide && (
        <div
          className="analog-badge__badge"
          aria-live="polite"
          aria-label={ariaLabel || `Badge: ${badge}`}
        >
          <Typography variant={textSizeMap[size]} text={badge} />
        </div>
      )}
    </div>
  );
};
