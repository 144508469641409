import { useViewModel } from './useViewModel';
import { Table } from '@cycling-web/analog-ui';

export const TeamMappingTable = () => {
  const {
    columns,
    items,
    loading,
    emptyStateProps,
    onRenderCell,
    assignRoleDialog,
  } = useViewModel();

  return (
    <>
      <Table
        columns={columns}
        items={items}
        loading={loading}
        skeletonCount={4}
        emptyState={emptyStateProps}
        onRenderCell={onRenderCell}
      />
      {assignRoleDialog}
    </>
  );
};
