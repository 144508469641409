import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { ChartTooltip } from '../components/ChartTooltip';
import { IChartTooltipBaseProps } from '../types';
import { CallbackDataParams } from 'echarts/types/dist/shared';

export const chartTooltipFormatter = (props: IChartTooltipBaseProps) => {
  return (params: CallbackDataParams[]) => {
    return ReactDOMServer.renderToString(
      <ChartTooltip {...props} params={params} />
    );
  };
};
