import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useRoutes } from 'react-router';
import './App.css';
import './ms/app-insights';
import { IMenuBaseOption, ToastContainer } from '@cycling-web/analog-ui';
import { routes } from './router';
import { ErrorBoundary } from 'react-error-boundary';
import {
  AuthContext,
  BackendErrorOverlay,
  IAuthContext,
  PageSpinner,
} from '@cycling-web/common';
import { useUsersStore } from './store/users/slice';
import { useTranslation } from 'react-i18next';
import { ROUTES } from './router/routes';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useAppStore } from './store/app/slice';
import { SUPPORT_EMAIL } from './constants';
import { Cookies } from './pages/Legal/components/Cookies';
import { CookieContextProvider } from './pages/Legal/context/cookieContext';

export const App = () => {
  const { t } = useTranslation();
  const router = useRoutes(routes);
  const user = useUsersStore((s) => s.userProfile);
  const userLoaded = useUsersStore((s) => s.userProfileLoaded);
  const navigate = useNavigate();
  const [msalReady, setMsalReady] = useState<boolean>(false);
  const { instance, inProgress } = useMsal();
  const error = useAppStore((s) => s.error);
  const loading = useAppStore((s) => s.loading);
  const showCookiesBanner = useAppStore((s) => s.showCookiesBanner);

  useEffect(() => {
    if (inProgress === InteractionStatus.Startup) {
      return;
    }

    instance
      .handleRedirectPromise()
      .then((result) => {
        if (result) {
          instance.setActiveAccount(result.account);
        }
      })
      .finally(() => {
        setMsalReady(true);
      });
  }, [inProgress, instance]);

  const authContext: IAuthContext = useMemo((): IAuthContext => {
    return {
      user,
      userLoaded,
      userMenuOptions: [
        {
          id: 'teams',
          text: t('label.teams'),
        },
        {
          id: 'legal',
          text: t('label.legal'),
        },
        {
          id: 'support',
          text: t('label.support'),
        },
        {
          id: 'settings',
          text: t('label.settings'),
        },
      ],
      handleUserMenuChange: (option: IMenuBaseOption) => {
        if (option.id === 'teams') {
          navigate(`/${ROUTES.TEAMS}`);
        }
        if (option.id === 'legal') {
          window.open(`/${ROUTES.LEGAL}`, '_blank');
          return;
        }
        if (option.id === 'settings') {
          const baseUrl = window.location.pathname
            .split('/')
            .filter((_, i: number) => i < 3)
            .join('/');
          navigate(`${baseUrl}/${ROUTES.SETTINGS}`);
        }
        if (option.id === 'support') {
          window.location.href = `mailto:${SUPPORT_EMAIL}`;
        }
      },
      handleSignOut: () => {
        navigate(`/${ROUTES.SIGN_OUT}`);
      },
    };
  }, [navigate, t, user, userLoaded]);

  const appLoading = loading || !msalReady;

  return (
    <AuthContext.Provider value={authContext}>
      <ErrorBoundary fallback={<div />}>
        <CookieContextProvider>
          <div className="app">
            {msalReady && router}
            <ToastContainer />
            {error && (
              <BackendErrorOverlay
                error={error}
                onDismiss={() => {
                  useAppStore.getState().setError(null);
                }}
              />
            )}
            {appLoading && <PageSpinner />}
            {showCookiesBanner && <Cookies />}
          </div>
        </CookieContextProvider>
      </ErrorBoundary>
    </AuthContext.Provider>
  );
};
