import './index.css';
import { useFetchMedicalReports } from './hooks/useFetchMedicalReports';
import { MedicalReportsFilters } from './components/MedicalReportsFilters';
import { MedicalReportsTable } from './components/MedicalReportsTable';
import { useUserRole } from '../../hooks/useUserRole';

export const AthleteMedicalReports = () => {
  const { isAdmin, isDoctor, isSecondaryDoctor, isPhysiotherapist } =
    useUserRole();
  const showMedicalFilters =
    isAdmin || isDoctor || isSecondaryDoctor || isPhysiotherapist;
  useFetchMedicalReports();

  return (
    <div className="medical-report-records-page">
      {showMedicalFilters && <MedicalReportsFilters />}
      <MedicalReportsTable />
    </div>
  );
};
