import React, { useCallback, useMemo } from 'react';
import './index.css';
import { useAthletePerformanceStore } from '../../store/slice';
import {
  IAthletePerformanceFilters,
  IAthletePowerProfileFilters,
} from '../../types';
import { IPeak } from '../../../../types/performance';
import { useTranslation } from 'react-i18next';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { DataTypeFilter } from '../../../../components/filters/DataTypeFilter';
import { AnyValue, DataType } from '../../../../types/common';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import {
  Chart,
  ChartColor,
  ChartLegendItem,
  chartTooltipFormatter,
  IChartRadarIndicatorConfig,
  IEChartOption,
  IEChartRadarIndicator,
} from '../../../../components/Chart';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../constants/charts';
import { defaultTooltip } from '../../../../components/Chart/constants/defaults';
import { Typography } from '@cycling-web/analog-ui';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';

type IMMPObject = {
  MMP10Sec: keyof IPeak;
  MMP30Sec: keyof IPeak;
  MMP1Min: keyof IPeak;
  MMP5Min: keyof IPeak;
  MMP10Min: keyof IPeak;
  MMP20Min: keyof IPeak;
  MMP30Min: keyof IPeak;
  MMP60Min: keyof IPeak;
};

type IProps = {
  data: IPeak[];
  loading?: boolean;
};

export const PowerProfileChart = ({ data, loading }: IProps) => {
  const { t } = useTranslation();

  const setFilters = useAthletePerformanceStore((s) => s.setFilters);
  const filters = useAthletePerformanceStore((s) => s.filters.powerProfile);
  const handleChangeFilter = useCallback(
    (key: keyof IAthletePowerProfileFilters) => {
      return (value: AnyValue) => {
        setFilters({
          powerProfile: {
            [key]: value,
          },
        } as Partial<IAthletePerformanceFilters>);
      };
    },
    [setFilters]
  );

  const peaks = useAthletePerformanceStore((s) => s.peak);
  const scores1 = peaks[filters.period1] ? peaks[filters.period1][0] : null;
  const scores2 = peaks[filters.period2] ? peaks[filters.period2][0] : null;

  const currentMetric = useMemo((): IMMPObject => {
    return {
      MMP10Sec: `maximumOfMmp10Seconds${filters.dataType}`,
      MMP30Sec: `maximumOfMmp30Seconds${filters.dataType}`,
      MMP1Min: `maximumOfMmp1Minutes${filters.dataType}`,
      MMP5Min: `maximumOfMmp5Minutes${filters.dataType}`,
      MMP10Min: `maximumOfMmp10Minutes${filters.dataType}`,
      MMP20Min: `maximumOfMmp20Minutes${filters.dataType}`,
      MMP30Min: `maximumOfMmp30Minutes${filters.dataType}`,
      MMP60Min: `maximumOfMmp60Minutes${filters.dataType}`,
    };
  }, [filters.dataType]);

  const source = useMemo(() => {
    const source: (string | number | null)[][] = [
      [
        ChartAxisName.PowerProfile60m,
        ChartAxisName.PowerProfile30m,
        ChartAxisName.PowerProfile20m,
        ChartAxisName.PowerProfile10m,
        ChartAxisName.PowerProfile5m,
        ChartAxisName.PowerProfile1m,
        ChartAxisName.PowerProfile30s,
        ChartAxisName.PowerProfile10s,
      ],
    ];

    if (scores1) {
      source.push([
        scores1[currentMetric.MMP60Min] || 0,
        scores1[currentMetric.MMP30Min] || 0,
        scores1[currentMetric.MMP20Min] || 0,
        scores1[currentMetric.MMP10Min] || 0,
        scores1[currentMetric.MMP5Min] || 0,
        scores1[currentMetric.MMP1Min] || 0,
        scores1[currentMetric.MMP30Sec] || 0,
        scores1[currentMetric.MMP10Sec] || 0,
      ]);
    }

    if (scores2) {
      source.push([
        scores2[currentMetric.MMP60Min] || 0,
        scores2[currentMetric.MMP30Min] || 0,
        scores2[currentMetric.MMP20Min] || 0,
        scores2[currentMetric.MMP10Min] || 0,
        scores2[currentMetric.MMP5Min] || 0,
        scores2[currentMetric.MMP1Min] || 0,
        scores2[currentMetric.MMP30Sec] || 0,
        scores2[currentMetric.MMP10Sec] || 0,
      ]);
    }

    return source;
  }, [scores1, scores2, currentMetric]);

  const getMaxValue = useCallback(
    (key: keyof IPeak): number => {
      const val1 = scores1?.[key] || 0;
      const val2 = scores2?.[key] || 0;
      const max = Math.max(val1, val2);
      return filters.dataType === DataType.Relative
        ? Math.round(max * 100) / 100
        : Math.round(max);
    },
    [filters.dataType, scores1, scores2]
  );

  const indicators = useMemo((): IEChartRadarIndicator[] => {
    const RadarLabelColors = {
      mmp10Seconds: '#54CDDE',
      mmp30Seconds: '#54CDDE',
      mmp1Minute: '#42A5F5',
      mmp5Minutes: '#42A5F5',
      mmp10Minutes: '#42A5F5',
      mmp20Minutes: '#42A5F5',
      mmp30Minutes: '#0056FF',
      mmp60Minutes: '#0056FF',
    };

    return [
      {
        name: ChartAxisName.PowerProfile60m,
        max: getMaxValue(currentMetric.MMP60Min),
        color: RadarLabelColors.mmp60Minutes,
      },
      {
        name: ChartAxisName.PowerProfile30m,
        max: getMaxValue(currentMetric.MMP30Min),
        color: RadarLabelColors.mmp30Minutes,
      },
      {
        name: ChartAxisName.PowerProfile20m,
        max: getMaxValue(currentMetric.MMP20Min),
        color: RadarLabelColors.mmp20Minutes,
      },
      {
        name: ChartAxisName.PowerProfile10m,
        max: getMaxValue(currentMetric.MMP10Min),
        color: RadarLabelColors.mmp10Minutes,
      },
      {
        name: ChartAxisName.PowerProfile5m,
        max: getMaxValue(currentMetric.MMP5Min),
        color: RadarLabelColors.mmp5Minutes,
      },
      {
        name: ChartAxisName.PowerProfile1m,
        max: getMaxValue(currentMetric.MMP1Min),
        color: RadarLabelColors.mmp1Minute,
      },
      {
        name: ChartAxisName.PowerProfile30s,
        max: getMaxValue(currentMetric.MMP30Sec),
        color: RadarLabelColors.mmp30Seconds,
      },
      {
        name: ChartAxisName.PowerProfile10s,
        max: getMaxValue(currentMetric.MMP10Sec),
        color: RadarLabelColors.mmp10Seconds,
      },
    ];
  }, [currentMetric, getMaxValue]);

  const parseFilteredValue = useCallback(
    (value: number): string => {
      return filters.dataType === DataType.Relative
        ? `${value.toFixed(2)} ${t('units.w')}/${t('units.kg')}`
        : `${value} ${t('units.w')}`;
    },
    [filters.dataType, t]
  );

  const onRenderTooltipValue = useCallback(
    (value: number) => {
      return parseFilteredValue(value);
    },
    [parseFilteredValue]
  );

  const parseLastNDays = useCallback(
    (value: number): string => {
      const days = Number(value) / (24 * 60 * 60 * 1000);
      return days === 7
        ? t('label.last_week')
        : t('label.last_n_days', {
            d: days,
          });
    },
    [t]
  );

  const onRenderTooltipHeader = useCallback(
    (series: AnyValue) => {
      return parseLastNDays(series.seriesName);
    },
    [parseLastNDays]
  );

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      radar: {
        indicator: indicators,
        startAngle: 135,
        splitLine: {
          lineStyle: {
            color: ChartColor.GridLine,
            type: 'dashed',
          },
        },
        axisLine: {
          lineStyle: {
            color: ChartColor.GridLine,
          },
        },
        splitArea: {
          show: false,
        },
        axisName: {
          show: false,
        },
      },
      dataset: {
        source: source,
      },
      series: [
        {
          name: filters.period1,
          type: 'radar',
          data: [
            {
              value: source[1],
            },
          ],
          itemStyle: {
            color: ChartColor.Blue,
          },
          areaStyle: {
            color: ChartColor.Blue30,
          },
        },
        {
          name: filters.period2,
          type: 'radar',
          data: [
            {
              value: source[2],
            },
          ],

          itemStyle: {
            color: ChartColor.LightBlue,
          },
          areaStyle: {
            color: ChartColor.LightBlue30,
          },
        },
      ],
      tooltip: {
        ...defaultTooltip,
        trigger: 'item',
        axisPointer: undefined,
        formatter: chartTooltipFormatter({
          onRenderHeader: onRenderTooltipHeader,
          onRenderValue: onRenderTooltipValue,
          source,
        }),
      },
      legend: {
        show: false,
        data: [filters.period1, filters.period2],
        top: 'bottom',
      },
    };
  }, [
    indicators,
    source,
    filters.period1,
    filters.period2,
    onRenderTooltipHeader,
    onRenderTooltipValue,
  ]);

  const filtersBarProps = {
    filters: (
      <>
        <DataTypeFilter
          options={[DataType.Absolute, DataType.Relative, DataType.Composite]}
          value={filters.dataType}
          onChange={handleChangeFilter('dataType')}
          selectProps={{ variant: 'light' }}
        />
        <LastNDaysFilter
          value={filters.period1}
          onChange={handleChangeFilter('period1')}
          selectProps={{ variant: 'light' }}
        />
        <LastNDaysFilter
          value={filters.period2}
          onChange={handleChangeFilter('period2')}
          selectProps={{ variant: 'light' }}
        />
      </>
    ),
    collapse: true,
  };

  const onRenderLegendItem = useCallback(
    (item) => {
      const name = parseLastNDays(item.name);
      return (
        <ChartLegendItem
          item={{
            ...item,
            name,
          }}
        />
      );
    },
    [parseLastNDays]
  );

  const onRenderIndicator = useCallback(
    (item: IEChartRadarIndicator, config?: IChartRadarIndicatorConfig) => {
      return (
        <div
          className="power-profile__radar-indicator"
          style={{
            flexDirection:
              config && config.angle > 180 && config.angle < 360
                ? 'column'
                : 'column-reverse',
          }}
        >
          <div style={{ color: item.color }}>{item.name}</div>
          <Typography
            variant="caption"
            text={item.max !== undefined && parseFilteredValue(item.max)}
          />
        </div>
      );
    },
    [parseFilteredValue]
  );

  return (
    <ChartWrapper>
      <Chart
        headerProps={{
          title: t('label.power_profile'),
          filtersBarProps,
        }}
        legendProps={{
          show: true,
          onRenderItem: onRenderLegendItem,
          deps: [filters.period1, filters.period2],
        }}
        radarProps={{
          onRenderIndicator: onRenderIndicator,
          indicatorOffset: {
            x: 30,
            y: 30,
          },
        }}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.AthletePowerProfile,
          }),
        }}
        option={option}
        loading={loading}
      />
    </ChartWrapper>
  );
};
