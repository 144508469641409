import './index.css';
import { IFileUploaderProps } from './types';
import {
  ChangeEvent,
  DragEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { clsx } from 'clsx';
import { Typography } from '../Typography';
import { FileUp } from 'lucide-react';
import { Attachment } from './Attachment';

export const FileUploader = (props: IFileUploaderProps) => {
  const {
    className,
    maxFiles = 1,
    maxFileSize,
    accept,
    onChange,
    showAttachments = true,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    addFiles(droppedFiles);
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    addFiles(selectedFiles);

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleUploadFiles = () => {
    inputRef.current?.click();
  };

  const handleRemoveFile = useCallback((fileToRemove?: File) => {
    setFiles((prevFiles) => {
      return prevFiles.filter((file) => {
        return (
          file.name !== fileToRemove?.name ||
          file.lastModified !== fileToRemove?.lastModified ||
          file.size !== fileToRemove?.size
        );
      });
    });
  }, []);

  const addFiles = (newFiles: File[]) => {
    setError(null);

    const validFiles = newFiles
      .filter((file) => {
        return !(maxFileSize && file.size > maxFileSize);
      })
      .filter((file) => {
        if (accept) {
          const acceptedTypes = accept.split(',').map((type) => type.trim());
          const isValidType = acceptedTypes.some((type) => {
            if (type.startsWith('.')) {
              return file.name.toLowerCase().endsWith(type.toLowerCase());
            } else {
              return file.type === type;
            }
          });

          if (!isValidType) {
            return false;
          }
        }
        return true;
      })
      .filter((_, i: number) => {
        return !maxFiles || i < maxFiles;
      });

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  useEffect(() => {
    if (onChange) {
      onChange(files);
    }
  }, [files, onChange]);

  const rootClass = clsx('analog-file-uploader', className);
  const dropzoneText = 'Drop files here or';
  const buttonText = 'browse files';

  const attachmentsJSX = files.map((file: File, i: number) => {
    return <Attachment key={i} file={file} onRemove={handleRemoveFile} />;
  });

  return (
    <div className={rootClass}>
      <div
        className="analog-file-uploader__dropzone"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <input
          ref={inputRef}
          className="analog-file-uploader__input"
          type="file"
          accept={accept}
          multiple={!!maxFiles && maxFiles > 1}
          onChange={handleFileSelect}
        />
        <Typography color="text-secondary" text={dropzoneText} />
        <button
          className="analog-file-uploader__button analog-typography--body"
          onClick={handleUploadFiles}
        >
          {buttonText}
        </button>
        <FileUp className="analog-file-uploader__icon" size={20} />
      </div>

      {error && <div className="analog-file-uploader__error">{error}</div>}

      {showAttachments && files.length > 0 && (
        <div className="analog-file-uploader__files">{attachmentsJSX}</div>
      )}
    </div>
  );
};
