import { ROUTES } from '../../../router/routes';
import { useMemo } from 'react';

export type INavigationLink =
  | 'terms_and_conditions'
  | 'privacy_notice'
  | 'cookies_notice'
  | 'glossary';

export const useNavigationLinks = () => {
  return useMemo((): Record<INavigationLink, string> => {
    return {
      terms_and_conditions: `/${ROUTES.LEGAL}/${ROUTES.TERMS_AND_CONDITIONS}`,
      privacy_notice: `/${ROUTES.LEGAL}/${ROUTES.PRIVACY_NOTICE}`,
      cookies_notice: `/${ROUTES.LEGAL}/${ROUTES.COOKIES_NOTICE}`,
      glossary: `/${ROUTES.LEGAL}/${ROUTES.GLOSSARY}`,
    };
  }, []);
};
