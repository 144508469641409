import TrainingPeaksLogo from '../../../assets/illustrations/training-peaks-logo.png';
import { Platform } from '@cycling-web/common';
import { OnboardingIntegrationItem } from '../IntegrationItem';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Skeleton, Toast, useBoolean } from '@cycling-web/analog-ui';
import { ConsentDialog } from '../ConsentDialog';
import { ConnectedDialog } from '../ConnectedDialog';
import { PlatformRepository } from '../../../api/platform/repository';
import { PlatformService } from '../../../api/platform/service';
import { LS } from '../../../constants';
import { getIntegrationsLink } from '../../../utils/getIntegrationsLink';
import { IPlatform } from '../../../types/platform';
import { usePlatformsStore } from '../../../store/platforms/slice';

type IProps = {
  platform: IPlatform;
  platformLoaded: boolean;
  onConnect: () => void;
  onDisconnect?: () => void;
  showDetails?: boolean;
};

export const TrainingPeaks = ({
  platform,
  platformLoaded,
  onConnect,
  onDisconnect,
  showDetails,
}: IProps) => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const code = params.get('code');
  const platformParam = params.get('platform');

  const fetchingRef = useRef<boolean>(false);
  const isConsentGiven = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const {
    value: showConsentDialog,
    setTrue: openConsentDialog,
    setFalse: dismissConsentDialog,
  } = useBoolean(false);

  const {
    value: showConnectedDialog,
    setTrue: openConnectedDialog,
    setFalse: dismissConnectedDialog,
  } = useBoolean(false);

  const platformRepository = useMemo(() => {
    return new PlatformRepository(new PlatformService());
  }, []);

  const connectTrainingPeaks = useCallback((): Promise<void> => {
    if (!code || fetchingRef.current) {
      return Promise.reject();
    }

    fetchingRef.current = true;
    setLoading(true);

    return platformRepository
      .addTrainingPeaksAccount({
        code,
      })
      .then(() => {
        onConnect();
        openConnectedDialog();
      })
      .catch((e) => {
        setError(true);
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
        fetchingRef.current = false;
      });
  }, [code, platformRepository, onConnect, openConnectedDialog]);

  useEffect(() => {
    if (platformParam === Platform.TrainingPeaks) {
      connectTrainingPeaks();
    }
  }, [connectTrainingPeaks, platformParam]);

  const onConnectPlatform = useCallback(() => {
    if (code) {
      localStorage.removeItem(LS.PreviousPage);
      connectTrainingPeaks();
    } else {
      const trainingPeaksUrl = getIntegrationsLink({
        integration: Platform.TrainingPeaks,
        redirectUrl: `${window.location.origin}/integrations`,
      });
      localStorage.setItem(
        LS.PreviousPage,
        `${window.location.href}?platform=${Platform.TrainingPeaks}`
      );
      window.open(trainingPeaksUrl);
    }
  }, [connectTrainingPeaks, code]);

  const handleConnectPlatform = useCallback(() => {
    if (isConsentGiven.current || code) {
      onConnectPlatform();
    } else {
      openConsentDialog();
    }
  }, [onConnectPlatform, openConsentDialog, code]);

  const handleGiveConsent = useCallback((): Promise<void> => {
    dismissConsentDialog();
    onConnectPlatform();
    return Promise.resolve();
  }, [dismissConsentDialog, onConnectPlatform]);

  const handleDisconnect = useCallback(() => {
    usePlatformsStore.getState().disconnect(platform);
    platformRepository
      .removeTrainingPeaksAccount()
      .then(() => {
        if (onDisconnect) {
          onDisconnect();
        }
      })
      .catch(() => {
        Toast.error({
          title: t('error.disconnect_app_title'),
        });
        usePlatformsStore.getState().setConnected(platform);
      });
  }, [platform, platformRepository, onDisconnect, t]);

  return (
    <>
      {code || platformLoaded ? (
        <OnboardingIntegrationItem
          icon={
            <img
              className="onboarding-platforms__item-img"
              src={TrainingPeaksLogo}
              alt={t('label.training_peaks')}
            />
          }
          type="platform"
          title={t('label.training_peaks')}
          handleIntegration={handleConnectPlatform}
          loading={loading}
          error={error}
          platform={platform}
          handleDisconnect={handleDisconnect}
          showDetails={showDetails}
        />
      ) : (
        <Skeleton width="100%" height="111.5px" />
      )}
      {showConsentDialog && (
        <ConsentDialog
          onDismiss={dismissConsentDialog}
          onSubmit={handleGiveConsent}
        />
      )}
      {showConnectedDialog && (
        <ConnectedDialog
          integrationName={t('label.training_peaks')}
          onDismiss={dismissConnectedDialog}
        />
      )}
    </>
  );
};
