import { forwardRef } from 'react';
import './index.css';
import { IDatepickerContext, IDatepickerProps } from './types';
import { DatepickerToggle } from './DatepickerToggle';
import { useViewModel } from './useViewModel';
import { DatepickerContext } from './context';
import { Calendar } from '../Calendar';
import { clsx } from 'clsx';
import { Dropdown } from '../Dropdown';

export const Datepicker = forwardRef<HTMLDivElement, IDatepickerProps>(
  (props, ref) => {
    const context: IDatepickerContext = useViewModel(props);
    const {
      min,
      max,
      onOpenChange,
      className,
      onRenderAnchor,
      value,
      onChange,
      range = false,
      innerRange,
      currentInnerRangePointer,
      size = 'm',
      variant = 'dark',
      dropdownProps,
      markers = [],
    } = context;
    const rootClass = clsx(
      'analog-datepicker',
      `analog-datepicker--${size}`,
      `analog-datepicker--${variant}`,
      className
    );

    const anchor = onRenderAnchor ? (
      onRenderAnchor(dropdownProps?.isOpen)
    ) : (
      <DatepickerToggle ref={ref} />
    );

    return (
      <DatepickerContext.Provider value={context}>
        <div className={rootClass}>
          <Dropdown
            {...dropdownProps}
            isOpen={dropdownProps?.isOpen}
            setIsOpen={onOpenChange}
            anchor={anchor}
            portal={dropdownProps?.portal || true}
            minWidth={dropdownProps?.minWidth || '312px'}
            minHeight={dropdownProps?.minHeight || '364px'}
            content={
              // @ts-ignore
              <Calendar
                value={value}
                onChange={onChange}
                min={min}
                max={max}
                isOpen={dropdownProps?.isOpen}
                onOpenChange={onOpenChange}
                range={range}
                innerRange={innerRange}
                currentInnerRangePointer={currentInnerRangePointer}
                markers={markers}
              />
            }
          />
        </div>
      </DatepickerContext.Provider>
    );
  }
);

Datepicker.displayName = 'Datepicker';
