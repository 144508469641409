import { useCallback, useMemo, useRef } from 'react';
import { Skeleton, Toast, useBoolean } from '@cycling-web/analog-ui';
import { OnboardingIntegrationItem } from '../IntegrationItem';
import { ConsentDialog } from '../ConsentDialog';
import UltraHumanLogo from '../../../assets/illustrations/ultrahuman-logo.svg?react';
import { UltraHumanModal } from '../UltraHumanModal';
import { IPlatform } from '../../../types/platform';
import { usePlatformsStore } from '../../../store/platforms/slice';
import { PlatformRepository } from '../../../api/platform/repository';
import { PlatformService } from '../../../api/platform/service';
import { useUsersStore } from '../../../store/users/slice';
import { useTranslation } from 'react-i18next';

type IProps = {
  platform: IPlatform;
  platformLoaded: boolean;
  onConnect: () => void;
  onDisconnect?: () => void;
  showDetails?: boolean;
};

export const UltraHuman = ({
  platform,
  platformLoaded,
  onConnect,
  onDisconnect,
  showDetails,
}: IProps) => {
  const { t } = useTranslation();
  const isConsentGiven = useRef<boolean>(false);
  const userProfile = useUsersStore((s) => s.userProfile);

  const platformRepository = useMemo(() => {
    return new PlatformRepository(new PlatformService());
  }, []);

  const {
    value: showUltrahumanModal,
    setTrue: openUltrahumanModal,
    setFalse: dismissUltrahumanModal,
  } = useBoolean(false);

  const {
    value: showConsentDialog,
    setTrue: openConsentDialog,
    setFalse: dismissConsentDialog,
  } = useBoolean(false);

  const handleConnect = useCallback(() => {
    isConsentGiven.current = true;
    openUltrahumanModal();
  }, [openUltrahumanModal]);

  const handleIntegration = useCallback(() => {
    if (isConsentGiven.current) {
      handleConnect();
    } else {
      openConsentDialog();
    }
  }, [handleConnect, openConsentDialog]);

  const handleGiveConsent = useCallback((): Promise<void> => {
    dismissConsentDialog();
    handleConnect();
    return Promise.resolve();
  }, [dismissConsentDialog, handleConnect]);

  const handleDisconnect = useCallback(() => {
    if (!userProfile) {
      return;
    }

    usePlatformsStore.getState().disconnect(platform);
    platformRepository
      .deleteUltrahumanAccount({
        email: userProfile.email,
      })
      .then(() => {
        if (onDisconnect) {
          onDisconnect();
        }
      })
      .catch(() => {
        Toast.error({
          title: t('error.disconnect_device_title'),
        });
        usePlatformsStore.getState().setConnected(platform);
      });
  }, [t, onDisconnect, platform, platformRepository, userProfile]);

  return (
    <>
      {platformLoaded ? (
        <OnboardingIntegrationItem
          icon={<UltraHumanLogo />}
          handleIntegration={handleIntegration}
          platform={platform}
          showDetails={showDetails}
          handleDisconnect={handleDisconnect}
        />
      ) : (
        <Skeleton width="100%" height="88px" />
      )}
      {showConsentDialog && (
        <ConsentDialog
          onDismiss={dismissConsentDialog}
          onSubmit={handleGiveConsent}
        />
      )}
      {showUltrahumanModal && (
        <UltraHumanModal
          onDismiss={dismissUltrahumanModal}
          onCompleteIntegration={onConnect}
        />
      )}
    </>
  );
};
