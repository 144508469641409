import './index.css';
import {
  Dialog,
  FormElement,
  Textarea,
  Toast,
  Typography,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { MedicalRepository } from '../../../../api/medical/repository';
import { MedicalService } from '../../../../api/medical/service';
import { IMedicalNote } from '../../../../types/medical';
import { useAthleteMedicalReportStore } from '../../store/slice';
import { useUsersStore } from '../../../../store/users/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../../constants';

type IProps = {
  onDismiss: () => void;
};

export const NoteModal = ({ onDismiss }: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const athleteReport = useAthleteMedicalReportStore((s) => s.report);
  const appendNote = useAthleteMedicalReportStore((s) => s.appendNote);
  const userProfile = useUsersStore((s) => s.userProfile);

  const [inputValue, setInputValue] = useState<string>('');

  const onInputChange = useCallback((value: string) => {
    setInputValue(value);
  }, []);

  const medicalRepository = useMemo(() => {
    return new MedicalRepository(new MedicalService());
  }, []);

  const handleSend = useCallback(() => {
    if (!athleteReport || !userProfile) {
      return;
    }
    setLoading(true);
    const payload = {
      medicalReportId: athleteReport.id,
      note: inputValue,
      createdBy:
        userProfile.fullName ||
        `${userProfile.firstName} ${userProfile.lastName}`,
      editable: false,
    };

    medicalRepository
      .createNote(payload)
      .then((note: IMedicalNote) => {
        appendNote(note);
        onDismiss();
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.send_note_title'),
              message: t('error.send_note_message'),
            },
            { toastId: 'send_note' }
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    onDismiss,
    athleteReport,
    userProfile,
    medicalRepository,
    inputValue,
    appendNote,
    t,
  ]);

  const content = (
    <div className="note-modal__popover">
      <div className="note-modal__hint">
        <Typography weight="bold" text={t('label.subjective')} />
        <Typography weight="regular" text={t('label.subjective_description')} />
      </div>
      <div className="note-modal__hint">
        <Typography weight="bold" text={t('label.objective')} />
        <Typography weight="regular" text={t('label.objective_description')} />
      </div>
      <div className="note-modal__hint">
        <Typography weight="bold" text={t('label.assessment')} />
        <Typography weight="regular" text={t('label.assessment_description')} />
      </div>
      <div className="note-modal__hint">
        <Typography weight="bold" text={t('label.plan')} />
        <Typography weight="regular" text={t('label.plan_description')} />
      </div>
    </div>
  );

  return (
    <Dialog
      title={t('label.new_note')}
      onDismiss={onDismiss}
      submitButtonProps={{
        content: t('action.send'),
        onClick: handleSend,
        loading,
        disabled: !inputValue,
      }}
    >
      <FormElement label={t('label.note')} tooltipProps={{ content }}>
        <Textarea
          placeholder={t('placeholder.note')}
          value={inputValue}
          onChange={onInputChange}
        />
      </FormElement>
    </Dialog>
  );
};
