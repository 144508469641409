import { createContext } from 'react';

export type IGroupProfilesContext = {
  openAthleteDialog: () => void;
  dismissAthleteDialog: () => void;
};

export const GroupProfilesContext = createContext<IGroupProfilesContext>(
  {} as IGroupProfilesContext
);
