import { TeamsService } from './service';
import { IUserTeam } from '../../types/teams';
import { IAcceptTeamInvitationRequest } from './types';

/** The repository is responsible for data transformation */

export interface ITeamsRepository {
  getUserTeams: () => Promise<IUserTeam[]>;

  acceptInvitation: (p: IAcceptTeamInvitationRequest) => Promise<void>;
}

export class TeamsRepository implements ITeamsRepository {
  constructor(private readonly service: TeamsService) {}

  async getUserTeams(): Promise<IUserTeam[]> {
    const response = await this.service.getUserTeams();
    return response.data;
  }

  async acceptInvitation(p: IAcceptTeamInvitationRequest): Promise<void> {
    const response = await this.service.acceptInvitation(p);
    return response.data;
  }
}
