import './index.css';
import {
  Button,
  ConfirmDialog,
  Toast,
  useBoolean,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { ApiErrorCode } from '../../../../constants';
import { useCallback, useMemo } from 'react';
import { PlatformRepository } from '../../../../api/platform/repository';
import { PlatformService } from '../../../../api/platform/service';
import { AxiosError } from 'axios';

export const DeleteAccount = () => {
  const { t } = useTranslation();

  const platformRepository = useMemo(() => {
    return new PlatformRepository(new PlatformService());
  }, []);

  const {
    value: showConfirmEraseDialog,
    setTrue: openConfirmEraseDialog,
    setFalse: dismissConfirmEraseDialog,
  } = useBoolean(false);

  const submitAction = useCallback((): Promise<void> => {
    return platformRepository.eraseData().catch((error: AxiosError) => {
      if (error?.response?.status !== ApiErrorCode.Unauthorized) {
        Toast.error({
          title: t('error.delete_platform_data_title'),
          message: error.message || t('error.delete_platform_data_text'),
        });
      }
    });
  }, [platformRepository, t]);

  return (
    <div className="settings-profile__delete-account">
      <div className="settings-profile__delete-account-actions">
        <Button
          content={t('action.delete_my_account')}
          onClick={openConfirmEraseDialog}
        />
      </div>

      {showConfirmEraseDialog && (
        <ConfirmDialog
          onDismiss={dismissConfirmEraseDialog}
          title={t('confirm.delete_account_title')}
          subtitle={t('confirm.delete_account_message')}
          submitText={t('action.delete_my_account')}
          submitAction={submitAction}
        />
      )}
    </div>
  );
};
