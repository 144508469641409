import React, { useCallback } from 'react';
import { PageFilters } from '@cycling-web/common';
import { Button, useBoolean } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { InviteUserFormModal } from '../InviteUserFormModal';
import { AnyValue } from '../../../../types/common';
import {
  initialFilters,
  useAdminTeamMemberStore,
} from '../../../AdminPanel/store/slice';
import { IAdminTeamMemberFilters } from '../../../AdminPanel/types';
import { useURLFilters } from '../../../../hooks/useURLFilters';

export const TeamMembersFilters = () => {
  const { t } = useTranslation();
  const filters = useAdminTeamMemberStore((s) => s.filters);
  const setFilters = useAdminTeamMemberStore((s) => s.setFilters);

  const initFilters = useCallback(() => {
    const params = new URLSearchParams(window.location.search);

    useAdminTeamMemberStore.getState().setFilters({
      search: params.get('search') || initialFilters.search,
    });
  }, []);

  const resetFilters = useCallback(() => {
    useAdminTeamMemberStore.getState().setFilters(initialFilters);
  }, []);

  useURLFilters({ filters, initFilters, resetFilters });

  const handleChangeFilter = useCallback(
    (key: keyof IAdminTeamMemberFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  );

  const handleSearchLClear = useCallback(() => {
    setFilters({ search: '' });
  }, [setFilters]);

  const {
    value: showInviteUserModal,
    setTrue: openInviteUserModal,
    setFalse: dismissInviteUserModal,
  } = useBoolean(false);

  return (
    <>
      <PageFilters
        searchInputProps={{
          value: filters.search,
          onChange: handleChangeFilter('search'),
          onClear: handleSearchLClear,
        }}
        actionElements={
          <Button content={t('action.invite')} onClick={openInviteUserModal} />
        }
      />
      {showInviteUserModal && (
        <InviteUserFormModal onDismiss={dismissInviteUserModal} />
      )}
    </>
  );
};
