import './index.css';
import { countryCodesMapping } from '../../../../utils/country-codes-mapping';
import { useContext } from 'react';
import { AthleteCardContext } from '../../context';
import { Skeleton } from '@cycling-web/analog-ui';
import { AthleteCardMenu } from '../AthleteCardMenu';
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from '../../../../constants';

export const Picture = () => {
  const { athlete, loading } = useContext(AthleteCardContext);
  const countryAlpha2Code =
    athlete?.countryCode && countryCodesMapping[athlete.countryCode]
      ? countryCodesMapping[athlete?.countryCode]
      : 'AE';
  const flagUrl = `${DEFAULT_CDN_URL}${countryAlpha2Code.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;

  return (
    <div
      className="athlete-card__header"
      style={{ backgroundImage: `url(${athlete?.picture})` }}
    >
      {!loading && (
        <div className="athlete-card__header-header">
          <div className="athlete-card__header-country">
            <img
              className="athlete-card__header-flag"
              src={flagUrl}
              alt={athlete?.countryCode}
            />
          </div>
          <AthleteCardMenu />
        </div>
      )}
      <div className="athlete-card__header-transition" />
    </div>
  );
};
