import { AnyValue } from '../types/common';

function convertArrayOfObjectsToCSV(headers: AnyValue[], array: AnyValue[]) {
  let result: string;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = headers.map((header) => header.field);

  result = '';
  result += headers.map((header) => header.label).join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item: { [x: string]: any }) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      let value = item[key];

      if (typeof value === 'object' && value !== null) {
        value = JSON.stringify(value);
      }
      if (typeof value === 'string') {
        value = value.replace(/[\r\n]+/g, ' '); // Replacing newline characters with a space
      }
      result += value;

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(
  headers: AnyValue[],
  array: AnyValue[],
  name: string
) {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(headers, array);
  if (csv == null) return;

  const filename = name + '.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}
