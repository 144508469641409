import { useFetchChatHistory } from './hooks/useFetchChatHistory';
import { useAiAssistantStore } from './store/slice';
import { clsx } from 'clsx';
import { useEffect } from 'react';

export const useViewModel = () => {
  useFetchChatHistory();
  const isOpen = useAiAssistantStore((s) => s.isOpen);
  const isExpanded = useAiAssistantStore((s) => s.isExpanded);
  const reset = useAiAssistantStore((s) => s.reset);

  const rootClass = clsx(
    'ai-assistant',
    isOpen && 'ai-assistant--open',
    isExpanded && 'ai-assistant--expanded'
  );

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return {
    rootClass,
  };
};
