import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { Toast } from '@cycling-web/analog-ui';
import { useAdminTeamMappingsStore } from '../store/slice';
import { UsersRepository } from '../../../api/users/repository';
import { UsersService } from '../../../api/users/service';
import { ITeamMapping } from '../../../types/users';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchAdminTeamMappings = () => {
  const { t } = useTranslation();
  const fetching = useRef<boolean>(false);

  const setMappings = useAdminTeamMappingsStore((s) => s.setMappings);
  const setMappingsLoaded = useAdminTeamMappingsStore(
    (s) => s.setMappingsLoaded
  );

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  useEffect(() => {
    if (fetching.current) {
      return;
    }

    fetching.current = true;
    usersRepository
      .getTeamMappings()
      .then((mappings: ITeamMapping[]) => {
        setMappings(mappings);
      })
      .catch((error: AxiosError) => {
        setMappingsLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_users_title'),
              message: t('error.get_users_message'),
            },
            { toastId: 'get_users' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [setMappings, setMappingsLoaded, t, usersRepository]);
};
