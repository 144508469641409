import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { WellnessRepository } from '../../../api/wellness/repository';
import { WellnessService } from '../../../api/wellness/service';
import { IBodyComposition } from '../../../types/wellness';
import { Toast } from '@cycling-web/analog-ui';
import { useAthleteWellnessStore } from '../store/slice';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchAthleteBodyComposition = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const fetching = useRef<boolean>(false);
  const setBodyComposition = useAthleteWellnessStore(
    (s) => s.setBodyComposition
  );
  const setBodyCompositionLoaded = useAthleteWellnessStore(
    (s) => s.setBodyCompositionLoaded
  );
  const filters = useAthleteWellnessStore((s) => s.filters);

  const wellnessRepository = useMemo(() => {
    return new WellnessRepository(new WellnessService());
  }, []);

  const fetchBodyComposition = useCallback(() => {
    if (!athleteId || fetching.current) {
      return;
    }

    fetching.current = true;

    const { startDate, endDate } = calculateDateRange(filters.period);

    wellnessRepository
      .getBodyComposition({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteId: +athleteId,
      })
      .then((data: IBodyComposition) => {
        setBodyComposition(data);
      })
      .catch((error: AxiosError) => {
        setBodyCompositionLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_athlete_body_composition_title'),
              message: t('error.get_athlete_body_composition_message'),
            },
            { toastId: 'get_athlete_body_composition' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [
    athleteId,
    filters.period,
    setBodyComposition,
    setBodyCompositionLoaded,
    t,
    wellnessRepository,
  ]);

  useEffect(() => {
    fetchBodyComposition();
  }, [fetchBodyComposition]);

  return {
    fetchBodyComposition,
  };
};
