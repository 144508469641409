import './index.css';
import { CompoundScoreChart } from './components/CompoundScoreChart';
import { useViewModel } from './useViewModel';
import { PowerProfileChart } from './components/PowerProfileChart';
import { PowerDurationCurveChart } from './components/PowerDurationCurveChart';
import { TorqueDurationCurveChart } from './components/TorqueDurationCurveChart';
import { MeanMaxPowerChart } from './components/MeanMaxPowerChart';
import { DurabilityChart } from './components/DurabilityChart';

export const AthletePerformance = () => {
  const {
    filters,
    handleChangeFilter,
    peak,
    peakLoading,
    pdCurve,
    pdCurveRelative,
    pdCurveLoading,
    powerDerivatives,
    powerDerivativesLoading,
    athlete,
    fatigueResistance,
    fatigueResistanceRelative,
    fatigueResistanceLoading,
  } = useViewModel();

  return (
    <div className="performance-page">
      <div className="performance-page-charts">
        <div className="performance-page-charts__top">
          <div className="compound-score-chart-wrapper">
            <CompoundScoreChart
              data={peak}
              filters={filters}
              handleChangeFilter={handleChangeFilter}
              loading={peakLoading}
            />
          </div>
          <div className="power-profile-chart-wrapper">
            <PowerProfileChart data={peak} loading={peakLoading} />
          </div>
        </div>
        <PowerDurationCurveChart
          data={{
            absolute: pdCurve,
            relative: pdCurveRelative,
          }}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
          loading={pdCurveLoading}
        />
        <TorqueDurationCurveChart
          data={powerDerivatives}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
          loading={powerDerivativesLoading}
          athlete={athlete}
        />
        <MeanMaxPowerChart
          data={powerDerivatives}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
          loading={powerDerivativesLoading}
        />
        <DurabilityChart
          data={{
            absolute: fatigueResistance,
            relative: fatigueResistanceRelative,
          }}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
          loading={fatigueResistanceLoading}
        />
      </div>
    </div>
  );
};
