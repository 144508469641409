import './index.css';
import { OnboardingFooter } from '../Footer';
import { EmptyState } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useUsersStore } from '../../../../store/users/slice';
import { useParams } from 'react-router';
import { parseTeamName } from '../../../../utils/teamName';

export const OnboardingCompleted = () => {
  const { t } = useTranslation();
  const userProfile = useUsersStore((s) => s.userProfile);
  const { teamId } = useParams();
  const team = teamId ? parseTeamName(teamId) : 'your team';
  const firstName = userProfile
    ? userProfile.firstName || userProfile.fullName?.split(' ')[0]
    : t('label.admin');

  return (
    <>
      <EmptyState
        className="onboarding-completed__banner"
        title={t('banner.onboarding_completed_title', {
          name: firstName,
        })}
        text={t('banner.onboarding_completed_text', { team })}
      />
      <OnboardingFooter stepCompleted={false} />
    </>
  );
};
