import './index.css';
import { IPerformanceReport } from '../../../../types/performance';
import { EMPTY } from '../../../../constants';
import {
  Button,
  IconButton,
  TruncatedText,
  useBoolean,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { FeedbackFormModal } from '../FeedbackFormModal';
import { Pencil } from 'lucide-react';

type IProps = {
  report: IPerformanceReport;
};

export const Comment = ({ report }: IProps) => {
  const { t } = useTranslation();
  const {
    value: showFeedbackModal,
    setTrue: openFeedbackModal,
    setFalse: dismissFeedbackModal,
  } = useBoolean(false);

  if (!report.commentEnabled) {
    return (
      <TruncatedText
        text={report.comments || EMPTY}
        tooltipProps={{ maxWidth: '400px' }}
      />
    );
  }

  const commentWithButton = (
    <>
      <TruncatedText
        text={report.comments || EMPTY}
        tooltipProps={{ maxWidth: '400px' }}
      />
      <IconButton
        className="performance-table__comment-edit"
        size="xs"
        variant="quiet"
        content={<Pencil />}
        onClick={openFeedbackModal}
      />
    </>
  );

  return (
    <>
      {report.comments ? (
        commentWithButton
      ) : (
        <Button
          size="s"
          variant="secondary"
          content={t('action.add_comment')}
          onClick={openFeedbackModal}
        />
      )}
      {showFeedbackModal && (
        <FeedbackFormModal
          report={report}
          reports={[]}
          onDismiss={dismissFeedbackModal}
          singleReport={true}
        />
      )}
    </>
  );
};
