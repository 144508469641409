export interface IScoreCard {
  value: string;
  label: string;
  units: string;
  lastUpdatedAt?: string;
  source?: string;
}

export enum SCORE_TYPE {
  TP = 'TP',
  COACH = 'COACH',
}
