import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { useAthletesStore } from '../store/athletes/slice';
import { AthletesRepository } from '../api/athletes/repository';
import { AthletesService } from '../api/athletes/service';
import { IAthlete } from '../types/athletes';
import { Toast } from '@cycling-web/analog-ui';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../constants';

export const useFetchAthletes = () => {
  const { t } = useTranslation();
  const fetching = useRef<boolean>(false);
  const setAthletes = useAthletesStore((s) => s.setAthletes);
  const setAthletesLoaded = useAthletesStore((s) => s.setAthletesLoaded);
  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);

  const athletesRepository = useMemo(() => {
    return new AthletesRepository(new AthletesService());
  }, []);

  useEffect(() => {
    if (fetching.current) {
      return;
    }

    fetching.current = true;
    athletesRepository
      .getAthletes()
      .then((athletes: IAthlete[]) => {
        setAthletes(athletes);
      })
      .catch((error: AxiosError) => {
        setAthletesLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_athletes_title'),
              message: t('error.get_athletes_message'),
            },
            { toastId: 'get_athletes' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [setAthletesLoaded, athletesRepository, setAthletes, t]);

  return {
    athletes,
    athletesLoaded,
  };
};
