import { useAthletesStore } from '../store/athletes/slice';
import { useGroupsStore } from '../store/groups/slice';
import { useMemo } from 'react';
import { IAthlete } from '../types/athletes';

export const useGroupAthletes = () => {
  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);
  const group = useGroupsStore((s) => s.group);
  const loading = !athletesLoaded && !group;

  const groupAthletes = useMemo(() => {
    const athleteIds = group?.athleteIds || [];
    return athletes.filter((a: IAthlete) => athleteIds.includes(a.id));
  }, [athletes, group]);

  return {
    groupAthletes,
    loading,
  };
};
