import { AnyValue } from '../types/common';
import { useEffect } from 'react';
import qs, { BooleanOptional, IStringifyOptions } from 'qs';
import { Url } from '../utils/url';

const qsOptions: IStringifyOptions<BooleanOptional> = {
  skipNulls: true,
  arrayFormat: 'comma',
  filter: (_, value) => {
    if (Array.isArray(value) && value.length === 0) {
      return undefined;
    }

    if (value === '') {
      return undefined;
    }

    return value;
  },
};

type IProps = {
  filters: AnyValue;
  initFilters?: () => void;
  resetFilters?: () => void;
};

export const useURLFilters = ({
  filters,
  initFilters,
  resetFilters,
}: IProps) => {
  useEffect(() => {
    if (initFilters) {
      initFilters();
    }

    return () => {
      if (resetFilters) {
        resetFilters();
      }
    };
  }, [initFilters, resetFilters]);

  useEffect(() => {
    const qp = qs.stringify(filters, qsOptions);
    Url.replaceWithSearchParam(qp);
  }, [filters]);
};
