import './index.css';
import { PerformanceTableFilters } from './components/Filters';
import { PerformanceTable } from './components/PerformanceTable';

export const PerformanceReportTable = () => {
  return (
    <div className="performance-table-page">
      <PerformanceTableFilters />
      <PerformanceTable />
    </div>
  );
};
