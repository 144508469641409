import { EMPTY } from '../../../constants';
import { Tag } from '@cycling-web/analog-ui';
import { IUserBase } from '../../../types/users';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type IProps = {
  user: IUserBase | undefined;
};

export const UserStatus = ({ user }: IProps) => {
  const { t } = useTranslation();

  if (!user) {
    return EMPTY;
  }

  const status = user.status ? user.status.toLowerCase() : '';

  const map: Record<string, ReactNode> = {
    pending: <Tag text={t('label.pending')} variant="primary" shape="round" />,
    accepted: (
      <Tag text={t('label.accepted')} variant="success" shape="round" />
    ),
  };

  return map[status] || EMPTY;
};
