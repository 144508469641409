import { IUserTeam } from '../../../../types/teams';
import { Button, Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { TeamsRepository } from '../../../../api/teams/repository';
import { TeamsService } from '../../../../api/teams/service';
import { useTeamsStore } from '../../../../store/teams/slice';
import { Link } from 'react-router';
import { stringifyTeamName } from '../../../../utils/teamName';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../../constants';

type IProps = {
  team: IUserTeam;
};

export const Actions = ({ team }: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const acceptInvitation = useTeamsStore((s) => s.acceptInvitation);

  const teamsRepository = useMemo(() => {
    return new TeamsRepository(new TeamsService());
  }, []);

  const handleAcceptInvitation = useCallback(() => {
    setLoading(true);
    teamsRepository
      .acceptInvitation({
        teamName: team.tenantName,
      })
      .then(() => {
        acceptInvitation(team);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.accept_invitation_title'),
              message: t('error.accept_invitation_message'),
            },
            { toastId: 'accept_invitation' }
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [acceptInvitation, t, team, teamsRepository]);

  return team.invitationAccepted ? (
    <Link to={`/${stringifyTeamName(team.tenantName)}`}>
      <Button
        size="xs"
        variant="quietNeutral"
        content={t('action.go_to_team')}
      />
    </Link>
  ) : (
    <Button
      size="xs"
      variant="secondary"
      content={t('action.accept_invitation')}
      onClick={handleAcceptInvitation}
      loading={loading}
    />
  );
};
