import { ISelectOption } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';

export const useWeekDays = (): ISelectOption[] => {
  const { t } = useTranslation();
  return [
    { id: 'monday', text: t('label.monday') },
    { id: 'tuesday', text: t('label.tuesday') },
    { id: 'wednesday', text: t('label.wednesday') },
    { id: 'thursday', text: t('label.thursday') },
    { id: 'friday', text: t('label.friday') },
    { id: 'saturday', text: t('label.saturday') },
    { id: 'sunday', text: t('label.sunday') },
  ];
};
