import './index.css';
import { useCallback, useState } from 'react';
import { useAthleteStore } from '../../../../store/athlete/slice';
import { useAthleteWellnessStore } from '../../../AthleteWellness/store/slice';
import { Button, EmptyState } from '@cycling-web/analog-ui';
import { WellnessScores } from '../../../../components/WellnessScores';
import { useTranslation } from 'react-i18next';
import { Bike } from 'lucide-react';
import { SubjectiveFeedbackForm } from '../../../../components/SubjectiveFeedbackForm';

export const Feedback = () => {
  const { t } = useTranslation();

  const athleteLoaded = useAthleteStore((s) => s.athleteLoaded);
  const wellnessLoaded = useAthleteWellnessStore((s) => s.wellnessLoaded);
  const wellness = useAthleteWellnessStore((s) => s.wellness);

  const loading = !athleteLoaded && !wellnessLoaded;

  const [showForm, setShowForm] = useState<boolean>(false);

  const openForm = useCallback(() => setShowForm(true), []);
  const dismissForm = useCallback(() => setShowForm(false), []);

  return (
    <div className="athlete-details-mobile__feedback">
      <div className="athlete-details-mobile__feedback-card">
        {!loading && !wellness?.wellness?.data ? (
          <EmptyState
            icon={<Bike size={32} />}
            title={t('banner.empty_subjective_feedback_title')}
            text={t('banner.empty_subjective_feedback_message')}
          />
        ) : (
          <WellnessScores wellness={wellness} wellnessLoaded={wellnessLoaded} />
        )}
        <Button
          size="l"
          content={t('action.enter_your_feedback')}
          onClick={openForm}
        />
      </div>
      {showForm && <div className="athlete-details-mobile__feedback-overlay" />}

      {showForm && <SubjectiveFeedbackForm onDismiss={dismissForm} />}
    </div>
  );
};
