import { useTranslation } from 'react-i18next';
import { Toast, useBoolean } from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { useGroupsStore } from '../../store/groups/slice';
import { GroupsRepository } from '../../api/groups/repository';
import { GroupsService } from '../../api/groups/service';
import { IGroup } from '../../types/groups';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../constants';

export const useViewModel = () => {
  const { t } = useTranslation();
  const {
    value: showAthleteDialog,
    setTrue: openAthleteDialog,
    setFalse: dismissAthleteDialog,
  } = useBoolean(false);

  const group = useGroupsStore((s) => s.group);
  const context = useMemo(() => {
    return {
      openAthleteDialog,
      dismissAthleteDialog,
    };
  }, [openAthleteDialog, dismissAthleteDialog]);

  const groupsRepository = useMemo(() => {
    return new GroupsRepository(new GroupsService());
  }, []);

  const onChange = useCallback(
    (athleteIds: number[]) => {
      if (!group) {
        return;
      }

      groupsRepository
        .updateGroup({
          ...group,
          athleteIds,
        })
        .then((group: IGroup) => {
          console.log(group);
        })
        .catch((error: AxiosError) => {
          if (error?.response?.status !== ApiErrorCode.Unauthorized) {
            Toast.error(
              {
                title: t('error.update_group_title'),
                message: t('error.update_group_message'),
              },
              { toastId: 'update_group' }
            );
          }
        });
    },
    [group, groupsRepository, t]
  );

  return {
    context,
    showAthleteDialog,
    group,
    onChange,
    dismissAthleteDialog,
  };
};
