import './index.css';
import { IChatbotHistoryMessage } from '../../../../types/chatbot';
import { useAiAssistantStore } from '../../store/slice';
import { Message } from '../Message';
import { MessageLoading } from '../Message/MessageLoading';
import { useEffect, useRef } from 'react';

export const Body = () => {
  const messages = useAiAssistantStore((s) => s.messages);
  const isProcessing = useAiAssistantStore((s) => s.isProcessing);
  const messagesScrollableElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      const el = messagesScrollableElementRef.current;
      if (el) {
        el.scrollTo(0, el.scrollHeight);
      }
    });
  }, [messages]);

  const messagesJSX = messages.map(
    (message: IChatbotHistoryMessage, i: number) => {
      return message.content.map((content) => {
        return (
          <Message
            key={i}
            message={message}
            content={content}
            isUser={message.role !== 'assistant'}
            isLast={i === messages.length - 1}
          />
        );
      });
    }
  );

  const processingMessage = isProcessing && <MessageLoading />;

  return (
    <div className="ai-assistant__body" ref={messagesScrollableElementRef}>
      {messagesJSX}
      {processingMessage}
    </div>
  );
};
