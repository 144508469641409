import { useAthleteWellnessStore } from '../store/slice';
import { useCallback } from 'react';
import { IAthleteWellnessFilters } from '../types';
import { AnyValue } from '../../../types/common';
import { IHandleChangeFilter } from '@cycling-web/common';

export const useStateFilters = () => {
  const filters = useAthleteWellnessStore((s) => s.filters);
  const setFilters = useAthleteWellnessStore((s) => s.setFilters);

  const handleChangeFilter = useCallback(
    (key: keyof IAthleteWellnessFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  ) as IHandleChangeFilter<IAthleteWellnessFilters>;

  return {
    filters,
    setFilters,
    handleChangeFilter,
  };
};
