import { useTranslation } from 'react-i18next';
import { ReactNode, useMemo } from 'react';
import { FileMinus } from 'lucide-react';
import {
  IEmptyStateProps,
  ITableColumn,
  ITableItem,
} from '@cycling-web/analog-ui';
import { IPerformanceReport } from '../../../../types/performance';
import { Athlete } from '../../../../components/columns/Athlete';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { EMPTY } from '../../../../constants';
import { TSBToken } from '../../../../components/TSBToken';
import { useTableColumns } from '../../hooks/useTableColumns';
import { useGroupReportsStore } from '../../../GroupDetails/store/slice';
import { filterGroupReportsDelegate } from '../../../GroupDetails/utils/filterReportsDelegate';
import { sortByAthleteDelegate } from '../../../../utils/sortByAthleteDelegate';

export const useViewModel = () => {
  const { t } = useTranslation();
  const filters = useGroupReportsStore((s) => s.filters);
  const items = useGroupReportsStore((s) => s.reports);
  const loaded = useGroupReportsStore((s) => s.reportsLoaded);
  const athletesMap = useAthletesStore((s) => s.athletesMap);

  const filteredItems = useMemo(() => {
    const filtered = items.filter(
      filterGroupReportsDelegate(filters, athletesMap)
    );
    filtered.sort(sortByAthleteDelegate(athletesMap));
    return filtered;
  }, [athletesMap, filters, items]);

  const columns: ITableColumn[] = useTableColumns();

  const onRenderCell = (column: ITableColumn, item: ITableItem) => {
    const castedItem = item as IPerformanceReport;

    const renderMap: Record<string, ReactNode> = {
      athlete: <Athlete athlete={athletesMap[castedItem.athleteId]} />,
      total_duration: castedItem.durationStr || EMPTY,
      elevation_gain: castedItem.climbInMeter
        ? Math.round(castedItem.climbInMeter)
        : EMPTY,
      work: castedItem.workInKj ? Math.round(castedItem.workInKj) : EMPTY,
      distance: castedItem.distanceInKm
        ? Math.round(castedItem.distanceInKm)
        : EMPTY,
      tss: castedItem.tss ? Math.round(castedItem.tss) : EMPTY,
      ctl: castedItem.ctl ? Math.round(castedItem.ctl) : EMPTY,
      tsb: <TSBToken value={castedItem.tsb} />,
      mcp: castedItem.mCPRelative || EMPTY,
    };

    return renderMap[column.key];
  };

  const emptyStateProps: IEmptyStateProps | undefined =
    loaded && filteredItems.length === 0
      ? {
          icon: <FileMinus size={32} />,
          title: t('banner.empty_reports_title'),
          text: t('banner.empty_reports_message'),
        }
      : undefined;

  return {
    columns,
    items: filteredItems,
    loading: !loaded,
    emptyStateProps,
    onRenderCell,
  };
};
