import type { IRadioProps } from './types';
import { useMemo } from 'react';
import './index.css';
import { clsx } from 'clsx';
import { Typography } from '../Typography';

/** Extends native radio input */
export const Radio = (props: IRadioProps) => {
  const rootClass: string = clsx(
    'analog-radio',
    props.disabled && 'analog-radio--disabled',
    props.className
  );

  const nativeProps: IRadioProps = useMemo(() => {
    const nativeProps: IRadioProps = { ...props };
    delete nativeProps.onRenderLabel;
    return nativeProps;
  }, [props]);

  return (
    <label className={rootClass}>
      <input
        {...nativeProps}
        size={undefined}
        type="radio"
        className="analog-radio__input"
      />

      <span className="analog-radio__check">
        <span className="analog-radio__mark" />
      </span>

      <div className={'analog-radio__text'} tabIndex={-1}>
        {props.onRenderLabel ? (
          props.onRenderLabel()
        ) : (
          <Typography variant="body" text={props.label} />
        )}
      </div>
    </label>
  );
};
