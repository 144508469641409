import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useUserRole } from './useUserRole';
import { usePlatformsStore } from '../store/platforms/slice';
import { PlatformRepository } from '../api/platform/repository';
import { PlatformService } from '../api/platform/service';
import { IPlatform } from '../types/platform';

export const useFetchPlatforms = () => {
  const { isAthlete } = useUserRole();
  const fetching = useRef<boolean>(false);
  const setPlatforms = usePlatformsStore((s) => s.setPlatforms);
  const setPlatformsLoaded = usePlatformsStore((s) => s.setPlatformsLoaded);

  const platformRepository = useMemo(() => {
    return new PlatformRepository(new PlatformService());
  }, []);

  const fetchPlatforms = useCallback(() => {
    if (!isAthlete || fetching.current) {
      return;
    }

    fetching.current = true;
    platformRepository
      .getPlatforms()
      .then((platforms: IPlatform[]) => {
        setPlatforms(platforms);
      })
      .catch((e) => {
        console.log(e);
        setPlatformsLoaded(true);
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [platformRepository, setPlatforms, setPlatformsLoaded, isAthlete]);

  useEffect(() => {
    fetchPlatforms();
  }, [fetchPlatforms]);

  return {
    fetchPlatforms,
  };
};
