import { UserRole } from '@cycling-web/common';

export const roleKeyMapper: Record<string, UserRole> = {
  coach: UserRole.Coach,
  sports_director: UserRole.SportsDirector,
  nutritionist: UserRole.Nutritionist,
  physiotherapist: UserRole.Physiotherapist,
  mental_coach: UserRole.MentalCoach,
  doctor: UserRole.Doctor,
  secondary_doctor: UserRole.SecondaryDoctor,
};
