import { forwardRef, useContext } from 'react';
import { clsx } from 'clsx';
import { DatepickerContext } from './context';
import { IDatepickerContext, IDatepickerSize } from './types';
import { IconButton } from '../Button/IconButton';
import { Calendar, X } from 'lucide-react';
import type { IButtonSize } from '../Button/types';
import { Typography } from '../Typography';

export const DatepickerToggle = forwardRef<HTMLDivElement, unknown>(
  (_, ref) => {
    const {
      dropdownProps,
      invalid,
      disabled,
      handleClear,
      placeholder,
      displayValue,
      size = 'm',
    }: IDatepickerContext = useContext(DatepickerContext);

    const rootClass: string = clsx(
      'analog-datepicker__toggle',
      dropdownProps?.isOpen && 'analog-datepicker__toggle--focused',
      invalid && 'analog-datepicker__toggle--invalid',
      disabled && 'analog-datepicker__toggle--disabled'
    );

    const buttonSize: Record<IDatepickerSize, IButtonSize> = {
      s: 'xs',
      m: 's',
      l: 'm',
    };

    return (
      <div className={rootClass} tabIndex={0} ref={ref}>
        {placeholder && !displayValue && (
          <Typography
            as="div"
            className="analog-datepicker__toggle-placeholder"
            text={placeholder}
          />
        )}

        <Typography
          as="div"
          className="analog-datepicker__toggle-value"
          text={displayValue}
        />

        {!disabled && (
          <div className="analog-datepicker__toggle-actions">
            {displayValue && handleClear && (
              <IconButton
                variant="quietNeutral"
                size={buttonSize[size]}
                onClick={handleClear}
                data-testid="clear-button"
                content={<X />}
              />
            )}
            <IconButton
              variant="quietNeutral"
              disabled={disabled}
              size={buttonSize[size]}
              content={<Calendar />}
            />
          </div>
        )}
      </div>
    );
  }
);

DatepickerToggle.displayName = 'DatepickerToggle';
