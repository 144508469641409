import './index.css';
import { MedicalReportDetailsHeader } from './components/Header';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { EmptyState } from '@cycling-web/analog-ui';
import { FileQuestion } from 'lucide-react';
import { ReportNotes } from './components/ReportNotes';
import { useViewModel } from './useViewModel';
import { ReportBodyMap } from './components/ReportBodyMap';
import { ReportAttachments } from './components/ReportAttachments';
import { ReportDetails } from './components/ReportDetails';
import { useTitle } from '../../hooks/useTitle';

export const AthleteMedicalReport = () => {
  const { t } = useTranslation();
  useTitle(t('label.medical_report'));
  const { reportLoaded, report } = useViewModel();

  return (
    <div className="medical-report-details-page">
      {reportLoaded && report ? (
        <>
          <MedicalReportDetailsHeader />
          <div className="medical-report-details__row">
            <ReportDetails athleteReport={report} />
            <ReportBodyMap athleteReport={report} />
          </div>
          <div className="medical-report-details__row">
            <ReportNotes />
            <ReportAttachments />
          </div>
        </>
      ) : (
        <EmptyState
          icon={<FileQuestion size={32} />}
          title={t('banner.empty_reports_title')}
          text={t('banner.empty_reports_message')}
        />
      )}
    </div>
  );
};
