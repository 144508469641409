import { IAthlete } from '../../../types/athletes';
import { IGroupReportsFilters } from '../../GroupDetails/types';

export const filterAthletesDelegate = (filters: IGroupReportsFilters) => {
  return (item: IAthlete) => {
    let valid = true;

    if (
      filters.search &&
      !item.fullName.toLowerCase().includes(filters.search.toLowerCase())
    ) {
      valid = false;
    }

    if (filters.athletes.length > 0 && !filters.athletes.includes(item.id)) {
      valid = false;
    }

    return valid;
  };
};
