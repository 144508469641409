import { ICookie } from '../types';
import { LS } from '../../../../../constants';

export const EssentialCookies: ICookie[] = [
  {
    name: LS.AccessToken,
    description:
      'These cookies help us securely verify your identity when you log in, so you can access secure areas of our Platform. They ensure that you remain logged in as you navigate the Platform and prevent unauthorized access to your Account.',
    duration: '1 hour.',
  },
  {
    name: LS.Team,
    description:
      'We use this cookie to remember the Team you have selected upon your most recent login (if your Account has been linked to multiple Teams).  This is so that you will not have to select that Team, each time you make a selection or take an action on our Platform, in order for such selection or action to be taken and processed within the context of that Team.',
    duration:
      'Until you close the browser tab, the browser, or log out of our Platform.',
  },
  {
    name: LS.PreviousPage,
    description:
      'Stores the URL of the last page you visited to make sure the "back" button takes you to the correct place.',
    duration:
      'Until you close the browser tab, the browser, or log out of our Platform.',
  },
  {
    name: '<user_id>.<tenant_id>-<msal_authority>-<tenant_id>',
    description:
      "This cookie is applied by Microsoft for the purposes of operating Microsoft Entra ID. It stores information specific to the User's login context and tenant, ensuring secure access to appropriate resources.",
    duration:
      'Until you close the browser tab, the browser, or log out of our Platform.',
  },
  {
    name: '<user_id>.<tenant_id>-<msal_authority>-accesstoken-<application_client_id>-<tenant_id>-email profile user.read openid--',
    description:
      'This cookie is applied by Microsoft for the purposes of operating Microsoft Entra ID. Stores access token scoped to permissions (email, profile, user.read, openid) for API/resource access.',
    duration: '1 hour.',
  },
  {
    name: '<user_id>.<tenant_id>-<msal_authority>-idtoken-<application_client_id>-<tenant_id>---',
    description:
      'This cookie is applied by Microsoft for the purposes of operating Microsoft Entra ID. Contains the ID token, used for authenticating and verifying user identity within the session.',
    duration: '1 hour.',
  },
  {
    name: '<user_id>.<tenant_id>-<msal_authority>-refreshtoken-<application_client_id>----',
    description:
      'This cookie is applied by Microsoft for the purposes of operating Microsoft Entra ID. Stores the refresh token, enabling renewal of access tokens without reauthentication.',
    duration: '1 hour.',
  },
  {
    name: 'msal.<application_client_id>.active-account',
    description:
      'This cookie is applied by Microsoft for the purposes of operating Microsoft Entra ID. Tracks the currently active account in the session, associated with the user’s authentication status.',
    duration:
      'Until you close the browser tab, the browser, or log out of our Platform.',
  },
  {
    name: 'msal.<application_client_id>.active-account-filters',
    description:
      'This cookie is applied by Microsoft for the purposes of operating Microsoft Entra ID. Contains filters applied to the active account, possibly for selecting specific data or session parameters.',
    duration:
      'Until you close the browser tab, the browser, or log out of our Platform.',
  },
  {
    name: 'msal.account.keys',
    description:
      'This cookie is applied by Microsoft for the purposes of operating Microsoft Entra ID. Racks cached account data, enabling the management of multiple accounts within the same application or session.',
    duration:
      'Until you close the browser tab, the browser, or log out of our Platform.',
  },
  {
    name: 'msal.token.keys.<application_client_id>',
    description:
      'This cookie is applied by Microsoft for the purposes of operating Microsoft Entra ID. Stores metadata about tokens (access, refresh) issued for specific scopes, allowing efficient management and token caching.',
    duration:
      'Until you close the browser tab, the browser, or log out of our Platform.',
  },
  {
    name: 'ESTSAUTH',
    description:
      'Helps securely verify your session and keeps you logged in for seamless access across our Platform.',
    duration: 'Until you close your browser or log out.',
  },
  {
    name: 'ESTSAUTHPERSISTENT',
    description:
      'Remembers your login details to keep you logged in, even if you return after a while.',
    duration: 'Up to 90 days.',
  },
  {
    name: 'ESTSAUTHLIGHT',
    description:
      'Stores session information to help recognize your browser for a smoother login experience.',
    duration: 'Until you close your browser or log out.',
  },
  {
    name: 'SignInStateCookie',
    description:
      'Keeps track of your sign-in status so you can sign out from multiple services at once.',
    duration: 'Until you close your browser or log out.',
  },
  {
    name: 'CCState',
    description:
      'Ensures secure communication between Microsoft’s services for backup authentication.',
    duration: 'Until you close your browser or log out.',
  },
  {
    name: 'buid',
    description:
      'Collects anonymous data about your browser to help improve the performance of login.',
    duration: 'Up to 24 hours.',
  },
  {
    name: 'fpc',
    description:
      'Tracks information about your device and browsing activity to manage requests and service limits.',
    duration: 'Up to 24 hours.',
  },
  {
    name: 'esctx',
    description:
      'Protects against security threats by ensuring your requests are from the same browser session.',
    duration: 'Until you close your browser or log out.',
  },
  {
    name: 'ch',
    description:
      'Helps confirm your identity by storing a secure, temporary token during your session.',
    duration: 'Up to 24 hours.',
  },
  {
    name: 'ESTSSSOTILES',
    description:
      'Keeps you logged in on multiple Microsoft services simultaneously.',
    duration: 'Up to 24 hours.',
  },
  {
    name: 'AADSSOTILES',
    description:
      'Manages session data for single sign-on across Microsoft services.',
    duration: 'Up to 24 hours.',
  },
  {
    name: 'ESTSUSERLIST',
    description:
      'Tracks which accounts you’ve used to sign in for easier access next time.',
    duration: 'Up to 24 hours.',
  },
  {
    name: 'SSOCOOKIEPULLED',
    description:
      'Prevents errors during sign-in by managing your session efficiently.',
    duration: 'Up to 24 hours.',
  },
  {
    name: 'cltm',
    description:
      'Gathers information about your browser and network to enhance your experience.',
    duration: 'Up to 24 hours.',
  },
  {
    name: 'brcap',
    description:
      'Checks if your device has touch capabilities to optimize the Platform for touchscreens.',
    duration: 'Until you close your browser or log out.',
  },
  {
    name: 'clrc',
    description: 'Manages your session locally to keep your browsing smooth.',
    duration: 'Until you close your browser or log out.',
  },
  {
    name: 'wlidperf',
    description:
      'Measures how quickly the Platform loads to improve performance.',
    duration: 'Until you close your browser or log out.',
  },
  {
    name: 'x-ms-gateway-slice',
    description:
      'Helps distribute traffic evenly to improve service stability.',
    duration: 'Until you close your browser or log out.',
  },
  {
    name: 'stsservicecookie',
    description:
      'Tracks your session to maintain a stable connection to our services.',
    duration: 'Until you close your browser or log out.',
  },
  {
    name: 'x-ms-refreshtokencredential',
    description:
      'Manages long-term sessions to keep you signed in without repeatedly asking for your credentials.',
    duration: 'Until you close your browser or log out.',
  },
  {
    name: 'MSFPC',
    description:
      'Identifies your browser across Microsoft services to personalize your experience and for analytics.',
    duration: 'Up to 24 hours.',
  },
  {
    name: 'cookies',
    description: 'Stores whether the user has acknowledged the Cookies Banner.',
    duration: 'Persistent until deleted manually.',
  },
  {
    name: 'cookiesVersion',
    description:
      'Stores the version of the Cookies Notice the user has acknowledged. It is used to show the Cookies Banner again if there are changes in the Cookies Notice, or if additional cookies are introduced.',
    duration: 'Persistent until deleted manually.',
  },
];

export const AnalyticalCookies: ICookie[] = [
  {
    name: 'server-telemetry-<application_client_id>',
    description:
      'This cookie is applied by Microsoft for the purposes of operating Microsoft Entra ID. This cookie monitors the performance and reliability of the Microsoft Authentication Library (MSAL) used in our Platform. It collects telemetry data, such as performance metrics and errors, which may be shared with Microsoft for diagnostics.',
    duration: 'Until you log out of our Platform.',
  },
  {
    name: 'AI_sentBuffer',
    description:
      'This cookie temporarily holds data before it is sent to and processed by Microsoft Azure Application Insights, which is hosted within Analog’s Azure cloud instance. We may retain this data for our ongoing analysis and improvements to the Platform.',
    duration:
      'This cookie is set on request to MS Azure Application Insights and it is cleared when response from this service is received.',
  },
  {
    name: 'AI_Buffer',
    description:
      'This cookie temporarily holds data before it is sent to and processed by Microsoft Azure Application Insights, which is hosted within Analog’s Azure cloud instance.',
    duration:
      'This cookie is set on request to MS Azure Application Insights and it is cleared when response from this service is received.',
  },
  {
    name: 'prevPageVisitData',
    description:
      "This cookie tracks information about the user's previous page visit for performance monitoring and analytics for Microsoft Azure Application Insights.",
    duration:
      'Until you close the browser tab, the browser, or log out of our Platform.',
  },
];
