import { useTranslation } from 'react-i18next';

export const CoachRatingTooltip = () => {
  const { t } = useTranslation();

  return (
    <div className="report-feedback-form__coach-rating-tooltip analog-typography--body regular">
      <div>
        <strong>1-2</strong>
        {t('label.coach_rating_1_2')}
      </div>
      <div>
        <strong>3-4</strong>
        {t('label.coach_rating_3_4')}
      </div>
      <div>
        <strong>5-6</strong>
        {t('label.coach_rating_5_6')}
      </div>
      <div>
        <strong>7-8</strong>
        {t('label.coach_rating_7_8')}
      </div>
      <div>
        <strong>9-10</strong>
        {t('label.coach_rating_9_10')}
      </div>
    </div>
  );
};
