import { useFetchPeak } from './hooks/useFetchPeak';
import { useFetchPowerDuration } from './hooks/useFetchPowerDuration';
import { useFetchPowerDerivatives } from './hooks/useFetchPowerDerivatives';
import { useFetchFatigueResistance } from './hooks/useFetchFatigueResistance';
import { initialFilters, useAthletePerformanceStore } from './store/slice';
import { useCallback, useEffect, useMemo } from 'react';
import qs from 'qs';
import { IAthletePerformanceFilters } from './types';
import { useURLFilters } from '../../hooks/useURLFilters';
import { useStateFilters } from './hooks/useStateFilters';
import {
  IFatigueResistance,
  IPdCurve,
  IPowerDerivative,
} from '../../types/performance';
import { useAthleteStore } from '../../store/athlete/slice';

export const useViewModel = () => {
  useFetchPeak();
  useFetchPowerDuration();
  useFetchPowerDerivatives();
  useFetchFatigueResistance();

  const { filters, handleChangeFilter } = useStateFilters();
  const athlete = useAthleteStore((s) => s.athlete);

  const peakRecord = useAthletePerformanceStore((s) => s.peak);
  const peak = useMemo(() => {
    return peakRecord[filters.period] || [];
  }, [filters.period, peakRecord]);
  const peakLoaded = useAthletePerformanceStore((s) => s.peakLoaded);

  const pdCurve: IPdCurve | null = useAthletePerformanceStore((s) => s.pdCurve);
  const pdCurveRelative: IPdCurve | null = useAthletePerformanceStore(
    (s) => s.pdCurveRelative
  );
  const pdCurveLoaded: boolean = useAthletePerformanceStore(
    (s) => s.pdCurveLoaded
  );

  const powerDerivatives: IPowerDerivative | null = useAthletePerformanceStore(
    (s) => s.powerDerivatives
  );
  const powerDerivativesLoaded: boolean = useAthletePerformanceStore(
    (s) => s.powerDerivativesLoaded
  );

  const fatigueResistance: IFatigueResistance | null =
    useAthletePerformanceStore((s) => s.fatigueResistance);
  const fatigueResistanceRelative: IFatigueResistance | null =
    useAthletePerformanceStore((s) => s.fatigueResistanceRelative);
  const fatigueResistanceLoaded: boolean = useAthletePerformanceStore(
    (s) => s.fatigueResistanceLoaded
  );

  const initFilters = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const qsFilters = qs.parse(
      params.toString()
    ) as unknown as IAthletePerformanceFilters;

    useAthletePerformanceStore.getState().setFilters({
      shared: {
        period: qsFilters?.shared?.period
          ? +qsFilters?.shared?.period
          : initialFilters.shared.period,
      },
      powerProfile: {
        period1: qsFilters?.powerProfile?.period1
          ? +qsFilters?.powerProfile?.period1
          : initialFilters.powerProfile.period1,
        period2: qsFilters?.powerProfile?.period2
          ? +qsFilters?.powerProfile?.period2
          : initialFilters.powerProfile.period2,
        dataType:
          qsFilters?.powerProfile?.dataType ||
          initialFilters.powerProfile.dataType,
      },
      powerDerivatives: {
        dataType:
          qsFilters?.powerDerivatives?.dataType ||
          initialFilters.powerDerivatives.dataType,
      },
      mmp: {
        dataType: qsFilters?.mmp?.dataType || initialFilters.mmp.dataType,
        dateGroupBy:
          qsFilters?.mmp?.dateGroupBy || initialFilters.mmp.dateGroupBy,
      },
      durability: {
        dataType:
          qsFilters?.durability?.dataType || initialFilters.durability.dataType,
        work: qsFilters?.durability?.work
          ? +qsFilters?.durability?.work
          : initialFilters.durability.work,
      },
    });
  }, []);

  useURLFilters({ filters, initFilters });

  useEffect(() => {
    return () => {
      useAthletePerformanceStore.getState().setFilters(initialFilters);
    };
  }, []);

  return {
    filters,
    handleChangeFilter,
    peak,
    peakLoading: !peakLoaded,
    pdCurve,
    pdCurveRelative,
    pdCurveLoading: !pdCurveLoaded,
    powerDerivatives,
    powerDerivativesLoading: !powerDerivativesLoaded,
    fatigueResistance,
    fatigueResistanceRelative,
    fatigueResistanceLoading: !fatigueResistanceLoaded,
    athlete,
  };
};
