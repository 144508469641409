export function isDateEqual(
  date1: Date | undefined,
  date2: Date | undefined
): boolean {
  if (!date1 || !date2) {
    return false;
  }

  const convertedDate1 = new Date(date1);
  const convertedDate2 = new Date(date2);

  return (
    convertedDate1.getDate() === convertedDate2.getDate() &&
    convertedDate1.getMonth() === convertedDate2.getMonth() &&
    convertedDate1.getFullYear() === convertedDate2.getFullYear()
  );
}
