import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { IWorkoutDetail } from '../../../types/performance';
import { Toast } from '@cycling-web/analog-ui';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { IAthlete } from '../../../types/athletes';
import { DateGroupBy } from '../../../types/enums';
import { useAthletesStore } from '../../../store/athletes/slice';
import { useDashboardStore } from '../store/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchTeamWorkoutDetails = () => {
  const { t } = useTranslation();
  const fetching = useRef<boolean>(false);
  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);
  const loading = !athletesLoaded;

  const filters = useDashboardStore((s) => s.filters);
  const setWorkoutDetails = useDashboardStore((s) => s.setWorkoutDetails);
  const setWorkoutDetailsLoaded = useDashboardStore(
    (s) => s.setWorkoutDetailsLoaded
  );

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService());
  }, []);

  useEffect(() => {
    if (loading || fetching.current || athletes.length === 0) {
      setWorkoutDetailsLoaded(true);
      return;
    }

    fetching.current = true;

    const filteredAthletes = athletes;
    const { startDate, endDate } = calculateDateRange(filters.period);

    performanceRepository
      .getWorkoutDetails({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteIds: filteredAthletes.map((a: IAthlete) => a.id),
        unit: DateGroupBy.Week,
        aggregatePerAthlete: true,
      })
      .then((data: IWorkoutDetail[]) => {
        setWorkoutDetails(data);
      })
      .catch((error: AxiosError) => {
        setWorkoutDetailsLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_workout_details_title'),
              message: t('error.get_workout_details_message'),
            },
            { toastId: 'get_workout_details' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [
    loading,
    athletes,
    filters.period,
    performanceRepository,
    setWorkoutDetails,
    setWorkoutDetailsLoaded,
    t,
  ]);
};
