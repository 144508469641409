import './index.css';
import { Divider, LabelValue } from '@cycling-web/analog-ui';
import { ChartAxisName } from '../../../../constants/charts';
import { useTranslation } from 'react-i18next';
import { EMPTY } from '../../../../constants';

type IProps = {
  cp: string | undefined;
  w: string | undefined;
};

export const TodayDetails = ({ cp, w }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="mcp-today">
      <LabelValue
        label={ChartAxisName.CP}
        value={cp || EMPTY}
        units={t('units.w')}
      />
      <Divider direction="vertical" height={16} />
      <LabelValue
        label={ChartAxisName.W}
        value={w || EMPTY}
        units={t('units.kj')}
      />
    </div>
  );
};
