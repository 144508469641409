import './index.css';
import { useTranslation } from 'react-i18next';
import { Page } from '@cycling-web/common';
import React from 'react';
import { SmartChartsHeader } from './components/Header';
import { useViewModel } from './useViewModel';
import { SmartChartsContext } from './context';
import { AthleteModal } from './components/AthletesModal';
import { Chat } from './components/Chat';
import { History } from './components/History';
import { ChartContainer } from './components/Chart';
import { clsx } from 'clsx';

export const SmartCharts2 = () => {
  const { t } = useTranslation();
  const { context } = useViewModel();
  const {
    expanded,
    showHistory,
    showAthletesModal,
    dismissAthletesModal,
    onSelectAthletes,
    selectedAthletes,
  } = context;

  const rootClass = clsx(
    'sc2__wrapper',
    showHistory && 'sc2__wrapper--history',
    expanded && 'sc2__wrapper--expanded'
  );

  return (
    <SmartChartsContext.Provider value={context}>
      <Page pageHeaderProps={{ title: t('label.smart_charts') }}>
        <div className="sc2__container">
          <div className={rootClass}>
            <SmartChartsHeader />
            <div className="sc2__main">
              {selectedAthletes.length > 0 && <ChartContainer />}
              <Chat />
            </div>
          </div>
          {showHistory && <History />}
        </div>

        {showAthletesModal && (
          <AthleteModal
            onDismiss={dismissAthletesModal}
            onSubmit={onSelectAthletes}
            selectedAthletes={selectedAthletes}
          />
        )}
      </Page>
    </SmartChartsContext.Provider>
  );
};
