export function renameFile(file: File, salt?: string): File {
  const originalFile = file;
  const timestamp = Date.now();
  const lastDotIndex = originalFile.name.lastIndexOf('.');

  let baseName = originalFile.name;
  let fileExtension = '';

  if (lastDotIndex >= 0) {
    baseName = originalFile.name.substring(0, lastDotIndex);
    fileExtension = originalFile.name.substring(lastDotIndex + 1);
  }

  const newFileName = `${baseName}-${salt || timestamp}.${fileExtension}`;

  return new File([originalFile], newFileName, {
    type: originalFile.type,
  });
}
