import { CoachService } from './service';
import {
  IGetAnthropometryRequest,
  ISaveFeedbackRequest,
  ISetDateOfNextSFTResponse,
  ISetTargetPowerOfSFTResponse,
  IUpdateStaffRequest,
} from './types';
import {
  IAnthropometry,
  IAnthropometryFormData,
  IPerformanceFormData,
  IStaff,
} from '../../types/coach';
import { format } from 'date-fns';
import { IReportFeedbackForm } from '../../types/performance';

/** The repository is responsible for data transformation */

export interface ICoachRepository {
  getAnthropometry: (p: IGetAnthropometryRequest) => Promise<IAnthropometry[]>;
  setAnthropometry: (p: IAnthropometryFormData) => Promise<void>;
  setDateOfNextSFT: (
    p: IPerformanceFormData
  ) => Promise<ISetDateOfNextSFTResponse>;
  setTargetPowerOfSFT: (
    p: IPerformanceFormData
  ) => Promise<ISetTargetPowerOfSFTResponse>;
  getProfile: () => Promise<IStaff>;
  updateProfile: (p: IUpdateStaffRequest) => Promise<IStaff>;
  saveFeedback: (p: IReportFeedbackForm) => Promise<any>;
}

export class CoachRepository implements ICoachRepository {
  constructor(private readonly service: CoachService) {}

  async getAnthropometry(
    p: IGetAnthropometryRequest
  ): Promise<IAnthropometry[]> {
    const response = await this.service.getAnthropometry(p);
    return response.data.data;
  }

  async setAnthropometry(p: IAnthropometryFormData): Promise<void> {
    return await this.service.setAnthropometry({
      athleteId: +p.athleteId,
      height: +p.height,
      weight: +p.weight,
      biceps: +p.biceps,
      triceps: +p.triceps,
      subscapula: +p.subscapula,
      iliacCrest: +p.iliacCrest,
      abdominal: +p.abdominal,
      thigh: +p.thigh,
      calf: +p.calf,
      supraspinale: +p.supraspinale,
      date: p.date,
    });
  }

  async setDateOfNextSFT(p: IPerformanceFormData): Promise<void> {
    return await this.service.setDateOfNextSFT({
      dateOfNextSft: p.dateOfNextSft,
      athleteId: p.athleteId,
      updatedAt: format(new Date(), 'yyyy-MM-dd'),
    });
  }

  async setTargetPowerOfSFT(p: IPerformanceFormData): Promise<void> {
    return await this.service.setTargetPowerOfSFT({
      targetPower: +p.targetPower,
      athleteId: p.athleteId,
      updatedAt: format(new Date(), 'yyyy-MM-dd'),
    });
  }

  async updateProfile(p: IUpdateStaffRequest): Promise<IStaff> {
    const response = await this.service.updateProfile(p);
    return response.data;
  }

  async getProfile(): Promise<IStaff> {
    const response = await this.service.getProfile();
    return response.data.data;
  }

  async saveFeedback(p: IReportFeedbackForm): Promise<any> {
    const payload: ISaveFeedbackRequest = {
      ...p,
      performanceRating: Number(p.performanceRating),
    };
    const response = await this.service.saveFeedback(payload);
    return response.data.data;
  }
}
