import './index.css';
import { PerformanceChartsFilters } from './components/Filters';
import { CompletedDurationChart } from './components/CompletedDurationChart';
import { useAthletesStore } from '../../store/athletes/slice';
import { DistanceChart } from './components/DistanceChart';
import { ElevationGainChart } from './components/ElevationGainChart';
import { TssChart } from './components/TssChart';
import { CtlChart } from './components/CtlChart';
import { TsbChart } from './components/TsbChart';
import { TeamCriticalPowerChart } from './components/TeamCriticalPowerChart';
import { usePerformanceReportStore } from '../PerformanceReport/store/slice';
import { useMemo } from 'react';
import { filterReportsDelegate } from '../PerformanceReport/utils/filterReportsDelegate';
import { sortByAthleteDelegate } from '../../utils/sortByAthleteDelegate';
import { ChartAxisName } from '../../constants/charts';
import { useTranslation } from 'react-i18next';

export const PerformanceReportCharts = () => {
  const { t } = useTranslation();
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const reports = usePerformanceReportStore((s) => s.reports);
  const reportsLoaded = usePerformanceReportStore((s) => s.reportsLoaded);
  const filters = usePerformanceReportStore((s) => s.filters);

  const filteredReports = useMemo(() => {
    const filtered = reports.filter(
      filterReportsDelegate(filters, athletesMap)
    );
    filtered.sort(sortByAthleteDelegate(athletesMap));
    return filtered;
  }, [reports, athletesMap, filters]);

  const sources = useMemo(() => {
    const sources: Record<string, (string | number)[][]> = {
      duration: [[ChartAxisName.Athlete, t('label.duration')]],
      distance: [[ChartAxisName.Athlete, t('label.distance')]],
      elevationGain: [[ChartAxisName.Athlete, t('label.elevation_gain')]],
      tss: [[ChartAxisName.Athlete, t('label.tss')]],
      ctl: [[ChartAxisName.Athlete, t('label.ctl')]],
      tsb: [[ChartAxisName.Athlete, t('label.tsb')]],
      mcp: [[ChartAxisName.Athlete, ChartAxisName.mCP_WKg]],
    };

    filteredReports.forEach((item) => {
      const athleteName = athletesMap[item.athleteId].fullName || '';
      sources.duration.push([athleteName, item.durationInHour]);
      sources.distance.push([athleteName, item.distanceInKm]);
      sources.elevationGain.push([athleteName, item.climbInMeter]);
      sources.tss.push([athleteName, item.tss]);
      sources.ctl.push([athleteName, item.ctl]);
      sources.tsb.push([athleteName, item.tsb]);
      sources.mcp.push([athleteName, item.mCPRelative]);
    });

    return sources;
  }, [t, filteredReports, athletesMap]);

  return (
    <div className="performance-charts-page">
      <PerformanceChartsFilters />

      <div className="performance-charts-page__charts">
        <CompletedDurationChart
          source={sources.duration}
          loading={!reportsLoaded}
        />
        <DistanceChart source={sources.distance} loading={!reportsLoaded} />
        <ElevationGainChart
          source={sources.elevationGain}
          loading={!reportsLoaded}
        />
        <TssChart source={sources.tss} loading={!reportsLoaded} />
        <CtlChart source={sources.ctl} loading={!reportsLoaded} />
        <TsbChart source={sources.tsb} loading={!reportsLoaded} />
        <TeamCriticalPowerChart source={sources.mcp} loading={!reportsLoaded} />
      </div>
    </div>
  );
};
