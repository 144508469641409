import { useCallback, useMemo, useState } from 'react';
import { IGroup } from '../../types/groups';
import { IGroupsContext } from './context';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../hooks/useTitle';

export const useViewModel = () => {
  const { t } = useTranslation();
  useTitle(t('label.groups'));

  const [showGroupFormModal, setShowGroupFormModal] = useState<IGroup | null>(
    null
  );

  const openGroupFormModal = useCallback(
    (group: IGroup) => () => {
      setShowGroupFormModal(group);
    },
    []
  );

  const dismissGroupFormModal = useCallback(() => {
    setShowGroupFormModal(null);
  }, []);

  const context = useMemo((): IGroupsContext => {
    return {
      showGroupFormModal,
      dismissGroupFormModal,
      openGroupFormModal,
    };
  }, [dismissGroupFormModal, openGroupFormModal, showGroupFormModal]);

  return {
    context,
  };
};
