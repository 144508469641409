import { IMenuBaseOption, IMenuContext, IMenuProps } from './types';
import { useCallback } from 'react';
import { useControlledValue } from '../../hooks/useControlledValue';

export const useViewModel = (props: IMenuProps): IMenuContext => {
  const { dropdownProps, onChange }: IMenuProps = props;
  const { isOpen: controlledIsOpen, setIsOpen: setControlledIsOpen } =
    dropdownProps;

  const { value: isOpen, onChange: setIsOpen } = useControlledValue<boolean>({
    controlledValue: controlledIsOpen,
    controlledOnChange: setControlledIsOpen,
  });

  const handleChange = useCallback(
    (option: IMenuBaseOption) => {
      return () => {
        if (option.options) {
          return;
        }
        setIsOpen(false);
        onChange(option);
      };
    },
    [setIsOpen, onChange]
  );

  return {
    ...props,
    dropdownProps: {
      ...props.dropdownProps,
      isOpen: isOpen,
      setIsOpen,
    },
    onChange: handleChange,
  };
};
