import './index.css';
import { Dialog, Table } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useViewModel } from './useViewModel';
import {
  ILatestTrainingLoad,
  IPerformanceReport,
} from '../../../../types/performance';

type IProps = {
  trainingLoad: ILatestTrainingLoad[];
  onDismiss: () => void;
};

export const TsbSummaryModal = ({ onDismiss, trainingLoad }: IProps) => {
  const { t } = useTranslation();
  const { columns, items, onRenderCell, onRowClick, emptyStateProps } =
    useViewModel({
      trainingLoad,
    });

  return (
    <Dialog
      className="dashboard-tsb-modal"
      onDismiss={onDismiss}
      outsidePress={false}
      title={t('label.tsb_watch')}
      onRenderFooter={() => null}
    >
      <Table
        className="tsb-summary-table"
        columns={columns}
        items={items}
        onRenderCell={onRenderCell}
        onRowClick={onRowClick}
        emptyState={emptyStateProps}
      />
    </Dialog>
  );
};
