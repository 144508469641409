import { Device, Platform } from '@cycling-web/common';

type IProps = {
  integration: Platform | Device;
  redirectUrl: string;
  clientId?: string;
};

export const getIntegrationsLink = ({
  integration,
  redirectUrl,
  clientId,
}: IProps): string => {
  if (integration === Platform.TrainingPeaks) {
    return `https://oauth.trainingpeaks.com/OAuth/Authorize?client_id=analogteamuae&response_type=code&redirect_uri=${redirectUrl}&scope=events%3Aread%20events%3Awrite%20file%3Awrite%20metrics%3Aread%20workouts%3Aread%20workouts%3Adetails%20workouts%3Awod%20workouts%3Aplan%20athlete:profile`;
  }

  if (integration === Device.Wahoo) {
    return `https://api.wahooligan.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUrl}&scope=email%20power_zones_read%20power_zones_write%20workouts_read%20workouts_write%20plans_read%20plans_write%20routes_read%20routes_write%20offline_data%20user_read&response_type=code`;
  }

  if (integration === Device.Whoop) {
    return `https://api.prod.whoop.com/oauth/oauth2/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=read:recovery%20offline&state=12345DRTBCUBOIUNS`;
  }

  return '';
};
