import { Skeleton } from '@cycling-web/analog-ui';
import { IAthlete } from '../../../../../../types/athletes';
import { Parameter } from '../Parameter';
import { useTranslation } from 'react-i18next';

type IProps = {
  athlete: IAthlete | null | undefined;
  loading?: boolean;
};

export const MFTP = ({ athlete, loading }: IProps) => {
  const { t } = useTranslation();

  const getVariance = (value: number | undefined) => {
    if (!value) {
      return null;
    }

    const sign = value > 0 ? '+' : '';
    const signValue = `${sign}${value.toFixed(0)}`;
    return (
      <span className="analog-typography--subtitle regular">({signValue})</span>
    );
  };

  const label = t('label.mftp');
  const value = (
    <>
      <span>{getVariance(0)}</span>
      <span>{athlete?.mftp?.toFixed(0) ?? '--'}</span>
    </>
  );

  if (loading) {
    return <Skeleton width="100px" height="24px" />;
  }

  return <Parameter label={label} value={value} />;
};
