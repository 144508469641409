import './index.css';
import { Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useViewModel } from './useViewModel';
import { PersonalInfoFormLoading } from './LoadingState';
import { OnboardingFooter } from '../Footer';
import { PersonalInfoForm } from './Form';

export const OnboardingPersonalInfo = () => {
  const { t } = useTranslation();
  const { athlete, loading } = useViewModel();

  return (
    <>
      <header className="onboarding__personal-header">
        <Typography
          variant="h4"
          weight="bold"
          text={t('label.personal_info')}
        />
      </header>
      {loading ? (
        <>
          <PersonalInfoFormLoading />
          <OnboardingFooter stepCompleted={true} disabled={true} />
        </>
      ) : (
        athlete && <PersonalInfoForm athlete={athlete} />
      )}
    </>
  );
};
