import './index.css';
import MenuCollapse from '../../assets/menu-collapse';
import { IconButton } from '@cycling-web/analog-ui';
import { useSmartChartsContext } from '../../context';
import { useSmartChartsStore } from '../../store/slice';
import { ISmartChartConfigBase } from '../../../../types/smart-charts';
import { HistoryItem } from '../HistoryItem';
import { useRef, useState } from 'react';
import { useOutsideClick } from 'rooks';

export const History = () => {
  const { dismissHistory } = useSmartChartsContext();
  const history = useSmartChartsStore((s) => s.history);
  const [focusedItem, setFocusedItem] = useState<string>('');
  const rootRef = useRef<HTMLDivElement>(null);

  useOutsideClick(rootRef, () => {
    setFocusedItem('');
  });

  const historyJSX = history.map((item: ISmartChartConfigBase) => {
    return (
      <HistoryItem
        key={item.id}
        item={item}
        focusedItem={focusedItem}
        setFocusedItem={setFocusedItem}
      />
    );
  });

  return (
    <div className="sc2__history">
      <div className="sc2__history-header">
        <IconButton
          variant="quietNeutral"
          content={<MenuCollapse />}
          onClick={dismissHistory}
        />
      </div>
      <div className="sc2__history-list" ref={rootRef}>
        {historyJSX}
      </div>
    </div>
  );
};
