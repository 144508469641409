import './index.css';
import { useTranslation } from 'react-i18next';
import {
  Attachment,
  FileUploader,
  Toast,
  Typography,
} from '@cycling-web/analog-ui';
import { IMedicalFile } from '../../../../types/medical';
import React, { useCallback, useMemo, useState } from 'react';
import { MedicalRepository } from '../../../../api/medical/repository';
import { MedicalService } from '../../../../api/medical/service';
import { useAthleteMedicalReportStore } from '../../store/slice';
import { format } from 'date-fns';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../../constants';
import { useUserRole } from '../../../../hooks/useUserRole';

export const ReportAttachments = () => {
  const { t } = useTranslation();
  const { isAthlete } = useUserRole();
  const report = useAthleteMedicalReportStore((s) => s.report);
  const attachments = useAthleteMedicalReportStore((s) => s.reportFiles);
  const appendFile = useAthleteMedicalReportStore((s) => s.appendFile);
  const deleteFile = useAthleteMedicalReportStore((s) => s.deleteFile);
  const [deletingId, setDeletingId] = useState<number>(-1);

  const medicalRepository = useMemo(() => {
    return new MedicalRepository(new MedicalService());
  }, []);

  const onFilesChange = useCallback(
    (files: File[]) => {
      if (!report || !files.length) {
        return;
      }

      medicalRepository
        .uploadFile({
          multipartFile: files[0],
          medicalReportId: report.id,
          fileDescription: `Medical Report ${report.id} ${format(
            new Date(),
            'yyyy-MM-dd HH:mm'
          )}`,
        })
        .then((file: IMedicalFile) => {
          appendFile(file);
        });
    },
    [appendFile, medicalRepository, report]
  );

  const handleDownload = useCallback(
    (file: IMedicalFile) => {
      return () => {
        try {
          const link = document.createElement('a');
          link.href = file.filePath;
          link.download = file.fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          Toast.error(
            {
              title: t('error.download_file_title'),
              message: t('error.download_file_message'),
            },
            { toastId: 'download_file' }
          );
        }
      };
    },
    [t]
  );

  const handleDelete = useCallback(
    (fileId: number) => {
      return () => {
        setDeletingId(fileId);
        medicalRepository
          .deleteFile(fileId)
          .then(() => {
            deleteFile(fileId);
          })
          .catch((error: AxiosError) => {
            if (error?.response?.status !== ApiErrorCode.Unauthorized) {
              Toast.error(
                {
                  title: t('error.delete_file_title'),
                  message: t('error.delete_file_message'),
                },
                { toastId: 'delete_file' }
              );
            }
          })
          .finally(() => {
            setDeletingId(-1);
          });
      };
    },
    [deleteFile, medicalRepository, t]
  );

  const attachmentsJSX = attachments.map((file: IMedicalFile) => {
    return (
      <Attachment
        key={file.id}
        name={file.fileName}
        size={file.fileSize}
        onDownload={handleDownload(file)}
        onRemove={isAthlete ? undefined : handleDelete(file.id)}
        deleting={deletingId === file.id}
      />
    );
  });

  return (
    <div className="medical-report__attachments">
      <header className="report-attachments__header">
        <Typography
          variant="headline"
          weight="bold"
          text={t('label.attachments')}
        />
      </header>

      {!isAthlete && (
        <div className="report-attachments__container">
          <FileUploader onChange={onFilesChange} showAttachments={false} />
        </div>
      )}
      {attachmentsJSX.length > 0 && (
        <div className="medical-report__attachments-list">{attachmentsJSX}</div>
      )}
    </div>
  );
};
