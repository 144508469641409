import { useSmartChartsStore } from '../../store/slice';
import ChatLogo from '../../../../assets/icons/chat-logo.svg?react';

export const MessageLoading = () => {
  const processingBufferMessage = useSmartChartsStore(
    (s) => s.processingBufferMessage
  );

  if (!processingBufferMessage) {
    return null;
  }

  const text = processingBufferMessage.turn_subprocesses.at(-1)?.heading.value;

  return (
    <div className="sc2__message analog-typography--subtitle">
      <div className="sc2__message-content">
        <div className="sc2__message-content-actor">
          <ChatLogo />
        </div>
        <div className="sc2__message-content-processing shimmer">{text}</div>
      </div>
    </div>
  );
};
