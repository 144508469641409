import { useTranslation } from 'react-i18next';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { User } from 'lucide-react';
import {
  Avatar,
  IEmptyStateProps,
  ITableColumn,
  ITableItem,
} from '@cycling-web/analog-ui';
import { useTableColumns } from '../../hooks/useTableColumns';
import { IUserBase } from '../../../../types/users';
import { useAdminTeamMemberStore } from '../../../AdminPanel/store/slice';
import { AssignButton } from '../AssignButton';
import { AssignRoleFormModal } from '../AssignRoleFormModal';
import { useAdminTeamMappingsStore } from '../../store/slice';
import { UserRole } from '@cycling-web/common';
import { AssignedStaff } from '../AssignedStaff';

export const useViewModel = () => {
  const { t } = useTranslation();
  const items = useAdminTeamMemberStore((s) => s.athletes);
  const staffMap = useAdminTeamMemberStore((s) => s.staffMap);
  const usersLoaded = useAdminTeamMemberStore((s) => s.usersLoaded);
  const mappings = useAdminTeamMappingsStore((s) => s.mappings);
  const mappingsLoaded = useAdminTeamMappingsStore((s) => s.mappingsLoaded);
  const loaded = usersLoaded && mappingsLoaded;

  const filteredItems = useMemo(() => {
    return items;
  }, [items]);

  const athleteToStaffMap: Record<
    number,
    Record<UserRole, number | undefined>
  > = useMemo(() => {
    const map: Record<number, Record<string, number | undefined>> = {};

    for (let i = 0; i < mappings.length; i++) {
      const athleteId = mappings[i].athleteId;
      map[athleteId] = {};

      for (const staffId in mappings[i].roleAndStaffIdMapping) {
        const roles: UserRole[] = mappings[i].roleAndStaffIdMapping[staffId];
        for (let j = 0; j < roles.length; j++) {
          map[athleteId][roles[j]] = Number(staffId);
        }
      }
    }

    return map;
  }, [mappings]);

  const [showAssignRoleDialog, setShowAssignRoleDialog] = useState<
    number | null
  >(null);

  const openAssignRoleDialog = useCallback((athleteId: number | undefined) => {
    return () => {
      if (athleteId !== undefined) {
        setShowAssignRoleDialog(athleteId);
      }
    };
  }, []);

  const dismissAssignRoleDialog = useCallback(() => {
    setShowAssignRoleDialog(null);
  }, []);

  const assignRoleDialog = showAssignRoleDialog !== null && (
    <AssignRoleFormModal
      athleteId={showAssignRoleDialog}
      onDismiss={dismissAssignRoleDialog}
      athleteToStaffMap={athleteToStaffMap[showAssignRoleDialog]}
    />
  );

  const columns: ITableColumn[] = useTableColumns();

  const onRenderCell = (column: ITableColumn, item: ITableItem) => {
    const castedItem = item as IUserBase;
    const mapping = castedItem.athleteId
      ? athleteToStaffMap[castedItem.athleteId]
      : null;

    const renderMap: Record<string, ReactNode> = {
      user: (
        <Avatar
          size="s"
          text={castedItem.name || castedItem.email}
          src={castedItem.picture}
          direction="default"
        />
      ),
      coach: mapping?.[UserRole.Coach] ? (
        <AssignedStaff
          user={staffMap[mapping[UserRole.Coach]]}
          onClick={openAssignRoleDialog(castedItem.athleteId)}
        />
      ) : (
        <AssignButton onClick={openAssignRoleDialog(castedItem.athleteId)} />
      ),
      sports_director: mapping?.[UserRole.SportsDirector] ? (
        <AssignedStaff
          user={staffMap[mapping[UserRole.SportsDirector]]}
          onClick={openAssignRoleDialog(castedItem.athleteId)}
        />
      ) : (
        <AssignButton onClick={openAssignRoleDialog(castedItem.athleteId)} />
      ),
      doctor: mapping?.[UserRole.Doctor] ? (
        <AssignedStaff
          user={staffMap[mapping[UserRole.Doctor]]}
          onClick={openAssignRoleDialog(castedItem.athleteId)}
        />
      ) : (
        <AssignButton onClick={openAssignRoleDialog(castedItem.athleteId)} />
      ),
      secondary_doctor: mapping?.[UserRole.SecondaryDoctor] ? (
        <AssignedStaff
          user={staffMap[mapping[UserRole.SecondaryDoctor]]}
          onClick={openAssignRoleDialog(castedItem.athleteId)}
        />
      ) : (
        <AssignButton onClick={openAssignRoleDialog(castedItem.athleteId)} />
      ),
      physiotherapist: mapping?.[UserRole.Physiotherapist] ? (
        <AssignedStaff
          user={staffMap[mapping[UserRole.Physiotherapist]]}
          onClick={openAssignRoleDialog(castedItem.athleteId)}
        />
      ) : (
        <AssignButton onClick={openAssignRoleDialog(castedItem.athleteId)} />
      ),
      nutritionist: mapping?.[UserRole.Nutritionist] ? (
        <AssignedStaff
          user={staffMap[mapping[UserRole.Nutritionist]]}
          onClick={openAssignRoleDialog(castedItem.athleteId)}
        />
      ) : (
        <AssignButton onClick={openAssignRoleDialog(castedItem.athleteId)} />
      ),
      mental_coach: mapping?.[UserRole.MentalCoach] ? (
        <AssignedStaff
          user={staffMap[mapping[UserRole.MentalCoach]]}
          onClick={openAssignRoleDialog(castedItem.athleteId)}
        />
      ) : (
        <AssignButton onClick={openAssignRoleDialog(castedItem.athleteId)} />
      ),
    };

    return renderMap[column.key];
  };

  const emptyStateProps: IEmptyStateProps | undefined =
    loaded && filteredItems.length === 0
      ? {
          icon: <User size={32} />,
          title: t('banner.empty_users_title'),
          text: t('banner.empty_users_message'),
        }
      : undefined;

  return {
    columns,
    items: filteredItems,
    loading: !loaded,
    emptyStateProps,
    onRenderCell,
    assignRoleDialog,
  };
};
