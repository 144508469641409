import { BooleanOptional, IStringifyOptions } from 'qs';

export const qsOptions: IStringifyOptions<BooleanOptional> = {
  skipNulls: true,
  arrayFormat: 'comma',
  filter: (_, value) => {
    if (Array.isArray(value) && value.length === 0) {
      return '';
    }
    return value;
  },
};
