import { useCallback, useEffect, useMemo } from 'react';
import {
  ICriticalPower,
  ILatestTrainingLoad,
  IPeak,
  IWorkoutDetail,
} from '../../types/performance';
import { AnyValue } from '../../types/common';
import { IHandleChangeFilter } from '@cycling-web/common';
import { useTranslation } from 'react-i18next';
import { useAthletesStore } from '../../store/athletes/slice';
import { sortByAthleteDelegate } from '../../utils/sortByAthleteDelegate';
import { ChartAxisName } from '../../constants/charts';
import { useDashboardStore } from './store/slice';
import { IDashboardFilters } from './types';
import { useFetchTeamCriticalPower } from './hooks/useFetchTeamCriticalPower';
import { useFetchTeamPeaks } from './hooks/useFetchTeamPeaks';
import { useFetchTeamWorkoutDetails } from './hooks/useFetchTeamWorkoutDetails';
import { useGroupsStore } from '../../store/groups/slice';
import { IGroup } from '../../types/groups';
import { useFetchTeamTrainingLoad } from './hooks/useFetchTeamTrainingLoad';

export const useViewModel = () => {
  const { t } = useTranslation();
  useFetchTeamPeaks();
  useFetchTeamCriticalPower();
  useFetchTeamWorkoutDetails();
  useFetchTeamTrainingLoad();

  const criticalPower: ICriticalPower[] = useDashboardStore(
    (s) => s.criticalPower
  );
  const criticalPowerLoaded: boolean = useDashboardStore(
    (s) => s.criticalPowerLoaded
  );

  const workoutDetails: IWorkoutDetail[] = useDashboardStore(
    (s) => s.workoutDetails
  );
  const workoutDetailsLoaded: boolean = useDashboardStore(
    (s) => s.workoutDetailsLoaded
  );

  const trainingLoad: ILatestTrainingLoad[] = useDashboardStore(
    (s) => s.trainingLoad
  );
  const trainingLoadLoaded: boolean = useDashboardStore(
    (s) => s.trainingLoadLoaded
  );

  const peaks: IPeak[] = useDashboardStore((s) => s.peaks);
  const peaksLoaded: boolean = useDashboardStore((s) => s.peaksLoaded);

  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const filters = useDashboardStore((s) => s.filters);
  const setFilters = useDashboardStore((s) => s.setFilters);

  const groups = useGroupsStore((s) => s.groups);
  const groupAthleteMap = useMemo(() => {
    let map: Record<number, boolean> | undefined = undefined;
    const group = groups.find((g: IGroup) => g.id === filters.groupId);

    if (group) {
      group.athleteIds.forEach((athleteId: number) => {
        if (!map) {
          map = {};
        }
        map[athleteId] = true;
      });
      return map;
    }

    return map;
  }, [filters.groupId, groups]);
  const filterByGroupDelegate = useCallback(
    (item: { athleteId: number }) => {
      return !groupAthleteMap ? true : groupAthleteMap[item.athleteId];
    },
    [groupAthleteMap]
  );

  const sortedWorkoutDetails = useMemo(() => {
    const sorted = [...workoutDetails].filter(filterByGroupDelegate);
    sorted.sort(sortByAthleteDelegate(athletesMap));
    return sorted;
  }, [athletesMap, filterByGroupDelegate, workoutDetails]);

  const sortedCriticalPower = useMemo(() => {
    const sorted = [...criticalPower].filter(filterByGroupDelegate);
    sorted.sort(sortByAthleteDelegate(athletesMap));
    return sorted;
  }, [athletesMap, criticalPower, filterByGroupDelegate]);

  const sortedPeaks = useMemo(() => {
    const sorted = [...peaks].filter(filterByGroupDelegate);
    sorted.sort(sortByAthleteDelegate(athletesMap));
    return sorted;
  }, [athletesMap, peaks, filterByGroupDelegate]);

  const sortedTrainingLoad = useMemo(() => {
    const copy = [...trainingLoad].filter(filterByGroupDelegate);
    copy.sort(sortByAthleteDelegate(athletesMap));
    return copy;
  }, [trainingLoad, athletesMap, filterByGroupDelegate]);

  const handleChangeFilter: IHandleChangeFilter<IDashboardFilters> =
    useCallback(
      (key: keyof IDashboardFilters) => {
        return (value: AnyValue) => {
          setFilters({ [key]: value });
        };
      },
      [setFilters]
    );

  const workoutDetailsSources = useMemo(() => {
    const tssAndIf: (string | number)[][] = [['Athlete', 'TSS', 'IF']];
    const durationAndDistance: (string | number)[][] = [
      ['Athlete', t('label.duration'), t('label.distance')],
    ];
    const durationAndElevation: (string | number)[][] = [
      ['Athlete', t('label.duration'), t('label.elevation')],
    ];
    const workAndIf: (string | number)[][] = [
      ['Athlete', t('label.work'), 'IF'],
    ];

    sortedWorkoutDetails.forEach((item: IWorkoutDetail) => {
      const athlete = athletesMap[item.athleteId];

      tssAndIf.push([athlete?.fullName || '']);
      durationAndDistance.push([athlete?.fullName || '']);
      durationAndElevation.push([athlete?.fullName || '']);
      workAndIf.push([athlete?.fullName || '']);

      tssAndIf[tssAndIf.length - 1].push(
        item.tss || 0,
        item.intensityFactor || 0
      );

      durationAndDistance[durationAndDistance.length - 1].push(
        item.durationInHour || 0,
        item.distanceInKm || 0
      );

      durationAndElevation[durationAndElevation.length - 1].push(
        item.durationInHour || 0,
        item.climbInMeter || 0
      );

      workAndIf[workAndIf.length - 1].push(
        item.workInKj || 0,
        item.intensityFactor || 0
      );
    });

    return {
      tssAndIf,
      durationAndDistance,
      durationAndElevation,
      workAndIf,
    };
  }, [athletesMap, t, sortedWorkoutDetails]);

  const criticalPowerSource = useMemo(() => {
    const source: (string | number)[][] = [
      [
        ChartAxisName.Athlete,
        ChartAxisName.mCP,
        ChartAxisName.mCPRelative,
        ChartAxisName.W,
      ],
    ];

    sortedCriticalPower.forEach((item: ICriticalPower) => {
      const athlete = athletesMap[item.athleteId];

      source.push([athlete?.fullName || '']);

      source[source.length - 1].push(
        item.cp ? Math.round(item.cp) : 0,
        item.cp ? (item.cp / item.athleteWeight).toFixed(2) : 0,
        item.w ? (item.w / 1000).toFixed(1) : 0
      );
    });

    return source;
  }, [sortedCriticalPower, athletesMap]);

  const peaksSource = useMemo(() => {
    const source: (string | number)[][] = [[ChartAxisName.Athlete]];

    sortedPeaks.forEach((item: IPeak, i: number) => {
      const athlete = athletesMap[item.athleteId];

      source.push([athlete?.fullName || '']);

      for (const key in item) {
        if (key !== 'athleteId') {
          if (i === 0) {
            source[0].push(key);
          }

          source[source.length - 1].push(item[key]);
        }
      }
    });

    return source;
  }, [sortedPeaks, athletesMap]);

  const trainingLoadSources = useMemo(() => {
    const sources: Record<string, (string | number)[][]> = {
      tsb: [[ChartAxisName.Athlete, t('label.tsb')]],
    };

    sortedTrainingLoad.forEach((item: ILatestTrainingLoad) => {
      const athlete = athletesMap[item.athleteId];
      const athleteName = athlete?.fullName || '';
      sources.tsb.push([athleteName, item.tsb]);
    });

    return sources;
  }, [t, sortedTrainingLoad, athletesMap]);

  useEffect(() => {
    return () => {
      useDashboardStore.getState().reset();
    };
  }, []);

  return {
    workoutDetailsSources,
    workoutDetailsLoading: !workoutDetailsLoaded,
    criticalPowerSource,
    criticalPowerLoading: !criticalPowerLoaded,
    peaksSource,
    peaksLoading: !peaksLoaded,
    trainingLoadSources,
    trainingLoadLoading: !trainingLoadLoaded,
    filters,
    handleChangeFilter,
  };
};
