export function isValidDate(
  date: Date | undefined,
  min: Date | undefined,
  max: Date | undefined
): boolean {
  let valid = true;

  if (!date) {
    return true;
  }

  if (min) {
    valid = date >= min;
  }

  if (max && valid) {
    valid = date <= max;
  }

  return valid;
}
