import { useNavigate, useSearchParams } from 'react-router';
import { useCallback, useEffect, useMemo } from 'react';
import { ROUTES } from '../../router/routes';
import { TeamsRepository } from '../../api/teams/repository';
import { TeamsService } from '../../api/teams/service';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../constants';
import { Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { stringifyTeamName } from '../../utils/teamName';

export const TeamInvitation = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const team = params.get('team');
  const navigate = useNavigate();

  const teamsRepository = useMemo(() => {
    return new TeamsRepository(new TeamsService());
  }, []);

  const handleAcceptInvitation = useCallback(() => {
    if (!team) {
      navigate(`/${ROUTES.TEAMS}`);
      return;
    }

    teamsRepository
      .acceptInvitation({
        teamName: team,
      })
      .then(() => {
        navigate(`/${stringifyTeamName(team)}`);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          // @ts-ignore
          const responseMessage = error.response?.data?.message;

          // TODO: This is fragile, refactor later
          if (
            responseMessage === 'User has already accepted the team invitation'
          ) {
            navigate(`/${stringifyTeamName(team)}`);
          } else {
            Toast.error(
              {
                title: t('error.accept_invitation_title'),
                message:
                  responseMessage || t('error.accept_invitation_message'),
              },
              { toastId: 'accept_invitation' }
            );
          }
        }
      });
  }, [navigate, t, team, teamsRepository]);

  useEffect(() => {
    handleAcceptInvitation();
  }, [handleAcceptInvitation]);

  return null;
};
