import './index.css';
import { useTranslation } from 'react-i18next';
import { PerformanceReportTabs } from './components/PerformanceReportTabs';
import { Outlet } from 'react-router';
import { Page } from '@cycling-web/common';
import { useFetchWeeklyPerformanceReports } from '../PerformanceReportTable/hooks/useFetchWeeklyPerformanceReports';
import { useEffect } from 'react';
import { usePerformanceReportStore } from './store/slice';
import { useTitle } from '../../hooks/useTitle';

export const PerformanceReport = () => {
  const { t } = useTranslation();
  useTitle(t('label.performance_report'));
  useFetchWeeklyPerformanceReports();
  const reset = usePerformanceReportStore((s) => s.reset);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Page pageHeaderProps={{ title: t('label.performance_report') }}>
      <PerformanceReportTabs />
      <Outlet />
    </Page>
  );
};
