import { ICollapsibleProps } from './types';
import './index.css';
import { clsx } from 'clsx';
import { ChevronUp } from 'lucide-react';

export const Collapsible = (props: ICollapsibleProps) => {
  const { className, icon, text, content, maxHeight, isOpen, onChange, id } =
    props;

  const toggleOpen = () => {
    onChange(!isOpen, id);
  };

  const rootClass: string = clsx(
    'analog-collapsible',
    isOpen ? 'analog-collapsible--open' : 'analog-collapsible--closed',
    className
  );

  const contentStyle =
    maxHeight !== undefined && isOpen
      ? {
          maxHeight,
        }
      : {};

  return (
    <div className={rootClass}>
      <div className="analog-collapsible__underlay" />
      <button className="analog-collapsible__header" onClick={toggleOpen}>
        {icon && <span className="analog-collapsible__icon">{icon}</span>}
        <span className="analog-collapsible__text analog-typography--body bold">
          {text}
        </span>
        {content && (
          <span className="analog-collapsible__chevron">
            <ChevronUp size={18} />
          </span>
        )}
      </button>
      {content && (
        <div className="analog-collapsible__content" style={contentStyle}>
          {content}
        </div>
      )}
    </div>
  );
};
