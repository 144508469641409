import './index.css';
import { Spinner } from '@cycling-web/analog-ui';
import React from 'react';

export const PageSpinner = () => {
  return (
    <div className="page-spinner__wrapper">
      <Spinner size="xxl" />
    </div>
  );
};
