import { PageFilters } from '@cycling-web/common';
import { WeeksFilter } from '../../../../components/filters/WeeksFilter';
import { MultipleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import { useCallback } from 'react';
import { AnyValue } from '../../../../types/common';
import {
  initialFilters,
  usePerformanceReportStore,
} from '../../../PerformanceReport/store/slice';
import { IPerformanceReportFilters } from '../../../PerformanceReport/types';
import { useURLFilters } from '../../../../hooks/useURLFilters';
import { Url } from '../../../../utils/url';

export const PerformanceChartsFilters = () => {
  const filters = usePerformanceReportStore((s) => s.filters);
  const setFilters = usePerformanceReportStore((s) => s.setFilters);

  const initFilters = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsAthletes = Url.prepareAthletes(params);

    usePerformanceReportStore.getState().setFilters({
      athletes: paramsAthletes || initialFilters.athletes,
      search: params.get('search') || initialFilters.search,
      week: params.get('week') || initialFilters.week,
    });
  }, []);

  useURLFilters({ filters, initFilters });

  const handleChangeFilter = useCallback(
    (key: keyof IPerformanceReportFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  );

  const handleCLearSearch = useCallback(() => {
    setFilters({ search: '' });
  }, [setFilters]);

  const filtersJSX = (
    <>
      <MultipleAthleteFilter
        value={filters.athletes.map(Number)}
        onChange={handleChangeFilter('athletes')}
        style={{ minWidth: '220px' }}
        className="performance-charts__athletes-filters"
      />
      <WeeksFilter value={filters.week} onChange={handleChangeFilter('week')} />
    </>
  );

  return (
    <PageFilters
      searchInputProps={{
        value: filters.search,
        onChange: handleChangeFilter('search'),
        onClear: handleCLearSearch,
      }}
      filterProps={{ filters: filtersJSX }}
    />
  );
};
