import { useAthletePerformanceStore } from '../store/slice';
import { useCallback } from 'react';
import { IAthletePerformanceFilters, IAthleteSharedFilters } from '../types';
import { AnyValue } from '../../../types/common';
import { ITrainingLoadFilters } from '../../AthleteTrainingLoad/types';
import { IHandleChangeFilter } from '@cycling-web/common';

type IUseStateFilters = {
  filters: IAthleteSharedFilters;
  setFilters: (filters: Partial<IAthletePerformanceFilters>) => void;
  handleChangeFilter: IHandleChangeFilter<IAthleteSharedFilters>;
};

export const useStateFilters = (): IUseStateFilters => {
  const filters = useAthletePerformanceStore((s) => s.filters.shared);
  const setFilters = useAthletePerformanceStore((s) => s.setFilters);

  const handleChangeFilter = useCallback(
    (key: keyof IAthleteSharedFilters) => {
      return (value: AnyValue) => {
        setFilters({ shared: { [key]: value } });
      };
    },
    [setFilters]
  );

  return {
    filters,
    setFilters,
    handleChangeFilter,
  };
};
