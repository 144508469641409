import { DateRange } from '../../../types/enums';

export const getScatterSizeByDateRange = (dateRange: DateRange): number => {
  switch (dateRange) {
    case DateRange.Week:
      return 12;
    case DateRange.Month:
      return 12;
    case DateRange.TwoMonth:
      return 12;
    case DateRange.ThreeMonth:
      return 8;
    case DateRange.Year:
      return 6;
    default:
      return 10;
  }
};
