import { addLeadingZero } from './addLeadingZero';

export const convertDateToString = (date: Date | undefined): string => {
  if (!date) {
    return '';
  }

  const convertedDate = new Date(date);

  const dd = addLeadingZero(convertedDate.getDate());
  const mm = addLeadingZero(convertedDate.getMonth() + 1);
  const yyyy = convertedDate.getFullYear();

  return `${dd}.${mm}.${yyyy}`;
};
