import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../../router/routes';
import { useAccount, useMsal } from '@azure/msal-react';
import { useUsersStore } from '../../../../store/users/slice';
import { UsersRepository } from '../../../../api/users/repository';
import { UsersService } from '../../../../api/users/service';
import { AuthenticationResult } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common/browser';
import { LS } from '../../../../constants';
import { complianceModuleInstance } from '../../../../utils/ComplianceModule';
import { TeamsRepository } from '../../../../api/teams/repository';
import { TeamsService } from '../../../../api/teams/service';
import { IUserTeam } from '../../../../types/teams';
import { stringifyTeamName } from '../../../../utils/teamName';
import { useAppStore } from '../../../../store/app/slice';
import { useTeamsStore } from '../../../../store/teams/slice';

type IProps = {
  children: ReactNode;
};

export const AuthGuard = ({ children }: IProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const fetchingRef = useRef<boolean>(false);
  const setAppLoading = useAppStore((s) => s.setLoading);
  const { instance } = useMsal();
  const account = useAccount();
  const navigate = useNavigate();
  const stableNavigateRef = useRef(navigate);

  const currentPagePathname: string = useMemo(() => {
    return (
      window.location.pathname + window.location.search + window.location.hash
    );
  }, []);

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  const teamsRepository = useMemo(() => {
    return new TeamsRepository(new TeamsService());
  }, []);

  const onAcquireToken = useCallback((accessToken: string | undefined) => {
    if (!accessToken) {
      return;
    }
    const token = `Bearer ${accessToken}`;
    localStorage.setItem(LS.AccessToken, token);
    useUsersStore.getState().setAuthenticated(true);
  }, []);

  const acquireTokenSilent = useCallback(
    (
      account: AccountInfo | null | undefined
    ): Promise<AuthenticationResult | null> => {
      if (!account) {
        return Promise.resolve(null);
      }

      const request = {
        scopes: ['openid'],
        account,
      };
      return instance.acquireTokenSilent(request);
    },
    [instance]
  );

  useEffect(() => {
    if (!account) {
      localStorage.setItem(LS.PreviousPage, currentPagePathname);
      stableNavigateRef.current(`/${ROUTES.SIGN_IN}`);
      return;
    }

    if (fetchingRef.current) {
      return;
    }

    fetchingRef.current = true;

    let response: AuthenticationResult | null = null;

    acquireTokenSilent(account)
      .then((result) => {
        if (!result) {
          return Promise.reject();
        }

        complianceModuleInstance.setMSALServerTelemetryConsent(false);

        response = result;
        return usersRepository.checkRegistration({
          idToken: response.idToken,
          accessToken: response.accessToken,
        });
      })
      .then(() => {
        onAcquireToken(response?.idToken);
        return teamsRepository.getUserTeams();
      })
      .then((teams: IUserTeam[]) => {
        useTeamsStore.getState().setTeams(teams);
        const pathname = window.location.pathname;
        if (teams.length === 1) {
          const team = teams[0];

          if (team.invitationAccepted) {
            const teamName = stringifyTeamName(teams[0].tenantName);
            if (
              !pathname.includes(teamName) &&
              !pathname.includes(ROUTES.INTEGRATIONS)
            ) {
              stableNavigateRef.current(
                `/${stringifyTeamName(teams[0].tenantName)}`
              );
            }
          } else {
            stableNavigateRef.current(
              `/${ROUTES.TEAM_INVITATION}?team=${team.tenantName}`
            );
          }
        } else {
          setAppLoading(false);
        }
      })
      .catch(() => {
        stableNavigateRef.current(`/${ROUTES.SIGN_OUT}`);
      })
      .finally(() => {
        setLoading(false);
        fetchingRef.current = false;
      });
  }, [
    account,
    acquireTokenSilent,
    currentPagePathname,
    instance,
    onAcquireToken,
    teamsRepository,
    usersRepository,
    setAppLoading,
  ]);

  if (loading) {
    return null;
  }

  return children;
};
