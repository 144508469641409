import './index.css';
import { Switch, Toast, Typography, useBoolean } from '@cycling-web/analog-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { useAdminTeamMemberStore } from '../../../AdminPanel/store/slice';
import { UserRole } from '@cycling-web/common';
import { UsersRepository } from '../../../../api/users/repository';
import { UsersService } from '../../../../api/users/service';
import { IUpdateStaffRoleRequest } from '../../../../api/users/types';
import { useTranslation } from 'react-i18next';

type IProps = {
  role: {
    id: UserRole;
    name: string;
    description: string;
  };
};

export const RoleSwitch = ({ role }: IProps) => {
  const { t } = useTranslation();
  const currentUser = useAdminTeamMemberStore((s) => s.currentUser);
  const updateCurrentUser = useAdminTeamMemberStore((s) => s.updateCurrentUser);

  const disabled =
    currentUser?.athleteId === undefined ? role.id === UserRole.Athlete : true;

  const { value: checked, toggle, setTrue } = useBoolean(false);

  useEffect(() => {
    if (currentUser?.roles?.includes(role.id)) {
      setTrue();
    }
  }, [currentUser?.roles, role.id, setTrue]);

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  const onChange = useCallback(() => {
    if (disabled || !currentUser?.staffId) {
      return;
    }

    toggle();

    const payload: IUpdateStaffRoleRequest = {
      staffId: currentUser.staffId,
      roles: [...currentUser.roles],
    };

    if (payload.roles.includes(role.id)) {
      payload.roles = payload.roles.filter((r: UserRole) => r !== role.id);
    } else {
      payload.roles.push(role.id);
    }

    usersRepository
      .updateStaffRole(payload)
      .then(() => {
        updateCurrentUser({
          roles: payload.roles,
        });
      })
      .catch((e) => {
        Toast.error({
          title: t('error.update_member_role'),
          message: e.message || '',
        });
        toggle();
      });
  }, [
    disabled,
    currentUser,
    toggle,
    role.id,
    usersRepository,
    updateCurrentUser,
    t,
  ]);

  return (
    <div className="team-member__role-switch">
      <div className="team-member__role-details">
        <Typography
          text={role.name}
          weight="bold"
          className="team-member__role-name"
        />
        <Typography
          text={role.description}
          variant="subtitle"
          weight="regular"
          className="team-member__role-description"
        />
      </div>

      <div className="team-member__role-actions">
        <Switch
          checked={checked}
          onChange={onChange}
          size="s"
          variant="primaryDark"
          disabled={disabled}
        />
      </div>
      {disabled && <div className="team-member__role-overlay" />}
    </div>
  );
};
