import { FormElement } from '@cycling-web/analog-ui';
import {
  InputControl,
  InputNumberControl,
  TextareaControl,
} from '@cycling-web/common';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-hook-form';
import { IReportFeedbackForm } from '../../../../types/performance';
import { CoachRatingTooltip } from './CoachRatingTooltip';
import { CoachCommentTooltip } from './CoachCommentTooltip';

export const ReportFeedbackForm = () => {
  const { t } = useTranslation();
  const { errors } = useFormState<IReportFeedbackForm>();

  return (
    <div className="report-feedback-form">
      <FormElement
        label={t('label.athlete_performance_rating')}
        tooltipProps={{
          placement: 'bottom-end',
          content: <CoachRatingTooltip />,
        }}
        message={errors.performanceRating?.message}
      >
        <InputNumberControl
          name="performanceRating"
          placeholder={t('placeholder.number_between', {
            min: 1,
            max: 10,
          })}
          invalid={!!errors.performanceRating}
          maxLength={2}
          autoComplete="off"
        />
      </FormElement>

      <FormElement
        label={t('label.coach_evaluation_comment')}
        tooltipProps={{
          placement: 'bottom-end',
          maxWidth: '420px',
          content: <CoachCommentTooltip />,
        }}
        message={errors.comments?.message}
      >
        <TextareaControl
          name="comments"
          placeholder={t('placeholder.comment')}
          invalid={!!errors.comments}
          maxLength={250}
          autoComplete="off"
        />
      </FormElement>

      <FormElement
        label={t('label.next_race')}
        message={errors.nextRace?.message}
      >
        <InputControl
          name="nextRace"
          placeholder={t('placeholder.next_race')}
          invalid={!!errors.nextRace}
          autoComplete="off"
        />
      </FormElement>
    </div>
  );
};
