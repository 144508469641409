import { useViewModel } from './useViewModel';
import { Table } from '@cycling-web/analog-ui';

export const TeamUsersTable = () => {
  const { columns, items, loading, emptyStateProps, onRenderCell, onRowClick } =
    useViewModel();

  return (
    <Table
      columns={columns}
      items={items}
      loading={loading}
      skeletonCount={4}
      emptyState={emptyStateProps}
      onRenderCell={onRenderCell}
      onRowClick={onRowClick}
    />
  );
};
