import './index.css';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import React, { useCallback, useMemo } from 'react';
import { useSmartChartsStore } from '../../store/slice';
import { computeGraphOption } from '../../utils-old/graphConfig';
import ReactECharts from 'echarts-for-react';
import {
  EmptyState,
  IconButton,
  Spinner,
  useBoolean,
} from '@cycling-web/analog-ui';
import { PinChartModal } from '../PinChartModal';
import { Maximize2, Minimize2, Pin } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSmartChartsContext } from '../../context';

export const ChartContainer = () => {
  const { t } = useTranslation();
  const { expanded, setExpanded } = useSmartChartsContext();
  const config = useSmartChartsStore((s) => s.config);
  const chartLoaded = useSmartChartsStore((s) => s.chartLoaded);
  const selectedAthletes = useSmartChartsStore((s) => s.selectedAthletes);
  const graphs = config?.ai_graph_in_scope?.ai_generated_graphs || [];
  const hasData = graphs.length > 0 && graphs[0] && graphs[0].plots.length > 0;

  const toggleExpand = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, [setExpanded]);

  const {
    value: showPinModal,
    setTrue: openPinModal,
    setFalse: dismissPinModal,
  } = useBoolean(false);

  const option = useMemo(() => {
    if (!config || !hasData) {
      return {};
    }

    // @ts-ignore
    const option = computeGraphOption(config, selectedAthletes);
    return option;
  }, [config, hasData, selectedAthletes]);

  // const onRenderTooltipValue = useCallback((value: number, _: string) => {
  //   return value;
  // }, []);

  if (graphs.length === 0 || !hasData) {
    return null;
  }

  return (
    <ChartWrapper minHeight="206px" className="sc2__chart-wrapper">
      {chartLoaded ? (
        <ReactECharts
          opts={{ renderer: 'svg' }}
          option={option}
          style={{ height: '100%', width: '100%' }}
        />
      ) : (
        <EmptyState
          icon={<Spinner size="l" />}
          text={t('banner.loading_chart_data')}
        />
      )}

      <div className="sc2__chart-actions">
        <IconButton
          size="s"
          variant="quietNeutralDark"
          content={<Pin />}
          onClick={openPinModal}
        />
        <IconButton
          size="s"
          variant="quietNeutralDark"
          content={expanded ? <Minimize2 /> : <Maximize2 />}
          onClick={toggleExpand}
        />
      </div>

      {showPinModal && <PinChartModal onDismiss={dismissPinModal} />}
    </ChartWrapper>
  );
};
