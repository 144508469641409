import './index.css';
import { useSmartChartsContext } from '../../context';
import {
  Avatar,
  AvatarStack,
  IAvatarStackAvatar,
  IconButton,
} from '@cycling-web/analog-ui';
import { IAthlete } from '../../../../types/athletes';
import { Pencil } from 'lucide-react';
import { useUserRole } from '../../../../hooks/useUserRole';

export const SelectedAthletes = () => {
  const { selectedAthletes, openAthletesModal } = useSmartChartsContext();
  const { isAthlete } = useUserRole();

  if (selectedAthletes.length === 0) {
    return null;
  }

  const avatarsProps: IAvatarStackAvatar[] = selectedAthletes.map(
    (a: IAthlete) => {
      return {
        text: a.fullName,
        src: a.picture,
      };
    }
  );

  return (
    <div className="sc2__selected-athletes">
      {selectedAthletes.length === 1 ? (
        <Avatar size="m" {...avatarsProps[0]} />
      ) : (
        <AvatarStack size="m" avatars={avatarsProps} />
      )}
      {!isAthlete && (
        <div className="sc2__selected-athletes-actions">
          <IconButton
            variant="quietNeutralDark"
            content={<Pencil />}
            onClick={openAthletesModal}
          />
        </div>
      )}
    </div>
  );
};
