import './index.css';
import {
  Button,
  FormElement,
  ISelectOption,
  Select,
  Typography,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useWeekDays } from '../../../../hooks/useWeekDays';
import { useMemo } from 'react';

export const PerformanceReportCommunication = () => {
  const { t } = useTranslation();
  const daysOptions: ISelectOption[] = useWeekDays();
  const timeOptions: ISelectOption[] = useMemo(() => {
    return Array.from({ length: 24 }, (_, i) => {
      const hour = i.toString().padStart(2, '0');
      return {
        id: i.toString(),
        text: `${hour}:00`,
      };
    });
  }, []);

  return (
    <div className="performance-report-communication">
      <header className="performance-report-communication__header">
        <Typography
          text={t('label.performance_report_communication')}
          variant="headline"
          weight="bold"
          color="text-primary"
        />
      </header>
      <div className="performance-report-communication__body">
        <div className="communication-section">
          <div className="communication-title">
            <Typography
              text={t('label.coaches_input_email')}
              color="text-tertiary"
            />
          </div>

          <div className="communication-row">
            <FormElement label={t('placeholder.select_day')}>
              <Select
                options={daysOptions}
                value={undefined}
                onChange={() => {}}
                placeholder={t('placeholder.select_day')}
                variant="light"
              />
            </FormElement>
            <FormElement label={t('placeholder.select_time')}>
              <Select
                options={timeOptions}
                value={undefined}
                onChange={() => {}}
                placeholder={t('placeholder.select_time')}
                variant="light"
                dropdownProps={{ maxHeight: '192px' }}
              />
            </FormElement>
          </div>
        </div>

        <div className="communication-section">
          <div className="communication-title">
            <Typography
              text={t('label.weekly_report_available_date')}
              color="text-tertiary"
            />
          </div>

          <div className="communication-row">
            <FormElement label={t('placeholder.select_day')}>
              <Select
                options={daysOptions}
                value={undefined}
                onChange={() => {}}
                placeholder={t('placeholder.select_day')}
                variant="light"
              />
            </FormElement>
            <FormElement label={t('placeholder.select_time')}>
              <Select
                options={timeOptions}
                value={undefined}
                onChange={() => {}}
                placeholder={t('placeholder.select_time')}
                variant="light"
                dropdownProps={{ maxHeight: '192px' }}
              />
            </FormElement>
          </div>
        </div>

        <footer className="communication-actions">
          <Button content={t('action.save')} />
        </footer>
      </div>
    </div>
  );
};
