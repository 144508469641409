export type IReportIllnessFormData = {
  id: number;
  athleteId: number;
  recordType: string;
  illnessType: string;
  onsetDate: Date | undefined;
  estimatedRecovery: Date | undefined;
  severity: string;
  symptom: string;
  ossicsCode: string;
  note: string;
};

export type IReportRehabilitationFormData = {
  id: number;
  athleteId: number;
  recordType: string;
  onsetDate: Date | undefined;
  estimatedRecovery: Date | undefined;
  side: string;
  bodyLocation: string;
  rehabilitationStage: string;
  type: string;
  assessmentTools: string[];
  modalityUsed: string[];
  note: string;
};

export type IReportInjuryFormData = {
  id: number;
  athleteId: number;
  recordType: string;
  onsetDate: Date | undefined;
  estimatedRecovery: Date | undefined;
  side: string;
  severity: string;
  area: string;
  ossicsCode: string;
  onsetMode: string[];
  note: string;
};

export type IMedicalReport = {
  id: number;
  athleteId: number;
  recordType: IMedicalReportRecordType;
  onsetMode: string;
  bodyLocation: IMedicalReportBodyLocation;
  side: IMedicalReportSize;
  rehabilitationStage: IMedicalReportRehabilitationStage;
  type: IMedicalReportType;
  illnessType: IMedicalReportIllnessType;
  assessmentTools: string;
  modalityUsed: string;
  note: string;
  symptom: string;
  severity: IMedicalReportSeverity;
  ossicsCode: IMedicalReportOssicsCode;
  area: IMedicalReportArea;
  recovered: boolean;
  onsetDate: number;
  estimatedRecovery: number;
  recoveredOn: number;
  createdBy: string;
  editable: boolean;
};

export type IMedicalReportRecordType = string;
export type IMedicalReportBodyLocation = string;
export type IMedicalReportSize = string;
export type IMedicalReportRehabilitationStage = string;
export type IMedicalReportType = string;
export type IMedicalReportIllnessType = string;
export type IMedicalReportSeverity = string;
export type IMedicalReportOssicsCode = string;
export type IMedicalReportArea = string;

export type IMedicalReportRecord = {
  id: number;
  athleteId: number;
  recordType: string;
  issue: string;
  severity: string;
  onsetDate: number;
  estimatedRecovery: number;
  createdBy: string;
  notesCount: number;
  attachmentsCount: number;
};

export type IMedicalNote = {
  id?: number;
  medicalReportId: number;
  note: string;
  createdBy: string;
  editable: boolean;
};

export type IMedicalFile = {
  id: number;
  medicalReportId: number;
  fileName: string;
  filePath: string;
  fileSize: string;
  fileDescription: string;
  createdBy: string;
  editable: boolean;
};

export type IUploadFileFormData = {
  multipartFile: File;
  medicalReportId: number;
  fileDescription?: string;
};

export enum MedicalRecordType {
  Illness = 'ILLNESS',
  Physio = 'PHYSIO',
  Injury = 'INJURY',
}
