import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Environment, getEnvironment } from '../utils/getEnvironment';
import { complianceModuleInstance } from '../utils/ComplianceModule';

let appInsights: ApplicationInsights | undefined = undefined;

if (getEnvironment() !== Environment.Local) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: import.meta.env.VITE_APPINSIGHTS_CONNECTION_STRING,
      autoTrackPageVisitTime: true,
      maxBatchInterval: 5000,
      enableAutoRouteTracking: true,
      disableCookiesUsage: complianceModuleInstance.getAppsInsightsConsent(),
      isStorageUseDisabled: complianceModuleInstance.getAppsInsightsConsent(),
      cookieCfg: {
        enabled: false,
      },
    },
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export { appInsights };
