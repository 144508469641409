import { useUsersStore } from '../../../../store/users/slice';
import { useUserRole } from '../../../../hooks/useUserRole';
import { useCallback, useMemo, useState } from 'react';
import { renameFile } from '../../../../utils/files';
import { Toast, useBoolean } from '@cycling-web/analog-ui';
import { UsersRepository } from '../../../../api/users/repository';
import { UsersService } from '../../../../api/users/service';
import { useTranslation } from 'react-i18next';

export const useViewModel = () => {
  const { t } = useTranslation();
  const userProfile = useUsersStore((s) => s.userProfile);
  const { roles } = useUserRole();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [base64, setBase64] = useState<string | undefined>(undefined);

  const {
    value: showUploadPictureModal,
    setTrue: openUploadPictureModal,
    setFalse: dismissUploadPictureModal,
  } = useBoolean(false);

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  const onImageChange = useCallback(
    (file: File, base64: string) => {
      setFile(file);
      setBase64(base64);
      openUploadPictureModal();
    },
    [openUploadPictureModal]
  );

  const onImageUpload = useCallback(
    (picture: File | null, src: string | null): Promise<void> => {
      if (!picture || !userProfile) {
        return Promise.resolve();
      }

      const payload = {
        userId: userProfile.id,
        picture: renameFile(picture),
      };

      return usersRepository
        .updatePicture(payload)
        .then(() => {
          if (src) {
            useUsersStore.getState().updateUserProfile({
              picture: src,
            });
          }
        })
        .catch(() => {
          Toast.error({
            title: t('error.file_too_large_title'),
          });
        });
    },
    [t, userProfile, usersRepository]
  );

  return {
    onImageUpload,
    userProfile,
    roles,
    showUploadPictureModal,
    onImageChange,
    dismissUploadPictureModal,
    file,
    base64,
  };
};
