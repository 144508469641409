import './index.css';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { Dialog, FormElement, Toast } from '@cycling-web/analog-ui';
import { IAthlete } from '../../types/athletes';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputNumberControl } from '@cycling-web/common';
import { IFTPFormData } from '../../types/performance';
import { AthletesRepository } from '../../api/athletes/repository';
import { AthletesService } from '../../api/athletes/service';
import { useAthleteStore } from '../../store/athlete/slice';

type IProps = {
  athlete: IAthlete;
  onDismiss: () => void;
};

export const FTPFormModal = ({ athlete, onDismiss }: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const updateAthlete = useAthleteStore((s) => s.updateAthlete);

  const athleteRepository = useMemo(() => {
    return new AthletesRepository(new AthletesService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        ftp: z
          .string()
          .min(1, { message: t('validation.required') })
          .refine((val) => /^\d+$/.test(val), {
            message: t('validation.numeric'),
          })
          .refine((val) => +val >= 100 && +val <= 999, {
            message: t('validation.out_of_range', { min: 100, max: 999 }),
          }),
      })
      .passthrough();
  }, [t]);

  const form = useForm<IFTPFormData>({
    defaultValues: {
      athleteId: athlete.id,
      ftp: '',
    },
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleSave = useCallback(
    (formData: IFTPFormData) => {
      setLoading(true);

      athleteRepository
        .updateProfile({
          ...athlete,
          ftp: +formData.ftp,
        })
        .then((response) => {
          updateAthlete({
            ...response,
            sftp: response.ftp,
          });
          onDismiss();
        })
        .catch(() => {
          Toast.error(
            {
              title: t('error.save_ftp_title'),
              message: t('error.save_ftp_message'),
            },
            { toastId: 'save_ftp' }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [athleteRepository, athlete, onDismiss, t, updateAthlete]
  );

  const onSubmit = useCallback(() => {
    handleSubmit(
      (formData: IFTPFormData) => {
        handleSave(formData);
      },
      (errors) => {
        console.log(errors);
      }
    )();
  }, [handleSave, handleSubmit]);

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.ftp')}
        onDismiss={onDismiss}
        outsidePress={false}
        submitButtonProps={{
          content: t('action.save'),
          onClick: onSubmit,
          loading: loading,
        }}
      >
        <div className="ftp-form-modal">
          <FormElement label={t('label.ftp')} message={errors.ftp?.message}>
            <InputNumberControl
              name="ftp"
              placeholder={t('placeholder.number_between', {
                min: 100,
                max: 999,
              })}
              invalid={!!errors.ftp}
              maxLength={3}
            />
          </FormElement>
        </div>
      </Dialog>
    </FormProvider>
  );
};
