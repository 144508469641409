import { EMPTY } from '../../../constants';
import { Tag } from '@cycling-web/analog-ui';
import { TsbUtils } from '../../../utils/tsb';

type IProps = {
  tsb: number;
};

export const TsbTag = ({ tsb }: IProps) => {
  if (tsb === undefined) {
    return EMPTY;
  }

  const { text, tagVariant } = TsbUtils.getContent(TsbUtils.getStatus(tsb));

  return <Tag text={text} variant={tagVariant} shape="round" />;
};
