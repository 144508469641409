import { useFetchAthleteWellness } from './hooks/useFetchAthleteWellness';
import { useFetchAthleteSelfReportedWellness } from './hooks/useFetchAthleteSelfReportedWellness';
import { initialFilters, useAthleteWellnessStore } from './store/slice';
import { useURLFilters } from '../../hooks/useURLFilters';
import { useCallback, useEffect } from 'react';
import qs from 'qs';
import { IAthleteWellnessFilters } from './types';
import { IHeartMeasurement, ISelfReportedWellness } from '../../types/wellness';
import { useFetchAthleteHeartMeasurements } from './hooks/useFetchAthleteHeartMeasurements';
import { useStateFilters } from './hooks/useStateFilters';

export const useViewModel = () => {
  useFetchAthleteWellness();
  useFetchAthleteSelfReportedWellness();
  useFetchAthleteHeartMeasurements();

  const selfReportedWellness: ISelfReportedWellness | null =
    useAthleteWellnessStore((s) => s.selfReportedWellness);
  const selfReportedWellnessLoaded: boolean = useAthleteWellnessStore(
    (s) => s.selfReportedWellnessLoaded
  );

  const heartMeasurements: IHeartMeasurement | null = useAthleteWellnessStore(
    (s) => s.heartMeasurements
  );
  const heartMeasurementsLoaded: boolean = useAthleteWellnessStore(
    (s) => s.heartMeasurementsLoaded
  );

  const { filters, handleChangeFilter } = useStateFilters();

  const initFilters = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const qsFilters = qs.parse(
      params.toString()
    ) as unknown as IAthleteWellnessFilters;

    useAthleteWellnessStore.getState().setFilters({
      period: qsFilters?.period ? +qsFilters.period : initialFilters.period,
      dateGroupBy: qsFilters?.dateGroupBy || initialFilters.dateGroupBy,
    });
  }, []);

  useURLFilters({ filters, initFilters });

  useEffect(() => {
    return () => {
      useAthleteWellnessStore.getState().setFilters(initialFilters);
    };
  }, []);

  return {
    selfReportedWellness,
    selfReportedWellnessLoading: !selfReportedWellnessLoaded,
    heartMeasurements,
    heartMeasurementsLoading: !heartMeasurementsLoaded,
    filters,
    handleChangeFilter,
  };
};
