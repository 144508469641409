import { IUserBase } from '../../../types/users';
import { IAdminTeamMemberFilters } from '../../AdminPanel/types';

export const filterUsersDelegate = (filters: IAdminTeamMemberFilters) => {
  return (item: IUserBase) => {
    let valid = true;

    if (
      filters.search &&
      !item?.name?.toLowerCase().includes(filters.search.toLowerCase())
    ) {
      valid = false;
    }

    return valid;
  };
};
