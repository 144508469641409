import { ITeamsSlice, ITeamsState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IUserTeam } from '../../types/teams';

export const initialState: ITeamsState = {
  teams: [],
  teamsLoaded: false,
  selectedTeam: null,
};

export const useTeamsStore = create<ITeamsSlice>()(
  immer((set) => ({
    ...initialState,
    setTeams: (teams: IUserTeam[]) => {
      set((state) => {
        state.teams = teams;
        state.teamsLoaded = true;
      });
    },
    setSelectedTeam: (team: IUserTeam | null) => {
      set((state) => {
        state.selectedTeam = team;
      });
    },
    acceptInvitation: (team: IUserTeam) => {
      set((state) => {
        const index = state.teams.findIndex(
          (t: IUserTeam) => t.tenantName === team.tenantName
        );
        if (index >= 0) {
          state.teams[index].invitationAccepted = true;
        }
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
