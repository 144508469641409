import { GroupsService } from './service';

import { IEditGroupFormData, IGroup, IGroupReport } from '../../types/groups';
import { IDeleteGroupRequest, IGetGroupReportRequest } from './types';

/** The repository is responsible for data transformation */

export interface IGroupsRepository {
  getGroups: () => Promise<IGroup[]>;
  getGroupById: (id: string) => Promise<IGroup>;
  createGroup: (payload: IEditGroupFormData) => Promise<IGroup>;
  updateGroup: (payload: IEditGroupFormData) => Promise<IGroup>;
  deleteGroup: (payload: IDeleteGroupRequest) => Promise<void>;
  getGroupReport: (payload: IGetGroupReportRequest) => Promise<IGroupReport[]>;
}

export class GroupsRepository implements IGroupsRepository {
  constructor(private readonly service: GroupsService) {}

  async getGroups(): Promise<IGroup[]> {
    const response = await this.service.getGroups();
    return response.data;
  }

  async getGroupById(id: string): Promise<IGroup> {
    const response = await this.service.getGroupById({
      id: +id,
    });
    return response.data.data[0];
  }

  async createGroup(payload: IEditGroupFormData): Promise<IGroup> {
    const copy = { ...payload };
    // @ts-ignore
    delete copy.id;
    const response = await this.service.createGroup(copy);
    return response.data;
  }

  async updateGroup(payload: IEditGroupFormData): Promise<IGroup> {
    const response = await this.service.updateGroup(payload);
    return response.data;
  }

  async deleteGroup(payload: IDeleteGroupRequest): Promise<void> {
    const response = await this.service.deleteGroup(payload);
    return response.data;
  }

  async getGroupReport(
    payload: IGetGroupReportRequest
  ): Promise<IGroupReport[]> {
    const response = await this.service.getGroupReport(payload);
    return response.data;
  }
}
