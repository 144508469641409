import { IAdminTeamMemberSlice, IAdminTeamMemberState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IUserBase } from '../../../types/users';
import { IAdminTeamMemberFilters } from '../types';

export const initialFilters: IAdminTeamMemberFilters = {
  search: '',
};

export const initialState: IAdminTeamMemberState = {
  users: [],
  athletes: [],
  staff: [],
  staffMap: {},
  usersLoaded: false,
  filters: initialFilters,
  currentUser: null,
};

export const useAdminTeamMemberStore = create<IAdminTeamMemberSlice>()(
  immer((set) => ({
    ...initialState,
    setUsers: (users: IUserBase[]) => {
      set((state) => {
        state.users = users;
        state.athletes = users.filter(
          (user) => user.athleteId !== undefined && user.status !== 'Pending'
        );
        state.staff = users.filter(
          (user) => user.staffId !== undefined && user.status !== 'Pending'
        );
        state.staffMap = state.staff.reduce(
          (acc: Record<number, IUserBase>, item: IUserBase) => {
            if (item.staffId) {
              acc[item.staffId] = item;
            }
            return acc;
          },
          {}
        );

        state.usersLoaded = true;
      });
    },
    setUsersLoaded: (loaded: boolean) => {
      set((state) => {
        state.usersLoaded = loaded;
      });
    },
    appendUser: (user: IUserBase) => {
      set((state) => {
        state.users.push(user);
      });
    },
    updateUser: (user: Partial<IUserBase>) => {
      set((state) => {
        const index = state.users.findIndex((u) => {
          return u.id === user.id;
        });
        if (index >= 0) {
          state.users[index] = {
            ...state.users[index],
            ...user,
          };
        }
      });
    },
    removeUser: (email: string) => {
      set((state) => {
        state.users = state.users.filter((u: IUserBase) => u.email !== email);
      });
    },
    setCurrentUser: (currentUser: IUserBase | null) => {
      set((state) => {
        state.currentUser = currentUser;
      });
    },
    updateCurrentUser: (currentUser: Partial<IUserBase>) => {
      set((state) => {
        if (!state.currentUser) {
          return state.currentUser;
        }
        state.currentUser = {
          ...state.currentUser,
          ...currentUser,
        };
      });
    },
    setFilters: (filters: Partial<IAdminTeamMemberFilters>) => {
      set((state) => {
        state.filters = {
          ...state.filters,
          ...filters,
        };
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
