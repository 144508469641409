import './index.css';
import { CTL } from '../../../../components/AthleteCard/features/Stats/components/CTL';
import { useAthleteStore } from '../../../../store/athlete/slice';
import { ATL } from '../../../../components/AthleteCard/features/Stats/components/ATL';
import { TSB } from '../../../../components/AthleteCard/features/Stats/components/TSB';
import { Divider, Skeleton } from '@cycling-web/analog-ui';
import { RHR } from '../../../../components/AthleteCard/features/Stats/components/RHR';
import { HRV } from '../../../../components/AthleteCard/features/Stats/components/HRV';
import { MCP } from '../../../../components/AthleteCard/features/Stats/components/MCP';
import { VO2Max } from '../../../../components/AthleteCard/features/Stats/components/VO2Max';
import { MFTP } from '../../../../components/AthleteCard/features/Stats/components/MFTP';
import { SFTP } from '../../../../components/AthleteCard/features/Stats/components/SFTP';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export const HeartMeasurements = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const stepRef = useRef<number>(0);
  const [showScrollButtons, setShowScrollButtons] = useState({
    left: false,
    right: false,
  });

  const athlete = useAthleteStore((s) => s.athlete);
  const athleteLoaded = useAthleteStore((s) => s.athleteLoaded);

  useEffect(() => {
    function onResize() {
      setTimeout(() => {
        if (!athlete || !scrollContainerRef.current || !contentRef.current) {
          return;
        }

        const containerWidth =
          scrollContainerRef.current.getBoundingClientRect().width;
        const contentWidth = contentRef.current.getBoundingClientRect().width;

        if (contentWidth <= containerWidth) {
          setShowScrollButtons({
            left: false,
            right: false,
          });
          contentRef.current.style.transform = `translateX(0px)`;
          return;
        }

        setShowScrollButtons({
          left: false,
          right: true,
        });
        // TODO: Ideally count number of steps
        stepRef.current = contentWidth - containerWidth;
      });
    }

    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [athlete]);

  const onScroll = useCallback((n: number) => {
    return () => {
      if (contentRef.current) {
        const x = n > 0 ? -stepRef.current * n : 0;
        contentRef.current.style.transform = `translateX(${x}px)`;
        setShowScrollButtons({
          left: n > 0,
          right: n < 0,
        });
      }
    };
  }, []);

  if (!athleteLoaded) {
    return (
      <div className="athlete-heart-measurements-wrapper">
        <div className="athlete-heart-measurements">
          <Skeleton width="60px" height="24px" />
          <Skeleton width="60px" height="24px" />
          <Skeleton width="60px" height="24px" />
          <Divider direction="vertical" />
          <Skeleton width="60px" height="24px" />
          <Skeleton width="60px" height="24px" />
          <Divider direction="vertical" />
          <Skeleton width="60px" height="24px" />
          <Skeleton width="60px" height="24px" />
          <Divider direction="vertical" />
          <Skeleton width="60px" height="24px" />
          <Skeleton width="60px" height="24px" />
        </div>
      </div>
    );
  }

  return (
    <div
      className="athlete-heart-measurements-wrapper"
      ref={scrollContainerRef}
    >
      {showScrollButtons.left && (
        <button
          className="athlete-heart-measurements__scrollButton scrollButton--left"
          onClick={onScroll(-1)}
        >
          <div className="athlete-heart-measurements__scrollButton-iconBox">
            <ChevronLeft size={16} />
          </div>
        </button>
      )}
      <div className="athlete-heart-measurements" ref={contentRef}>
        <CTL athlete={athlete} />
        <ATL athlete={athlete} />
        <TSB athlete={athlete} />
        <Divider direction="vertical" />
        <RHR athlete={athlete} />
        <HRV athlete={athlete} />
        <Divider direction="vertical" />
        <MCP athlete={athlete} />
        <VO2Max athlete={athlete} />
        <Divider direction="vertical" />
        <MFTP athlete={athlete} />
        <SFTP athlete={athlete} />
      </div>
      {showScrollButtons.right && (
        <button
          className="athlete-heart-measurements__scrollButton scrollButton--right"
          onClick={onScroll(1)}
        >
          <div className="athlete-heart-measurements__scrollButton-iconBox">
            <ChevronRight size={16} />
          </div>
        </button>
      )}
    </div>
  );
};
