import { useContext } from 'react';
import { DialogContext } from './context';

export const DialogBody = () => {
  const { onRenderBody, children } = useContext(DialogContext);

  if (onRenderBody) {
    return onRenderBody();
  }

  if (!children) {
    return null;
  }

  return <div className="analog-dialog__body">{children}</div>;
};
