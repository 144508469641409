import { useCallback, useState } from 'react';
import { AnyType } from '../types/common';

type R<T> = {
  filters: T;
  handleChangeFilter: (k: keyof T) => (v: AnyType) => void;
};

export const useFilters = <T>(defaultValue: T): R<T> => {
  const [filters, setFilters] = useState<T>(defaultValue);

  const handleChangeFilter = useCallback(
    (key: keyof T) => {
      return (value: AnyType) => {
        setFilters((filters: T) => {
          return {
            ...filters,
            [key]: value,
          };
        });
      };
    },
    [setFilters]
  );

  return {
    filters,
    handleChangeFilter,
  };
};
