import { IAthleteSlice, IAthleteState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IAthlete } from '../../types/athletes';

export const initialState: IAthleteState = {
  athlete: null,
  athleteLoaded: false,
};

export const useAthleteStore = create<IAthleteSlice>()(
  immer((set) => ({
    ...initialState,
    setAthlete: (athlete: IAthlete | null) => {
      set((state) => {
        state.athlete = athlete;
        state.athleteLoaded = true;
      });
    },
    setAthleteLoaded: (athleteLoaded: boolean) => {
      set((state) => {
        state.athleteLoaded = athleteLoaded;
      });
    },
    updateAthlete: (athlete: Partial<IAthlete>) => {
      set((state) => {
        if (state.athlete) {
          state.athlete = {
            ...state.athlete,
            ...athlete,
          };
        }
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
