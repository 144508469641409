import { Trans, useTranslation } from 'react-i18next';

export const CoachCommentTooltip = () => {
  const { t } = useTranslation();

  return (
    <div className="report-feedback-form__coach-comment-tooltip analog-typography--body regular">
      <p>{t('label.coach_comment_tooltip')}</p>
      <ul>
        <li>
          <Trans i18nKey="legal.coach_comment_tooltip_strengths">
            <strong>Strengths</strong>: Highlight the athlete's strong points
            and successes.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="legal.coach_comment_tooltip_areas">
            <strong>Areas for Improvement</strong>: Identify specific areas
            where the athlete can improve.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="legal.coach_comment_tooltip_progress">
            <strong>Progress</strong>: Mention any noticeable progress or
            achievements.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="legal.coach_comment_tooltip_additional">
            <strong>Additional Notes</strong>: Include any other relevant
            observations or recommendations.
          </Trans>
        </li>
      </ul>
    </div>
  );
};
