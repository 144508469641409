import {
  IGetCriticalPowerRequest,
  IGetCriticalPowerResponse,
  IGetFatigueResistanceRelativeRequest,
  IGetFatigueResistanceRelativeResponse,
  IGetFatigueResistanceRequest,
  IGetFatigueResistanceResponse,
  IGetFitnessFatigueRequest,
  IGetFitnessFatigueResponse,
  IGetITRIMPTSSRequest,
  IGetITRIMPTSSResponse,
  IGetLastSFTRequest,
  IGetLastSFTResponse,
  IGetLatestTrainingLoadRequest,
  IGetLatestTrainingLoadResponse,
  IGetPdCurveRelativeRequest,
  IGetPdCurveRelativeResponse,
  IGetPdCurveRequest,
  IGetPdCurveResponse,
  IGetPeakRequest,
  IGetPeakResponse,
  IGetPowerDerivativesRequest,
  IGetPowerDerivativesResponse,
  IGetTrainingLoadRequest,
  IGetTrainingLoadResponse,
  IGetWeeklyReportRequest,
  IGetWeeklyReportResponse,
  IGetWorkoutDetailsRequest,
  IGetWorkoutDetailsResponse,
  ISetPowerHeldRequest,
  ISetPowerHeldResponse,
  ISetRPERequest,
  ISetRPEResponse,
  ISetTHRRequest,
  ISetTHRResponse,
  ISetTTERequest,
  ISetTTEResponse,
} from './types';
import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';
import qs from 'qs';
import { qsOptions } from '../utils/qsOptions';

interface IPerformanceService {
  setRPE: (p: ISetRPERequest) => Promise<ISetRPEResponse>;
  setTTE: (p: ISetTTERequest) => Promise<ISetTTEResponse>;
  setTHR: (p: ISetTHRRequest) => Promise<ISetTHRResponse>;
  setPowerHeld: (p: ISetPowerHeldRequest) => Promise<ISetPowerHeldResponse>;

  getPerformanceReport: (
    p: IGetWeeklyReportRequest
  ) => R<IGetWeeklyReportResponse>;

  getTrainingLoad: (p: IGetTrainingLoadRequest) => R<IGetTrainingLoadResponse>;
  getLatestTrainingLoad: (
    p: IGetLatestTrainingLoadRequest
  ) => R<IGetLatestTrainingLoadResponse>;

  getWorkoutDetails: (
    p: IGetWorkoutDetailsRequest
  ) => R<IGetWorkoutDetailsResponse>;
  getPowerDerivatives: (
    p: IGetPowerDerivativesRequest
  ) => R<IGetPowerDerivativesResponse>;
  getPeak: (p: IGetPeakRequest) => R<IGetPeakResponse>;
  getPdCurveRelative: (
    p: IGetPdCurveRelativeRequest
  ) => R<IGetPdCurveRelativeResponse>;
  getPdCurve: (p: IGetPdCurveRequest) => R<IGetPdCurveResponse>;
  getLastSFT: (p: IGetLastSFTRequest) => R<IGetLastSFTResponse>;
  getITRIMPTSS: (p: IGetITRIMPTSSRequest) => R<IGetITRIMPTSSResponse>;
  getFitnessFatigue: (
    p: IGetFitnessFatigueRequest
  ) => R<IGetFitnessFatigueResponse>;
  getFatigueResistanceRelative: (
    p: IGetFatigueResistanceRelativeRequest
  ) => R<IGetFatigueResistanceRelativeResponse>;
  getFatigueResistance: (
    p: IGetFatigueResistanceRequest
  ) => R<IGetFatigueResistanceResponse>;
  getCriticalPower: (
    p: IGetCriticalPowerRequest
  ) => R<IGetCriticalPowerResponse>;
}

export class PerformanceService implements IPerformanceService {
  async setRPE(p: ISetRPERequest): Promise<ISetRPEResponse> {
    try {
      return axiosInstance.post(`/v1/performance/input/sft/rpe`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async setTTE(p: ISetTTERequest): Promise<ISetTTEResponse> {
    try {
      return axiosInstance.post(`/v1/performance/input/sft/tte`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async setTHR(p: ISetTHRRequest): Promise<ISetTHRResponse> {
    try {
      return axiosInstance.post(`/v1/performance/input/sft/thr`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async setPowerHeld(p: ISetPowerHeldRequest): Promise<ISetPowerHeldResponse> {
    try {
      return axiosInstance.post(`/v1/performance/input/sft/power-held`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPerformanceReport(
    p: IGetWeeklyReportRequest
  ): R<IGetWeeklyReportResponse> {
    try {
      return axiosInstance.get(`/v1/report/weekly/${p.year}/${p.week}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getWorkoutDetails(
    p: IGetWorkoutDetailsRequest
  ): R<IGetWorkoutDetailsResponse> {
    try {
      const params = qs.stringify(
        {
          athleteIds: p.athleteIds,
          unit: p.unit,
          aggregatePerAthlete: p.aggregatePerAthlete,
        },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/performance/workoutDetails/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getTrainingLoad(
    p: IGetTrainingLoadRequest
  ): R<IGetTrainingLoadResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/trainingLoad/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPowerDerivatives(
    p: IGetPowerDerivativesRequest
  ): R<IGetPowerDerivativesResponse> {
    try {
      const params = qs.stringify(
        { athleteIds: p.athleteIds, unit: p.unit },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/performance/powerDerivatives/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPeak(p: IGetPeakRequest): R<IGetPeakResponse> {
    try {
      const params = qs.stringify(
        {
          athleteIds: p.athleteIds,
          allAthletes: p.allAthletes,
        },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/performance/peak/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPdCurveRelative(
    p: IGetPdCurveRelativeRequest
  ): R<IGetPdCurveRelativeResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/pdCurveRelative/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPdCurve(p: IGetPdCurveRequest): R<IGetPdCurveResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/pdCurve/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getLastSFT(p: IGetLastSFTRequest): R<IGetLastSFTResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(`/v1/performance/lastSFT?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getITRIMPTSS(p: IGetITRIMPTSSRequest): R<IGetITRIMPTSSResponse> {
    try {
      const params = qs.stringify(
        { athleteIds: p.athleteIds, unit: p.unit },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/performance/iTRIMP-TSS/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getFitnessFatigue(
    p: IGetFitnessFatigueRequest
  ): R<IGetFitnessFatigueResponse> {
    try {
      const params = qs.stringify(
        { athleteIds: p.athleteIds, unit: p.unit },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/performance/fitness-fatigue/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getFatigueResistanceRelative(
    p: IGetFatigueResistanceRelativeRequest
  ): R<IGetFatigueResistanceRelativeResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/fatigueResistanceRelative/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getFatigueResistance(
    p: IGetFatigueResistanceRequest
  ): R<IGetFatigueResistanceResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/fatigueResistance/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getCriticalPower(
    p: IGetCriticalPowerRequest
  ): R<IGetCriticalPowerResponse> {
    try {
      const params = qs.stringify({ athleteIds: p.athleteIds }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/criticalPower/${p.startDate}/${p.endDate}?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getLatestTrainingLoad(
    p: IGetLatestTrainingLoadRequest
  ): R<IGetLatestTrainingLoadResponse> {
    try {
      const params = qs.stringify({ athleteIds: p.athleteIds }, qsOptions);
      return axiosInstance.get(`/v1/performance/latestTrainingLoad?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
