import './index.css';
import { useViewModel } from './useViewModel';
import { EmptyState } from '@cycling-web/analog-ui';
import { AthleteCard } from '../../components/AthleteCard';
import { Feedback } from './components/Feedback';
import { useTranslation } from 'react-i18next';
import { AthleteDetailsMobileContext } from './context';
import { PageSpinner } from '@cycling-web/common';

export const AthleteDetailsMobile = () => {
  const { t } = useTranslation();
  const context = useViewModel();
  const { handleSingOut, athlete, athleteLoaded } = context;

  const emptyStateJSX = (
    <div className="athlete-details-mobile__empty">
      <EmptyState
        title="No data available"
        text="It looks like you are not an athlete. Sign in under athlete account to see your data."
        buttonProps={{
          content: t('action.sign_out'),
          onClick: handleSingOut,
        }}
      />
    </div>
  );

  return (
    <AthleteDetailsMobileContext.Provider value={context}>
      {athleteLoaded ? (
        athlete ? (
          <div className="athlete-details-mobile">
            <AthleteCard athlete={athlete} />
            <Feedback />
          </div>
        ) : (
          emptyStateJSX
        )
      ) : (
        <PageSpinner />
      )}
    </AthleteDetailsMobileContext.Provider>
  );
};
