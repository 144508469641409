import { StubPerformanceClient } from '@azure/msal-browser';
import { Configuration } from '@azure/msal-browser/src/config/Configuration';
import { Environment, getEnvironment } from '../utils/getEnvironment';

const getCredentials = () => {
  if (getEnvironment() === Environment.Production) {
    return {
      clientId: import.meta.env.VITE_MSAL_CLIENT_ID_PROD,
      authority: import.meta.env.VITE_MSAL_AUTHORITY_PROD,
    };
  }

  return {
    clientId: import.meta.env.VITE_MSAL_CLIENT_ID_DEV,
    authority: import.meta.env.VITE_MSAL_AUTHORITY_DEV,
  };
};

const { clientId, authority } = getCredentials();

export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  telemetry: {
    application: {
      appName: '',
      appVersion: '',
    },
    client: new StubPerformanceClient(),
  },
};
