import './locales';
import './styles.css';

import { StrictMode } from 'react';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router';
import { App } from './App';
import { msalConfig } from './ms/msal-config';
import { intercept } from './axios-instance';

export const msalInstance = new PublicClientApplication(msalConfig);
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

intercept();

async function enableMocking() {
  const enabled = false;

  if (enabled) {
    const { worker } = await import('./msw/browser');
    return worker.start();
  }
}

enableMocking().then(() => {
  root.render(
    <StrictMode>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MsalProvider>
    </StrictMode>
  );
});
