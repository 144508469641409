import { ChartColor } from '../components/Chart';
import { t } from 'i18next';
import { TsbStatus } from '../constants/performance';
import { ITagVariant } from '@cycling-web/analog-ui';

type IResult = {
  text: string;
  color: string;
  tagVariant: ITagVariant;
};

export const TsbUtils = {
  breakpoints: {
    [TsbStatus.Detraining]: 25,
    [TsbStatus.CompetitionReady]: 10,
    [TsbStatus.Transitional]: -10,
    [TsbStatus.ProductiveTraining]: -30,
  },
  getStatus(value: number): TsbStatus {
    if (value > TsbUtils.breakpoints[TsbStatus.Detraining]) {
      return TsbStatus.Detraining;
    } else if (value > TsbUtils.breakpoints[TsbStatus.CompetitionReady]) {
      return TsbStatus.CompetitionReady;
    } else if (value > TsbUtils.breakpoints[TsbStatus.Transitional]) {
      return TsbStatus.Transitional;
    } else if (value > TsbUtils.breakpoints[TsbStatus.ProductiveTraining]) {
      return TsbStatus.ProductiveTraining;
    }
    return TsbStatus.Overreaching;
  },

  getContent(status: TsbStatus) {
    const map: Record<TsbStatus, IResult> = {
      [TsbStatus.Detraining]: {
        text: t(`label.detraining`),
        color: ChartColor.LightCyan,
        tagVariant: 'cyan-light',
      },
      [TsbStatus.CompetitionReady]: {
        text: t(`label.competition_ready`, { break: ' ' }),
        color: ChartColor.LightGreen,
        tagVariant: 'green-light',
      },
      [TsbStatus.Transitional]: {
        text: t(`label.transitional`),
        color: ChartColor.Yellow,
        tagVariant: 'yellow',
      },
      [TsbStatus.ProductiveTraining]: {
        text: t(`label.productive_training`, { break: ' ' }),
        color: ChartColor.Orange,
        tagVariant: 'orange',
      },
      [TsbStatus.Overreaching]: {
        text: t(`label.overreaching`),
        color: ChartColor.LightRed,
        tagVariant: 'red-light',
      },
    };

    return map[status];
  },
};
