import './index.css';
import { Typography } from '@cycling-web/analog-ui';
import { ReactNode } from 'react';
import { clsx } from 'clsx';

type IProps = {
  text: string;
  children: ReactNode;
  className?: string;
};

export const WellnessWidget = ({ text, children, className }: IProps) => {
  const rootClass = clsx('wellness-widget', className);
  return (
    <div className={rootClass}>
      <div className="wellness-widget__header">
        <Typography text={text} variant="h4" weight="bold" />
      </div>

      <div className="wellness-widget__card">{children}</div>
    </div>
  );
};
