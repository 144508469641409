import { useTranslation } from 'react-i18next';
import { PageFilters } from '@cycling-web/common';
import { WeeksFilter } from '../../../../components/filters/WeeksFilter';
import { MultipleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import { Button, ITableColumn } from '@cycling-web/analog-ui';
import {
  initialFilters,
  usePerformanceReportStore,
} from '../../../PerformanceReport/store/slice';
import { useCallback } from 'react';
import { AnyValue } from '../../../../types/common';
import { IPerformanceReportFilters } from '../../../PerformanceReport/types';
import { IPerformanceReport } from '../../../../types/performance';
import { EMPTY } from '../../../../constants';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { useTableColumns } from '../../hooks/useTableColumns';
import { downloadCSV } from '../../../../utils/csv';
import { useURLFilters } from '../../../../hooks/useURLFilters';
import { Url } from '../../../../utils/url';

export const PerformanceTableFilters = () => {
  const { t } = useTranslation();
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const reports = usePerformanceReportStore((s) => s.reports);
  const filters = usePerformanceReportStore((s) => s.filters);
  const setFilters = usePerformanceReportStore((s) => s.setFilters);

  const initFilters = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsAthletes = Url.prepareAthletes(params);

    usePerformanceReportStore.getState().setFilters({
      athletes: paramsAthletes || initialFilters.athletes,
      search: params.get('search') || initialFilters.search,
      week: params.get('week') || initialFilters.week,
    });
  }, []);

  useURLFilters({ filters, initFilters });

  const columns = useTableColumns();

  const handleChangeFilter = useCallback(
    (key: keyof IPerformanceReportFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  );

  const handleExportReport = useCallback(() => {
    const headers = columns.map((column: ITableColumn) => {
      return { label: column.name, field: column.key };
    });

    const mappedData: AnyValue[] = reports.map((item: IPerformanceReport) => {
      const athlete = athletesMap[item.athleteId]
        ? athletesMap[item.athleteId].fullName
        : EMPTY;

      return {
        athlete: athlete,
        total_duration: item.durationStr || EMPTY,
        elevation_gain: item.climbInMeter || EMPTY,
        work: item.workInKj || EMPTY,
        distance: item.distanceInKm || EMPTY,
        tss: item.tss || EMPTY,
        ctl: item.ctl || EMPTY,
        tsb: item.tsb || EMPTY,
        coach_rating: item.coachRating || EMPTY,
        coach_comment: item.comments || EMPTY,
        next_race: item.nextRace || EMPTY,
        mcp: item.mCPRelative || EMPTY,
        coach: item.coachName || EMPTY,
      };
    });

    const name = `${t('label.weekly_report')} W${filters.week}`;

    downloadCSV(headers, mappedData, name);
  }, [columns, reports, t, filters.week, athletesMap]);

  const handleCLearSearch = useCallback(() => {
    setFilters({ search: '' });
  }, [setFilters]);

  const filtersJSX = (
    <>
      <MultipleAthleteFilter
        value={filters.athletes.map(Number)}
        onChange={handleChangeFilter('athletes')}
        style={{ minWidth: '220px' }}
        className="performance-table__athletes-filters"
      />
      <WeeksFilter value={filters.week} onChange={handleChangeFilter('week')} />
    </>
  );

  return (
    <PageFilters
      searchInputProps={{
        value: filters.search,
        onChange: handleChangeFilter('search'),
        onClear: handleCLearSearch,
      }}
      filterProps={{ filters: filtersJSX }}
      actionElements={
        <Button content={t('action.export')} onClick={handleExportReport} />
      }
    />
  );
};
