export type IWellness = {
  athleteIds: number[];
  height: IWellnessParameter;
  bmi: IWellnessParameter;
  hrv: IWellnessParameter;
  rhr: IWellnessParameter;
  bodyMass: IWellnessParameter;
  skinFold: IWellnessParameter;
  bodyFatPercent: IWellnessParameter;
  fatMass: IWellnessParameter;
  fatMassPercent: IWellnessParameter;
  wellness?: IWellnessData;
};

export type IWellnessParameter = {
  source: string;
  filled: boolean;
  date: string;
  data: number;
};

export type IWellnessData = {
  source: string;
  timeSinceEpoch: number;
  data: IWellnessScores;
};

export type IWellnessScores = {
  generalScore: number;
  fatigueScore: number;
  stressScore: number;
  moodScore: number;
  sleepScore: number;
  totalScore: number;
  comment: string;
};

export type ISubjectiveFeedbackFormData = {
  athleteId?: number;
  timeSinceEpoch: number;
  overall: string;
  fatigue: string;
  stress: string;
  mood: string;
  sleep: string;
  inputBy: number;
  comment: string;
};

/** Overall Wellness Chart */

export type ISelfReportedWellness = {
  startTime: number;
  endTime: number;
  timeSinceEpoch?: number;
  points: ISelfReportedWellnessPoint[];
};

export type ISelfReportedWellnessPoint = {
  source: string;
  timeSinceEpoch: number;
  data: ISelfReportedWellnessPointData;
};

export type ISelfReportedWellnessPointData = {
  totalScore: number;
  overallScore: number;
  fatigueScore: number;
  stressScore: number;
  moodScore: number;
  sleepScore: number;
};

/** Body Composition Chart */

export type IBodyComposition = {
  athleteId: number;
  startDate: string;
  endDate: string;
  points: IBodyCompositionPoint[];
};

export type IBodyCompositionPoint = {
  startDate: string;
  endDate: string;
  size: number;
  data: IBodyCompositionPointData;
};

export type IBodyCompositionPointData = {
  bodyMass: number;
  skinFold: number;
  bodyFatPercent: number;
};

/** Heart Measurements Chart */

export type IHeartMeasurement = {
  athleteId: number;
  startDate: string;
  endDate: string;
  average: IHeartMeasurementPointData;
  points: IHeartMeasurementPoint[];
};

export type IHeartMeasurementPoint = {
  startDate: string;
  endDate: string;
  size: number;
  data: IHeartMeasurementPointData;
};

export type IHeartMeasurementPointData = {
  hrv: number;
  hrvBaseline: number;
  rhr: number;
  rhrBaseline: number;
};

export enum RhrHrvStatus {
  OptimalRecovery = 'optimal_recovery',
  RecoveryReady = 'recovery_ready',
  HighStress = 'high_stress',
  MildFatigue = 'mild_fatigue',
  MixedSignals = 'mixed_signals',
  Overreaching = 'overreaching',
}
