import { appInsights } from './app-insights';
import { getEnvironment } from '../utils/getEnvironment';
import { useUsersStore } from '../store/users/slice';

function generateTelemetryProperties(additionalProperties?: {
  [key: string]: string;
}) {
  const environment = getEnvironment();
  const userId = useUsersStore.getState().userProfile?.id;

  return {
    environment,
    userId,
    url: window.location.href,
    ...additionalProperties,
  };
}

function trackUserInteractionEvent(
  eventName: string,
  additionalProperties?: { [key: string]: string }
) {
  if (appInsights) {
    const properties = generateTelemetryProperties(additionalProperties);
    appInsights.trackEvent({ name: eventName, properties });
  }
}

function trackChartInteraction(
  interactionType: string,
  additionalProperties?: { [key: string]: string | undefined }
) {
  if (appInsights) {
    const properties = generateTelemetryProperties({
      interactionType,
      ...additionalProperties,
    });
    appInsights.trackEvent({ name: 'ChartInteraction', properties });
  }
}

function trackChartViewEvent(
  interactionType: string,
  additionalProperties?: { [key: string]: string | undefined }
) {
  if (appInsights) {
    const properties = generateTelemetryProperties({
      interactionType,
      ...additionalProperties,
    });
    appInsights.trackEvent({ name: 'ChartInteraction', properties });
  }
}

function trackError(
  error: Error,
  additionalProperties?: { [key: string]: string }
) {
  if (appInsights) {
    const properties = generateTelemetryProperties(additionalProperties);
    appInsights.trackException({ exception: error, properties });
  }
}

export {
  trackChartInteraction,
  trackChartViewEvent,
  trackError,
  trackUserInteractionEvent,
};
