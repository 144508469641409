import { DateGroupBy } from '../types/enums';

export const Url = {
  prepareAthletes(params: URLSearchParams): number[] {
    const items = params.get('athletes');

    if (!items) {
      return [];
    }

    return items.split(',').map(Number);
  },

  preparePeriod(params: URLSearchParams): number | undefined {
    const item = params.get('period');

    if (!item) {
      return undefined;
    }

    return Number(item);
  },

  prepareDateGroupBy(params: URLSearchParams): DateGroupBy | undefined {
    const item = params.get('dateGroupBy');

    if (!item) {
      return undefined;
    }

    return item as DateGroupBy;
  },

  replaceWithSearchParam(qp: string): void {
    const query = qp ? `?${qp}` : '';
    const newUrl = `${window.location.pathname}${query}`;
    window.history.replaceState(null, '', newUrl);
  },
};
