import { MathJax } from 'better-react-mathjax';

type IProps = {
  content: string;
};

export const FractionPart = ({ content }: IProps) => {
  return (
    <div className="ai-message__fraction">
      <MathJax inline>{content}</MathJax>
    </div>
  );
};
