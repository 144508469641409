import React, { useCallback, useMemo } from 'react';
import { DateRange } from '../../../../types/enums';
import { useTranslation } from 'react-i18next';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import { IHandleChangeFilter } from '@cycling-web/common';
import {
  Chart,
  ChartColor,
  chartTooltipFormatter,
  IEChartOption,
} from '../../../../components/Chart';
import { IPeak } from '../../../../types/performance';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';
import { ChartTrackingNames } from '../../../../constants/charts';

type IProps = {
  data: IPeak[];
  filters: {
    period: DateRange;
  };
  handleChangeFilter: IHandleChangeFilter;
  loading?: boolean;
};

export const CompoundScoreChart = ({
  data,
  filters,
  handleChangeFilter,
  loading,
}: IProps) => {
  const { t } = useTranslation();

  const source = useMemo(() => {
    const source: (number | string)[][] = [['Data', t('label.compound_score')]];

    data.forEach((item: IPeak) => {
      source.push(
        ['10sec', parseInt(item.maximumOfMmp10SecondsComposite.toFixed(0))],
        ['30sec', parseInt(item.maximumOfMmp30SecondsComposite.toFixed(0))],
        ['1min', parseInt(item.maximumOfMmp1MinutesComposite.toFixed(0))],
        ['5min', parseInt(item.maximumOfMmp5MinutesComposite.toFixed(0))],
        ['10min', parseInt(item.maximumOfMmp10MinutesComposite.toFixed(0))],
        ['20min', parseInt(item.maximumOfMmp20MinutesComposite.toFixed(0))],
        ['30min', parseInt(item.maximumOfMmp30MinutesComposite.toFixed(0))],
        ['60min', parseInt(item.maximumOfMmp60MinutesComposite.toFixed(0))]
      );
    });

    return source;
  }, [data, t]);

  const onRenderTooltipValue = useCallback((value: number) => {
    return value;
  }, []);

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      xAxis: {
        type: 'category',
      },
      yAxis: [
        {
          type: 'value',
          axisPointer: {
            show: true,
            label: {
              show: false,
            },
          },
        },
      ],
      series: [
        {
          type: 'bar',
          itemStyle: {
            color: (params) => {
              return params.dataIndex % 2 === 0
                ? ChartColor.LightBlue
                : ChartColor.Blue;
            },
          },
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
        }),
      },
    };
  }, [onRenderTooltipValue, source]);

  const filtersBarProps = {
    filters: (
      <LastNDaysFilter
        value={filters.period}
        onChange={handleChangeFilter('period')}
        selectProps={{ variant: 'light' }}
      />
    ),
  };

  return (
    <ChartWrapper>
      <Chart
        headerProps={{
          title: t('label.compound_score'),
          filtersBarProps,
        }}
        legendProps={{
          show: false,
        }}
        option={option}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.CompoundScore,
          }),
        }}
      />
    </ChartWrapper>
  );
};
