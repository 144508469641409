import { useTranslation } from 'react-i18next';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import React, { useCallback, useMemo } from 'react';
import {
  Chart,
  ChartColor,
  chartTooltipFormatter,
  IEChartOption,
} from '../../../../components/Chart';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';
import { ChartTrackingNames } from '../../../../constants/charts';

type IProps = {
  source: (string | number)[][];
  loading?: boolean;
};

export const ElevationGainChart = ({ source, loading }: IProps) => {
  const { t } = useTranslation();

  const onRenderTooltipValue = useCallback(
    (value: number, _: string) => {
      return `${Math.round(value)} ${t('units.m')}`;
    },
    [t]
  );

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      grid: {
        bottom: 110,
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          rotate: 45,
          interval: 0,
          fontSize: 10,
        },
      },
      yAxis: [
        {
          type: 'value',
          min: 0,
          position: 'left',
          axisPointer: {
            show: true,
            label: {
              show: false,
            },
          },
        },
      ],
      series: [
        {
          name: t('label.elevation_gain'),
          type: 'bar',
          yAxisIndex: 0,
          z: 2,
          itemStyle: {
            color: (params) => {
              return params.dataIndex % 2 === 0
                ? ChartColor.LightPurple
                : ChartColor.LightRose;
            },
          },
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
        }),
      },
    };
  }, [onRenderTooltipValue, source, t]);

  return (
    <ChartWrapper>
      <Chart
        headerProps={{
          title: t('label.elevation_gain'),
        }}
        legendProps={{
          show: false,
        }}
        option={option}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.ElevationGain,
          }),
        }}
      />
    </ChartWrapper>
  );
};
