import { IStaffSlice, IStaffState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IStaff } from '../../types/coach';

export const initialState: IStaffState = {
  staff: null,
  staffLoaded: false,
};

export const useStaffStore = create<IStaffSlice>()(
  immer((set) => ({
    ...initialState,
    setStaff: (staff: IStaff | null) => {
      set((state) => {
        state.staff = staff;
        state.staffLoaded = true;
      });
    },
    setStaffLoaded: (staffLoaded: boolean) => {
      set((state) => {
        state.staffLoaded = staffLoaded;
      });
    },
    updateProfile: (staff: Partial<IStaff>) => {
      set((state) => {
        if (state.staff) {
          state.staff = {
            ...state.staff,
            ...staff,
          };
        }
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
