import './index.css';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { EMPTY } from '../../../../constants';
import { IChartTooltipProps } from '../../types';
import { ReactNode } from 'react';

export const ChartTooltip = ({
  params,
  onRenderValue,
  onRenderSeriesName,
  onRenderHeader,
  source,
}: IChartTooltipProps) => {
  if (params.length === 0) {
    return null;
  }

  // @ts-ignore
  const isRadar = params.seriesType === 'radar';

  const showTooltip =
    isRadar ||
    params.every((series: CallbackDataParams) => {
      if (!series.data || !series.dimensionNames) {
        return false;
      }

      if (Array.isArray(series.data)) {
        return series.data.length >= series.dimensionNames.length;
      }

      return true;
    });

  if (!showTooltip) {
    return null;
  }

  let seriesJSX: ReactNode = '';

  if (isRadar) {
    const series = params as any;
    const labels = source ? source[0] : [];
    const values = series.value;
    seriesJSX = values.map((value, i) => {
      return (
        <div key={i} className="chart-tooltip__item">
          <div className="chart-tooltip__item-label">
            {series.color && (
              <div
                className="chart-tooltip__item-circle"
                style={{ background: series.color as string }}
              />
            )}
            {onRenderSeriesName ? onRenderSeriesName(series.name) : labels[i]}
          </div>
          <div className="chart-tooltip__item-value">
            {onRenderValue ? onRenderValue(value, series.name, series) : value}
          </div>
        </div>
      );
    });
  } else {
    seriesJSX = params.map((series: CallbackDataParams, i: number) => {
      const index =
        series.dimensionNames?.findIndex(
          (name: string) => name === series.seriesName
        ) || -1;

      const seriesName = series.seriesName || EMPTY;
      const value = series.value ? series.value[index] : EMPTY;

      return (
        <div key={i} className="chart-tooltip__item">
          <div className="chart-tooltip__item-label">
            {series.color && (
              <div
                className="chart-tooltip__item-circle"
                style={{ background: series.color as string }}
              />
            )}
            {onRenderSeriesName ? onRenderSeriesName(seriesName) : seriesName}
          </div>
          <div className="chart-tooltip__item-value">
            {onRenderValue ? onRenderValue(value, seriesName, series) : value}
          </div>
        </div>
      );
    });
  }

  return (
    <div className="chart-tooltip">
      <header className="chart-tooltip__header">
        {onRenderHeader
          ? onRenderHeader(params)
          : // @ts-ignore
            params[0]?.name || params.seriesName || ''}
      </header>
      <div className="chart-tooltip__series">{seriesJSX}</div>
    </div>
  );
};
