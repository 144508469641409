import { IEnums } from '../types/dictionaries';
import { useDictionariesStore } from '../store/dictionaries/slice';
import { EMPTY } from '../constants';
import { useCallback } from 'react';
import { MedicalRecordType } from '../types/medical';

type IProps = {
  container?: keyof IEnums;
  recordType?: string;
  key: string;
  isArray?: boolean;
};

export const useDictionaryValue = () => {
  const enums = useDictionariesStore((s) => s.enums);

  const getDictionaryValue = useCallback(
    ({ container, recordType, key, isArray }: IProps) => {
      try {
        if (container) {
          if (isArray) {
            const keys = JSON.parse(key) as string[];
            return keys.map((k: string) => enums[container][k]).join(', ');
          }

          return enums[container][key] || EMPTY;
        } else if (recordType) {
          if (recordType === MedicalRecordType.Physio) {
            return enums.bodyLocation[key] || EMPTY;
          } else if (recordType === MedicalRecordType.Illness) {
            return enums.symptom[key] || EMPTY;
          } else if (recordType === MedicalRecordType.Injury) {
            return enums.bodyArea[key] || EMPTY;
          }

          return EMPTY;
        }

        return EMPTY;
      } catch (e) {
        console.log(e);
        return EMPTY;
      }
    },
    [enums]
  );

  return getDictionaryValue;
};
