import './index.css';
import { Dialog, FormElement } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { InputControl } from '@cycling-web/common';
import { IUserBase } from '../../../../types/users';
import { AthletesRepository } from '../../../../api/athletes/repository';
import { AthletesService } from '../../../../api/athletes/service';
import { useViewModel } from './useViewModel';

type IProps = {
  user: IUserBase;
  onDismiss: () => void;
};

export const UserFormModal = (props: IProps) => {
  const { t } = useTranslation();
  const { form, errors, onDismiss, onSubmit, loading } = useViewModel(props);

  return (
    <FormProvider {...form}>
      <Dialog
        onDismiss={onDismiss}
        outsidePress={false}
        title={t('label.user_details')}
        cancelButtonProps={{
          onClick: onDismiss,
        }}
        submitButtonProps={{
          content: t('action.update'),
          onClick: onSubmit,
          loading: loading,
        }}
      >
        <div className="user-form-modal">
          <FormElement
            label={t('label.first_name')}
            message={errors.firstName?.message}
          >
            <InputControl
              name="firstName"
              placeholder={t('placeholder.first_name')}
              invalid={!!errors.firstName}
            />
          </FormElement>

          <FormElement
            label={t('label.last_name')}
            message={errors.lastName?.message}
          >
            <InputControl
              name="lastName"
              placeholder={t('placeholder.last_name')}
              invalid={!!errors.lastName}
            />
          </FormElement>
        </div>
      </Dialog>
    </FormProvider>
  );
};
