import './index.css';
import { useEffect, useRef, useState } from 'react';
import { useChartContext } from '../../context';
import {
  IChartRadarIndicatorConfig,
  IChartRadarProps,
  IEChartRadarIndicator,
} from '../../types';
import { Typography } from '@cycling-web/analog-ui';

type IRect = {
  width: number;
  height: number;
  radius: number;
  centerX: number;
  centerY: number;
};

export const RadarIndicators = ({
  show = true,
  onRenderIndicator,
  indicatorOffset,
}: IChartRadarProps) => {
  const finished = useRef<boolean>(false);
  const { ready, chartRef, option } = useChartContext();
  const [size, setSize] = useState<IRect>({
    width: 0,
    height: 0,
    radius: 0,
    centerX: 0,
    centerY: 0,
  });

  useEffect(() => {
    if (!ready || finished.current) {
      return;
    }

    chartRef.current?.on('finished', () => {
      finished.current = true;

      if (chartRef.current) {
        const option = chartRef.current.getOption();
        const defaultEChartsRadius = '75%';
        const strRadius: string =
          option?.radar?.[0]?.radius || defaultEChartsRadius;
        const indicators_space = 0;
        const scale =
          (indicators_space + Number(strRadius.replace('%', ''))) / 100;

        const width = chartRef.current.getWidth() * scale;
        const height = chartRef.current.getHeight() * scale;
        const radius = Math.min(width, height) / 2;
        const centerX = width / 2;
        const centerY = height / 2;

        setSize({
          width,
          height,
          radius,
          centerX,
          centerY,
        });
      }
    });
  }, [chartRef, ready]);

  if (!show || !ready || size.width === 0) {
    return null;
  }

  // @ts-ignore
  const indicators = (option.radar?.indicator || []) as IEChartRadarIndicator[];
  const indicatorsJSX = indicators.map(
    (indicator: IEChartRadarIndicator, i: number) => {
      const defaultEChartsStartAngle = 90;
      const startAngle =
        chartRef.current?.getOption().radar?.[0].startAngle ||
        defaultEChartsStartAngle;

      const stepSize = 360 / indicators.length;

      let angleInDegrees = -(startAngle + stepSize * i) % 360;
      angleInDegrees = (angleInDegrees + 360) % 360;

      const angle = (angleInDegrees * Math.PI) / 180;

      const xOffset = (indicatorOffset?.x || 0) * Math.cos(angle);
      const yOffset = (indicatorOffset?.y || 0) * Math.sin(angle);

      const x = size.centerX + size.radius * Math.cos(angle) + xOffset;
      const y = size.centerY + size.radius * Math.sin(angle) + yOffset;

      const normalizedAngle = 360 - angleInDegrees;
      const stepsFrom90 = (startAngle - 90) / stepSize;
      const rotationOffset =
        normalizedAngle > 180 && normalizedAngle < 360 ? 180 : 0;
      const rotationAngle =
        -(360 / indicators.length) * (i + stepsFrom90) + rotationOffset;

      const xPositionOnCircle =
        angleInDegrees % 90 === 0
          ? 0
          : Math.sign(Math.cos(((2 - angleInDegrees) * Math.PI) / 180));

      const yPositionOnCircle =
        angleInDegrees % 180 === 0
          ? 0
          : Math.sign(Math.sin(((2 - angleInDegrees) * Math.PI) / 180));

      const config: IChartRadarIndicatorConfig = {
        x: xPositionOnCircle,
        y: yPositionOnCircle,
        angle: normalizedAngle,
      };

      return (
        <div
          key={indicator.name + i}
          className="chart__radar-indicator analog-typography--subtitle"
          style={{
            left: `${x}px`,
            top: `${y}px`,
            transform: `translate(-50%, -50%) rotate(${rotationAngle}deg)`,
          }}
        >
          {onRenderIndicator ? (
            onRenderIndicator(indicator, config)
          ) : (
            <Typography variant="subtitle" text={indicator.name} />
          )}
        </div>
      );
    }
  );

  return (
    <div className="chart__radar-indicators" style={size}>
      {indicatorsJSX}
    </div>
  );
};
