import { PageFilters } from '@cycling-web/common';
import { useCallback } from 'react';
import {
  initialFilters,
  useAthletesStore,
} from '../../../../store/athletes/slice';
import { IAthletesFilters } from '../../../../types/athletes';
import { AnyValue } from '../../../../types/common';
import { useURLFilters } from '../../../../hooks/useURLFilters';

export const AthleteFilters = () => {
  const filters = useAthletesStore((s) => s.filters);
  const setFilters = useAthletesStore((s) => s.setFilters);

  const initFilters = useCallback(() => {
    const params = new URLSearchParams(window.location.search);

    useAthletesStore.getState().setFilters({
      search: params.get('search') || initialFilters.search,
      ids: initialFilters.ids,
    });
  }, []);

  const resetFilters = useCallback(() => {
    useAthletesStore.getState().setFilters(initialFilters);
  }, []);

  useURLFilters({
    filters,
    initFilters,
    resetFilters,
  });

  const handleChangeFilter = useCallback(
    (key: keyof IAthletesFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  );

  const handleClearSearch = useCallback(() => {
    setFilters({ search: '' });
  }, [setFilters]);

  return (
    <PageFilters
      searchInputProps={{
        value: filters.search,
        onChange: handleChangeFilter('search'),
        onClear: handleClearSearch,
      }}
    />
  );
};
