import './index.css';
import { IChartLegendItemProps } from '../../types';
import { clsx } from 'clsx';

export const ChartLegendItem = ({
  item,
  onRenderItem,
}: IChartLegendItemProps) => {
  if (onRenderItem) {
    return onRenderItem(item);
  }

  const background: string =
    item.type === 'dashed'
      ? 'transparent'
      : Array.isArray(item.color)
      ? `linear-gradient(to right, ${item.color
          .map((color, index) => {
            const prevPercent = (100 / item.color.length) * index;
            const percent = (100 / item.color.length) * (index + 1);
            return index === 0
              ? `${color} ${percent}%`
              : `${color} ${prevPercent}%, ${color} ${percent}%`;
          })
          .join(', ')})`
      : item.color;

  const borderColor =
    item.type === 'dashed'
      ? Array.isArray(item.color)
        ? item.color[0]
        : item.color
      : 'transparent';

  return (
    <>
      <span
        className="chart-legend-item__mark"
        style={{ background, borderColor, borderStyle: item.type }}
      />
      <span className="chart-legend-item__text">{item.name}</span>
    </>
  );
};
