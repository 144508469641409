export const curveFormatTime = (seconds: number): string => {
  if (seconds >= 3600) {
    return Math.round(seconds / 3600) + 'h';
  } else if (seconds >= 60) {
    return Math.round(seconds / 60) + 'm';
  } else {
    return Math.round(seconds) + 's';
  }
};

export const curveDisplayValues: number[] = [
  1, 2, 5, 10, 20, 40, 60, 120, 300, 600, 1200, 2400, 3600, 7200, 14400, 21600,
];
