import './index.css';
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
} from '@floating-ui/react';
import { IDialogProps } from './types';
import { clsx } from 'clsx';
import { useViewModel } from './useViewModel';
import { DialogContext } from './context';
import { DialogFooter } from './DialogFooter';
import { DialogHeader } from './DialogHeader';
import { DialogBody } from './DialogBody';
import { MouseEvent } from 'react';

export const Dialog = (props: IDialogProps) => {
  const context = useViewModel(props);
  const {
    labelId,
    descriptionId,
    className,
    isFullScreen,
    floatingContext,
    getFloatingProps,
    preventDefaultOnClick = true,
    stopPropagationOnClick = true,
  } = context;

  const rootClass: string = clsx('analog-dialog__overlay', className);

  const contentClass: string = clsx(
    'analog-dialog__content',
    isFullScreen && 'analog-dialog__content--fullScreen'
  );

  const onRootClick = (e: MouseEvent) => {
    if (preventDefaultOnClick) {
      e.preventDefault();
    }

    if (stopPropagationOnClick) {
      e.stopPropagation();
    }
  };

  return (
    <FloatingPortal>
      <FloatingOverlay className={rootClass} lockScroll={true}>
        <FloatingFocusManager context={floatingContext}>
          <DialogContext.Provider value={context}>
            <div
              ref={floatingContext.refs.setFloating}
              className={contentClass}
              aria-labelledby={labelId}
              aria-describedby={descriptionId}
              {...getFloatingProps()}
              onClick={onRootClick}
            >
              <DialogHeader />
              <DialogBody />
              <DialogFooter />
            </div>
          </DialogContext.Provider>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};
