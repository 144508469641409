import './index.css';
import { Dialog } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useIsScrolledToBottom } from '../../../../hooks/useIsScrolledToBottom';
import { PrivacyNotice } from '../../../Legal/components/PrivacyNotice';

type IProps = {
  onDecline: () => void;
  onConfirm: () => void;
};

export const PrivacyNoticeDialog = ({ onConfirm, onDecline }: IProps) => {
  const { t } = useTranslation();
  const [scrollAreaRef, setScrollAreaRef] = useState<HTMLDivElement | null>(
    null
  );

  const onRenderBody = useCallback(() => {
    return (
      <div
        className="analog-terms-and-conditions-dialog"
        ref={(node) => setScrollAreaRef(node)}
      >
        <PrivacyNotice />
      </div>
    );
  }, []);

  const isScrolled = useIsScrolledToBottom(scrollAreaRef);

  return (
    <Dialog
      title={t('label.privacy_notice')}
      onDismiss={onDecline}
      outsidePress={false}
      cancelButtonProps={{
        variant: 'quietNeutralDark',
        onClick: onDecline,
        content: t('action.decline'),
      }}
      submitButtonProps={{
        content: t('action.agree'),
        onClick: onConfirm,
        disabled: !isScrolled,
      }}
      onRenderBody={onRenderBody}
    />
  );
};
