import { FormElement } from '@cycling-web/analog-ui';
import {
  DatepickerControl,
  FormColumn,
  FormRow,
  InputNumberControl,
} from '@cycling-web/common';
import { useTranslation } from 'react-i18next';
import { useViewModel } from './useViewModel';
import { OnboardingFooter } from '../../Footer';
import { FormProvider } from 'react-hook-form';
import { IAthlete } from '../../../../../types/athletes';

type IProps = {
  athlete: IAthlete;
};

export const PerformanceInfoForm = ({ athlete }: IProps) => {
  const { t } = useTranslation();
  const { form, onSubmit, loading } = useViewModel({
    athlete,
  });
  const { formState } = form;

  return (
    <FormProvider {...form}>
      <div className="onboarding__performance-form">
        <FormElement
          label={t('label.date_of_birth')}
          message={formState.errors.dobTimeStampUTC?.message}
        >
          <DatepickerControl
            name="dobTimeStampUTC"
            placeholder={t('placeholder.date')}
            invalid={!!formState.errors.dobTimeStampUTC}
          />
        </FormElement>

        <FormRow>
          <FormColumn>
            <FormElement
              label={t('label.height')}
              message={formState.errors.height?.message}
            >
              <InputNumberControl
                name="height"
                placeholder={t('placeholder.height')}
                invalid={!!formState.errors.height}
                decimalPlaces={1}
                autoComplete="off"
              />
            </FormElement>
          </FormColumn>
          <FormColumn>
            <FormElement
              label={t('label.weight')}
              message={formState.errors.weight?.message}
            >
              <InputNumberControl
                name="weight"
                placeholder={t('placeholder.weight')}
                invalid={!!formState.errors.weight}
                decimalPlaces={1}
                autoComplete="off"
              />
            </FormElement>
          </FormColumn>
        </FormRow>

        <FormElement
          label={t('label.ftp')}
          message={formState.errors.ftp?.message}
        >
          <InputNumberControl
            name="ftp"
            placeholder={t('placeholder.number_between', {
              min: 100,
              max: 999,
            })}
            invalid={!!formState.errors.ftp}
            maxLength={3}
          />
        </FormElement>
      </div>
      <OnboardingFooter
        stepCompleted={true}
        onNextClick={onSubmit}
        loading={loading}
      />
    </FormProvider>
  );
};
