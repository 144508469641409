export const LS = {
  AccessToken: 'token',
  Team: 'team',
  PreviousPage: 'previous_page',
  Cookies: 'cookies',
  CookiesVersion: 'cookiesVersion',
};

export const EMPTY = '-';

export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;

export const DEFAULT_CDN_URL =
  'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/';
export const DEFAULT_CDN_SUFFIX = 'svg';

export const ALL_FILTER_OPTION_ID = -1;

export const ApiErrorCode = {
  Unauthorized: 401,
  Forbidden: 403,
};

export const SUPPORT_EMAIL = 'support-sports@analog.io';
