import './index.css';
import { ITypographyProps, ITypographyVariant } from './types';
import { ElementType } from 'react';
import { clsx } from 'clsx';
import { ISkeletonProps } from '../Skeleton/types';
import { Skeleton } from '../Skeleton';

export const Typography = <T extends ElementType = 'span'>({
  as: Component = 'span',
  variant = 'body',
  weight = 'regular',
  color = '',
  text,
  loading,
  skeletonProps,
  ...props
}: ITypographyProps<T>) => {
  const rootClass = clsx(
    `analog-typography--${variant}`,
    color && `analog-typography--${color}`,
    weight,
    props.className
  );

  const skeletonHeight: Record<ITypographyVariant, ISkeletonProps> = {
    h1: {
      height: '43.2px',
    },
    h2: {
      height: '38.4px',
    },
    h3: {
      height: '31.2px',
    },
    h4: {
      height: '26px',
    },
    headline: {
      height: '24px',
    },
    body: {
      height: '21px',
    },
    subtitle: {
      height: '18px',
    },
    caption: {
      height: '15px',
    },
    footnote: {
      height: '12px',
    },
    'button-s': {
      height: '18px',
    },
    'button-m': {
      height: '21px',
    },
  };

  return (
    <Component {...props} className={rootClass}>
      {loading ? (
        <Skeleton {...skeletonHeight[variant]} {...skeletonProps} />
      ) : (
        text
      )}
    </Component>
  );
};
