import './index.css';
import { IAlertProps } from './types';
import { Button, IButtonProps, Typography } from '@cycling-web/analog-ui';
import { clsx } from 'clsx';

export const Alert = ({ icon, text, actions, className }: IAlertProps) => {
  const rootClass = clsx('analog-alert', className);

  const actionsJSX = (actions || []).map(
    (buttonProps: IButtonProps, i: number) => {
      return <Button key={i} {...buttonProps} />;
    }
  );

  return (
    <div className={rootClass}>
      {icon && <div className="analog-alert__icon">{icon}</div>}
      <Typography
        as="div"
        variant="body"
        className="analog-alert__icon"
        text={text}
      />
      {actionsJSX.length > 0 && (
        <div className="analog-alert__actions">{actionsJSX}</div>
      )}
    </div>
  );
};
