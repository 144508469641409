import './index.css';
import { Outlet } from 'react-router';
import { AuthGuard } from './components/AuthGuard';

// TODO: Use this page for common layouts

export const PrivatePage = () => {
  return (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  );
};
