import { PageFilters } from '@cycling-web/common';
import { useCallback } from 'react';
import { AnyValue } from '../../../../types/common';
import { useURLFilters } from '../../../../hooks/useURLFilters';
import { Url } from '../../../../utils/url';
import { initialFilters, useDashboardStore } from '../../store/slice';
import { SingleGroupFilter } from '../../../../components/filters/GroupFilter';
import { IDashboardFilters } from '../../types';

export const DashboardFilters = () => {
  const filters = useDashboardStore((s) => s.filters);
  const setFilters = useDashboardStore((s) => s.setFilters);

  const initFilters = useCallback(() => {
    const params = new URLSearchParams(window.location.search);

    useDashboardStore.getState().setFilters({
      period: Url.preparePeriod(params) || initialFilters.period,
      groupId: params.get('groupId')
        ? Number(params.get('groupId'))
        : initialFilters.groupId,
    });
  }, []);

  useURLFilters({ filters, initFilters });

  const handleChangeFilter = useCallback(
    (key: keyof IDashboardFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  );

  const filtersJSX = (
    <SingleGroupFilter
      value={filters.groupId}
      onChange={handleChangeFilter('groupId')}
      dropdownProps={{
        minWidth: '260px',
      }}
      skeletonProps={{ width: '32px', height: '32px' }}
      showAllOption
    />
  );

  return <PageFilters filterProps={{ filters: filtersJSX }} />;
};
