import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 18.0003V20.0003H3V18.0003H21ZM6.596 3.9043L8.01 5.3183L4.828 8.5003L8.01 11.6823L6.596 13.0963L2 8.5003L6.596 3.9043ZM21 11.0003V13.0003H12V11.0003H21ZM21 4.0003V6.0003H12V4.0003H21Z"
        fill="currentColor"
      />
    </svg>
  );
};
