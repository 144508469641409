import './index.css';
import { ISelectOption, Select, Skeleton } from '@cycling-web/analog-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useViewModel } from './useViewModel';
import { ISingleAthleteFilterProps } from './types';
import { clsx } from 'clsx';

export const SingleAthleteFilter = (props: ISingleAthleteFilterProps) => {
  const {
    value,
    onChange,
    dropdownProps,
    onClear,
    selectProps,
    skeletonProps,
    style = {
      minWidth: '220px',
    },
    className,
  } = props;
  const { t } = useTranslation();
  const { options, onRenderOption, athletesLoaded } = useViewModel(props);

  const handleChange = useCallback(
    (option: ISelectOption) => {
      onChange(Number(option.id));
    },
    [onChange]
  );

  const rootClass = clsx('athlete-filter', className);

  return athletesLoaded ? (
    <div className={rootClass} style={style}>
      <Select
        options={options}
        value={options.find((o: ISelectOption) => value === +o.id)}
        multiple={false}
        placeholder={t('placeholder.athletes')}
        dropdownProps={{
          ...dropdownProps,
          contentClassName: 'athlete-filter__dropdown',
          minWidth: dropdownProps?.minWidth || '400px',
          maxHeight: dropdownProps?.maxHeight || '264px',
        }}
        onChange={handleChange}
        onClear={onClear}
        onRenderOption={onRenderOption}
        {...selectProps}
      />
    </div>
  ) : (
    <Skeleton
      width={skeletonProps?.width || '220px'}
      height={skeletonProps?.height || '40px'}
    />
  );
};
