import './index.css';
import { useTranslation } from 'react-i18next';
import { GroupList } from './components/GroupList';
import { EditGroupFormModal } from './components/EditGroupFormModal';
import { GroupsContext } from './context';
import { Page } from '@cycling-web/common';
import { GroupFilters } from './components/GroupFilters';
import { useViewModel } from './useViewModel';

export const Groups = () => {
  const { t } = useTranslation();
  const { context } = useViewModel();
  const { showGroupFormModal, dismissGroupFormModal } = context;

  return (
    <GroupsContext.Provider value={context}>
      <Page pageHeaderProps={{ title: t('label.groups') }}>
        <GroupFilters />
        <GroupList />

        {showGroupFormModal && (
          <EditGroupFormModal
            onDismiss={dismissGroupFormModal}
            group={showGroupFormModal}
          />
        )}
      </Page>
    </GroupsContext.Provider>
  );
};
