import { useEffect } from 'react';
import { parseTeamName } from '../utils/teamName';

export const useTitle = (title: string | null | undefined) => {
  useEffect(() => {
    if (title) {
      setTitle(title);
    }
  }, [title]);
};

export function setTitle(title: string): void {
  const teamName = localStorage.getItem('team');
  const team = teamName ? `${parseTeamName(teamName)} - ` : '';
  document.title = `${team}${title}`;
}
