import { useContext } from 'react';
import { Pagination } from '../Pagination';
import { TableContext } from './TableContext';
import { ITableContext } from './types';
import { Button } from '../Button/Button';
import { defaultRowsPerPageOptions } from '../Pagination/constants';
import { IPaginationRowsPerPageProps } from '../Pagination/types';

export const TablePagination = () => {
  const {
    pageCount,
    onPageChange,
    page,
    selectedItems,
    items,
    onSelectAll,
    selectAll,
    rowsPerPage,
    onChangeRowsPerPage,
    pagination,
  }: ITableContext = useContext(TableContext);

  const showSelectAllButton: boolean =
    selectedItems.length !== 0 && selectedItems.length <= items.length;

  const rowsPerPageProps: IPaginationRowsPerPageProps | undefined =
    !pagination ||
    pagination.showRowsPerPageSelect === false ||
    showSelectAllButton
      ? undefined
      : {
          value: rowsPerPage,
          onChange: onChangeRowsPerPage,
          options: pagination?.rowsPerPageOptions || defaultRowsPerPageOptions,
        };

  const paginationAdornment = (
    <div className="analog-table__pagination-actions">
      {showSelectAllButton && (
        <>
          <p className="analog-table__pagination-selected analog-typography--body regular">
            Selected
            <span>{selectedItems.length}</span>
            of
            <span>{pagination?.total}</span>
            results
          </p>
          <Button
            size="xs"
            variant="quietNeutral"
            onClick={onSelectAll}
            disabled={selectAll}
            content="Select All"
          />
        </>
      )}
    </div>
  );

  return (
    <div className="analog-table__pagination">
      <Pagination
        total={pagination?.total}
        count={pageCount}
        onChange={onPageChange}
        page={page}
        rowsPerPage={rowsPerPageProps}
        adornment={paginationAdornment}
      />
    </div>
  );
};
