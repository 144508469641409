import { useParams } from 'react-router';
import { useMemo } from 'react';

export const useBaseUrl = (): string => {
  const { teamId } = useParams();

  return useMemo(() => {
    return `/${teamId}`;
  }, [teamId]);
};
