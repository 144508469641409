import { NavLink } from 'react-router';
import { Popover } from '@cycling-web/analog-ui';
import { clsx } from 'clsx';
import { ReactNode, useCallback, useState } from 'react';
import { INavigationLink } from '@cycling-web/common';

type IProps = {
  selected: boolean;
  toggleIcon: ReactNode;
  options: INavigationLink[];
};

export const MobileMenuPopover = ({
  selected,
  toggleIcon,
  options,
}: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const optionLinkClass = ({ isActive }: { isActive: boolean }) => {
    return clsx(
      'menu-mobile__option-link',
      'analog-typography--body',
      'bold',
      isActive && 'menu-mobile__option-link--active'
    );
  };

  const anchorClass = clsx(
    'menu-mobile__button',
    selected && 'menu-mobile__button--selected'
  );

  const onOptionClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  const optionsJSX = options.map((option: INavigationLink, i: number) => {
    return (
      <NavLink
        key={i}
        className={optionLinkClass}
        to={option.url}
        onClick={onOptionClick}
      >
        {option.text}
      </NavLink>
    );
  });

  return (
    <Popover
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      anchor={<button className={anchorClass}>{toggleIcon}</button>}
      content={<div className="menu-mobile__option-content">{optionsJSX}</div>}
      placement="right-start"
      minWidth="208px"
    />
  );
};
