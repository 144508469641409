import { Controller, useFormContext } from 'react-hook-form';
import { IInputNumberProps } from '@cycling-web/analog-ui';
import { InputNumber } from '@cycling-web/analog-ui';

type IProps = IInputNumberProps & {
  name: string;
};

export const InputNumberControl = (props: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, onBlur, value } }) => (
        <InputNumber
          {...props}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
      )}
    />
  );
};
