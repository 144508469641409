import './index.css';
import * as echarts from 'echarts';
import { MutableRefObject, useEffect, useRef } from 'react';
import { getEventName } from '../../utils/getEventName';
import { prepareChart } from '../../utils/prepareChart';
import { changeAfterRender } from '../../utils/changeAfterRender';
import { useChartContext } from '../../context';

export const ChartCore = () => {
  const {
    option,
    events,
    chartRef: outerChartRef,
    setReady,
    group,
  } = useChartContext();

  const chartWrapperRef = useRef<HTMLDivElement>(null);
  const localChartRef = useRef<echarts.EChartsType | null>(null);
  const chartRef =
    (outerChartRef as MutableRefObject<echarts.EChartsType | null>) ||
    localChartRef;

  useEffect(() => {
    if (!chartWrapperRef.current) {
      return;
    }

    chartRef.current = echarts.init(chartWrapperRef.current);

    function onResize() {
      if (chartRef.current) {
        chartRef.current.resize();
      }
    }

    window.addEventListener('resize', onResize);

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
      window.removeEventListener('resize', onResize);
    };
  }, [chartRef]);

  useEffect(() => {
    if (!group || !chartRef.current) {
      return;
    }

    chartRef.current.group = group;
    echarts.connect(group);

    return () => {
      if (group) {
        echarts.disconnect(group);
      }
    };
  }, [chartRef, group]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.setOption(prepareChart(option), { lazyUpdate: true });
      changeAfterRender(chartRef.current);
      setReady(true);
    }
  }, [chartRef, option, setReady]);

  useEffect(() => {
    if (events) {
      Object.keys(events).forEach((eventName) => {
        if (chartRef.current) {
          chartRef.current.on(getEventName(eventName), events[eventName]);
        }
      });
    }
  }, [chartRef, events]);

  return <div className="chart-core" ref={chartWrapperRef} />;
};
