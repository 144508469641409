import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { AthletesRepository } from '../../../../api/athletes/repository';
import { AthletesService } from '../../../../api/athletes/service';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { IUserBase } from '../../../../types/users';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { IAthlete } from '../../../../types/athletes';
import { Toast } from '@cycling-web/analog-ui';
import { useAdminTeamMemberStore } from '../../../AdminPanel/store/slice';

type IProps = {
  user: IUserBase;
  onDismiss: () => void;
};

export const useViewModel = (props: IProps) => {
  const { t } = useTranslation();
  const { user, onDismiss } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const athleteMap = useAthletesStore((s) => s.athletesMap);
  const athlete = useMemo(() => {
    if (!user.athleteId) {
      return undefined;
    }
    return athleteMap[user.athleteId];
  }, [athleteMap, user.athleteId]);

  const athletesRepository = useMemo(() => {
    return new AthletesRepository(new AthletesService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        firstName: z.string().min(1, { message: t('validation.required') }),
        lastName: z.string().min(1, { message: t('validation.required') }),
      })
      .passthrough();
  }, [t]);

  const form = useForm({
    defaultValues: {
      firstName: athlete?.firstName || '',
      lastName: athlete?.lastName || '',
    },
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = useCallback(() => {
    handleSubmit((formData) => {
      if (!user.athleteId) {
        return;
      }

      const athlete = athleteMap[user.athleteId];

      if (!athlete) {
        return;
      }
      setLoading(true);

      athletesRepository
        .updateProfile({
          ...athlete,
          ...formData,
          fullName: `${formData?.firstName} ${formData?.lastName}`,
        })
        .then((updatedAthlete: IAthlete) => {
          useAthletesStore.getState().updateAthlete({
            ...athlete,
            ...updatedAthlete,
          });
          useAdminTeamMemberStore.getState().updateUser({
            ...user,
            name: updatedAthlete.fullName,
          });
          onDismiss();
        })
        .catch(() => {
          Toast.error({
            title: t('error.update_profile_title'),
            message: t('error.update_profile_message'),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [athleteMap, athletesRepository, handleSubmit, onDismiss, t, user]);

  return {
    ...props,
    form,
    onSubmit,
    loading,
    errors,
  };
};
