import { IAthletesSlice, IAthletesState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IAthlete, IAthletesFilters } from '../../types/athletes';

export const initialFilters: IAthletesFilters = {
  search: '',
  ids: [],
};

export const initialState: IAthletesState = {
  athletes: [],
  athletesMap: {},
  athletesLoaded: false,
  filters: initialFilters,
};

export const useAthletesStore = create<IAthletesSlice>()(
  immer((set) => ({
    ...initialState,
    setAthletes: (athletes: IAthlete[]) => {
      set((state) => {
        const athletesMap: Record<number, IAthlete> = {};

        athletes.forEach((athlete: IAthlete) => {
          athletesMap[athlete.id] = athlete;
        });

        const sortedAthletes = [...athletes];
        sortedAthletes.sort((a, b) => a.lastName.localeCompare(b.lastName));
        state.athletes = sortedAthletes;
        state.athletesMap = athletesMap;
        state.athletesLoaded = true;
      });
    },
    updateAthlete: (athlete: Partial<IAthlete>) => {
      set((state) => {
        const index = state.athletes.findIndex(
          (a: IAthlete) => a.id === athlete.id
        );

        if (index >= 0) {
          const updatedAthlete = {
            ...state.athletes[index],
            ...athlete,
          };
          state.athletes[index] = updatedAthlete;
          state.athletesMap[state.athletes[index].id] = updatedAthlete;
        }
      });
    },
    setFilters: (filters: Partial<IAthletesFilters>) => {
      set((state) => {
        state.filters = {
          ...state.filters,
          ...filters,
        };
      });
    },
    setAthletesLoaded: (athletesLoaded: boolean) => {
      set((state) => {
        state.athletesLoaded = athletesLoaded;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
