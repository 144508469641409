import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useEffect, useMemo, useRef } from 'react';
import { MedicalRepository } from '../../../api/medical/repository';
import { MedicalService } from '../../../api/medical/service';
import {
  IMedicalFile,
  IMedicalNote,
  IMedicalReport,
} from '../../../types/medical';
import { Toast } from '@cycling-web/analog-ui';
import { useAthleteMedicalReportStore } from '../store/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchAthleteMedicalReport = () => {
  const { t } = useTranslation();
  const { reportId } = useParams();
  const fetchingRef = useRef<boolean>(false);
  const setReport = useAthleteMedicalReportStore((s) => s.setReport);
  const setReportNotes = useAthleteMedicalReportStore((s) => s.setReportNotes);
  const setReportFiles = useAthleteMedicalReportStore((s) => s.setReportFiles);
  const setReportLoaded = useAthleteMedicalReportStore(
    (s) => s.setReportLoaded
  );

  const medicalRepository = useMemo(() => {
    return new MedicalRepository(new MedicalService());
  }, []);

  useEffect(() => {
    if (!reportId || fetchingRef.current) {
      return;
    }
    fetchingRef.current = true;

    const getReportById = medicalRepository.getReportById({
      id: +reportId,
    });
    const getReportNotes = medicalRepository.getNotes({
      medicalReportId: +reportId,
    });
    const getReportFiles = medicalRepository.getFiles({
      medicalReportId: +reportId,
    });
    const getReport = Promise.all([
      getReportById,
      getReportNotes,
      getReportFiles,
    ]);

    getReport
      .then((response: [IMedicalReport, IMedicalNote[], IMedicalFile[]]) => {
        setReport(response[0]);
        setReportNotes(response[1]);
        setReportFiles(response[2]);
      })
      .catch((error: AxiosError) => {
        setReportLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_medical_report_title'),
              message: t('error.get_medical_report_message'),
            },
            { toastId: 'get_medical_report' }
          );
        }
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [
    medicalRepository,
    reportId,
    setReport,
    setReportFiles,
    setReportLoaded,
    setReportNotes,
    t,
  ]);
};
