export const smoothCurveDelegate = (data: number[], index: number): number => {
  const maxWindowSize = 1;
  const windowSize = Math.max(maxWindowSize, Math.floor(index / 3) + 3);
  const start = Math.max(0, index - Math.floor(windowSize / 2));
  const end = Math.min(data.length, index + Math.floor(windowSize / 2) + 1);

  let sum = 0;
  for (let i = start; i < end; i++) {
    sum += data[i];
  }

  return sum / (end - start);
};
