import './index.css';
import { IMedicalNote } from '../../../../types/medical';
import { Avatar, Button, Typography, useBoolean } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useAthleteMedicalReportStore } from '../../store/slice';
import { useEffect, useRef } from 'react';
import { NoteModal } from '../NoteModal';
import { clsx } from 'clsx';
import { useUserRole } from '../../../../hooks/useUserRole';

export const ReportNotes = () => {
  const { t } = useTranslation();
  const { isAthlete } = useUserRole();
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const notes = useAthleteMedicalReportStore((s) => s.reportNotes);

  const {
    value: showNoteModal,
    setTrue: openNoteModal,
    setFalse: dismissNoteModal,
  } = useBoolean(false);

  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTo(
        0,
        scrollableContainerRef.current.offsetHeight
      );
    }
  }, [notes]);

  const notesHeaderClass = clsx(
    'report-notes__header',
    notes.length > 0 && 'report-notes__header--with-notes'
  );

  const messagesJSX = notes.map((message: IMedicalNote) => {
    return (
      <div key={message.id} className="report-notes__message-wrapper">
        <div className="report-notes__message-avatar">
          <Avatar size="s" text={message.createdBy} showDetails={false} />
        </div>
        <div className="report-notes__message">
          <header className="report-notes__message-header">
            <Typography
              className="report-notes__message-author"
              weight="bold"
              text={message.createdBy}
            />
          </header>
          <div className="report-notes__message-body">
            <Typography
              as="pre"
              className="report-notes__message-message"
              weight="regular"
              text={message.note}
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="report-notes">
      <header className={notesHeaderClass}>
        <Typography variant="headline" weight="bold" text={t('label.notes')} />
        {!isAthlete && (
          <Button
            variant="secondary"
            content={t('label.new_note')}
            size="s"
            onClick={openNoteModal}
          />
        )}
      </header>
      {notes.length > 0 && (
        <div className="report-notes__main" ref={scrollableContainerRef}>
          {messagesJSX}
        </div>
      )}
      {showNoteModal && <NoteModal onDismiss={dismissNoteModal} />}
    </div>
  );
};
