import './index.css';
import { IPageHeaderProps, PageHeader } from '../PageHeader';
import { ReactNode, UIEvent, useState } from 'react';
import { IPageFiltersProps, PageFilters } from '../PageFilters';
import throttle from 'lodash/throttle';

type IProps = {
  pageHeaderProps?: IPageHeaderProps;
  pageFiltersProps?: IPageFiltersProps;
  children: ReactNode;
};

export const Page = (props: IProps) => {
  const [showHeaderDivider, setShowHeaderDivider] = useState<boolean>(false);

  const onScroll = throttle((e: UIEvent<HTMLElement>) => {
    const PADDING = 24;
    const scrollAmount = (e.target as HTMLElement).scrollTop;
    setShowHeaderDivider(scrollAmount >= PADDING);
  });

  return (
    <div className="page">
      {props.pageHeaderProps && (
        <PageHeader
          showHeaderDivider={showHeaderDivider}
          {...props.pageHeaderProps}
        />
      )}
      {props.pageFiltersProps && (
        <div className="page__filters">
          <PageFilters {...props.pageFiltersProps} />
        </div>
      )}
      <div className="page__content" onScroll={onScroll}>
        {props.children}
      </div>
    </div>
  );
};
