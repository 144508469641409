import { AnyValue } from '../types/common';

export function sortByDelegate(
  direction: 'asc' | 'desc' = 'asc',
  field?: string
) {
  return (a: AnyValue, b: AnyValue) => {
    const valueA = typeof a === 'object' && a !== null && field ? a[field] : a;
    const valueB = typeof b === 'object' && b !== null && field ? b[field] : b;

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    } else if (typeof valueA === 'string' && typeof valueB === 'string') {
      return direction === 'asc'
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    } else {
      return 0;
    }
  };
}
