import './index.css';
import { BodyComposition } from '../BodyComposition';
import { WellnessScores } from '../../../../components/WellnessScores';
import { useAthleteWellnessStore } from '../../store/slice';
import { WellnessWidget } from '../WellnessWidget';
import { useTranslation } from 'react-i18next';
import { useAthleteStore } from '../../../../store/athlete/slice';

export const DailyWellnessScores = () => {
  const { t } = useTranslation();
  const athleteLoaded = useAthleteStore((s) => s.athleteLoaded);
  const wellnessLoaded = useAthleteWellnessStore((s) => s.wellnessLoaded);
  const wellness = useAthleteWellnessStore((s) => s.wellness);

  return (
    <div className="daily-wellness-scores">
      <div className="daily-wellness-scores__tables">
        <WellnessWidget
          text={t('label.wellness')}
          className="wellness-widget__stats"
        >
          <WellnessScores
            wellness={wellness}
            wellnessLoaded={wellnessLoaded && athleteLoaded}
            showGauge
          />
        </WellnessWidget>
        <BodyComposition />
      </div>
    </div>
  );
};
