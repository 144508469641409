import { ChangeEvent, MouseEvent, ReactNode } from 'react';
import { ITooltipProps } from '../Tooltip/types';
import { IEmptyStateProps } from '../EmptyState/types';

export interface ITableColumn {
  key: string;
  name: string;
  minWidth?: string | number;
  maxWidth?: string | number;
  grow?: number;
  align?: ITableColumnAlign;
  tooltip?: ITableColumnTooltip;
  allowSort?: boolean;
  onSort?: OnColumnSort;
}

export type OnColumnSort = (
  direction: 'asc' | 'desc'
) => (a: any, b: any) => number;
export type OnTableSort = (column: ITableColumn) => void;

export type ITableColumnAlign = 'left' | 'center' | 'right';

export type ITableItem = any;

export interface ITableProps {
  columns: ITableColumn[];
  items: ITableItem[];
  checkbox?: boolean;
  className?: string;
  pagination?: ITablePaginationProps;
  onRenderCell?: (column: ITableColumn, item: ITableItem) => ReactNode;
  onRowClick?: (item: ITableItem, e: MouseEvent) => void;
  onRowsSelect?: (items: ITableItem[]) => void;
  loading?: boolean;
  emptyState?: IEmptyStateProps;
  idKey?: string;
  translations?: ITableTranslations;
  skeletonCount?: number;
  sort?: ITableSortConfig;
  onSort?: OnTableSort;
}

export type ITableColumnTooltip = Omit<ITooltipProps, 'anchor'>;

export type ITablePaginationProps = ITablePaginationBaseProps &
  (ITablePaginationControlledProps | ITablePaginationUncontrolledProps);

export type ITablePaginationBaseProps = {
  total?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  showRowsPerPageSelect?: boolean;
  server?: boolean;
};

export type ITablePaginationControlledProps = {
  page: number;
  onChange: (page: number, e?: MouseEvent) => void;
};

export type ITablePaginationUncontrolledProps = {
  page?: undefined;
  onChange?: undefined;
};

export interface ITableContext extends ITableProps {
  page: number;
  onPageChange: (page: number) => void;
  onSelect: (item: ITableItem) => (e: ChangeEvent<HTMLInputElement>) => void;
  onSelectAllFromPage: () => void;
  selectedItems: ITableItem[];
  selectAll: boolean;
  pageCount: number;
  itemsOnCurrentPage: ITableItem[];
  onSelectAll: () => void;
  rowsPerPage: number;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  sortedItems: ITableItem[];
  sortConfig: ISortConfig | undefined;
  onChangeSortConfig: OnTableSort;
}

export enum ColumnSortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface ISortConfig {
  key: string;
  direction: ColumnSortDirection | 'asc' | 'desc';
  onSort?: OnColumnSort;
}

export type ITableSortConfig = Omit<ISortConfig, 'onSort'>;

export interface ITableTranslations {
  show?: string;
}
