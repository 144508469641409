import { cloneElement, ReactElement, ReactHTMLElement, ReactNode } from 'react';
import { IInputSize } from './types';

interface IProps {
  icon: ReactNode;
  size: IInputSize;
}

export const AdornmentIcon = ({ icon, size = 's' }: IProps) => {
  const iconSizeMap: Record<IInputSize, number> = {
    s: 16,
    m: 20,
    l: 24,
  };

  if (!icon) {
    return null;
  }

  return cloneElement(icon as ReactHTMLElement<HTMLElement>, {
    size: iconSizeMap[size],
  });
};
