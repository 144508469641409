import { Skeleton } from '@cycling-web/analog-ui';
import { IAthlete } from '../../../../../../types/athletes';
import { Parameter } from '../Parameter';
import { TsbUtils } from '../../../../../../utils/tsb';
import { useTranslation } from 'react-i18next';

type IProps = {
  athlete: IAthlete | null | undefined;
  loading?: boolean;
};

export const TSB = ({ athlete, loading }: IProps) => {
  const { t } = useTranslation();
  if (loading) {
    return <Skeleton width="60px" height="24px" />;
  }

  let color = '#f3f3f6';

  if (athlete) {
    color = TsbUtils.getContent(TsbUtils.getStatus(athlete?.tsb)).color;
  }

  const style = { color };

  const label = t('label.tsb');
  const value = <span style={style}>{athlete?.tsb?.toFixed(0) ?? '--'}</span>;

  return <Parameter label={label} value={value} />;
};
