import { useTranslation } from 'react-i18next';
import { PageFilters } from '@cycling-web/common';
import { Button, ISelectOption, ITableColumn } from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { AnyValue } from '../../../../types/common';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import { IPerformanceReport } from '../../../../types/performance';
import { EMPTY } from '../../../../constants';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { useTableColumns } from '../../hooks/useTableColumns';
import { format, subSeconds } from 'date-fns';
import { MultipleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import {
  initialFilters,
  useGroupReportsStore,
} from '../../../GroupDetails/store/slice';
import { IGroupReportsFilters } from '../../../GroupDetails/types';
import { downloadCSV } from '../../../../utils/csv';
import { IAthlete } from '../../../../types/athletes';
import { useGroupAthletes } from '../../../../hooks/useGroupAthletes';
import { useURLFilters } from '../../../../hooks/useURLFilters';
import { Url } from '../../../../utils/url';

export const GroupOverviewFilters = () => {
  const { t } = useTranslation();

  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const reports = useGroupReportsStore((s) => s.reports);
  const filters = useGroupReportsStore((s) => s.filters);
  const setFilters = useGroupReportsStore((s) => s.setFilters);

  const initFilters = useCallback(() => {
    const params = new URLSearchParams(window.location.search);

    useGroupReportsStore.getState().setFilters({
      search: params.get('search') || initialFilters.search,
      period: Url.preparePeriod(params) || initialFilters.period,
      athletes: Url.prepareAthletes(params) || initialFilters.athletes,
    });
  }, []);

  useURLFilters({ filters, initFilters });
  const { groupAthletes } = useGroupAthletes();

  const athleteOptions: ISelectOption[] = useMemo(() => {
    return groupAthletes.map((a: IAthlete) => {
      return {
        id: a.id.toString(),
        text: a.fullName,
      };
    });
  }, [groupAthletes]);

  const columns: ITableColumn[] = useTableColumns();

  const handleChangeFilter = useCallback(
    (key: keyof IGroupReportsFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  );

  const handleClearSearch = useCallback(() => {
    setFilters({ search: '' });
  }, [setFilters]);

  const handleClearAthletes = useCallback(() => {
    setFilters({ athletes: [] });
  }, [setFilters]);

  const handleExportReport = useCallback(() => {
    const headers = columns.map((column: ITableColumn) => {
      return { label: column.name, field: column.key };
    });

    const mappedData: AnyValue[] = reports.map((item: IPerformanceReport) => {
      const athlete = athletesMap[item.athleteId]
        ? athletesMap[item.athleteId].fullName
        : EMPTY;

      return {
        athlete: athlete,
        total_duration: item.durationStr || EMPTY,
        elevation_gain: item.climbInMeter || EMPTY,
        work: item.workInKj || EMPTY,
        distance: item.distanceInKm || EMPTY,
        tss: item.tss || EMPTY,
        ctl: item.ctl || EMPTY,
        tsb: item.tsb || EMPTY,
        coach_rating: item.coachRating || EMPTY,
        coach_comment: item.comments || EMPTY,
        next_race: item.nextRace || EMPTY,
        mcp: item.mCPRelative || EMPTY,
      };
    });

    const endDate = format(new Date(), 'yyyy-MM-dd');
    const startDate = format(
      subSeconds(new Date(), filters.period / 1000),
      'yyyy-MM-dd'
    );

    const name = `${t('label.group_report')} ${startDate} - ${endDate}`;

    downloadCSV(headers, mappedData, name);
  }, [columns, reports, t, filters.period, athletesMap]);

  const filtersJSX = (
    <>
      <MultipleAthleteFilter
        options={athleteOptions}
        value={filters.athletes.map(Number)}
        onChange={handleChangeFilter('athletes')}
        onClear={handleClearAthletes}
        style={{ minWidth: '220px' }}
        className="group-table__athletes-filters"
      />
      <LastNDaysFilter
        value={filters.period}
        onChange={handleChangeFilter('period')}
      />
    </>
  );

  return (
    <PageFilters
      searchInputProps={{
        value: filters.search,
        onChange: handleChangeFilter('search'),
        onClear: handleClearSearch,
      }}
      filterProps={{ filters: filtersJSX }}
      actionElements={
        <Button content={t('action.export')} onClick={handleExportReport} />
      }
    />
  );
};
