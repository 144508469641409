import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Components } from 'react-markdown/lib';

type IProps = {
  content: string;
};

export const MarkdownPart = ({ content }: IProps) => {
  const customRendered: Components = {
    table(props) {
      return (
        <div className="ai-message__table-wrapper">
          <table className="ai-message__table" {...props} />
        </div>
      );
    },
  };

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={customRendered}>
      {content}
    </ReactMarkdown>
  );
};
