import './index.css';
import { clsx } from 'clsx';
import { ReactNode } from 'react';

export type IFormColumnProps = {
  children: ReactNode;
  className?: string;
};

export const FormColumn = ({ className, children }: IFormColumnProps) => {
  const rootClass = clsx('analog-form-column', className);
  return <div className={rootClass}>{children}</div>;
};
