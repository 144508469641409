import { AxiosResponse } from 'axios';

export type R<T> = Promise<AxiosResponse<T>>;

export type WithData<T> = {
  code: number;
  data: T;
  message: string;
};

export enum IPeriodUnit {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  EVERY_SIX_WEEKS = 'EVERY_SIX_WEEKS',
  EVERY_TWELVE_WEEKS = 'EVERY_TWELVE_WEEKS',
}

export type AnyValue = any;

export enum DataType {
  Absolute = 'Absolute',
  Relative = 'Relative',
  Composite = 'Composite',
}
