import { Outlet } from 'react-router';
import { useBreakpoints } from '@cycling-web/common';
import { AthleteDetailsMobile } from '../AthleteDetailsMobile';
import { useUserRole } from '../../hooks/useUserRole';
import React, { useEffect } from 'react';
import { useAthleteStore } from '../../store/athlete/slice';

export const AthleteProfile = () => {
  const { isMobile } = useBreakpoints();
  const { isAthlete } = useUserRole();

  useEffect(() => {
    return () => {
      useAthleteStore.getState().reset();
    };
  }, []);

  if (isMobile && isAthlete) {
    return <AthleteDetailsMobile />;
  }

  return <Outlet />;
};
