import './index.css';
import { Skeleton, Typography } from '@cycling-web/analog-ui';
import { useEffect, useState } from 'react';

interface IProps {
  text: string;
  value: number;
  loaded: boolean;
}

export const Progress = ({ text, value, loaded }: IProps) => {
  const [maxWidth, setMaxWidth] = useState<number>(0);

  useEffect(() => {
    setMaxWidth((value * 100) / 20);
  }, [value]);

  return (
    <div className="wellness-score-progress">
      <Typography
        text={text}
        variant="body"
        className="wellness-score-progress__label"
      />
      <div className="wellness-score-progress__bar">
        {loaded ? (
          <div className="progress-background">
            <div
              style={{ maxWidth: `${maxWidth}%` }}
              className="progress-fill"
            />
          </div>
        ) : (
          <Skeleton height="10px" width="100%" />
        )}

        {loaded ? (
          <Typography
            text={value}
            variant="body"
            className="wellness-score-progress__value"
          />
        ) : (
          <Skeleton height="21px" width="26px" />
        )}
      </div>
    </div>
  );
};
