import { IDashboardSlice, IDashboardState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IDashboardFilters } from '../types';
import { DateRange } from '../../../types/enums';
import {
  ICriticalPower,
  ILatestTrainingLoad,
  IPeak,
  IWorkoutDetail,
} from '../../../types/performance';
import { ALL_FILTER_OPTION_ID } from '../../../constants';

export const initialFilters: IDashboardFilters = {
  groupId: ALL_FILTER_OPTION_ID,
  period: DateRange.ThreeMonth,
};

export const initialState: IDashboardState = {
  filters: initialFilters,
  peaks: [],
  peaksLoaded: false,
  trainingLoad: [],
  trainingLoadLoaded: false,
  criticalPower: [],
  criticalPowerLoaded: false,
  workoutDetails: [],
  workoutDetailsLoaded: false,
};

export const useDashboardStore = create<IDashboardSlice>()(
  immer((set) => ({
    ...initialState,
    setFilters: (filters: Partial<IDashboardFilters>) => {
      set((state) => {
        state.filters = {
          ...state.filters,
          ...filters,
        };
      });
    },
    setTrainingLoad: (trainingLoad: ILatestTrainingLoad[]) => {
      set((state) => {
        state.trainingLoad = trainingLoad;
        state.trainingLoadLoaded = true;
      });
    },
    setTrainingLoadLoaded: (loaded: boolean) => {
      set((state) => {
        state.trainingLoadLoaded = loaded;
      });
    },
    setPeaks: (peaks: IPeak[]) => {
      set((state) => {
        state.peaks = peaks;
        state.peaksLoaded = true;
      });
    },
    setPeaksLoaded: (loaded: boolean) => {
      set((state) => {
        state.peaksLoaded = loaded;
      });
    },
    setCriticalPower: (criticalPower: ICriticalPower[]) => {
      set((state) => {
        state.criticalPower = criticalPower;
        state.criticalPowerLoaded = true;
      });
    },
    setCriticalPowerLoaded: (loaded: boolean) => {
      set((state) => {
        state.criticalPowerLoaded = loaded;
      });
    },
    setWorkoutDetails: (workoutDetails: IWorkoutDetail[]) => {
      set((state) => {
        state.workoutDetails = workoutDetails;
        state.workoutDetailsLoaded = true;
      });
    },
    setWorkoutDetailsLoaded: (loaded: boolean) => {
      set((state) => {
        state.workoutDetailsLoaded = loaded;
      });
    },
    reset: () => {
      set({
        ...initialState,
        filters: initialFilters,
      });
    },
  }))
);
