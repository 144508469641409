import './index.css';
import { MouseEvent, useCallback, useContext, useMemo, useState } from 'react';
import { IMenuBaseOption, IMenuOption, Menu } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { EllipsisVertical } from 'lucide-react';
import { AnthropometryFormModal } from '../../../AnthropometryFormModal';
import { AthleteCardContext } from '../../context';
import { HeightWeightFormModal } from '../../../HeightWeightFormModal';
import { ReportIllnessFormModal } from '../../../ReportIllnessFormModal';
import { ReportInjuryFormModal } from '../../../ReportInjuryFormModal';
import { ReportRehabilitationFormModal } from '../../../ReportRehabilitationFormModal';
import { useBreakpoints } from '@cycling-web/common';
import { useUserRole } from '../../../../hooks/useUserRole';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../../router/routes';
import {
  IMedicalReport,
  IMedicalReportRecord,
} from '../../../../types/medical';
import { MedicalRepository } from '../../../../api/medical/repository';
import { MedicalService } from '../../../../api/medical/service';
import { useAthleteMedicalReportsStore } from '../../../../pages/AthleteMedicalReports/store/slice';
import { useAthletesStore } from '../../../../store/athletes/slice';

export const AthleteCardMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAdmin, isDoctor, isSecondaryDoctor, isPhysiotherapist } =
    useUserRole();
  const showMedicalButtons =
    isAdmin || isDoctor || isSecondaryDoctor || isPhysiotherapist;

  const handleAppendReport = useCallback((report: IMedicalReport) => {
    useAthletesStore.getState().updateAthlete({
      id: report.athleteId,
      medicalReport: report,
    });
  }, []);

  const [showAddDataModal, setShowAddDataModal] = useState<string>('');
  const { athlete } = useContext(AthleteCardContext);
  const { isMobile } = useBreakpoints();

  const dismissAddDataModal = useCallback(() => {
    setShowAddDataModal('');
  }, []);

  const menuOptions: IMenuOption[] = useMemo(() => {
    const options: IMenuOption[] = isMobile
      ? [{ id: 'logout', text: t('action.sign_out') }]
      : [
          { id: 'anthropometry', text: t('label.anthropometry') },
          { id: 'height_weight', text: t('label.height_weight') },
        ];

    if (showMedicalButtons && !isMobile) {
      options.push(
        { id: 'report_illness', text: t('label.report_illness') },
        {
          id: 'report_rehabilitation',
          text: t('label.report_rehabilitation'),
        },
        { id: 'report_injury', text: t('label.report_injury') }
      );
    }

    return options;
  }, [isMobile, t, showMedicalButtons]);

  const onMenuChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id) {
        if (option.id === 'logout') {
          navigate(`/${ROUTES.SIGN_OUT}`);
        } else {
          setShowAddDataModal(option.id);
        }
      }
    },
    [navigate]
  );

  const preventDefault = (e: MouseEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <Menu
        options={menuOptions}
        onChange={onMenuChange}
        dropdownProps={{
          anchor: (
            <div className="athlete-card__header-menu" onClick={preventDefault}>
              <EllipsisVertical />
            </div>
          ),
          placement: 'bottom-end',
          offset: 0,
        }}
      />

      {athlete && (
        <>
          {showAddDataModal === 'anthropometry' && (
            <AnthropometryFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
            />
          )}
          {showAddDataModal === 'height_weight' && (
            <HeightWeightFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
            />
          )}
          {showAddDataModal === 'report_illness' && (
            <ReportIllnessFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
              onSubmitSuccess={handleAppendReport}
            />
          )}
          {showAddDataModal === 'report_rehabilitation' && (
            <ReportRehabilitationFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
              onSubmitSuccess={handleAppendReport}
            />
          )}
          {showAddDataModal === 'report_injury' && (
            <ReportInjuryFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
              onSubmitSuccess={handleAppendReport}
            />
          )}
        </>
      )}
    </>
  );
};
