import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { useAthleteStore } from '../../../store/athlete/slice';
import { MedicalRepository } from '../../../api/medical/repository';
import { MedicalService } from '../../../api/medical/service';
import { IMedicalReportRecord } from '../../../types/medical';
import { Toast } from '@cycling-web/analog-ui';
import { useAthleteMedicalReportsStore } from '../store/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchMedicalReports = () => {
  const { t } = useTranslation();
  const fetchingRef = useRef<boolean>(false);
  const athlete = useAthleteStore((s) => s.athlete);
  const setReports = useAthleteMedicalReportsStore((s) => s.setReports);
  const setReportsLoaded = useAthleteMedicalReportsStore(
    (s) => s.setReportsLoaded
  );

  const medicalRepository = useMemo(() => {
    return new MedicalRepository(new MedicalService());
  }, []);

  useEffect(() => {
    if (!athlete || fetchingRef.current) {
      return;
    }
    fetchingRef.current = true;
    medicalRepository
      .getReports({
        athleteId: athlete.id,
        page: 0,
        size: 100,
      })
      .then((records: IMedicalReportRecord[]) => {
        setReports(records);
      })
      .catch((error: AxiosError) => {
        setReportsLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_medical_reports_title'),
              message: t('error.get_medical_reports_message'),
            },
            { toastId: 'get_medical_reports' }
          );
        }
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [athlete, medicalRepository, setReports, setReportsLoaded, t]);
};
