import { FormElement } from '@cycling-web/analog-ui';
import {
  FormColumn,
  FormRow,
  InputControl,
  SelectSingleControl,
} from '@cycling-web/common';
import { useTranslation } from 'react-i18next';
import { useViewModel } from './useViewModel';
import { OnboardingFooter } from '../../Footer';
import { FormProvider } from 'react-hook-form';
import { NationalityControl } from './NationalityControl';
import { IAthlete } from '../../../../../types/athletes';
import { ImageUploader } from '../ImageUploader';
import { PhoneControl } from '../../../../../components/PhoneControl';

type IProps = {
  athlete: IAthlete;
};

export const PersonalInfoForm = ({ athlete }: IProps) => {
  const { t } = useTranslation();
  const { form, genderOptions, timezoneOptions, onSubmit, loading } =
    useViewModel({
      athlete,
    });
  const { formState } = form;

  return (
    <FormProvider {...form}>
      <div className="onboarding__personal-form">
        <ImageUploader />

        <FormRow>
          <FormColumn>
            <FormElement
              label={t('label.first_name')}
              message={formState.errors.firstName?.message}
            >
              <InputControl
                name="firstName"
                placeholder={t('placeholder.first_name')}
                invalid={!!formState.errors.firstName}
              />
            </FormElement>
          </FormColumn>
          <FormColumn>
            <FormElement
              label={t('label.last_name')}
              message={formState.errors.lastName?.message}
            >
              <InputControl
                name="lastName"
                placeholder={t('placeholder.last_name')}
                invalid={!!formState.errors.lastName}
              />
            </FormElement>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn>
            <FormElement
              label={t('label.phone_number')}
              message={formState.errors.phone?.message}
            >
              <PhoneControl
                name="phone"
                placeholder={t('placeholder.phone_number')}
                invalid={!!formState.errors.phone}
              />
            </FormElement>
          </FormColumn>
          <FormColumn>
            <FormElement
              label={t('label.nationality')}
              message={formState.errors.countryCode?.message}
            >
              <NationalityControl />
            </FormElement>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn>
            <FormElement
              label={t('label.gender')}
              message={formState.errors.gender?.message}
            >
              <SelectSingleControl
                options={genderOptions}
                name="gender"
                placeholder={t('placeholder.gender')}
                invalid={!!formState.errors.gender}
              />
            </FormElement>
          </FormColumn>
          <FormColumn>
            <FormElement
              label={t('label.timezone')}
              message={formState.errors.timezone?.message}
            >
              <SelectSingleControl
                options={timezoneOptions}
                name="timezone"
                placeholder={t('placeholder.timezone')}
                invalid={!!formState.errors.timezone}
                search
                dropdownProps={{
                  maxHeight: '290px',
                }}
              />
            </FormElement>
          </FormColumn>
        </FormRow>
      </div>
      <OnboardingFooter
        stepCompleted={true}
        onNextClick={onSubmit}
        loading={loading}
      />
    </FormProvider>
  );
};
