import { BodyMap } from '../body-map/body-map';
import { useWatch } from 'react-hook-form';
import { MedicalRecordType } from '../../types/medical';

export const BodyMapView = () => {
  const [side, bodyLocation] = useWatch({ name: ['side', 'bodyLocation'] });

  return (
    <BodyMap
      recordType={MedicalRecordType.Physio}
      selectedSide={side}
      selectedBodyLocation={bodyLocation}
      selectedArea={''}
      selectedSymptom=""
      selectedSeverity=""
      isAthleteCard={false}
    />
  );
};
