import { useCallback, useMemo } from 'react';
import { IPaginationProps, IPaginationRowsPerPageProps } from './types';
import { Select } from '../Select';
import { ISelectOption } from '../Select/types';
import { defaultRowsPerPageOptions } from './constants';
import { Button } from '../Button/Button';
import { ChevronDown } from 'lucide-react';
import { clsx } from 'clsx';

export const RowsPerPage = (props: IPaginationProps) => {
  const rowsPerPage = props.rowsPerPage as IPaginationRowsPerPageProps;

  const options: ISelectOption[] = useMemo(() => {
    return (rowsPerPage.options || defaultRowsPerPageOptions).map(
      (option: number) => {
        return {
          id: option.toString(),
          text: option.toString(),
        };
      }
    );
  }, [rowsPerPage.options]);

  const value = useMemo(() => {
    return {
      id: rowsPerPage.value.toString(),
      text: rowsPerPage.value.toString(),
    };
  }, [rowsPerPage.value]);

  const onChange = useCallback(
    (option: ISelectOption): void => {
      rowsPerPage.onChange(Number(option.id));
    },
    [rowsPerPage]
  );

  const onRenderAnchor = useCallback(
    (open?: boolean) => {
      const iconClass = clsx(
        'analog-pagination__dropdown-anchorIcon',
        open && 'analog-pagination__dropdown-anchorIcon--rotate'
      );

      return (
        <div className="analog-pagination__dropdown-anchor">
          <Button
            size="xs"
            variant="quietNeutral"
            disabled={props.disabled}
            endIcon={<ChevronDown size={16} className={iconClass} />}
            content={value.text}
          ></Button>
        </div>
      );
    },
    [value, props.disabled]
  );

  const itemsFrom = (props.page - 1) * rowsPerPage.value + 1;
  const itemsTo = props.page * rowsPerPage.value;

  return (
    <div className="analog-pagination__dropdown">
      <span className="analog-pagination__dropdown-label analog-typography--body regular">
        {props.translations?.show || 'Show'}
      </span>
      <Select
        options={options}
        value={value}
        onChange={onChange}
        onRenderAnchor={onRenderAnchor}
        dropdownProps={{
          minWidth: '100px',
        }}
        disabled={props.disabled}
      />
      <p className="analog-pagination__count-label analog-typography--body regular">
        <span>
          {itemsFrom} - {itemsTo}
        </span>
        of
        <span>{props.total}</span>
        results
      </p>
    </div>
  );
};
