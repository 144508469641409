import './index.css';
import { Select } from '@cycling-web/analog-ui';
import { IExtendSelectProps, ISelectOption } from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataType } from '../../../types/common';

type IProps = {
  options?: DataType[];
  value: DataType;
  onChange: (value: DataType) => void;
  selectProps?: IExtendSelectProps;
};

export const DataTypeFilter = ({
  value,
  onChange,
  options = [DataType.Absolute, DataType.Relative],
  selectProps,
}: IProps) => {
  const { t } = useTranslation();

  const textMap: Record<DataType, string> = useMemo(() => {
    return {
      [DataType.Absolute]: t('label.absolute'),
      [DataType.Relative]: t('label.relative'),
      [DataType.Composite]: t('label.composite'),
    };
  }, [t]);

  const selectOptions: ISelectOption[] = useMemo(() => {
    return options.map((o: DataType) => {
      return {
        id: o.toString(),
        text: textMap[o],
      };
    });
  }, [options, textMap]);

  const handleChange = useCallback(
    (option: ISelectOption) => {
      onChange(option.id as DataType);
    },
    [onChange]
  );

  return (
    <div className="data-type-filter">
      <Select
        options={selectOptions}
        value={selectOptions.find((o: ISelectOption) => o.id === value)}
        onChange={handleChange}
        placeholder={t('placeholder.data_type')}
        {...selectProps}
      />
    </div>
  );
};
