import { IAthlete } from '../../../types/athletes';
import { EMPTY } from '../../../constants';
import { Avatar } from '@cycling-web/analog-ui';

type IProps = {
  athlete: IAthlete | undefined;
};

export const Athlete = ({ athlete }: IProps) => {
  if (!athlete) {
    return EMPTY;
  }
  return (
    <div className="medical-report-table__athlete">
      <Avatar
        src={athlete.picture}
        text={athlete.fullName}
        alt={athlete.fullName}
        direction="default"
        size="s"
      />
    </div>
  );
};
