import { DictionariesService } from './service';
import { IEnums } from '../../types/dictionaries';
import { UserRole } from '@cycling-web/common';

/** The repository is responsible for data transformation */

export interface IDictionariesRepository {
  getUserRoles: () => Promise<UserRole[]>;

  getEnums: () => Promise<IEnums>;
}

export class DictionariesRepository implements IDictionariesRepository {
  constructor(private readonly service: DictionariesService) {}

  async getUserRoles(): Promise<UserRole[]> {
    const response = await this.service.getUserRoles();
    return response.data;
  }

  async getEnums(): Promise<IEnums> {
    const response = await this.service.getEnums();
    return response.data;
  }
}
