import React, { useCallback, useMemo } from 'react';
import { DateGroupBy, DateRange } from '../../../../types/enums';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import { IHandleChangeFilter } from '@cycling-web/common';
import {
  Chart,
  ChartColor,
  ChartLegendItem,
  chartTooltipFormatter,
  getDateAxisInterval,
  getScatterSizeByDateRange,
  IEChartOption,
} from '../../../../components/Chart';
import { GroupByPeriodFilter } from '../../../../components/filters/GroupByPeriodFilter';
import { useTranslation } from 'react-i18next';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';
import { ChartTrackingNames } from '../../../../constants/charts';

type IProps = {
  source: (string | number)[][];
  filters: {
    dateGroupBy?: DateGroupBy;
    period: DateRange;
  };
  handleChangeFilter: IHandleChangeFilter;
  loading?: boolean;
};

export const TssAndIfChart = ({
  source,
  filters,
  handleChangeFilter,
  loading,
}: IProps) => {
  const { t } = useTranslation();

  const onRenderTooltipValue = useCallback(
    (value: number, seriesName: string) => {
      if (seriesName === 'TSS') {
        return Math.round(value);
      }
      return value.toFixed(2);
    },
    []
  );

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          rotate: 30,
          interval: getDateAxisInterval(filters),
        },
      },
      yAxis: [
        {
          type: 'value',
          min: 0,
          position: 'left',
          axisPointer: {
            show: true,
            label: {
              show: false,
            },
          },
        },
        {
          type: 'value',
          min: 0,
          position: 'right',
          axisPointer: {
            show: true,
            label: {
              show: false,
            },
          },
        },
      ],
      series: [
        {
          name: 'TSS',
          type: 'bar',
          yAxisIndex: 0,
          z: 2,
          itemStyle: {
            color: (params) => {
              return params.dataIndex % 2 === 0
                ? ChartColor.LightBlue
                : ChartColor.Blue;
            },
          },
        },
        {
          name: 'IF',
          type: 'scatter',
          yAxisIndex: 1,
          z: 3,
          symbolSize: (value) => {
            return value[2] === 0
              ? 0
              : getScatterSizeByDateRange(filters.period);
          },
          itemStyle: {
            color: (params) => {
              const value = params.value[2];
              if (value > 0.8) {
                return ChartColor.LightRed;
              } else if (value > 0.59) {
                return ChartColor.LightGreen;
              } else {
                return ChartColor.Yellow;
              }
            },
            opacity: 1,
          },
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
        }),
      },
    };
  }, [filters, onRenderTooltipValue, source]);

  const filtersBarProps = {
    filters: (
      <>
        <LastNDaysFilter
          value={filters.period}
          onChange={handleChangeFilter('period')}
          selectProps={{ variant: 'light' }}
        />
        {filters.dateGroupBy && (
          <GroupByPeriodFilter
            value={filters.dateGroupBy}
            onChange={handleChangeFilter('dateGroupBy')}
            selectProps={{ variant: 'light' }}
          />
        )}
      </>
    ),
  };

  const onRenderLegendItem = (item) => {
    if (item.name === 'TSS') {
      return (
        <ChartLegendItem
          item={{
            ...item,
            color: [ChartColor.LightBlue, ChartColor.Blue],
          }}
        />
      );
    }

    return (
      <>
        IF
        <ChartLegendItem
          item={{
            ...item,
            name: `${t('label.high')} >0.8`,
            color: ChartColor.LightRed,
          }}
        />
        <ChartLegendItem
          item={{
            ...item,
            name: `${t('label.medium')} >0.59`,
            color: ChartColor.LightGreen,
          }}
        />
        <ChartLegendItem
          item={{
            ...item,
            name: `${t('label.low')} <=0.59`,
            color: ChartColor.Yellow,
          }}
        />
      </>
    );
  };

  return (
    <ChartWrapper>
      <Chart
        headerProps={{
          title: 'TSS & IF',
          filtersBarProps,
        }}
        legendProps={{
          onRenderItem: onRenderLegendItem,
        }}
        option={option}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.TssAndIf,
          }),
        }}
      />
    </ChartWrapper>
  );
};
