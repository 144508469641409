import { useAthletesStore } from '../../../../store/athletes/slice';
import { useMemo } from 'react';
import { filterAthletesDelegate } from '../utils/filterAthletesDelegate';
import { AthleteList } from '../../../../components/AthleteList';

export const List = () => {
  const filters = useAthletesStore((s) => s.filters);
  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);

  const filteredAthletes = useMemo(() => {
    return athletes.filter(filterAthletesDelegate(filters));
  }, [athletes, filters]);

  return <AthleteList athletes={filteredAthletes} loaded={athletesLoaded} />;
};
