import { useTranslation } from 'react-i18next';
import { useContext, useMemo } from 'react';
import { Plus } from 'lucide-react';
import { GroupProfilesContext } from '../../context';
import { useGroupsStore } from '../../../../store/groups/slice';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { IAthlete } from '../../../../types/athletes';
import { AthleteList } from '../../../../components/AthleteList';

export const List = () => {
  const { t } = useTranslation();
  const group = useGroupsStore((s) => s.group);
  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);

  const { openAthleteDialog } = useContext(GroupProfilesContext);

  const filteredAthletes = useMemo((): IAthlete[] => {
    if (!group) {
      return [];
    }

    const athletesInGroup: Record<number, boolean> = {};
    group.athleteIds.forEach((id) => {
      athletesInGroup[id] = true;
    });

    return athletes.filter((a: IAthlete) => athletesInGroup[a.id]);
  }, [group, athletes]);

  return (
    <AthleteList
      athletes={filteredAthletes}
      loaded={athletesLoaded}
      emptyStateProps={{
        buttonProps: {
          content: t('action.add_athletes'),
          startIcon: <Plus />,
          onClick: openAthleteDialog,
        },
      }}
    />
  );
};
