import './index.css';
import { ChartSpline } from 'lucide-react';
import { EmptyState, Spinner } from '@cycling-web/analog-ui';
import { t } from 'i18next';
import { PinnedChart } from './components/PinnedChart';
import { useFetchPinnedCharts } from './hooks/useFetchPinnedCharts';
import { IPinnedChart } from './types';
import { usePinnedTabStore } from '../../store/pinned-tabs/slice';

export const PinnedTab = () => {
  useFetchPinnedCharts();

  const charts = usePinnedTabStore((s) => s.charts);
  const chartsLoaded = usePinnedTabStore((s) => s.chartsLoaded);

  if (!chartsLoaded) {
    return (
      <EmptyState
        icon={<Spinner size="xl" />}
        title={t('banner.loading_chart_data')}
      />
    );
  }

  if (charts.length === 0) {
    return (
      <EmptyState
        icon={<ChartSpline size={40} />}
        title={t('banner.empty_pinned_charts_title')}
        text={t('banner.empty_pinned_charts_text')}
      />
    );
  }

  const chartsJSX = charts.map((graph: IPinnedChart) => {
    return <PinnedChart key={graph.id} graph={graph} />;
  });

  return <div className="pinned-charts">{chartsJSX}</div>;
};
