import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { ROUTES } from '../../router/routes';
import { useUsersStore } from '../../store/users/slice';
import { PageSpinner } from '@cycling-web/common';
import { LS } from '../../constants';

export const SignOut = () => {
  const { instance } = useMsal();

  useEffect(() => {
    const accounts = instance.getAllAccounts();
    const account = instance.getActiveAccount();

    useUsersStore.getState().setAuthenticated(false);

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key !== LS.Cookies && key !== LS.CookiesVersion) {
        localStorage.removeItem(key);
      }
    }

    if (accounts.length === 0) {
      window.location.href = `${window.location.origin}/${ROUTES.SIGN_IN}`;
      return;
    }

    instance
      .logout({
        account,
        postLogoutRedirectUri: `${window.location.origin}/${ROUTES.SIGN_IN}`,
      })
      .finally(() => {
        window.location.reload();
      });
  }, [instance]);

  return <PageSpinner />;
};
