import './index.css';
import { ICookie } from '../../types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@cycling-web/analog-ui';

type IProps = {
  cookie: ICookie;
};

export const Cookie = ({ cookie }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="cookie-item">
      <div className="cookie-item__row">
        <Typography
          as="p"
          variant="subtitle"
          weight="regular"
          className="cookie-item__label"
          text={t('label.cookie')}
        />
        <Typography
          as="p"
          variant="subtitle"
          weight="regular"
          className="cookie-item__value"
          text={cookie.name}
        />
      </div>

      <div className="cookie-item__row">
        <Typography
          as="p"
          variant="subtitle"
          weight="regular"
          className="cookie-item__label"
          text={t('label.purpose')}
        />
        <Typography
          as="p"
          variant="subtitle"
          weight="regular"
          className="cookie-item__value"
          text={cookie.description}
        />
      </div>

      <div className="cookie-item__row">
        <Typography
          as="p"
          variant="subtitle"
          weight="regular"
          className="cookie-item__label"
          text={t('label.duration')}
        />
        <Typography
          as="p"
          variant="subtitle"
          weight="regular"
          className="cookie-item__value"
          text={cookie.duration}
        />
      </div>
    </div>
  );
};
