import './index.css';
import { Button, IconButton } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { SelectedAthletes } from '../SelectedAthletes';
import MenuExpand from '../../assets/menu-expand';
import { useSmartChartsContext } from '../../context';

export const SmartChartsHeader = () => {
  const { t } = useTranslation();
  const { startNewSession, showHistory, openHistory } = useSmartChartsContext();

  return (
    <div className="sc2__header">
      <div className="sc2__header-athletes">
        <SelectedAthletes />
      </div>

      <div className="sc2__header-actions">
        <Button
          variant="secondary"
          content={t('smart_charts.action.new_session')}
          onClick={startNewSession}
        />
        {!showHistory && (
          <IconButton
            variant="tertiaryLight"
            content={<MenuExpand />}
            onClick={openHistory}
          />
        )}
      </div>
    </div>
  );
};
