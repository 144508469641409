import { IChatbotHistoryMessage } from '../../../types/chatbot';

export const DEFAULT_MESSAGE: IChatbotHistoryMessage = {
  id: '',
  created_at: 0,
  assistant_id: '',
  thread_id: '',
  role: 'assistant',
  content: [
    {
      type: 'text',
      text: {
        value: 'Hello! How can I help you today?',
        annotations: [],
      },
    },
  ],
  feedback_type: '',
};
