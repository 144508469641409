import './index.css';
import { Button, Dialog } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useCallback, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { dataURLToBlob } from '../../utils/files';

type IProps = {
  aspectRatio?: number;
  src?: string;
  file?: File;
  onSubmit: (file: File | null, src: string | null) => Promise<void>;
  onDismiss: () => void;
};

export const ProfilePictureDialog = ({
  src,
  file,
  onSubmit,
  onDismiss,
  aspectRatio = 1,
}: IProps) => {
  const { t } = useTranslation();
  const cropperRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSaveClick = useCallback(() => {
    setLoading(true);
    const cropper = cropperRef.current?.cropper;

    if (cropper) {
      const croppedImage = cropper
        .getCroppedCanvas({
          width: 800,
          height: 800 / aspectRatio,
        })
        ?.toDataURL('image/jpeg', 0.8);
      if (croppedImage) {
        const { blob, fileName } = dataURLToBlob(croppedImage);
        const croppedFile = new File([blob], file?.name || fileName, {
          type: blob.type,
        });
        onSubmit(croppedFile, croppedImage).then(() => {
          onDismiss();
        });
      } else {
        console.error('Unable to crop the image.');
        setLoading(false);
      }
    } else {
      console.error('Cropper instance not found.');
      setLoading(false);
    }
  }, [file, onSubmit, onDismiss]);

  const onRenderFooter = useCallback(() => {
    return (
      <div className="analog__profile-picture-dialog-footer">
        <div className="analog__profile-picture-dialog-footerRight">
          <Button
            content={t('action.save')}
            onClick={handleSaveClick}
            loading={loading}
          />
        </div>
      </div>
    );
  }, [handleSaveClick, loading, t]);

  return (
    <Dialog
      onDismiss={onDismiss}
      title={t('label.upload_picture')}
      onRenderFooter={onRenderFooter}
      className="analog__profile-picture-dialog"
      preventDefaultOnClick={false}
      outsidePress={false}
    >
      <div className="analog__profile-picture-body">
        <Cropper
          src={src || ''}
          style={{ height: 360, width: '100%' }}
          aspectRatio={aspectRatio}
          viewMode={1}
          dragMode="move"
          guides={false}
          cropBoxResizable={false}
          cropBoxMovable={false}
          background={false}
          responsive={true}
          autoCropArea={0.8}
          ref={cropperRef}
        />
      </div>
    </Dialog>
  );
};
