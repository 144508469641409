import { useTranslation } from 'react-i18next';
import { Button, FormElement, Typography } from '@cycling-web/analog-ui';
import { InstructionStep } from './InstructionStep';
import { useEffect, useMemo, useRef, useState } from 'react';
import Image1 from './assets/step1.png';
import Image2 from './assets/step2.png';
import Image3 from './assets/step3.png';
import Image4 from './assets/step4.png';
import { PlatformRepository } from '../../../api/platform/repository';
import { PlatformService } from '../../../api/platform/service';

type IProps = {
  onComplete: () => void;
  onStepChange: (n: number) => () => void;
};

export const UHStep2 = ({ onStepChange, onComplete }: IProps) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>('');
  const [codeLoading, setCodeLoading] = useState<boolean>(false);
  const fetchingRef = useRef<boolean>(false);

  const platformRepository = useMemo(() => {
    return new PlatformRepository(new PlatformService());
  }, []);

  useEffect(() => {
    if (fetchingRef.current) {
      return;
    }
    fetchingRef.current = true;
    setCodeLoading(true);
    platformRepository
      .getUltrahumanAccessCode()
      .then((code) => {
        setCode(code);
      })
      .finally(() => {
        setCodeLoading(false);
        fetchingRef.current = false;
      });
  }, [platformRepository]);

  return (
    <div className="uh__step">
      <header className="uh__step-header">
        <Typography
          variant="subtitle"
          weight="regular"
          text={t('label.ultrahuman_step2_title')}
          className="uh__step-header-title"
        />
        <div className="uh__step-header-code">
          <Typography
            variant="h4"
            weight="bold"
            text={code}
            loading={codeLoading}
            skeletonProps={{ width: '120px' }}
          />
        </div>
      </header>
      <div>
        <FormElement label={t('label.ultrahuman_step2_text')}>
          <div className="uh__instruction">
            <InstructionStep
              number={1}
              title={t('label.ultrahuman_instruction_step1')}
              image={Image1}
            />
            <InstructionStep
              number={2}
              title={t('label.ultrahuman_instruction_step2')}
              image={Image2}
            />
            <InstructionStep
              number={3}
              title={t('label.ultrahuman_instruction_step3')}
              image={Image3}
            />
            <InstructionStep
              number={4}
              title={t('label.ultrahuman_instruction_step4')}
              image={Image4}
            />
          </div>
        </FormElement>
      </div>
      <footer className="ultrahuman-onboarding-modal__footer">
        <Button
          variant="quietNeutral"
          content={t('action.back')}
          onClick={onStepChange(-1)}
        />
        <Button content={t('action.done')} onClick={onComplete} />
      </footer>
    </div>
  );
};
