import './index.css';
import { Plus } from 'lucide-react';
import { MouseEvent, useCallback } from 'react';

type IProps = {
  onClick: () => void;
};

export const AssignButton = ({ onClick }: IProps) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );

  return (
    <button className="team-member__assign-button" onClick={handleClick}>
      <Plus size={16} />
    </button>
  );
};
