import './index.css';
import { Input, Typography } from '@cycling-web/analog-ui';
import { IInputProps } from '@cycling-web/analog-ui';
import { FiltersBar, IFiltersBarProps } from '../FiltersBar';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type IPageFiltersProps = {
  title?: ReactNode;
  searchInputProps?: IInputProps;
  filterProps?: IFiltersBarProps;
  actionElements?: ReactNode;
};

export const PageFilters = (props: IPageFiltersProps) => {
  const { t } = useTranslation();

  if (
    !props.searchInputProps &&
    !props.filterProps &&
    !props.actionElements &&
    !props.title
  ) {
    return null;
  }

  return (
    <div className="page-filters">
      <div className="page-filters__left">
        {props.title && (
          <Typography as="h4" variant="h4" weight="bold" text={props.title} />
        )}
        {props.searchInputProps && (
          <div className="page-filters__search">
            <Input
              {...props.searchInputProps}
              placeholder={t('placeholder.search')}
            />
          </div>
        )}
        {props.filterProps && <FiltersBar {...props.filterProps} />}
      </div>
      {props.actionElements && (
        <div className="page-filters__actions">{props.actionElements}</div>
      )}
    </div>
  );
};
