import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { IPeak } from '../../../types/performance';
import { Toast } from '@cycling-web/analog-ui';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { IAthlete } from '../../../types/athletes';
import { useDashboardStore } from '../store/slice';
import { useAthletesStore } from '../../../store/athletes/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchTeamPeaks = () => {
  const { t } = useTranslation();
  const fetching = useRef<boolean>(false);
  const filters = useDashboardStore((s) => s.filters);
  const setPeaks = useDashboardStore((s) => s.setPeaks);
  const setPeaksLoaded = useDashboardStore((s) => s.setPeaksLoaded);

  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);
  const loading = !athletesLoaded;

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService());
  }, []);

  useEffect(() => {
    if (loading || fetching.current || athletes.length === 0) {
      setPeaksLoaded(true);
      return;
    }

    fetching.current = true;

    const filteredAthletes = athletes;

    const { startDate, endDate } = calculateDateRange(filters.period);

    performanceRepository
      .getPeak({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteIds: filteredAthletes.map((a: IAthlete) => a.id),
        allAthletes: false,
      })
      .then((peaks: IPeak[]) => {
        setPeaks(peaks);
      })
      .catch((error: AxiosError) => {
        setPeaksLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_team_power_profile_title'),
              message: t('error.get_team_power_profile_message'),
            },
            { toastId: 'get_team_power_profile' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [
    loading,
    athletes,
    filters.period,
    performanceRepository,
    setPeaks,
    setPeaksLoaded,
    t,
  ]);
};
