import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { ILatestTrainingLoad } from '../../../types/performance';
import { Toast } from '@cycling-web/analog-ui';
import { IAthlete } from '../../../types/athletes';
import { useDashboardStore } from '../store/slice';
import { useAthletesStore } from '../../../store/athletes/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchTeamTrainingLoad = () => {
  const { t } = useTranslation();
  const fetching = useRef<boolean>(false);
  const filters = useDashboardStore((s) => s.filters);
  const setTrainingLoad = useDashboardStore((s) => s.setTrainingLoad);
  const setTrainingLoadLoaded = useDashboardStore(
    (s) => s.setTrainingLoadLoaded
  );

  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);
  const loading = !athletesLoaded;

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService());
  }, []);

  useEffect(() => {
    if (loading || fetching.current || athletes.length === 0) {
      setTrainingLoadLoaded(true);
      return;
    }

    fetching.current = true;

    const filteredAthletes = athletes;

    performanceRepository
      .getLatestTrainingLoad({
        athleteIds: filteredAthletes.map((a: IAthlete) => a.id),
      })
      .then((data: ILatestTrainingLoad[]) => {
        setTrainingLoad(data);
      })
      .catch((error: AxiosError) => {
        setTrainingLoadLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_team_critical_power_title'),
              message: t('error.get_team_critical_power_message'),
            },
            { toastId: 'get_team_critical_power' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [
    loading,
    athletes,
    filters.period,
    performanceRepository,
    t,
    setTrainingLoad,
    setTrainingLoadLoaded,
  ]);
};
