import './index.css';
import { clsx } from 'clsx';
import { MarkdownPart } from './MarkdownPart';
import { FractionPart } from './FractionPart';
import { LatexPart } from './LatexPart';
import {
  ISmartChartTurn,
  SmartChartActor,
} from '../../../../types/smart-charts';
import ChatLogo from '../../../../assets/icons/chat-logo.svg?react';

type IProps = {
  message: ISmartChartTurn;
};

const parseMessage = (message: string) => {
  const parts: Array<{ type: string; content: string }> = [];
  const regex =
    /(\$\$.*?\$\$|\$.*?\$|\\\[.*?\\]|\\\((.*?)\\\)|\\begin\{(.*?)}|\\frac\{.*?}\{.*?})/g;
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = regex.exec(message)) !== null) {
    if (match.index > lastIndex) {
      parts.push({
        type: 'markdown',
        content: message.slice(lastIndex, match.index),
      });
    }

    const latex = match[0];
    if (/\\frac\{.*?}\{.*?}/.test(latex)) {
      parts.push({ type: 'fraction', content: latex });
    } else {
      parts.push({ type: 'latex', content: latex });
    }
    lastIndex = regex.lastIndex;
  }

  if (lastIndex < message.length) {
    parts.push({
      type: 'markdown',
      content: message.slice(lastIndex),
    });
  }

  return parts;
};

export const Message = ({ message }: IProps) => {
  const isUser = message.actor === SmartChartActor.User;

  const rootClass = clsx(
    'sc2__message',
    isUser ? 'sc2__message--right' : '',
    'analog-typography--subtitle'
  );

  const parts = parseMessage(message.message);
  const partsJSX = parts.map((part, index: number) => {
    return (
      <div key={`wrapper-${index}`} className="sc2__message-part">
        {part.type === 'markdown' && (
          <MarkdownPart key={index} content={part.content} />
        )}
        {part.type === 'fraction' && (
          <FractionPart key={index} content={part.content} />
        )}
        {part.type === 'latex' && (
          <LatexPart key={index} content={part.content} />
        )}
      </div>
    );
  });

  return (
    <div className={rootClass}>
      <div className="sc2__message-content">
        {!isUser && (
          <div className="sc2__message-content-actor">
            <ChatLogo />
          </div>
        )}
        <div className="sc2__message-content-parts">{partsJSX}</div>
      </div>
    </div>
  );
};
