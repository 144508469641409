import './index.css';
import { SummaryCard } from '../SummaryCard';
import { Activity, BriefcaseMedical, Gauge, HeartPulse } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { useDashboardStore } from '../../store/slice';
import { useCallback, useMemo, useState } from 'react';
import { IAthlete } from '../../../../types/athletes';
import { HealthSummaryModal } from '../HealthSummaryModal';
import { ILatestTrainingLoad } from '../../../../types/performance';
import { TsbSummaryModal } from '../TsbSummaryModal';
import { RecoverySummaryModal } from '../RecoverySummaryModal';
import { CheckInSummaryModal } from '../CheckInSummaryModal';
import { HrvRhrUtils } from '../../../../utils/hrv-rhr';
import { MedicalRecordType } from '../../../../types/medical';

enum Card {
  Health = 'Health',
  CheckIn = 'CheckIn',
  Recovery = 'Recovery',
  TSBWatch = 'TSBWatch',
}

export const Summary = () => {
  const { t } = useTranslation();
  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);

  const trainingLoad = useDashboardStore((s) => s.trainingLoad);
  const trainingLoadLoaded = useDashboardStore((s) => s.trainingLoadLoaded);

  const loading = !athletesLoaded || !trainingLoadLoaded;

  const [showModal, setShowModal] = useState<Card | null>(null);

  const openModal = useCallback(
    (name: Card) => {
      return () => {
        if (!loading) {
          setShowModal(name);
        }
      };
    },
    [loading]
  );

  const dismissModal = useCallback(() => {
    setShowModal(null);
  }, []);

  const injuredAthletes = useMemo(() => {
    return athletes.filter((a: IAthlete) => {
      return (
        a.medicalReport &&
        a.medicalReport.recordType === MedicalRecordType.Injury
      );
    });
  }, [athletes]);

  const subjectiveFeedbackAthletes = useMemo(() => {
    return athletes.filter((a: IAthlete) => {
      return a.selfReportedWellnessScore !== undefined;
    });
  }, [athletes]);

  const recoveryAthletes = useMemo(() => {
    return athletes.filter((a: IAthlete) => {
      return (
        HrvRhrUtils.getStatus({
          hrvVariance: a.hrvVariance,
          rhrVariance: a.rhrVariance,
        }) !== undefined
      );
    });
  }, [athletes]);

  const tsbWatchTrainingLoad = useMemo(() => {
    return trainingLoad.filter((r: ILatestTrainingLoad) => {
      return r.tsb < -30 || r.tsb > 25;
    });
  }, [trainingLoad]);

  return (
    <div className="dashboard-summary">
      <SummaryCard
        title={t('label.health')}
        icon={<BriefcaseMedical />}
        count={injuredAthletes.length}
        total={athletes.length}
        loading={loading}
        onClick={openModal(Card.Health)}
      />
      <SummaryCard
        title={t('label.check_in')}
        icon={<Gauge />}
        count={subjectiveFeedbackAthletes.length}
        total={athletes.length}
        loading={loading}
        onClick={openModal(Card.CheckIn)}
      />
      <SummaryCard
        title={t('label.recovery')}
        icon={<HeartPulse />}
        count={recoveryAthletes.length}
        total={athletes.length}
        loading={loading}
        onClick={openModal(Card.Recovery)}
      />
      <SummaryCard
        title={t('label.tsb_watch')}
        icon={<Activity />}
        count={tsbWatchTrainingLoad.length}
        total={athletes.length}
        loading={loading}
        onClick={openModal(Card.TSBWatch)}
      />

      {showModal === Card.Health && (
        <HealthSummaryModal
          onDismiss={dismissModal}
          athletes={injuredAthletes}
        />
      )}
      {showModal === Card.CheckIn && (
        <CheckInSummaryModal
          onDismiss={dismissModal}
          athletes={subjectiveFeedbackAthletes}
        />
      )}
      {showModal === Card.Recovery && (
        <RecoverySummaryModal
          onDismiss={dismissModal}
          athletes={recoveryAthletes}
        />
      )}
      {showModal === Card.TSBWatch && (
        <TsbSummaryModal
          onDismiss={dismissModal}
          trainingLoad={tsbWatchTrainingLoad}
        />
      )}
    </div>
  );
};
