import { UsersService } from './service';
import {
  IAthleteMappingForm,
  IInviteUserForm,
  ITeamMapping,
  IUserBase,
  IUserProfile,
} from '../../types/users';
import {
  ICheckRegistrationRequest,
  IDeleteInvitationRequest,
  IResendInvitationRequest,
  ISetOnboardingStatusRequest,
  IUpdateAthleteMappingsRequest,
  IUpdatePictureRequest,
  IUpdateStaffRoleRequest,
} from './types';
import { roleKeyMapper } from '../../constants/users';

/** The repository is responsible for data transformation */

export interface IUsersRepository {
  checkRegistration: (p: ICheckRegistrationRequest) => Promise<void>;
  getUserProfile: () => Promise<IUserProfile>;
  inviteUser: (payload: IInviteUserForm) => Promise<void>;
  resendInvitation: (p: IResendInvitationRequest) => Promise<void>;
  deleteInvitation: (p: IDeleteInvitationRequest) => Promise<void>;
  getUsers: () => Promise<IUserBase[]>;
  setOnboardingStatus: (p: ISetOnboardingStatusRequest) => Promise<void>;
  getTeamMappings: () => Promise<ITeamMapping[]>;
  updateAthleteMapping: (p: IAthleteMappingForm) => Promise<void>;
  updateStaffRole: (p: IUpdateStaffRoleRequest) => Promise<void>;
  updatePicture: (p: IUpdatePictureRequest) => Promise<void>;
}

export class UsersRepository implements IUsersRepository {
  constructor(private readonly service: UsersService) {}

  async checkRegistration(p: ICheckRegistrationRequest): Promise<void> {
    const response = await this.service.checkRegistration(p);
    return response.data;
  }

  async getUserProfile(): Promise<IUserProfile> {
    const response = await this.service.getUserProfile();
    return response.data;
  }

  async inviteUser(payload: IInviteUserForm): Promise<void> {
    const response = await this.service.inviteUser(payload);
    return response.data;
  }

  async resendInvitation(payload: IResendInvitationRequest): Promise<void> {
    const response = await this.service.resendInvitation(payload);
    return response.data;
  }

  async deleteInvitation(payload: IDeleteInvitationRequest): Promise<void> {
    const response = await this.service.deleteInvitation(payload);
    return response.data;
  }

  async getUsers(): Promise<IUserBase[]> {
    const response = await this.service.getUsers();
    return response.data.map((user: IUserBase) => {
      return {
        ...user,
        roles: user.roles || [],
      };
    });
  }

  async setOnboardingStatus(p: ISetOnboardingStatusRequest): Promise<void> {
    const response = await this.service.setOnboardingStatus(p);
    return response.data;
  }

  async getTeamMappings(): Promise<ITeamMapping[]> {
    const response = await this.service.getTeamMappings();
    return response.data;
  }

  async updateAthleteMapping(p: IAthleteMappingForm): Promise<void> {
    const payload: IUpdateAthleteMappingsRequest = {
      athleteId: p.athleteId,
      roleStaffIdMap: {},
    };

    for (const key in p.roleStaffIdMap) {
      if (p.roleStaffIdMap[key]) {
        payload.roleStaffIdMap[roleKeyMapper[key]] = Number(
          p.roleStaffIdMap[key]
        );
      }
    }

    const response = await this.service.updateAthleteMapping(payload);
    return response.data;
  }

  async updateStaffRole(p: IUpdateStaffRoleRequest): Promise<void> {
    const response = await this.service.updateStaffRole(p);
    return response.data;
  }

  async updatePicture(p: IUpdatePictureRequest): Promise<void> {
    const formData = new FormData();
    if (p.userId) {
      formData.append('userId', p.userId.toString());
    }
    formData.append('picture', p.picture);
    const response = await this.service.updatePicture(formData);
    return response.data;
  }
}
