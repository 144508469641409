import './index.css';
import { Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useViewModel } from './useViewModel';
import { FileUploadButton, ProfilePictureDialog } from '@cycling-web/common';
import { User } from 'lucide-react';

export const ProfileSummary = () => {
  const { t } = useTranslation();
  const {
    userProfile,
    roles,
    onImageUpload,
    showUploadPictureModal,
    onImageChange,
    dismissUploadPictureModal,
    file,
    base64,
  } = useViewModel();

  return (
    <div className="settings-profile__summary">
      <div className="settings-profile__summary-image analog-typography--headline">
        {userProfile?.picture ? (
          <img
            className="settings-profile__summary-image-img"
            src={userProfile.picture}
            alt={userProfile?.fullName}
          />
        ) : (
          <User size={42} />
        )}
        <FileUploadButton
          inputProps={{ accept: 'image/jpeg image/png' }}
          onChange={onImageChange}
        >
          <button className="settings-profile__summary-image-button analog-typography--button-s">
            {t('action.change')}
          </button>
        </FileUploadButton>
      </div>
      <div className="settings-profile__summary-details">
        <Typography
          className="settings-profile__summary-name"
          variant="h4"
          weight="bold"
          text={userProfile?.fullName}
        />
        <Typography
          className="settings-profile__summary-roles"
          variant="headline"
          weight="regular"
          text={roles.join(', ')}
        />
      </div>

      {showUploadPictureModal && (
        <ProfilePictureDialog
          file={file}
          src={base64}
          onDismiss={dismissUploadPictureModal}
          onSubmit={onImageUpload}
        />
      )}
    </div>
  );
};
