import './index.css';
import { Button } from '@cycling-web/analog-ui';
import { useViewModel } from './useViewModel';
import { Auth } from '../Auth';
import { useTranslation } from 'react-i18next';

export const SignIn = () => {
  const { t } = useTranslation();
  const { signInWithMicrosoft, loading } = useViewModel();

  return (
    <Auth>
      <div className="sign-in__form">
        <h3 className="sign-in__title analog-typography--h3 bold">
          {t('label.login_welcome_title')}
        </h3>
        <p className="sign-in__text analog-typography--body regular">
          {t('label.login_welcome_text')}
        </p>

        <div className="sing-in__footer">
          <Button
            type="button"
            size="l"
            fullWidth
            content={t('action.login')}
            onClick={signInWithMicrosoft}
            loading={loading}
          />

          <div className="create-account-message analog-typography--button-m">
            <span>{t('label.no_account_message')}</span>
            <button
              className="create-account-message__button"
              onClick={signInWithMicrosoft}
            >
              {t('label.create_account_message')}
            </button>
          </div>
        </div>
      </div>
    </Auth>
  );
};
