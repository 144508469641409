import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useEffect, useMemo, useRef } from 'react';
import { WellnessRepository } from '../../../api/wellness/repository';
import { WellnessService } from '../../../api/wellness/service';
import { IHeartMeasurement } from '../../../types/wellness';
import { Toast } from '@cycling-web/analog-ui';
import { useAthleteWellnessStore } from '../store/slice';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchAthleteHeartMeasurements = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const fetching = useRef<boolean>(false);
  const setHeartMeasurements = useAthleteWellnessStore(
    (s) => s.setHeartMeasurements
  );
  const setHeartMeasurementsLoaded = useAthleteWellnessStore(
    (s) => s.setHeartMeasurementsLoaded
  );
  const filters = useAthleteWellnessStore((s) => s.filters);

  const wellnessRepository = useMemo(() => {
    return new WellnessRepository(new WellnessService());
  }, []);

  useEffect(() => {
    if (!athleteId || fetching.current) {
      return;
    }

    fetching.current = true;

    const { startDate, endDate } = calculateDateRange(filters.period);

    wellnessRepository
      .getHeartMeasurements({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteId: +athleteId,
        unit: filters.dateGroupBy,
      })
      .then((data: IHeartMeasurement) => {
        setHeartMeasurements(data);
      })
      .catch((error: AxiosError) => {
        setHeartMeasurementsLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_athlete_heart_measurements_title'),
              message: t('error.get_athlete_heart_measurements_message'),
            },
            { toastId: 'get_athlete_heart_measurements' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [
    athleteId,
    filters.dateGroupBy,
    filters.period,
    setHeartMeasurements,
    setHeartMeasurementsLoaded,
    t,
    wellnessRepository,
  ]);
};
