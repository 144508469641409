import { AnyValue } from './common';

export type IChatbotConfig = {
  user_id: number;
  athlete_id: number;
  thread_id: string | null;
  assistant_id: string | null;
};

export type IChatbotHistory = IChatbotConfig & {
  messages: IChatbotHistoryMessage[];
};

export type IChatbotHistoryMessage = {
  id: string;
  created_at: number;
  assistant_id: string | null;
  thread_id: string | null;
  role: string;
  feedback_type: string;
  content: IChatbotHistoryMessageContent[];
};

export type IChatbotHistoryMessageContent = {
  type: string;
  text: IChatbotHistoryMessageText;
};

export type IChatbotHistoryMessageText = {
  value: string;
  annotations: AnyValue[];
};

export enum IChatbotFeedbackType {
  Good = 'thumbs_up',
  Bad = 'thumbs_down',
}

export type IMessageFeedback = {
  message_id: string;
  thread_id: string;
  feedback_type: IChatbotFeedbackType;
  tags: string[];
  user_message: string;
};
