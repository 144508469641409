import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IPdCurve } from '../../../../types/performance';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { DateRange } from '../../../../types/enums';
import { IHandleChangeFilter } from '@cycling-web/common';
import {
  Chart,
  ChartColor,
  ChartLegendItem,
  chartTooltipFormatter,
  IEChartOption,
} from '../../../../components/Chart';
import { getGradient } from '../../../../components/Chart/utils/getGradient';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import { smoothCurveDelegate } from './utils';
import { defaultChartText } from '../../../../components/Chart/constants/defaults';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../constants/charts';
import { TodayDetails } from './TodayDetails';
import { EMPTY } from '../../../../constants';
import { formatTime } from '../../../../utils/date-time';
import { DataTypeFilter } from '../../../../components/filters/DataTypeFilter';
import { useAthletePerformanceStore } from '../../store/slice';
import {
  IAthletePerformanceFilters,
  IAthletePowerDurationCurveFilters,
} from '../../types';
import { AnyValue, DataType } from '../../../../types/common';
import { curveDisplayValues, curveFormatTime } from '../../../../utils/charts';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';

type IProps = {
  data: {
    absolute: IPdCurve | null;
    relative: IPdCurve | null;
  };
  filters: {
    period: DateRange;
  };
  handleChangeFilter: IHandleChangeFilter;
  loading?: boolean;
};

export const PowerDurationCurveChart = ({
  data: { absolute, relative },
  filters,
  handleChangeFilter,
  loading,
}: IProps) => {
  const { t } = useTranslation();

  const setFilters = useAthletePerformanceStore((s) => s.setFilters);
  const pdFilters = useAthletePerformanceStore(
    (s) => s.filters.powerDerivatives
  );
  const pdHandleChangeFilter = useCallback(
    (key: keyof IAthletePowerDurationCurveFilters) => {
      return (value: AnyValue) => {
        setFilters({
          powerDerivatives: {
            [key]: value,
          },
        } as Partial<IAthletePerformanceFilters>);
      };
    },
    [setFilters]
  );
  const isAbsolute = pdFilters.dataType === DataType.Absolute;

  const data = useMemo(() => {
    return isAbsolute ? absolute : relative;
  }, [absolute, isAbsolute, relative]);
  const dataPoints = useMemo(() => {
    return data?.dataPoints || [];
  }, [data?.dataPoints]);

  const source = useMemo(() => {
    const source: (string | number)[][] = [
      [ChartAxisName.Date, t('label.pd_curve'), t('label.mmp_curve')],
    ];

    for (let i = 1; i < dataPoints.length; i++) {
      if (i < 4800) {
        source.push([
          Math.log2(i),
          smoothCurveDelegate(dataPoints, i),
          dataPoints[i],
        ]);
      } else {
        if (i % 60 === 0) {
          source.push([
            Math.log2(i),
            smoothCurveDelegate(dataPoints, i),
            dataPoints[i],
          ]);
        }
      }
    }

    return source;
  }, [dataPoints, t]);

  const displayValuesIndexRef = useRef<number>(0);
  const savedXAxisLabels = useRef<string[]>([]);
  const xAxisLabelsSaved = useRef<boolean>(false);

  const axisLabelFormatter = useCallback((value: number, i: number) => {
    if (i === 0 && savedXAxisLabels.current.length > 0) {
      xAxisLabelsSaved.current = true;
    }

    if (xAxisLabelsSaved.current) {
      return savedXAxisLabels.current[i];
    }

    const parsedValue = Math.pow(2, value);
    const tolerance = 0.01 * parsedValue;

    const targetDisplayValue =
      curveDisplayValues[displayValuesIndexRef.current];
    const delta = Math.abs(parsedValue - targetDisplayValue);
    const isPassedMaxTolerance = parsedValue - targetDisplayValue > tolerance;
    const isCloseEnough = delta <= tolerance;

    if (isPassedMaxTolerance) {
      displayValuesIndexRef.current++;
    }

    if (isCloseEnough) {
      const displayValue = curveFormatTime(targetDisplayValue);
      savedXAxisLabels.current.push(displayValue);
      return displayValue;
    }

    savedXAxisLabels.current.push('');
    return '';
  }, []);

  const onRenderTooltipHeader = useCallback((params) => {
    const time = params[0].value[0];
    const parsedValue = Math.round(Math.pow(2, time));
    return formatTime(parsedValue);
  }, []);

  const onRenderTooltipValue = useCallback(
    (value: number, _: string) => {
      if (isAbsolute) {
        return `${Math.round(value)} ${t('units.w')}`;
      }
      return `${Math.round(value * 100) / 100} ${t('units.w')}/${t(
        'units.kg'
      )}`;
    },
    [isAbsolute, t]
  );

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      xAxis: {
        type: 'value',
        splitNumber: source.length,
        axisLabel: {
          hideOverlap: true,
          formatter: axisLabelFormatter,
        },
      },
      yAxis: [
        {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
        },
      ],
      series: [
        {
          name: t('label.pd_curve'),
          type: 'line',
          large: true,
          smooth: true,
          yAxisIndex: 0,
          itemStyle: {
            color: ChartColor.Red,
          },
          areaStyle: {
            ...getGradient({
              from: ChartColor.Red30,
              to: ChartColor.Red30,
            }),
            origin: 'start',
          },
          symbolSize: 0,
          encode: {
            x: ChartAxisName.Date,
            y: t('label.pd_curve'),
          },
          markLine: {
            data: [
              {
                label: {
                  ...defaultChartText,
                  fontSize: 10,
                  formatter: `${ChartAxisName.CP} ${data?.cp?.toFixed(0)}`,
                  position: 'insideEndTop',
                  color: ChartColor.AxisLabelText,
                  distance: 6,
                },
                yAxis: data?.cp?.toFixed(0),
                lineStyle: {
                  color: ChartColor.LightGreen50,
                },
              },
            ],
          },
        },
        {
          name: t('label.mmp_curve'),
          type: 'line',
          large: true,
          smooth: true,
          yAxisIndex: 0,
          itemStyle: {
            color: ChartColor.LightGreen,
          },
          lineStyle: {
            type: 'dashed',
          },
          symbolSize: 0,
          encode: {
            x: ChartAxisName.Date,
            y: t('label.mmp_curve'),
          },
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
          onRenderHeader: onRenderTooltipHeader,
        }),
      },
    };
  }, [
    t,
    axisLabelFormatter,
    data?.cp,
    onRenderTooltipHeader,
    onRenderTooltipValue,
    source,
  ]);

  const onRenderLegendItem = (item) => {
    return <ChartLegendItem item={item} />;
  };

  const filtersBarProps = {
    filters: (
      <>
        <LastNDaysFilter
          value={filters.period}
          onChange={handleChangeFilter('period')}
          selectProps={{ variant: 'light' }}
        />
        <DataTypeFilter
          value={pdFilters.dataType}
          onChange={pdHandleChangeFilter('dataType')}
          selectProps={{ variant: 'light' }}
        />
      </>
    ),
  };

  const onFinished = useCallback(() => {
    displayValuesIndexRef.current = 0;
  }, []);

  return (
    <ChartWrapper minHeight="540px">
      <Chart
        headerProps={{
          title: t('label.power_duration_curve'),
          subtitle: (
            <TodayDetails
              cp={data?.cp ? Math.round(data?.cp).toString() : EMPTY}
              w={data?.w ? (Math.round(data?.w / 100) / 10).toString() : EMPTY}
            />
          ),
          filtersBarProps,
        }}
        option={option}
        legendProps={{
          onRenderItem: onRenderLegendItem,
        }}
        loading={loading}
        events={{
          onFinished,
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.PowerDurationCurve,
          }),
        }}
      />
    </ChartWrapper>
  );
};
