import './index.css';
import { useCallback } from 'react';
import { Button, Dialog, EmptyState } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import InfoCircleFilled from '../../../assets/icons/info-circle-filled.svg?react';
import { useSearchParams } from 'react-router';

type IProps = {
  integrationName: string;
  onDismiss: () => void;
};

export const ConnectedDialog = ({ integrationName, onDismiss }: IProps) => {
  const { t } = useTranslation();
  const [_, setSearchParams] = useSearchParams();

  const handleDismiss = useCallback(() => {
    setSearchParams({});
    onDismiss();
  }, [onDismiss, setSearchParams]);

  const onRenderFooter = useCallback(() => {
    return (
      <footer className="connected-dialog__footer">
        <Button content={t('action.done')} onClick={handleDismiss} />;
      </footer>
    );
  }, [handleDismiss, t]);

  return (
    <Dialog
      title={t('label.data_sharing')}
      onDismiss={handleDismiss}
      onRenderFooter={onRenderFooter}
      outsidePress={false}
    >
      <EmptyState
        icon={<InfoCircleFilled className="connected-dialog__banner-icon" />}
        title={t('banner.successfully_connected_title', {
          item: integrationName,
        })}
        text={t('banner.successfully_connected_text')}
      />
    </Dialog>
  );
};
