import { Controller, useFormContext } from 'react-hook-form';
import { ITextareaProps, Textarea } from '@cycling-web/analog-ui';

type IProps = ITextareaProps & {
  name: string;
};

export const TextareaControl = (props: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, onBlur, value } }) => (
        <Textarea
          {...props}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
      )}
    />
  );
};
