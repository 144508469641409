import { Page } from '@cycling-web/common';
import { useTranslation } from 'react-i18next';
import { AthleteMedicalReport } from '../AthleteMedicalReport';

export const MedicalReport = () => {
  const { t } = useTranslation();

  return (
    <Page pageHeaderProps={{ title: t('label.medical_report') }}>
      <AthleteMedicalReport />
    </Page>
  );
};
