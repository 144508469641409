import './index.css';
import { Divider } from '@cycling-web/analog-ui';
import { ProfileSummary } from './components/ProfileSummary';
import { ProfileDetails } from './components/ProfileDetails';
import { DeleteAccount } from './components/DeleteAccount';

export const SettingsProfile = () => {
  return (
    <div className="settings-profile">
      <div className="settings-profile__section">
        <ProfileSummary />
      </div>
      <Divider />
      <div className="settings-profile__section">
        <ProfileDetails />
      </div>
      <Divider />
      <div className="settings-profile__section">
        <DeleteAccount />
      </div>
    </div>
  );
};
