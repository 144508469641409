import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { IInputProps, IInputSize, IInputVariant } from './types';
import type { ISpinnerSize } from '../Spinner/types';
import type { IButtonSize } from '../Button/types';
import './index.css';
import { clsx } from 'clsx';
import { IconButton } from '../Button/IconButton';
import { Spinner } from '../Spinner';
import { Copy, X as Close } from 'lucide-react';
import { AdornmentIcon } from './AdornmentIcon';
import { onCopy } from '../../utils/clipboard';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';

export const Input = forwardRef<HTMLInputElement, IInputProps>((props, ref) => {
  const size: IInputSize = props.size || 'm';
  const variant: IInputVariant = props.variant || 'dark';
  const onChange = props.onChange;
  const regex = props.regex;

  const rootClass: string = clsx(
    'analog-input',
    `analog-input--${size}`,
    `analog-input--${variant}`,
    props.focused && 'analog-input--focused',
    props.invalid && 'analog-input--invalid',
    props.disabled && 'analog-input--disabled',
    props.readOnly && 'analog-input--readOnly',
    props.className
  );

  const fontSizeMap: Record<IInputSize, string> = {
    s: 'analog-typography--body',
    m: 'analog-typography--body',
    l: 'analog-typography--body',
  };

  const inputClass: string = clsx('analog-input__input', fontSizeMap[size]);

  const buttonSize: Record<IInputSize, IButtonSize> = {
    s: 'xs',
    m: 's',
    l: 'm',
  };

  const spinnerSizeMap: Record<IInputSize, ISpinnerSize> = {
    s: 'xs',
    m: 's',
    l: 'm',
  };

  const spinnerSize: ISpinnerSize =
    props.spinnerProps?.size || spinnerSizeMap[size];
  const spinnerClass: string = clsx('analog-input__spinner');

  const nativeProps: IInputProps = useMemo(() => {
    const nativeProps = { ...props };
    delete nativeProps.startAdornment;
    delete nativeProps.endAdornment;
    delete nativeProps.onClear;
    delete nativeProps.loading;
    delete nativeProps.invalid;
    delete nativeProps.spinnerProps;
    delete nativeProps.size;
    delete nativeProps.focused;
    delete nativeProps.canCopy;
    delete nativeProps.translations;
    delete nativeProps.variant;
    delete nativeProps.regex;
    return nativeProps;
  }, [props]);

  const clearButtonJSX = !props.disabled && props.onClear && (
    <IconButton
      variant="quietNeutral"
      size={buttonSize[size]}
      onClick={props.onClear}
      content={<Close />}
    />
  );

  const [copyTooltipContent, setCopyTooltipContent] = useState(
    props.translations?.copy || 'Copy'
  );

  const handleCopy = useCallback(() => {
    onCopy(`${props.value || props.defaultValue || ''}`).then(() => {
      setCopyTooltipContent(props.translations?.copied || 'Copied');
      setTimeout(() => {
        setCopyTooltipContent(props.translations?.copy || 'Copy');
      }, 1000);
    });
  }, [
    props.value,
    props.defaultValue,
    props.translations?.copied,
    props.translations?.copy,
  ]);

  const copyButtonJSX = !props.disabled && props.canCopy && (
    <Tooltip
      portal
      placement="bottom-end"
      content={<Typography variant="subtitle" text={copyTooltipContent} />}
      delay={{
        open: 500,
        close: 0,
      }}
      showArrow={false}
      anchor={
        <IconButton
          variant="quietNeutral"
          size={buttonSize[size]}
          onClick={handleCopy}
          content={<Copy />}
        />
      }
    />
  );

  const spinnerJSX = !props.disabled && props.loading && (
    <div className={spinnerClass}>
      <Spinner
        variant={props.spinnerProps?.variant || 'primary'}
        size={spinnerSize}
      />
    </div>
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      let value = e.currentTarget.value;

      if (regex) {
        value = value
          .split('')
          .filter((char) => regex.test(char))
          .join('');
      }

      if (onChange) {
        onChange(value, e);
      }
    },
    [onChange, regex]
  );

  const value: string = useMemo(
    () => props.value?.toString() || '',
    [props.value]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!regex) {
        return;
      }

      const isValidCharacter = regex.test(e.key);

      const navigationKeys = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
        'Tab',
        'Enter',
      ];

      if (!isValidCharacter && !navigationKeys.includes(e.key)) {
        e.preventDefault();
      }
    },
    [regex]
  );

  return (
    <div className={rootClass}>
      {props.startAdornment && (
        <div className="analog-input__adornment analog-input__startAdornment">
          <AdornmentIcon icon={props.startAdornment} size={size} />
        </div>
      )}
      <input
        {...nativeProps}
        size={undefined}
        className={inputClass}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={ref}
      />
      {(clearButtonJSX ||
        copyButtonJSX ||
        props.endAdornment ||
        spinnerJSX) && (
        <div className="analog-input__adornment analog-input__endAdornment">
          {value.length > 0 && clearButtonJSX}
          {copyButtonJSX}
          {!spinnerJSX && props.endAdornment}
          {spinnerJSX}
        </div>
      )}
    </div>
  );
});

Input.displayName = 'Input';
