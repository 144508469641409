import type { ChangeEvent } from 'react';
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import type { IInputTimeProps } from './types';
import { Input } from './';
import { Clock } from 'lucide-react';
import { IconButton } from '../Button/IconButton';

export const InputTime = forwardRef<HTMLInputElement, IInputTimeProps>(
  (props, ref) => {
    const { onChange, min, max, onPreventInput, disabled, step } = props;
    const previousValue = useRef<string>(props.value || '');
    const minArray = useMemo(
      () => (min?.split('') ?? []).map((item) => Number(item)),
      [min]
    );
    const maxArray = useMemo(
      () => (max?.split('') ?? []).map((item) => Number(item)),
      [max]
    );

    const checkDisallowTime = useCallback(
      (value: string[]) => {
        const array = value.map((item) => Number(item));
        const hasMin = minArray.length === 5;
        const hasMax = maxArray.length === 5;

        if (hasMin) {
          const hourLessThanMin =
            array[0] < minArray[0] ||
            (array[0] === minArray[0] && array[1] < minArray[1]);
          const isSameHour =
            array[0] === minArray[0] && array[1] === minArray[1];
          const minuteLessThanMin =
            isSameHour &&
            (array[3] < minArray[3] ||
              (array[3] === minArray[3] && array[4] < minArray[4]));

          if (hourLessThanMin || minuteLessThanMin) {
            return true;
          }
        }

        if (hasMax) {
          const hourGreaterThanMax =
            array[0] > maxArray[0] ||
            (array[0] === maxArray[0] && array[1] > maxArray[1]);
          const isSameHour =
            array[0] === maxArray[0] && array[1] === maxArray[1];
          const minuteGreaterThanMax =
            isSameHour &&
            (array[3] > maxArray[3] ||
              (array[3] === maxArray[3] && array[4] > maxArray[4]));

          if (hourGreaterThanMax || minuteGreaterThanMax) {
            return true;
          }
        }

        if (typeof step === 'number') {
          const firstMinDigit = (isNaN(array[3]) ? 0 : array[3]) * 10;
          const secondMinDigit = isNaN(array[4]) ? 0 : array[4];
          const minute = firstMinDigit + secondMinDigit;
          const divider = firstMinDigit / step;
          const minStep = Math.floor((Math.floor(divider) * step) / 10) * 10;
          const maxStep = Math.floor((Math.ceil(divider) * step) / 10) * 10;
          return !(
            minute % step === 0 ||
            (isNaN(array[4]) &&
              (minStep === firstMinDigit || maxStep === firstMinDigit))
          );
        }

        return false;
      },
      [minArray, maxArray, step]
    );

    const handleChange = useCallback(
      (value: string, e?: ChangeEvent<HTMLInputElement>) => {
        const input = e?.target as HTMLInputElement;
        const currentCursorPosition = input?.selectionStart ?? value.length;
        const cursorPosition = currentCursorPosition - 1;
        let nextCursorPosition = currentCursorPosition;

        let array = value.replace(/[^0-9:]/g, '').split('');

        if (previousValue.current.length < value.length || value.length === 1) {
          if (cursorPosition === 0) {
            if (array.length === 1) {
              if (+array[0] > 2) {
                array[1] = array[0];
                array[0] = '0';
                array[2] = ':';
                nextCursorPosition = 3;
              }
            } else {
              if (+array[0] > 2) {
                array.shift();
                nextCursorPosition = 0;
              } else {
                if (array[3] === ':') {
                  array.splice(1, 1);
                }
                if (+array[0] === 2) {
                  if (+array[1] > 3) {
                    array[1] = '3';
                  }
                }
              }
            }
          }

          if (cursorPosition === 1) {
            if (array[0] === '2' && +array[1] > 3) {
              array.splice(1, 1);
              nextCursorPosition = 1;
            } else {
              if (array.length === 2) {
                array[2] = ':';
                nextCursorPosition = 3;
              } else {
                array.splice(2, 1);
                if (!array.includes(':')) {
                  array.splice(2, 0, ':');
                }
              }
            }
          }

          if (cursorPosition === 2) {
            const value = array[2];
            array.splice(2, 1);
            if (+value <= 5) {
              array[2] = ':';
              array[3] = value;
              nextCursorPosition = 4;
            } else {
              nextCursorPosition = 3;
            }
          }

          if (cursorPosition === 3) {
            if (+array[3] > 5) {
              array.splice(3, 1);
              nextCursorPosition = 3;
            }
          }
        } else {
          nextCursorPosition = currentCursorPosition;
        }

        if (array.length > 5) {
          array = array.filter((_, i: number) => i < 5);
        }

        const isDisallowTime = checkDisallowTime(array);
        if (isDisallowTime) {
          onPreventInput?.();
          return;
        }

        const nextValue = array.join('');
        previousValue.current = nextValue;
        onChange(nextValue);

        if (cursorPosition !== undefined) {
          if (nextCursorPosition > nextValue.length) {
            nextCursorPosition = nextValue.length;
          }

          setTimeout(() => {
            input.setSelectionRange(nextCursorPosition, nextCursorPosition);
          }, 0);
        }
      },
      [onChange, checkDisallowTime, onPreventInput]
    );

    const nativeProps = useMemo(() => {
      const nativeProps = { ...props };
      delete nativeProps.onPreventInput;
      return nativeProps;
    }, [props]);

    return (
      <Input
        {...nativeProps}
        ref={ref}
        onChange={handleChange}
        endAdornment={
          <IconButton
            variant="quietNeutral"
            disabled={disabled}
            size="s"
            content={<Clock />}
          />
        }
      />
    );
  }
);

InputTime.displayName = 'InputTime';
