import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { ICalendarContext, TimePeriod } from './types';
import { CalendarContext } from './context';
import { clsx } from 'clsx';

export const Years = () => {
  const {
    handleTimePeriodChange,
    value,
    onChange,
    onDateForPeriodChange,
    range,
    innerRange,
    currentInnerRangePointer,
  }: ICalendarContext = useContext(CalendarContext);
  const scrollToYearRef = useRef<HTMLButtonElement>(null);

  const yearsRange: number[] = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const result: number[] = [];

    for (let i = currentYear - 99; i <= currentYear + 99; i++) {
      result.push(i);
    }

    return result;
  }, []);

  const onYearClick = useCallback(
    (year: number) => (): void => {
      if (range) {
        const currentInnerRangeValue =
          innerRange.current[currentInnerRangePointer.current];

        if (!currentInnerRangeValue) {
          innerRange.current.push(new Date());
        }

        const nextValue: Date =
          innerRange.current[innerRange.current.length - 1] || new Date();
        nextValue.setFullYear(year);
        onDateForPeriodChange(nextValue);
        handleTimePeriodChange(TimePeriod.Months);
      } else {
        const nextValue: Date = value ? new Date(value) : new Date();
        nextValue.setFullYear(year);
        onChange(nextValue);
        onDateForPeriodChange(nextValue);
        handleTimePeriodChange(TimePeriod.Months);
      }
    },
    [
      range,
      innerRange,
      currentInnerRangePointer,
      onDateForPeriodChange,
      handleTimePeriodChange,
      value,
      onChange,
    ]
  );

  const yearsJSX = yearsRange.map((year: number) => {
    const today: Date = new Date();
    const isCurrentYear: boolean = today.getFullYear() === year;
    const isSelectedYear = range
      ? false
      : !!value && value.getFullYear() === year;

    const refCondition = isSelectedYear || isCurrentYear;
    const ref = refCondition ? scrollToYearRef : null;

    const rootClass: string = clsx(
      'analog-calendar__dateButton analog-calendar__dateButton--current analog-calendar__year',
      isCurrentYear && 'analog-calendar__dateButton--today',
      isSelectedYear && 'analog-calendar__dateButton--selected'
    );

    return (
      <button
        key={year}
        ref={ref}
        className={rootClass}
        onClick={onYearClick(year)}
      >
        {year}
      </button>
    );
  });

  useLayoutEffect(() => {
    if (!scrollToYearRef.current) {
      return;
    }

    scrollToYearRef.current.scrollIntoView();
  }, []);

  return (
    <div className="analog-calendar__years-wrapper">
      <div className="analog-calendar__dates analog-typography--button-m">
        {yearsJSX}
      </div>
    </div>
  );
};
