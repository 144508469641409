import './index.css';
import { IMenuContext, IMenuProps } from './types';
import { MenuContent } from './MenuContent';
import { useViewModel } from './useViewModel';
import { clsx } from 'clsx';
import { Dropdown } from '../Dropdown';
import { MenuContext } from './context';
import { FloatingTree } from '@floating-ui/react';

export const Menu = (props: IMenuProps) => {
  const context: IMenuContext = useViewModel(props);
  const { dropdownProps }: IMenuContext = context;

  const rootClass: string = clsx('analog-menu', props.className);

  return (
    <FloatingTree>
      <MenuContext.Provider value={context}>
        <Dropdown
          {...dropdownProps}
          className={rootClass}
          content={<MenuContent options={props.options} />}
          interactions={['click', 'listNavigation', 'dismiss']}
        />
      </MenuContext.Provider>
    </FloatingTree>
  );
};
