import { PageFilters } from '@cycling-web/common';
import { useCallback, useMemo } from 'react';
import { AnyValue } from '../../../../types/common';
import { MultipleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import {
  initialFilters,
  useGroupReportsStore,
} from '../../../GroupDetails/store/slice';
import { IGroupReportsFilters } from '../../../GroupDetails/types';
import { ISelectOption } from '@cycling-web/analog-ui';
import { IAthlete } from '../../../../types/athletes';
import { useGroupAthletes } from '../../../../hooks/useGroupAthletes';
import { useURLFilters } from '../../../../hooks/useURLFilters';
import { Url } from '../../../../utils/url';

export const GroupChartsFilters = () => {
  const filters = useGroupReportsStore((s) => s.filters);
  const setFilters = useGroupReportsStore((s) => s.setFilters);

  const initFilters = useCallback(() => {
    const params = new URLSearchParams(window.location.search);

    useGroupReportsStore.getState().setFilters({
      search: params.get('search') || initialFilters.search,
      period: Url.preparePeriod(params) || initialFilters.period,
      athletes: Url.prepareAthletes(params) || initialFilters.athletes,
    });
  }, []);

  useURLFilters({ filters, initFilters });

  const { groupAthletes } = useGroupAthletes();

  const athleteOptions: ISelectOption[] = useMemo(() => {
    return groupAthletes.map((a: IAthlete) => {
      return {
        id: a.id.toString(),
        text: a.fullName,
      };
    });
  }, [groupAthletes]);

  const handleChangeFilter = useCallback(
    (key: keyof IGroupReportsFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  );

  const handleClearSearch = useCallback(() => {
    setFilters({ search: '' });
  }, [setFilters]);

  const handleClearAthletes = useCallback(() => {
    setFilters({ athletes: [] });
  }, [setFilters]);

  const filtersJSX = (
    <MultipleAthleteFilter
      options={athleteOptions}
      value={filters.athletes.map(Number)}
      onChange={handleChangeFilter('athletes')}
      onClear={handleClearAthletes}
      style={{ minWidth: '220px' }}
      className="group-charts__athletes-filters"
    />
  );

  return (
    <PageFilters
      searchInputProps={{
        value: filters.search,
        onChange: handleChangeFilter('search'),
        onClear: handleClearSearch,
      }}
      filterProps={{ filters: filtersJSX }}
    />
  );
};
