import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { ICriticalPower } from '../../../types/performance';
import { Toast } from '@cycling-web/analog-ui';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { useGroupReportsStore } from '../../GroupDetails/store/slice';
import { filterAthletesDelegate } from '../utils/filterAthletesDelegate';
import { IAthlete } from '../../../types/athletes';
import { useGroupAthletes } from '../../../hooks/useGroupAthletes';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchGroupCriticalPower = () => {
  const { t } = useTranslation();
  const fetching = useRef<boolean>(false);
  const { groupAthletes, loading } = useGroupAthletes();
  const filters = useGroupReportsStore((s) => s.filters);
  const setCriticalPower = useGroupReportsStore((s) => s.setCriticalPower);
  const setCriticalPowerLoaded = useGroupReportsStore(
    (s) => s.setCriticalPowerLoaded
  );

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService());
  }, []);

  useEffect(() => {
    if (loading || fetching.current || groupAthletes.length === 0) {
      return;
    }

    fetching.current = true;

    const filteredAthletes = groupAthletes.filter(
      filterAthletesDelegate(filters)
    );
    const { startDate, endDate } = calculateDateRange(filters.period);

    performanceRepository
      .getCriticalPower({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteIds: filteredAthletes.map((a: IAthlete) => a.id),
      })
      .then((data: ICriticalPower[]) => {
        setCriticalPower(data);
      })
      .catch((error: AxiosError) => {
        setCriticalPowerLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_team_critical_power_title'),
              message: t('error.get_team_critical_power_message'),
            },
            { toastId: 'get_team_critical_power' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [
    loading,
    groupAthletes,
    filters,
    performanceRepository,
    setCriticalPower,
    setCriticalPowerLoaded,
    t,
  ]);
};
