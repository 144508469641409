import { Skeleton } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { IAthlete } from '../../../../../../types/athletes';
import { Parameter } from '../Parameter';
import { HrvRhrUtils } from '../../../../../../utils/hrv-rhr';
import { EMPTY } from '../../../../../../constants';

type IProps = {
  athlete: IAthlete | null | undefined;
  loading?: boolean;
};

export const HRV = ({ athlete, loading }: IProps) => {
  const { t } = useTranslation();
  const label = t('label.hrv');

  if (loading) {
    return <Skeleton width="100px" height="24px" />;
  }

  if (!athlete) {
    return <Parameter label={label} value={`${EMPTY}${EMPTY}`} />;
  }

  const status = HrvRhrUtils.getStatus({
    hrvVariance: athlete.hrvVariance,
    rhrVariance: athlete.rhrVariance,
  });

  let color = '#f3f3f6';

  if (status) {
    color = HrvRhrUtils.getContent(status).color;
  }

  const sign = athlete.hrvVariance > 0 ? '+' : '';
  const signValue = `${sign}${athlete.hrvVariance.toFixed(0)}`;

  const value = (
    <>
      <span className="analog-typography--subtitle regular" style={{ color }}>
        ({signValue})
      </span>
      <span style={{ whiteSpace: 'nowrap' }}>
        {athlete?.hrv ? athlete.hrv.toFixed(0) : `${EMPTY}${EMPTY}`}
      </span>
    </>
  );

  return <Parameter label={label} value={value} />;
};
