import { createContext } from 'react';
import { IGroup } from '../../types/groups';

export type IGroupsContext = {
  showGroupFormModal: IGroup | null;
  dismissGroupFormModal: () => void;
  openGroupFormModal: (group: IGroup) => () => void;
};

export const GroupsContext = createContext<IGroupsContext>(
  {} as IGroupsContext
);

export const DEFAULT_GROUP: IGroup = {
  id: -1,
  name: '',
  description: '',
  athleteIds: [],
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
};
