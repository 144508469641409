import './index.css';
import { Table } from '@cycling-web/analog-ui';
import { PageHeader } from '@cycling-web/common';
import { useViewModel } from './useViewModel';
import { useTranslation } from 'react-i18next';

export const UserTeams = () => {
  const { t } = useTranslation();
  const {
    columns,
    items,
    loading,
    emptyStateProps,
    onRenderCell,
    onRenderMenu,
  } = useViewModel();

  return (
    <div className="user-teams">
      <PageHeader title={t('label.teams')} onRenderMenu={onRenderMenu} />
      <main className="user-teams__content">
        <Table
          columns={columns}
          items={items}
          loading={loading}
          skeletonCount={4}
          emptyState={emptyStateProps}
          onRenderCell={onRenderCell}
        />
      </main>
    </div>
  );
};
