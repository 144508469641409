import './index.css';
import { IPerformanceReport } from '../../../../types/performance';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { Avatar, Divider, Typography } from '@cycling-web/analog-ui';
import { ReactNode, useMemo } from 'react';
import { differenceInYears } from 'date-fns';
import { UTCMidnightToSameDate } from '../../../../utils/date-time';
import {
  DEFAULT_CDN_SUFFIX,
  DEFAULT_CDN_URL,
  EMPTY,
} from '../../../../constants';
import { countryCodesMapping } from '../../../../utils/country-codes-mapping';
import { useTranslation } from 'react-i18next';
import { useTableRenderMap } from '../../hooks/useTableRenderMap';

type IProps = {
  report: IPerformanceReport;
};

type ITableRecord = {
  label: string;
  value: ReactNode;
};

export const FeedbackAthleteCard = ({ report }: IProps) => {
  const { t } = useTranslation();
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const athlete = athletesMap[report.athleteId];
  const groups = athlete.group.map((g) => g.name).join(', ');
  const age = useMemo((): string => {
    return athlete?.dobTimeStampUTC
      ? differenceInYears(
          new Date(),
          UTCMidnightToSameDate(athlete.dobTimeStampUTC)
        ).toString()
      : EMPTY;
  }, [athlete?.dobTimeStampUTC]);
  const countryAlpha2Code =
    athlete?.countryCode && countryCodesMapping[athlete.countryCode]
      ? countryCodesMapping[athlete?.countryCode]
      : 'AE';
  const flagUrl = `${DEFAULT_CDN_URL}${countryAlpha2Code.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;
  const onRenderCell = useTableRenderMap();

  const table: ITableRecord[] = [
    {
      label: t('label.total_duration'),
      value: onRenderCell({ key: 'total_duration' }, report),
    },
    {
      label: t('label.elevation_gain'),
      value: report.climbInMeter
        ? `${onRenderCell({ key: 'elevation_gain' }, report)} ${t('units.m')}`
        : EMPTY,
    },
    {
      label: t('label.distance'),
      value: report.distanceInKm
        ? `${onRenderCell({ key: 'distance' }, report)} ${t('units.km')}`
        : EMPTY,
    },
    {
      label: t('label.tss'),
      value: onRenderCell({ key: 'tss' }, report),
    },
    {
      label: t('label.ctl'),
      value: onRenderCell({ key: 'ctl' }, report),
    },
    {
      label: t('label.tsb'),
      value: onRenderCell({ key: 'tsb' }, report),
    },
    {
      label: `${t('label.mcp')} (${t('units.w')}/${t('units.kg')})`,
      value: onRenderCell({ key: 'mcp' }, report),
    },
  ];

  const tableJSX = table.map((item, i: number) => {
    return (
      <div
        key={item.label}
        className={`feedback-athlete-card__table-row ${
          i % 2 === 0 ? 'even' : 'odd'
        }`}
      >
        <div className="feedback-athlete-card__table-col-label analog-typography--subtitle">
          {item.label}
        </div>
        <div className="feedback-athlete-card__table-col-value analog-typography--subtitle">
          {item.value}
        </div>
      </div>
    );
  });

  return (
    <div className="feedback-athlete-card">
      <header className="feedback-athlete-card__header">
        <Avatar size="xxl" src={athlete.picture} alt={athlete.fullName} />
        <div className="feedback-athlete-card__header-details">
          <div className="feedback-athlete-card__header-details__row">
            <Typography
              className="feedback-athlete-card__header-name"
              variant="headline"
              weight="bold"
              text={athlete.fullName}
            />
            <img
              style={{ width: '24px', borderRadius: '4px' }}
              src={flagUrl}
              alt={athlete.countryCode}
            />
          </div>
          {groups.length > 0 && (
            <Typography
              className="feedback-athlete-card__header-groups"
              variant="caption"
              weight="regular"
              text={athlete.group.map((g) => g.name).join(', ')}
            />
          )}
          <div className="feedback-athlete-card__header-stats analog-typography--subtitle">
            <div className="feedback-athlete-card__stats-item">{age} Yrs</div>
            <Divider direction="vertical" height={16} />
            <div className="feedback-athlete-card__stats-item">
              H: {athlete?.height || '--'}
            </div>
            <Divider direction="vertical" height={16} />
            <div className="feedback-athlete-card__stats-item">
              W: {athlete?.weight || '--'}
            </div>
          </div>
        </div>
      </header>

      <div className="feedback-athlete-card__table">{tableJSX}</div>
    </div>
  );
};
