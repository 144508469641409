import './index.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { ITabsItem } from '@cycling-web/analog-ui';
import { ROUTES } from '../../../../router/routes';
import { PageTabs } from '@cycling-web/common';

export const AdminPageTabs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const url = pathname.split('/');

  const items: ITabsItem[] = [
    {
      path: ROUTES.TEAM_MEMBERS,
      text: t('label.team_members'),
      isActive:
        url.at(-1) === ROUTES.TEAM_MEMBERS ||
        url.at(-2) === ROUTES.TEAM_MEMBERS ||
        url.at(-3) === ROUTES.TEAM_MEMBERS,
    },
    {
      path: ROUTES.TEAM_MAPPING,
      text: t('label.team_mapping'),
      isActive: url.at(-1) === ROUTES.TEAM_MAPPING,
    },
    // {
    //   path: ROUTES.COMMUNICATION_SETTINGS,
    //   text: t('label.communication_settings'),
    //   isActive: url.at(-1) === ROUTES.COMMUNICATION_SETTINGS,
    // },
  ];

  return <PageTabs tabs={items} />;
};
