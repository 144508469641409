import { validateGraphConfig } from '../../../SmartCharts/utils/graphConfig';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { ChartHeader } from '../../../../components/ChartHeader';
import ReactECharts from 'echarts-for-react';
import React, { useCallback, useMemo } from 'react';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import {
  ConfirmDialog,
  IconButton,
  Toast,
  useBoolean,
} from '@cycling-web/analog-ui';
import { Trash2 } from 'lucide-react';
import { IPinnedChart, IPinnedTabsFilters } from '../../types';
import { AnyValue } from '../../../../types/common';
import { SmartChartsRepository } from '../../../../api/smart-charts2/repository';
import { SmartChartsService } from '../../../../api/smart-charts2/service';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../../constants';
import { usePinnedTabStore } from '../../../../store/pinned-tabs/slice';

type IProps = {
  graph: IPinnedChart;
};

export const PinnedChart = ({ graph }: IProps) => {
  const { t } = useTranslation();
  const { pinnedTabId } = useParams();

  const filters = usePinnedTabStore((s) => s.filters);
  const setFilters = usePinnedTabStore((s) => s.setFilters);
  const handleChangeFilter = useCallback(
    (key: keyof IPinnedTabsFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  );

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  const {
    value: showDeleteUserModal,
    setTrue: openDeleteUserModal,
    setFalse: dismissDeleteUserModal,
  } = useBoolean(false);

  const handleOnDelete = useCallback((): Promise<void> => {
    if (!pinnedTabId) {
      return Promise.reject();
    }

    return smartChartsRepository
      .deletePinnedTab({
        graphId: graph.id,
        pinnedTabId: pinnedTabId,
      })
      .then(() => {
        usePinnedTabStore.getState().deleteChart(graph.id);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error({
            title: t('error.delete_pinned_chart_title'),
            message: t('error.delete_pinned_chart_message'),
          });
        }
      });
  }, [graph, pinnedTabId, smartChartsRepository, t]);

  const option = useMemo(() => {
    const tmp = { ...graph.option };
    delete tmp.title;
    return tmp;
  }, [graph]);

  const filtersBarProps = {
    filters: (
      <LastNDaysFilter
        value={filters.period}
        onChange={handleChangeFilter('period')}
        selectProps={{ variant: 'light' }}
      />
    ),
  };

  const actionsJSX = (
    <IconButton
      content={<Trash2 />}
      variant="quietNeutral"
      onClick={openDeleteUserModal}
    />
  );

  return (
    validateGraphConfig(option) && (
      <ChartWrapper minHeight="332px">
        <ChartHeader
          title={graph.option.title.text}
          filtersBarProps={filtersBarProps}
          actions={actionsJSX}
        />
        <ReactECharts opts={{ renderer: 'svg' }} option={option} />

        {showDeleteUserModal && (
          <ConfirmDialog
            onDismiss={dismissDeleteUserModal}
            title={t('confirm.delete_chart_title')}
            subtitle={t('confirm.delete_chart_subtitle')}
            submitText={t('action.delete')}
            submitAction={handleOnDelete}
            errorTitle={t('error.delete_chart_title')}
            errorMessage={t('error.delete_group_message')}
          />
        )}
      </ChartWrapper>
    )
  );
};
