import { useMemo } from 'react';
import { useDictionariesStore } from '../store/dictionaries/slice';
import { IEnums } from '../types/dictionaries';

type IBaseOption = {
  id: string;
  text: string;
};

export const useDictionaryOptions = (name: keyof IEnums): IBaseOption[] => {
  const enums = useDictionariesStore((s) => s.enums);

  return useMemo((): IBaseOption[] => {
    return Object.entries(enums[name]).map((value) => {
      return {
        id: value[0],
        text: value[1],
      };
    });
  }, [enums, name]);
};
