import * as echarts from 'echarts';

export const changeAfterRender = (chart: echarts.EChartsType) => {
  const appliedOption = chart.getOption();

  if (appliedOption.radar) {
    return;
  }

  appliedOption.grid = (
    appliedOption.grid as echarts.GridComponentOption[]
  ).map((grid) => {
    (appliedOption.yAxis as echarts.YAXisComponentOption[]).forEach((axis) => {
      if (axis.offset) {
        if (typeof grid.right === 'number' && axis.position === 'right') {
          grid.right = (grid.right || 0) + axis.offset;
        }
        if (typeof grid.left === 'number' && axis.position === 'left') {
          grid.left = (grid.left || 0) + axis.offset;
        }
      }
    });

    (appliedOption.xAxis as echarts.XAXisComponentOption[]).forEach((axis) => {
      if (axis.offset) {
        if (typeof grid.top === 'number' && axis.position === 'top') {
          grid.top = (grid.top || 0) + axis.offset;
        }
        if (typeof grid.bottom === 'number' && axis.position === 'bottom') {
          grid.bottom = (grid.bottom || 0) + axis.offset;
        }
      }
    });

    return grid;
  });

  chart.setOption(appliedOption);
};
