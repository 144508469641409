import { useViewModel } from './useViewModel';
import { Table } from '@cycling-web/analog-ui';
import { AlertTriangle } from 'lucide-react';
import { Alert } from '../Alert';
import { useTranslation } from 'react-i18next';
import { FeedbackFormModal } from '../FeedbackFormModal';

export const PerformanceTable = () => {
  const { t } = useTranslation();
  const {
    columns,
    items,
    loading,
    emptyStateProps,
    onRenderCell,
    openFeedbackModal,
    dismissFeedbackModal,
    userProfile,
    currentReport,
    reportsWithFeedback,
    unfinishedReports,
  } = useViewModel();

  return (
    <>
      {unfinishedReports.length > 0 && (
        <Alert
          className="performance-table-page__alert"
          icon={<AlertTriangle size={18} />}
          text={t('label.weekly_report_alert', {
            name: userProfile?.firstName,
          })}
          actions={[
            {
              variant: 'primary',
              size: 's',
              content: t('action.start_reporting'),
              onClick: openFeedbackModal,
            },
          ]}
        />
      )}
      <Table
        columns={columns}
        items={items}
        loading={loading}
        skeletonCount={4}
        emptyState={emptyStateProps}
        onRenderCell={onRenderCell}
      />

      {currentReport && (
        <FeedbackFormModal
          report={currentReport}
          onDismiss={dismissFeedbackModal}
          reports={reportsWithFeedback}
          singleReport={false}
        />
      )}
    </>
  );
};
