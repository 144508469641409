import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { useGroupsStore } from '../store/groups/slice';
import { Toast } from '@cycling-web/analog-ui';
import { GroupsRepository } from '../api/groups/repository';
import { GroupsService } from '../api/groups/service';
import { IGroup } from '../types/groups';
import { ApiErrorCode } from '../constants';
import { AxiosError } from 'axios';

export const useFetchGroups = () => {
  const { t } = useTranslation();
  const fetching = useRef<boolean>(false);
  const setGroups = useGroupsStore((s) => s.setGroups);
  const setGroupsLoaded = useGroupsStore((s) => s.setGroupsLoaded);
  const groupsLoaded = useGroupsStore((s) => s.groupsLoaded);

  const groupsRepository = useMemo(() => {
    return new GroupsRepository(new GroupsService());
  }, []);

  useEffect(() => {
    if (fetching.current) {
      return;
    }

    fetching.current = true;

    groupsRepository
      .getGroups()
      .then((groups: IGroup[]) => {
        setGroups(groups);
      })
      .catch((error: AxiosError) => {
        setGroupsLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_groups_title'),
              message: t('error.get_groups_message'),
            },
            { toastId: 'get_groups' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [setGroupsLoaded, groupsRepository, t, setGroups]);

  return {
    groupsLoaded,
  };
};
