import { IGroup, IGroupsFilters } from '../../../types/groups';

export const filterGroupDelegate = (filters: IGroupsFilters) => {
  return (group: IGroup) => {
    let valid = true;

    if (
      filters.search &&
      !group.name.toLowerCase().includes(filters.search.toLowerCase())
    ) {
      valid = false;
    }

    return valid;
  };
};
